import { useQuery } from '@tanstack/react-query';
import { healthStatusService } from '../service';
import { toast } from 'react-toastify';
import { Box, MantineTheme, Sx, useMantineTheme } from '@mantine/core';

const useHealthCheckStatus = () => {
  const theme = useMantineTheme();

  const styles = (theme: MantineTheme): Sx => {
    return {
      display: 'flex',
      flexDirection: 'column',
      '.title': {
        fontWeight: 600,
        fontSize: '16px',
        color: theme.colors.dark,
      },
      '.message': {
        fontWeight: 400,
        fontSize: '14px',
        color: theme.colors.dark,
      },
    };
  };

  const getPayload = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 5);
    return {
      start: now.toISOString(),
      end: new Date().toISOString(),
    };
  };

  useQuery({
    queryKey: ['get-integrator-status'],
    queryFn: () => healthStatusService.fetchLatestHealthStatus(getPayload()),
    onSuccess: (data) => {
      if (data?.healthStatuses?.length > 0) {
        toast(
          <Box sx={styles(theme)}>
            <div className="title">{`An incident has occurred`}</div>
            <div className="message">{`Apologies for any service disruptions, we are aware and investigating the issue. Once resolved, the notification will no longer be displayed, thank you for your patience and understanding.`}</div>
          </Box>,
          {
            position: 'top-center',
            autoClose: 60000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
            pauseOnFocusLoss: false,
            style: {
              background: theme.colors.yellow[5],
            },
          },
        );
      }
    },
    onError: (error: any) => {},
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });
};

export default useHealthCheckStatus;
