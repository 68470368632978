import { MantineTheme, Sx } from '@mantine/core';

export const capabilityRowStyles = (theme: MantineTheme): Sx => {
  return {
    '.description': {
      fontSize: '14px',
      color: theme.colors.grubtech[4],
      marginLeft: '2rem',
    },
    '.asterix': {
      fontSize: '16px',
      color: theme.colors.red[5],
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  };
};
