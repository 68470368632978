import { SandboxCreationFormData, SandboxSummaryDto } from '../../models';
import { actionCreator } from '../common';
import { SandboxActionTypes } from '../types';

export const sandboxActionCreator = {
  changeMenudata: actionCreator<SandboxCreationFormData | null>(
    SandboxActionTypes.CHANGE_MENU_DATA,
  ),
  changeEditingMenuId: actionCreator<string | null>(SandboxActionTypes.CHANGE_EDITING_MENU_ID),
  changeSandboxSummaryData: actionCreator<SandboxSummaryDto[]>(
    SandboxActionTypes.CHANGE_SANDBOX_SUMMARY_DATA,
  ),
  newSandboxCreated: actionCreator<boolean>(SandboxActionTypes.NEW_SANDBOX_CREATED),
  retrySandboxCreation: actionCreator<boolean>(SandboxActionTypes.RETRY_SANDBOX_CREATION),
  toggleIsEditingSandbox: actionCreator<string | null>(
    SandboxActionTypes.TOGGLE_IS_EDITING_SANDBOX,
  ),
};
