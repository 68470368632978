export enum IntegrationActionTypes {
  CHANGE_INTEGRATION_DEFINITIONS = 'CHANGE_INTEGRATION_DEFINITIONS',
  CHANGE_ACTIVATED_INTEGRATIONS = 'CHANGE_ACTIVATED_INTEGRATIONS',
  CHANGE_UPDATE_ACTIVATED_INTEGRATIONS = 'CHANGE_UPDATE_ACTIVATED_INTEGRATIONS',
  CHNAGE_INTEGRATION_PDF_PAGES_COUNT = 'CHNAGE_INTEGRATION_PDF_PAGES_COUNT',
  CHANGE_IS_AGREE_TO_CONDITIONS = 'CHANGE_IS_AGREE_TO_CONDITIONS',
  CHANGE_CAPABILITY = 'CHANGE_CAPABILITY',
  CHANGE_ACTIVATING_FLOW = 'CHANGE_ACTIVATING_FLOW',
  CHANGE_ACTIVATED_PROJECT = 'CHANGE_ACTIVATED_PROJECT',
}
