export const convertEnumToString = (value: string) => {
  const str = value
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/api/g, 'API')
    .replace(/web hooks/g, 'webhooks')
    .replace(/contract/g, 'NDA');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatAddressFieldLabel = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).replace(/([A-Z0-9])/g, ' $1');
};

export const urlValidationRegex = /^(http|https):\/\//;
export const noSpecialCharactersRegex = /^[A-Za-z0-9\s]+$/;
export const noSpacesRegex = /^[A-Za-z0-9]+$/;

export const generateFileName = (
  file: any,
  organisationId: string,
  testCaseInstanceId: string,
  stepId: string,
  imageDefinitionId: string,
): string => {
  const filePath = file.path.split('.');
  return `${organisationId}/tests/${testCaseInstanceId}/steps/${stepId}/${imageDefinitionId}/${imageDefinitionId}.${
    file.path.split('.')[filePath.length - 1]
  }`;
};

export const endPointReplacer = (url: string, resolvers: Record<string, string>) => {
  let urlLocal = url;
  for (const key in resolvers) {
    urlLocal = urlLocal.replace(key, resolvers[key]);
  }
  return urlLocal;
};

export const CAPABILITY_KEY_SEPERATOR = '|';

export const concatParentChildKey = (parent = '', child: string) => {
  if (parent.trim()) {
    return parent + CAPABILITY_KEY_SEPERATOR + child;
  } else {
    return child;
  }
};
export const formatDateTime = (isoString: string) => {
  if (isoString) {
    const date = new Date(isoString);

    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const timeFormatOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZoneName: 'short',
    };

    const formattedDate = date.toLocaleDateString(undefined, dateFormatOptions);
    const formattedTime = date.toLocaleTimeString(undefined, timeFormatOptions);
    return `${formattedDate} ${formattedTime}`;
  }
  return '';
};
export const getStatusString = (status: string) => {
  return status
    .toLowerCase()
    .replaceAll('_', ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
