import {
  commonAxios,
  organizationAxios,
  userAxios,
  userOnboardingAxios,
} from '../config/axios.config';
import {
  AddressFieldWithCountryAddressId,
  Organization,
  User,
  UserOnBoardingProgress,
} from '../models';

const organizationService = {
  getOrganization: async (organizationId: string): Promise<Organization> => {
    const { data } = await organizationAxios.get(`/byId/${organizationId}`);
    return data;
  },
  getInvitationCode: async (organizationId: string): Promise<string> => {
    const { data } = await organizationAxios.get(`/invitation-code/${organizationId}`);
    return data;
  },
  getAddressFieldsByCountry: async (
    countryId: string,
  ): Promise<AddressFieldWithCountryAddressId> => {
    const { data } = await commonAxios.get(`/address-fields-by-country-id/${countryId}`);
    return data;
  },
  updateOrganization: async (organization: Organization): Promise<Organization> => {
    const { data } = await organizationAxios.put('', organization);
    return data;
  },
  getCompanyUsers: async (organizationId: string): Promise<User[]> => {
    const { data } = await userAxios.get(`/company-users/byOrganizationId/${organizationId}`);
    return data;
  },

  createOrganization: async (payload: {
    organization: Organization;
    userId: string;
  }): Promise<Organization> => {
    const { data } = await organizationAxios.post(
      `/userId/${payload.userId}`,
      payload.organization,
    );
    return data;
  },

  joinForExistingOrganisation: async (invitationCode: string): Promise<UserOnBoardingProgress> => {
    const { data } = await userOnboardingAxios.patch(
      `/submission/organization-invitation/${invitationCode}`,
    );
    return data;
  },
};

export default organizationService;
