import { FC, useState } from 'react';
import { Box, Button, Menu, useMantineTheme } from '@mantine/core';
import { languageChangerStyles } from './styles';
import { IconCircle, IconCircleCheckFilled, IconWorld } from '@tabler/icons-react';

export type LanguageChangerProps = {
  id?: string;
};

const languagePlaceHolders = {
  en: {
    name: 'English',
  },
  ar: {
    name: 'عربي',
  },
};

const LanguageChanger: FC<LanguageChangerProps> = ({ id }) => {
  const theme = useMantineTheme();
  const [selectedLang, setSelectedLang] = useState<keyof typeof languagePlaceHolders>('en');

  return (
    <Box sx={{ ...languageChangerStyles(theme) }}>
      <Menu id={id}>
        <Menu.Target>
          <Button variant="unstyled" className={`languageChanger__button`} leftIcon={<IconWorld />}>
            {languagePlaceHolders[selectedLang].name}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {Object.keys(languagePlaceHolders).map((language) => (
            <Menu.Item
              onClick={() => {
                setSelectedLang(language as keyof typeof languagePlaceHolders);
              }}
            >
              <Box
                className={`languageChanger__language ${
                  selectedLang === language
                    ? 'languageChanger__language--selected'
                    : 'languageChanger__language--default'
                }`}
              >
                {selectedLang === language ? (
                  <IconCircleCheckFilled width={18} />
                ) : (
                  <IconCircle width={18} />
                )}
                {languagePlaceHolders[language as keyof typeof languagePlaceHolders].name}
              </Box>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

export default LanguageChanger;
