import { Sx, MantineTheme } from '@mantine/core';

export const repoDetailsStyles = (theme: MantineTheme): Sx => {
  return {
    '.child-view': {
      padding: 0,
    },
    '.project-setting-paper': {
      backgroundColor: 'transparent !important',
      border: 0,
      boxShadow: 'none',
      '.project-setting-content': {
        gap: theme.spacing.xs,
        '.project-setting-section': {
          backgroundColor: theme.white,
          paddingLeft: theme.spacing.lg,
          paddingRight: theme.spacing.lg,
          paddingBottom: theme.spacing.lg,

          '.repo-request-status': {
            display: 'flex',
            marginTop: theme.spacing.sm,
            fontSize: theme.fontSizes.sm,

            '.repo-slug': {
              fontWeight: 'bold',
              fontStyle: 'italic',
              marginLeft: theme.spacing.sm,
            },
            '.repo-request-status-lable': {
              width: '100px',
              '.repo-status': {
                '.text-description': {
                  textTransform: 'uppercase',
                },
              },
            },
          },
        },
        '.button-section': {
          padding: 0,
          marginRight: 10,
          '.rquest-btn-container': {
            backgroundColor: theme.white,
            padding: theme.spacing.xs,
            '.request-repository-button': {
              height: theme.spacing.xl,
              width: '100%',
              fontSize: theme.fontSizes.xs,
            },
          },
        },
      },
    },

    '.project-setting-alert': {
      marginBottom: theme.spacing.xl,
    },
  };
};
