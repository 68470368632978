import { MantineTheme, Sx } from '@mantine/core';

export const logsContainerStyles = (theme: MantineTheme): Sx => {
  return {
    display: 'flex',
    width: '100%',
    height: '268px',
    padding: '5px 16px 24px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flexShrink: 0,
    borderRadius: '0px 0px 8px 8px',
    background: theme.colors.white[0],
    boxShadow:
      '0px 4px 8px 0px rgba(32, 35, 45, 0.03), 0px 2px 4px 0px rgba(32, 35, 45, 0.02), 0px 1px 2px 0px rgba(32, 35, 45, 0.01)',

    '.logsContainer__title': {
      marginBottom: theme.spacing.xs,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      color: theme.colors.font[0],
    },
    '.logsContainer__textContainer': {
      borderRadius: '4px',
      width: '100%',
    },
    '.logsContainer__copyContainer': {
      marginTop: theme.spacing.sm,
    },
    '.logsContainer__copyButton': {
      backgroundColor: theme.colors.white[0],
      border: `1px solid ${theme.colors.grubtech[0]}`,
    },
  };
};

export const logsWindowStyles = (theme: MantineTheme): Sx => {
  return {
    height: '143px',
    backgroundColor: theme.colors.background[0],
    padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    overflow: 'scroll',
    overflowX: 'hidden',

    '.invocation-id': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.colors.grubtech[5],
    },
  };
};
