import { MantineTheme, Sx } from '@mantine/core';

export const forgotPasswordStyles = (theme: MantineTheme): Sx => {
  return {
    '.forgotPasswordPage__checkMarkIcon': {
      width: '120px',
      height: '120px',
      marginBottom: theme.spacing.xl,
    },
  };
};
