import { Box, Button, Text, Title, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { definitionSettingsCardStyles } from './style';
import { OrganizationIntegrationParameters, ParameterTypeTexts } from '../../../models';
import { useNavigate } from 'react-router-dom';

export type DefinitionSettingsCardProps = {
  summary: OrganizationIntegrationParameters;
  projectId: string;
};

const DefinitionSettingsCard: FC<DefinitionSettingsCardProps> = ({ summary, projectId }) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const configureIntegration = () => {
    navigate(
      `/projects/${projectId}/definition-settings/parameters/${summary.integration.id.toLowerCase()}`,
    );
  };

  const getEndpointUrl = (url: string) => {
    return url || 'N/A';
  };
  return (
    <Box
      sx={definitionSettingsCardStyles(theme)}
      id={summary.integration.id}
      className="content"
      style={summary.isDisabled ? { pointerEvents: 'none', opacity: '0.6' } : {}}
    >
      <Title order={3} className="title" data-cy={summary.name}>
        {summary.name}
      </Title>
      <Text className="definition">{summary.description}</Text>
      {summary.parameterMap && Object.keys(summary.parameterMap).length > 0 && (
        <div className="sub-status">
          <Title order={6} className="status-title">
            {'Global Settings'}
          </Title>
          <div className="sub-status-content">
            <div className="sub-status-title-container">
              <Text className="sub-status-title">Field</Text>
              <Text className="sub-status-title">Type</Text>
            </div>
            {Object.entries(summary.parameterMap).map(([key, paramInfo]) => (
              <div className="sub-status-data-container">
                <Text className="sub-status-element-text">{key}</Text>
                <Text className="sub-status-element-text">
                  {ParameterTypeTexts[paramInfo.type]}
                </Text>
              </div>
            ))}
          </div>
        </div>
      )}
      {summary.customApplicationUrl && (
        <div className="sub-status">
          <Title order={6} className="status-title">
            {'Custom application URL'}
          </Title>
          <div className="sub-status-content">
            <div className="sub-status-title-container">
              <Text className="sub-status-title">Field</Text>
              <Text className="sub-status-title">URL</Text>
            </div>

            <>
              <div className="sub-status-data-container">
                <Text className="sub-status-element-text">{'Partner'}</Text>
                <Text className="sub-status-element-text">
                  {getEndpointUrl(summary.customApplicationUrl.partnerApplicationUrl)}
                </Text>
              </div>
              <div className="sub-status-data-container">
                <Text className="sub-status-element-text">{'Brand'}</Text>
                <Text className="sub-status-element-text">
                  {getEndpointUrl(summary.customApplicationUrl.brandApplicationUrl)}
                </Text>
              </div>
              <div className="sub-status-data-container">
                <Text className="sub-status-element-text">{'Location'}</Text>
                <Text className="sub-status-element-text">
                  {getEndpointUrl(summary.customApplicationUrl.locationApplicationUrl)}
                </Text>
              </div>
              <div className="sub-status-data-container">
                <Text className="sub-status-element-text">{'Channel'}</Text>
                <Text className="sub-status-element-text">
                  {getEndpointUrl(summary.customApplicationUrl.channelApplicationUrl)}
                </Text>
              </div>
            </>
          </div>
        </div>
      )}
      <div className="button-section">
        <Button
          className="configure-button"
          data-cy={`configure-button-${summary?.integration ?? ''}`}
          onClick={() => {
            configureIntegration();
          }}
          fullWidth
        >
          Configure
        </Button>
      </div>
    </Box>
  );
};

export default DefinitionSettingsCard;
