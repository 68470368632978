import { bitbucketAxios } from '../config/axios.config';
import { BitBucketPermission } from '../models/bitbucket';
const bitbucketService = {
  getUserPermissionList: async (
    organizationId: string,
    projectId: string,
    repoName: string,
  ): Promise<BitBucketPermission[]> => {
    const { data } = await bitbucketAxios.get(
      `/repo-permission/organizations/${organizationId}/projects/${projectId}/repo-name/${repoName}`,
    );
    return data;
  },
  permissionRequest: async (data: BitBucketPermission): Promise<BitBucketPermission> => {
    return await bitbucketAxios.post(`/repo-permission`, data);
  },
  permissionGranted: async (id: string, permission: string): Promise<BitBucketPermission> => {
    return await bitbucketAxios.put(
      `/repo-permission/${id}/grant-permission?permission=${permission}`,
    );
  },
  revokeRequest: async (id: string): Promise<any> => {
    return await bitbucketAxios.put(`/repo-permission/${id}/revoke-permission`);
  },
  checkRequestStatus: async (id: string, email: string): Promise<BitBucketPermission> => {
    return await bitbucketAxios.get(`/repo-permission/${id}/user-status?email=${email}`);
  },
};
export default bitbucketService;
