import { FC } from 'react';
import {
  Modal,
  Title,
  List,
  Divider,
  Flex,
  Button,
  useMantineTheme,
  Text,
  Box,
} from '@mantine/core';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { inviteModalStyles } from './style';

type InviteModalProps = {
  id: string;
  opened: boolean;
  close: () => void;
  invitationCode: string;
};

const InviteModal: FC<InviteModalProps> = ({ opened, close, invitationCode }) => {
  const theme = useMantineTheme();

  return (
    <>
      <Modal
        size={'lg'}
        opened={opened}
        onClose={() => {
          close();
        }}
        title={
          <>
            <Title order={3}>Invite new team member(s)</Title>
          </>
        }
        padding={theme.spacing.xl}
        centered
      >
        <Box sx={inviteModalStyles(theme)} id={'invite-modal'} className="content">
          <Divider className="title-divider" />
          <Text size={'md'} weight={'bold'} color="black">
            How to invite:
          </Text>
          <List type="ordered">
            <List.Item>Copy the invitation code below</List.Item>
            <List.Item>Send it to your team member</List.Item>
          </List>
          <Box className="generated-code-box">{invitationCode}</Box>
          <Box className="warning-text">
            <IconInfoCircleFilled className="info-icon" size={30} />
            Your team member will need to create an account for the Portal, then use this unique
            code to join your organization.
          </Box>
          <Divider className="button-divider" />
          <Flex justify={'flex-end'} mt={24}>
            <Button
              sx={{
                color: '#fff',
                border: `1px solid ${theme.colors.grubtech[0]}`,
                fontWeight: 500,
              }}
              onClick={() => {
                navigator.clipboard.writeText(invitationCode);
              }}
            >
              Copy invitation code
            </Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

export default InviteModal;
