import { AddressFieldWithCountryAddressId, City, Country, Organization, User } from '../../models';
import { actionCreator } from '../common';
import { OrganizationActionTypes } from '../types/organization';

export const organizationActionCreator = {
  changeOrganization: actionCreator<Organization>(OrganizationActionTypes.CHANGE_ORGANIZATION),
  changeInvitationCode: actionCreator<string>(OrganizationActionTypes.CHANGE_INVITATION_CODE),
  changeCountries: actionCreator<Country[]>(OrganizationActionTypes.CHANGE_COUNTRIES),
  changeAddressFields: actionCreator<AddressFieldWithCountryAddressId>(
    OrganizationActionTypes.CHANGE_ADDRESS_FIELD,
  ),
  changeUpdateOrganization: actionCreator<Organization>(
    OrganizationActionTypes.CHANGE_UPDATE_ORGANIZATION,
  ),
  changeCompanyUsers: actionCreator<User[]>(OrganizationActionTypes.CHANGE_COMPANY_USERS),
  changeCreateOrganization: actionCreator<Organization>(
    OrganizationActionTypes.CHANGE_CREATE_ORGANIZATION,
  ),
  changeCities: actionCreator<City[]>(OrganizationActionTypes.CHANGE_CITIES),
};
