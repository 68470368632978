import { Box, Text, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { IconChevronLeft } from '@tabler/icons-react';
import { backTextStyles } from './styles';

type BackTextProps = {
  backText?: string;
  to: LinkProps['to'];
};

const BackText: FC<BackTextProps> = ({ to = '/', backText = 'Back' }) => {
  const theme = useMantineTheme();

  return (
    <Box sx={backTextStyles(theme)}>
      <Link className="backText" to={to}>
        <IconChevronLeft size={24} />
        <Text className="backText__text" size={20} fw={500} data-cy="navigate-back-text">
          {backText}
        </Text>
      </Link>
    </Box>
  );
};

export default BackText;
