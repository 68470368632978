import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { IconArrowRight, IconExternalLink, IconLock } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { customLinkStyles } from './style';

interface LinkProps {
  to: string;
  target?: string;
  text: string;
  withIcon?: boolean;
  centered?: boolean;
  inline?: boolean;
  dataCy?: string;
  isDisabled?: boolean;
}

const CustomLink: React.FC<LinkProps> = ({
  to,
  target = '_self',
  text,
  withIcon = false,
  centered = false,
  inline = false,
  dataCy = 'custom-link',
  isDisabled = false,
}) => {
  const theme = useMantineTheme();
  return (
    <Box sx={customLinkStyles(theme, isDisabled)} display={inline ? 'inline-block' : 'block'}>
      <Link to={isDisabled ? '' : to} target={target} className="custom-link" data-cy={dataCy}>
        {withIcon ? (
          <Flex align="center" justify={centered ? 'center' : 'flex-start'} gap={5}>
            <Text size={'sm'}>{text}</Text>
            {target === '_blank' ? (
              <IconExternalLink size={theme.fontSizes.xl} />
            ) : isDisabled ? (
              <IconLock size={20} className="sidebar-link-lock" />
            ) : (
              <IconArrowRight size={theme.fontSizes.xl} />
            )}
          </Flex>
        ) : (
          <Text>{text}</Text>
        )}
      </Link>
    </Box>
  );
};

export default CustomLink;
