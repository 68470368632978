import { MantineTheme, Sx } from '@mantine/core';

export const integrationParametersStyles = (theme: MantineTheme): Sx => {
  return {
    '.segmented-control-lbl-active': {
      color: theme.colors.white[0],
    },
    '.parameters-segmented-control': {
      marginTop: '26px',
    },
    '.parameter-container': {
      display: 'flex',
      width: '100%',
      height: '100%',
      background: 'white',
      marginTop: '3px',
      padding: '16px',
      flexDirection: 'column',
    },
    '.description': {
      padding: '12px 24px',
      background: 'white',
    },
    '.title-container': {
      display: 'flex',
      width: '100%',
      paddingLeft: '8px',
      marginBottom: '13px',
      marginTop: '13px',
    },
    '.field-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
    },
    '.type-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
    },
    '.value-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
    },
    '.global-settings-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      paddingLeft: '8px',
    },
    '.global-settings-container': {
      width: '100%',
    },
    '.global-settings-content-container': {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      padding: '16px',
    },
    '.field-input': {
      paddingRight: '5px',
    },
    '.type-dropdown': {
      paddingRight: '5px',
    },
    '.value-input': {},
    '.trash-icon': {
      marginLeft: 'auto',
    },
    '.integration-settings-container': {
      width: '100%',
      marginTop: '20px',
    },
    '.integration-settings-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      paddingLeft: '8px',
      marginBottom: '20px',
    },
    '.integration-settings-row': {
      display: 'flex',
      paddingLeft: '8px',
      marginBottom: '16px',
    },
    '.integration-settings-checkbox': {
      flexBasis: '30%',
    },
    '.custom-application-url-container': {
      width: '100%',
      marginTop: '15px',
      paddingLeft: '8px',
    },
    '.custom-application-url-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '5px',
    },
    '.custom-application-row': {
      display: 'flex',
      width: '30%',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    '.custom-application-url-description': {
      marginBottom: '16px',
    },
    '.button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.save-button': {
      marginLeft: '10px',
    },
  };
};
