import { Box, useMantineTheme } from '@mantine/core';
import { snackbarStyles } from './styles';
import { TestCaseStatus } from '../../models';
import { FC, ReactNode, useCallback } from 'react';
import { IconClockCancel, IconHelpCircle } from '@tabler/icons-react';
import { pass, error } from '../../assets/images';
import { snackBarIconPending } from '../../assets/images/testFramework';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import documentationService from '../../service/documentation.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';

type SnackBarProps = {
  id: string;
  type: TestCaseStatus | undefined;
  title?: ReactNode;
  description?: ReactNode;
  documentationUrl?: string | null;
};

const SnackBar: FC<SnackBarProps> = ({ id, type, title, documentationUrl, description }) => {
  const theme = useMantineTheme();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const documentationAuthRequest = useMutation({
    mutationFn: (payload: { email: string; url: string }) => {
      return documentationService.getDocumentationAuth(payload.email);
    },
    onSuccess: (data, initialValues) => {
      if (!!data && data?.authToken) {
        window.open(`${initialValues.url}?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getIcon = useCallback(() => {
    switch (type) {
      case TestCaseStatus.SUCCESS:
        return <img src={pass} alt={'success'} />;
      case TestCaseStatus.PENDING:
        return <img src={snackBarIconPending} alt={'pending'} />;
      case TestCaseStatus.FAILED:
        return <img src={error} alt={'error'} />;
      case TestCaseStatus.TIMED_OUT:
        return <IconClockCancel size={25} />;
    }
  }, [type]);

  return (
    <Box sx={snackbarStyles(theme, type, title ? true : false)} id={id}>
      <div className="icon">{getIcon()}</div>
      <div className="description-content">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>

      {documentationUrl && (
        <div className="document-url-icon">
          <IconHelpCircle
            size={18}
            onClick={() => {
              documentationAuthRequest.mutate({ email: userData.email, url: documentationUrl });
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default SnackBar;
