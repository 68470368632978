import { MantineTheme, Sx } from '@mantine/core';

export const productionDeploymentStyle = (theme: MantineTheme): Sx => {
  return {
    '.deployment-table-header': {
      marginTop: theme.spacing.md,
      backgroundColor: theme.colors.white[0],
    },
    '.table-row-status': {
      textTransform: 'capitalize',
    },
  };
};
