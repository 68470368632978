import {
  Box,
  Flex,
  Paper,
  Select,
  Table,
  TextInput,
  useMantineTheme,
  Text,
  Button,
} from '@mantine/core';
import { IconChevronDown, IconClipboard, IconSearch } from '@tabler/icons-react';
import { logsStyles } from './style';
import { DatePickerInput } from '@mantine/dates';

const Logs: React.FC = () => {
  const theme = useMantineTheme();

  //temp data
  const logsData = [];

  return (
    <Box sx={logsStyles(theme)}>
      <Flex align={'center'} justify={'space-between'}>
        <Select
          className="logs-env-select"
          data={[
            { value: 'production', label: 'Environment: Production' },
            { value: 'sandbox', label: 'Environment: Sandbox' },
          ]}
          rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
          defaultValue="production"
          data-cy="logs-env-select"
        />
        <DatePickerInput
          numberOfColumns={2}
          type="range"
          allowSingleDateInRange
          data-cy="logs-date-range"
        />
      </Flex>
      <Flex align={'center'} gap={theme.spacing.xs} className="logs-filters">
        <Select
          className="logs-select"
          data={[
            { value: 'all', label: 'API: All' },
            { value: 'order-api', label: 'API: Order API' },
            { value: 'menu-api', label: 'API: Menu API' },
            { value: 'signature-api', label: 'API: Signature API' },
            { value: 'sites-api', label: 'API: Sites API' },
            { value: 'picking-api', label: 'API: Picking API' },
          ]}
          rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
          defaultValue="all"
          data-cy="logs-api-select"
        />
        <Select
          className="logs-select"
          data={[
            { value: 'all', label: 'Sites: All' },
            { value: 'site-1', label: 'Sites: Site 1' },
            { value: 'site-2', label: 'Sites: Site 2' },
            { value: 'site-3', label: 'Sites: Site 3' },
            { value: 'site-4', label: 'Sites: Site 4' },
            { value: 'site-5', label: 'Sites: Site 5' },
          ]}
          rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
          defaultValue="all"
          data-cy="logs-sites-select"
        />
        <Select
          className="logs-select"
          data={[
            { value: 'all', label: 'Events: ALL' },
            { value: 'EventWebhookOrderNew', label: 'Events: EventWebhookOrderNew' },
            { value: 'EventWebhookOrderUpdate', label: 'Events: EventWebhookOrderUpdate' },
          ]}
          rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
          defaultValue="all"
          data-cy="logs-events-select"
        />
        <Select
          className="logs-select"
          data={[
            { value: 'both', label: 'Success: Both' },
            { value: 'true', label: 'Success: True' },
            { value: 'false', label: 'Success: False' },
          ]}
          rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
          defaultValue="both"
          data-cy="logs-success-select"
        />
        <TextInput
          className="logs-search"
          placeholder="Search by any field"
          icon={<IconSearch size={14} stroke={1.5} />}
          data-cy="logs-search"
        />
      </Flex>
      <Paper shadow="xs" className="logs-paper">
        <Table className="logs-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Site</th>
              <th>Event</th>
              <th>Status Code</th>
              <th>Error Message</th>
            </tr>
          </thead>
          {logsData.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                    <IconClipboard size={48} color={theme.colors.gray[5]} />
                    <Text size={'sm'}>No events found for your search</Text>
                    <Flex justify={'center'} gap={theme.spacing.xs}>
                      <Button variant="outline" data-cy="empty-refresh-btn">
                        Refresh
                      </Button>
                      <Button className="reset-btn" variant="outline" data-cy="empty-reset-btn">
                        Reset
                      </Button>
                    </Flex>
                  </Flex>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </Table>
      </Paper>
    </Box>
  );
};

export default Logs;
