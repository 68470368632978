import { MantineTheme, Sx } from '@mantine/core';

export const projectTypeCardStyles = (theme: MantineTheme): Sx => {
  return {
    // General Card Style
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.sm,
    padding: theme.spacing.md,
    marginBottom: theme.spacing.md,
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
    '&.selected': {
      backgroundColor: theme.colors.blue[0],
    },

    // New Section for Project Type Cards
    '#partner-platform-card': {
      border: `1px solid ${theme.colors.gray[3]}`,
      borderRadius: theme.radius.sm,
      padding: theme.spacing.md,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        borderColor: theme.colors.blue[6],
        boxShadow: theme.shadows.md,
      },
      '.api-suite-title': {
        fontSize: theme.fontSizes.lg,
        fontWeight: 600,
        marginBottom: theme.spacing.xs,
      },
      '.api-suite-description': {
        fontSize: theme.fontSizes.sm,
        color: theme.colors.gray[6],
      },
      '.api-suite-includes': {
        marginTop: theme.spacing.md,
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,
      },
      '.go-button': {
        marginTop: theme.spacing.md,
        display: 'flex',
        justifyContent: 'flex-end',
        '.mantine-Button': {
          backgroundColor: theme.colors.blue[6],
          color: theme.white,
          '&:hover': {
            backgroundColor: theme.colors.blue[7],
          },
        },
      },
    },
  };
};
