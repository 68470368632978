import { Box, Text, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { navigationTextStyles } from './styles';

type NavigationTextProps = {
  navigationText: string;
  to: LinkProps['to'];
  questionText?: string;
  align?: 'center' | 'end' | 'start';
};

const NavigationText: FC<NavigationTextProps> = ({
  to = '/',
  questionText,
  navigationText = 'Sign Up',
  align = 'center',
}) => {
  const theme = useMantineTheme();

  return (
    <Box sx={{ ...navigationTextStyles(theme), justifyContent: align }}>
      {questionText && (
        <Text fw={400} className="navigationText__question" color={theme.colors.dark[2]}>
          {questionText}
        </Text>
      )}
      <Link className="navigationText__navigation" to={to}>
        <Text fw={400}>{navigationText}</Text>
      </Link>
    </Box>
  );
};

export default NavigationText;
