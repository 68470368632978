import { Button, Modal, Textarea, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { skipConfirmationModalStyles } from './styles';
import { skipModalImage } from '../../../../../assets/images/testFramework';
import { useFormik } from 'formik';
import { string as validateString, object as validateObject } from 'yup';

export type SkipConfirmationModalProps = {
  id: string;
  opened: boolean;
  close: () => void;
  handleSubmit: (reason: string) => void;
};

const SkipConfirmationModal: FC<SkipConfirmationModalProps> = ({
  id,
  opened,
  close,
  handleSubmit,
}) => {
  const theme = useMantineTheme();

  const { values, errors, isValid, touched, handleBlur, handleChange } = useFormik({
    initialValues: { comment: '' },
    onSubmit: () => {
      return;
    },
    validationSchema: validateObject().shape({
      comment: validateString().trim().required('Reason is required'),
    }),
    validateOnMount: true,
  });

  return (
    <Modal
      sx={skipConfirmationModalStyles(theme)}
      id={id}
      opened={opened}
      onClose={close}
      title="lol"
      centered
      size="lg"
    >
      <img src={skipModalImage} alt={'skip-modal'} />
      <div className="text-info">Are you sure you want to skip this</div>
      <div className="text-info">scenario?</div>
      <Textarea
        placeholder="Type here"
        label="Add the reason to skip"
        name="comment"
        withAsterisk
        minRows={5}
        maxRows={6}
        value={values.comment}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.comment && errors.comment}
      />
      <div className="devider"></div>
      <div className="button-container">
        <Button size="lg" variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button size="lg" onClick={() => handleSubmit(values.comment)} disabled={!isValid}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default SkipConfirmationModal;
