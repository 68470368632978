import {
  Box,
  Paper,
  Grid,
  Button,
  useMantineTheme,
  TextInput,
  LoadingOverlay,
  ThemeIcon,
  Flex,
  Text,
} from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { repoDetailsStyles } from './style';
import { useMutation } from '@tanstack/react-query';
import repositoryService from '../../../service/repository.service';
import { AuthenticationState } from '../../../redux/reducers';
import { RootState } from '../../../redux/common';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  RepoRequest,
  RepoRequestStatus,
  RepositoryStatus,
  RepositoryStatusStep,
} from '../../../models/repository';
import { notifications } from '@mantine/notifications';
import { IconBan, IconCircleCheck } from '@tabler/icons-react';
import { pendingIconActiveStatue } from '../../../assets/images';

export interface RepoDetailsProps {
  projectName: string;
  suite: string;
  repositoryRequest: RepoRequestStatus | null;
  isRepoCreated: boolean;
  refresh: () => void;
}
const RepoDetails: FC<RepoDetailsProps> = ({
  projectName,
  suite,
  repositoryRequest,
  isRepoCreated,
  refresh,
}) => {
  const theme = useMantineTheme();
  const [repoName, setRepoName] = useState('');

  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const { projectId } = useParams();

  function removeNumbersAndSpecialCharacters(input: string) {
    return input.replace(/[^a-zA-Z\s]/g, '').toLowerCase();
  }

  useEffect(() => {
    if (repositoryRequest && repositoryRequest.repositoryName) {
      setRepoName(repositoryRequest.repositoryName);
    } else {
      setRepoName(
        `${removeNumbersAndSpecialCharacters(projectName)}-${suite.toLowerCase()}-integration`,
      );
    }
  }, [projectName, suite, repositoryRequest]);

  const requestRepository = useMutation({
    mutationFn: (payload: { organizationId: string; projectId: string; request: RepoRequest }) => {
      return repositoryService.requestRepository(
        payload.organizationId,
        payload.projectId,
        payload.request,
      );
    },
    onSuccess: () => {
      notifications.show({
        title: 'Successfully requested repository',
        message: '',
        color: 'green',
      });
      refresh();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const onClickRequestRepository = () => {
    requestRepository.mutate({
      organizationId: userData.organizationId,
      projectId: projectId ?? '',
      request: {
        repoName: repoName,
        projectName: removeNumbersAndSpecialCharacters(projectName),
        integrationId: {
          id: suite,
        },
      },
    });
  };
  const getStatus = (repositoryStatuses: RepositoryStatus[]) => {
    if (
      repositoryStatuses.some((status) => status.status === RepositoryStatusStep.REPOSITORY_CREATED)
    ) {
      return (
        <Flex ml={85} gap={'xs'} justify="space-between" className="repo-status">
          <IconCircleCheck color="green" size="1rem" />
          <Text color={'green'} className="text-description">
            Created
          </Text>
        </Flex>
      );
    } else if (
      repositoryStatuses.some(
        (status) => status.status === RepositoryStatusStep.REPOSITORY_REQUESTED_REJECTED,
      )
    ) {
      return (
        <Flex ml={85} gap={'xs'} justify="space-between" className="repo-status">
          <IconBan color="red" size="1rem" />
          <Text color={'red'} className="text-description">
            Rejected
          </Text>
        </Flex>
      );
    } else if (
      repositoryStatuses.some(
        (status) => status.status === RepositoryStatusStep.REPOSITORY_REQUESTED_APPROVED,
      )
    ) {
      return (
        <Flex ml={85} gap={'xs'} justify="space-between" className="repo-status">
          <IconCircleCheck color="#4589E4" size="1rem" />
          <Text color={'#4589E4'} className="text-description">
            Approved
          </Text>
        </Flex>
      );
    } else if (
      repositoryStatuses.some(
        (status) => status.status === RepositoryStatusStep.REPOSITORY_REQUESTED,
      )
    ) {
      return (
        <Flex ml={85} gap={'xs'} justify="space-between" className="repo-status">
          <img src={pendingIconActiveStatue} width={'18px'} height={'18px'} alt="" />
          <Text color={'blue'} className="text-description">
            Pending
          </Text>
        </Flex>
      );
    }
    return null;
  };
  return (
    <Box sx={repoDetailsStyles(theme)}>
      <LoadingOverlay visible={requestRepository.isLoading} />
      <Paper shadow="xs" className="project-setting-paper">
        <Grid className="project-setting-content">
          <Grid.Col span={9} className="project-setting-section">
            <div className="styled-section-column-direction">
              <TextInput
                name="repo-name"
                placeholder="<ProjectName>-<IntegrationSuite>-<Integration>"
                value={repoName}
                data-cy={`repo-name`}
                error={''}
                disabled={true}
                label="Repository Name"
              />
            </div>
            {repositoryRequest && repositoryRequest.repositoryStatuses && (
              <div className="repo-request-status">
                <div className="repo-request-status-lable">
                  <ThemeIcon color="#ffffff" size={24} radius="xl">
                    {getStatus(repositoryRequest.repositoryStatuses)}
                  </ThemeIcon>
                </div>
                {isRepoCreated && (
                  <div className="repo-slug">
                    git@bitbucket.org:grubtech/{repositoryRequest.repositoryName}.git
                  </div>
                )}
              </div>
            )}
          </Grid.Col>
          <Grid.Col offset={0.15} span={'auto'} className="button-section">
            <div className="rquest-btn-container">
              <Button
                disabled={repositoryRequest?.repositoryName !== null}
                variant="outline"
                onClick={() => {
                  onClickRequestRepository();
                }}
                className="request-repository-button"
              >
                Request Repository
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </Paper>
    </Box>
  );
};
export default RepoDetails;
