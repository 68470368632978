import { FC } from 'react';
import { useFormik } from 'formik';
import { array as validateArray, string as validateString, object as validateObject } from 'yup';

import {
  Button,
  Checkbox,
  Grid,
  Select,
  TextInput,
  Title,
  Text,
  useMantineTheme,
  ActionIcon,
} from '@mantine/core';
import { IconPlus, IconTrash, IconWebhook } from '@tabler/icons-react';

import ParameterKeyValueInput from './ParameterKeyValueInput';
import { noSpacesRegex, urlValidationRegex } from '../../utils/string';
import { HTTP_METHOD_VALUES, REQUEST_TYPE_VALUES } from '../../constants/webhookConstants';
import { DataPassingMedium, OAuthParameterType, OAuthValue } from '../../models';

type OAuthFieldsProps = {
  onSave: (values: OAuthValue) => void;
  defaultValues: OAuthValue | null;
  isParentValid: boolean;
  isDisabled?: boolean;
};

type KeyValuePair = {
  key: string;
  value: string;
};

type FormValues = {
  authUrl: string;
  authDataPassingMedium: string;
  method: string;
  headerPrefix: string;
  requestType: string;
  request: Array<KeyValuePair>;
  response: Array<KeyValuePair>;
};

const initialValues: FormValues = {
  authUrl: '',
  authDataPassingMedium: '',
  method: '',
  headerPrefix: '',
  requestType: '',
  request: [{ key: '', value: '' }],
  response: [
    { key: 'TOKEN', value: '' },
    { key: 'VALID_TIME', value: '' },
  ],
};

const validationSchema = validateObject({
  authUrl: validateString()
    .matches(urlValidationRegex, 'Must be a valid url')
    .url('Must be a valid url')
    .required('URL is required'),
  authDataPassingMedium: validateString(),
  method: validateString()
    .oneOf(
      HTTP_METHOD_VALUES.map((item) => item.value),
      'Select a valid option for HTTP Method',
    )
    .required('HTTP Method is required'),
  headerPrefix: validateString()
    .matches(noSpacesRegex, 'Prefix should not contain any special characters')
    .required('Prefix is required'),
  requestType: validateString()
    .oneOf(
      REQUEST_TYPE_VALUES.map((item) => item.value),
      'Select a valid option for Request Type',
    )
    .required('Request Type is required'),
  request: validateArray()
    .of(
      validateObject().test({
        name: 'pair needs to be filled',
        message: `Key or value both required if one of them is provided`,
        test: (val: any) => {
          const booley =
            (!val?.key?.trim() && !val.value?.trim()) ||
            (!!val?.key?.trim() && !!val?.value?.trim());
          return booley;
        },
      }),
    )
    .test({
      name: 'at least one filled',
      message: `At least one parameter is required`,
      test: (val: any) => {
        return val.some((item: KeyValuePair) => {
          return !!item.key && !!item.value;
        });
      },
    }),
  response: validateArray().of(
    validateObject({
      key: validateString(),
      value: validateString(),
    }).test({
      name: 'response token value required',
      message: 'TOKEN value is required',
      test: (val) => {
        if (val.key === 'TOKEN') {
          return !!val.value?.trim();
        }
        return true;
      },
    }),
  ),
});

const getInitialValues = (requestValue: OAuthValue | null) => {
  if (requestValue) {
    return {
      ...requestValue,
      request: Object.entries(requestValue.request).map((item) => ({
        key: item[0],
        value: item[1],
      })),
      response: Object.entries(requestValue.response).map((item) => ({
        key: item[0],
        value: item[1],
      })),
    };
  }
  return initialValues;
};

const OAuthFields: FC<OAuthFieldsProps> = ({
  onSave,
  defaultValues,
  isParentValid,
  isDisabled,
}) => {
  const theme = useMantineTheme();
  const { setFieldValue, values, setTouched, touched, handleChange, handleBlur, errors, isValid } =
    useFormik({
      initialValues: getInitialValues(defaultValues) as FormValues,
      validationSchema,
      onSubmit: (values: FormValues) => {
        // Handle form submission
      },
      validateOnBlur: true,
      validateOnMount: true,
    });

  const addNewKeyValue = (parameterType: OAuthParameterType) => {
    const newKeyValues = [...values[parameterType], { key: '', value: '' }];
    setFieldValue(parameterType, newKeyValues);
  };

  const removeNewKeyValue = (parameterType: OAuthParameterType, index: number) => {
    const newKeyValues = values[parameterType].filter((item, itemIndex) => itemIndex !== index);
    setFieldValue(parameterType, newKeyValues);
  };

  const handleKeyValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    parameterType: OAuthParameterType,
    valueType: 'key' | 'value',
  ) => {
    const newKeyValues = [...values[parameterType]];
    newKeyValues[index][valueType] = e.target.value;
    setFieldValue(parameterType, newKeyValues);
  };

  const prepareFormData = () => {
    return {
      ...values,
      request: values.request.reduce((prevObj, currentItem) => {
        if (!!currentItem.key.trim() && !!currentItem.value.trim()) {
          return { ...prevObj, [currentItem.key]: currentItem.value };
        }
        return { ...prevObj };
      }, {}),
      response: {
        TOKEN: values.response[0].value,
        ...(!!values.response[1]?.value?.trim()
          ? { VALID_TIME: values.response[1].value?.trim() }
          : {}),
      },
    } as OAuthValue;
  };

  return (
    <>
      <Grid.Col span={10}>
        <TextInput
          name="authUrl"
          placeholder="Auth URL"
          icon={<IconWebhook />}
          className="oAuth-fields__input"
          value={values.authUrl}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-oAuth2-authUrl`}
          error={touched.authUrl && errors.authUrl}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <Select
          name="method"
          className="oAuth-fields__input"
          placeholder="Select HTTP method"
          data={HTTP_METHOD_VALUES}
          icon={<IconWebhook />}
          onChange={(selected) => {
            setFieldValue('method', selected);
          }}
          onBlur={() => {
            setTouched({ ...touched, method: true });
          }}
          data-cy="webhook-urls-select-input-http-methods"
          value={values.method}
          error={touched.method && errors.method}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <Checkbox
          checked={values.authDataPassingMedium === DataPassingMedium.QUERY_PARAM}
          disabled={isDisabled}
          label="Query Parameter"
          onChange={(event) => {
            if (event.target.checked) {
              setFieldValue('authDataPassingMedium', DataPassingMedium.QUERY_PARAM);
            } else {
              setFieldValue('authDataPassingMedium', null);
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <TextInput
          name="headerPrefix"
          placeholder="Header prefix (E.g. Bearer)"
          icon={<IconWebhook />}
          className="oAuth-fields__input"
          value={values.headerPrefix}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-oAuth2-headerPrefix`}
          error={touched.headerPrefix && errors.headerPrefix}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <Select
          name="requestType"
          className="oAuth-fields__input"
          placeholder="Select Request Type"
          data={REQUEST_TYPE_VALUES}
          icon={<IconWebhook />}
          onChange={(selected) => {
            setFieldValue('requestType', selected);
          }}
          onBlur={() => {
            setTouched({ ...touched, requestType: true });
          }}
          data-cy="webhook-urls-select-input-request-type"
          value={values.requestType}
          error={touched.requestType && errors.requestType}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={5}>
        <Grid>
          <Grid.Col span={11}>
            <Title order={5}>Request Body Parameters</Title>
          </Grid.Col>
          <Grid.Col span={12}>
            {values.request.map((requestParam, index: number) => (
              <Grid>
                <Grid.Col span={10}>
                  <ParameterKeyValueInput
                    id={`${index}`}
                    valueVal={requestParam.value}
                    keyVal={requestParam.key}
                    onKeyChange={(e) => {
                      handleKeyValueChange(e, index, OAuthParameterType.REQUEST, 'key');
                    }}
                    onValueChange={(e) => {
                      handleKeyValueChange(e, index, OAuthParameterType.REQUEST, 'value');
                    }}
                    isDisabled={isDisabled}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  {index !== 0 ? (
                    <ActionIcon
                      size="sm"
                      onClick={() => {
                        removeNewKeyValue(OAuthParameterType.REQUEST, index);
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                  ) : null}
                </Grid.Col>
              </Grid>
            ))}
          </Grid.Col>
          <Grid.Col span={12}>
            <Text fz={theme.fontSizes.sm} color={theme.colors.red[6]}>
              {errors?.request
                ? Array.isArray(errors.request)
                  ? 'Provied field pair needs to be filled.'
                  : errors.request
                : ''}
            </Text>
          </Grid.Col>
          <Button
            variant="outline"
            leftIcon={<IconPlus />}
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              addNewKeyValue(OAuthParameterType.REQUEST);
            }}
            disabled={isDisabled}
          >
            Add New
          </Button>
        </Grid>
      </Grid.Col>
      <Grid.Col span={4} offset={1}>
        <Grid>
          <Grid.Col span={11}>
            <Title order={5}>Response Body Parameters</Title>
          </Grid.Col>
          <Grid.Col span={12}>
            {values.response.map((responseParam, index: number) => (
              <ParameterKeyValueInput
                id={`${index}`}
                valueVal={responseParam.value}
                keyVal={responseParam.key}
                onKeyChange={() => {}}
                onValueChange={(e) => {
                  handleKeyValueChange(e, index, OAuthParameterType.RESPONSE, 'value');
                }}
                isDisabled={isDisabled}
              />
            ))}
            <Grid.Col span={12}>
              <Text fz={theme.fontSizes.sm} color={theme.colors.red[6]}>
                {(errors?.response?.[0] as string) ?? ''}
              </Text>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={1} offset={10}>
        <Button
          className="webhooks-button"
          disabled={!isValid || !isParentValid || isDisabled}
          onClick={() => {
            onSave(prepareFormData());
          }}
          data-cy="webhook-urls-oauth-save-button"
          size="md"
        >
          Save
        </Button>
      </Grid.Col>
    </>
  );
};

export default OAuthFields;
