import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import PageLayout from '../../../components/PageLayout';
import { newSandBoxSiteStyles } from './styles';
import {
  Paper,
  Group,
  Title,
  TextInput,
  Text,
  Table,
  Alert,
  Button,
  useMantineTheme,
  LoadingOverlay,
  UnstyledButton,
  Select,
  SimpleGrid,
} from '@mantine/core';
import { IconAlertCircle, IconAsterisk, IconCheck, IconEdit } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { commonService, sandboxService } from '../../../service';
import { useDispatch, useSelector } from 'react-redux';
import { sandboxActionCreator } from '../../../redux/actions';
import { AuthenticationState, OrganizationState, SandboxState } from '../../../redux/reducers';
import { RootState } from '../../../redux/common';
import { useCallback, useEffect, useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import {
  AddressBuilderField,
  CreateSandboxApiData,
  IntegrationId,
  Organization,
  SandboxMenuDataType,
  SandboxPropertyId,
} from '../../../models';
import { convertEnumToString, formatAddressFieldLabel } from '../../../utils/string';
import {
  string as validateString,
  object as validateObject,
  array as validateArray,
  number as validateNumber,
  boolean as validateBoolean,
} from 'yup';
import useGetValidCountryList from '../../../hooks/useGetValidCountryList';
import { getValidationSchema } from '../../Organisation/DetailsPage/DetailPage';
import { organizationActionCreator } from '../../../redux/actions/organization';
import organizationService from '../../../service/organizations.service';
import AddressBuilder from '../../Organisation/AddressBuilder/AddressBuilder';

const NewSandboxSite: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getCountryList] = useGetValidCountryList();
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }
  const { sandboxCreationData, menuEditingId, retrySandboxCreation } = useSelector<
    RootState,
    SandboxState
  >((state: RootState) => {
    return state.sandbox;
  });
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const { organization, addressFields, cities } = useSelector<RootState, OrganizationState>(
    (state: RootState) => {
      return state.organization;
    },
  );

  const updateOrganization = useMutation({
    mutationFn: (organization: Organization) => {
      return organizationService.updateOrganization(organization);
    },
    onSuccess: (data) => {
      createSandbox.mutate({
        partnerId: userData.organizationId,
        creationData: {
          ...sandboxCreationData,
          sandboxMenuDataRows: values.menuData,
          sandboxPropertyValues:
            values?.sandboxPropertyDefinitions?.map((sandboxPropertyDefinition) => {
              return {
                integration: sandboxPropertyDefinition.integration,
                propertyId: sandboxPropertyDefinition.id,
                value: sandboxPropertyDefinition.value,
              };
            }) ?? [],
          organizationId: userData.organizationId,
          projectId: projectId,
          email: values.email,
        },
        projectId: projectId,
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const generateValidateSchema = () => {
    return validateObject().shape({
      email: validateString()
        .trim()
        .required(`Email is required`)
        .email('Please enter valid email')
        .test(
          'verify-email',
          'This email is already taken, Please use another email',
          async (value) => {
            try {
              const isEmailValid = await verifyEmail.mutateAsync(value);
              return !isEmailValid;
            } catch (error) {
              return false;
            }
          },
        ),
      menuData: validateArray().of(
        validateObject().shape({
          name: validateString().trim().required(`Name is required`),
          price: validateNumber()
            .when('type', (type, schema) => {
              if (type[0] !== SandboxMenuDataType.MENU)
                return schema.moreThan(0, 'Price must be greater than 0');
              return schema;
            })
            .typeError('Price must be an integer number')
            .integer('Price must be an integer number')
            .required('Price is required'),
        }),
      ),
      sandboxPropertyDefinitions: validateArray().of(
        validateObject().shape({
          mandatory: validateBoolean(),
          value: validateString()
            .trim()
            .when('mandatory', (mandatory, schema) => {
              if (mandatory[0]) {
                return schema.required('value is required');
              }
              return schema;
            }),
        }),
      ),
      countryId: validateString().trim().required('Country required'),
      cityId: validateString().trim().required('City required'),
      locationCoordinates: validateObject().shape({
        latitude: validateNumber()
          .min(-90, 'latitude must be within from -90 to 90 range')
          .max(90, 'latitude must be within from -90 to 90 range')
          .required('latitude is required'),
        longitude: validateNumber()
          .min(-180, 'longitude must be within from -180 to 180 range')
          .max(180, 'longitude must be within from -180 to 180 range')
          .required('longitude is required'),
      }),
    });
  };

  const generateInitialValues = () => {
    return {
      menuData: sandboxCreationData?.sandboxMenuDataRows ?? [],
      sandboxPropertyDefinitions:
        sandboxCreationData?.sandboxPropertyDefinitions.map((sandboxPropertyDefinition) => {
          return { ...sandboxPropertyDefinition, value: '' };
        }) ?? [],
      email: '',
      // countryId: organization?.countryId ?? '',
      // cityId: organization?.cityId ?? '',
      // locationCoordinates: {
      //   latitude: organization?.locationCoordinates?.latitude ?? 0,
      //   longitude: organization?.locationCoordinates?.longitude ?? 0,
      // },
      countryId: '',
      cityId: '',
      locationCoordinates: {
        latitude: 0,
        longitude: 0,
      },
    };
  };

  const {
    values,
    resetForm,
    touched,
    errors,
    handleBlur,
    isValid,
    setFieldError,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: generateInitialValues(),
    onSubmit: () => {},
    validationSchema: generateValidateSchema(),
    validateOnMount: true,
  });

  useEffect(() => {
    dispatch(sandboxActionCreator.changeEditingMenuId(null));
    // eslint-disable-next-line
  }, []);

  const [addressFieldValues, setAddressFieldValues] = useState<AddressBuilderField[]>([]);

  const addressFieldsFormikInstance = useFormik({
    initialValues: {} as FormikValues,
    validationSchema: getValidationSchema(addressFieldValues),
    onSubmit: () => {
      return;
    },
  });

  useEffect(() => {
    addressFieldValues.forEach(({ name, value }) =>
      addressFieldsFormikInstance.setFieldValue(name, ''),
    );
    // eslint-disable-next-line
  }, [addressFieldValues]);

  const { isLoading: isLoadingCountries } = useQuery({
    queryKey: ['get-countries'],
    queryFn: () => commonService.getAllCountries(),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCountries(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isInitialLoading: isLoadingCities, isFetching: isRefetchingCities } = useQuery({
    queryKey: ['get-cities-by-Country-id', values?.countryId],
    queryFn: () => commonService.getCitiesByCountryId(values?.countryId),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCities(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: values?.countryId && values?.countryId?.length > 0 ? true : false,
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      if (values.countryId === data.countryId) {
        data?.address?.fields?.forEach((item) => {
          addressFieldsFormikInstance.setFieldValue(item.addressFieldId, item.value);
        });
      }
      if (data.countryId) {
        getAddressFieldsByCountry.mutate(data.countryId);
      }
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingSanboxData } = useQuery({
    queryKey: ['get-sandbox-menu-data', userData.organizationId],
    queryFn: () => sandboxService.getSandboxMenuData(userData.organizationId, projectId),
    onSuccess: (data) => {
      dispatch(sandboxActionCreator.changeMenudata(data));
      setFieldValue('menuData', data.sandboxMenuDataRows);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const {
    data: sandboxPropertyDefinitionValues,
    isLoading: isLoadingGetAllSandboxPropertyDefinitionValue,
  } = useQuery({
    queryKey: ['get-all-sandbox-property-definition-value', userData.organizationId],
    queryFn: () =>
      sandboxService.getAllSandboxPropertyDefinitionValue(userData.organizationId, projectId),
    onSuccess: (data) => {},
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  useEffect(() => {
    setValues(generateInitialValues());
  }, [sandboxCreationData, organization]);

  const getAddressValue = (countryId: string, addressFieldId: string) => {
    if (countryId === values.countryId) {
      return (
        organization?.address?.fields?.find((field) => field?.addressFieldId === addressFieldId)
          ?.value || ''
      );
    }
    return '';
  };

  const getAddressFieldsByCountry = useMutation({
    mutationFn: (countryId: string) => {
      return organizationService.getAddressFieldsByCountry(countryId);
    },
    onSuccess: (data) => {
      const addressBuilderFields =
        data &&
        data?.addressFieldWithOptionalInfoList.map((item) => {
          return {
            label: formatAddressFieldLabel(item.addressField.displayName),
            name: item.addressField.id,
            value: getAddressValue(item.countryId, item.addressField.id),
            optional: item.isOptional,
            requiredMessage: 'is required',
          };
        });
      if (addressBuilderFields) {
        setAddressFieldValues(addressBuilderFields);
      }
      dispatch(organizationActionCreator.changeAddressFields(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const createSandbox = useMutation({
    mutationFn: (payload: CreateSandboxApiData) => {
      return sandboxService.createSandbox(payload);
    },
    onSuccess: (data) => {
      if (
        data &&
        data.sandboxPropertyValueValidations &&
        data.sandboxPropertyValueValidations.length
      ) {
        notifications.show({
          title: 'Sandbox could not be created',
          message: data.sandboxPropertyValueValidations[0].validationErrorMessage,
          autoClose: 2000,
          color: 'red',
        });
        return;
      }
      navigate(`/projects/${projectId}/sandbox-sites`);
      dispatch(sandboxActionCreator.changeMenudata(null));
      dispatch(sandboxActionCreator.changeEditingMenuId(null));
      dispatch(sandboxActionCreator.newSandboxCreated(true));
      dispatch(sandboxActionCreator.retrySandboxCreation(false));
      resetForm();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const verifyEmail = useMutation({
    mutationFn: (email: string) => {
      return sandboxService.verifyEmail(email);
    },
    onSuccess: (data) => {
      if (data?.id) {
        setFieldError('email', 'This email is already taken, Please use another email');
        return false;
      }
      return true;
    },
    onError: (error: any) => {
      setFieldError('email', 'Something went wrong');
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
      return false;
    },
  });

  const getAddressBuilder = useCallback(() => {
    return (
      <AddressBuilder fields={addressFieldValues} formikInstance={addressFieldsFormikInstance} />
    );
    // eslint-disable-next-line
  }, [values, addressFieldValues, addressFieldsFormikInstance]);

  const onClickBack = () => {
    navigate(`/projects/${projectId}/sandbox-sites`);
  };

  const removeAddressFields = () => {
    Object.keys(addressFieldsFormikInstance.values).forEach((item: string) => {
      addressFieldsFormikInstance.setFieldValue(item, '');
    });
  };

  const onClickSubmit = () => {
    updateOrganization.mutate(getUpdatedOrganization());
  };

  const menuRows = useCallback(() => {
    const isDisabledIcons = (index: number) => {
      return (
        (touched &&
          touched?.menuData &&
          touched?.menuData[index]?.price &&
          errors &&
          errors?.menuData &&
          //@ts-ignore
          errors?.menuData[index]?.price) ||
        (touched &&
          touched?.menuData &&
          touched?.menuData[index]?.name &&
          errors &&
          errors?.menuData &&
          //@ts-ignore
          errors?.menuData[index]?.name)
      );
    };

    return values?.menuData?.map(({ itemId, name, type, price }, index) => (
      <tr key={itemId} data-cy={`menu-item-${itemId}`}>
        <td data-cy={`menu-item-${itemId}-id`}>{itemId}</td>
        <td data-cy={`menu-item-${itemId}-name`}>
          <TextInput
            value={name}
            variant={menuEditingId === itemId ? 'default' : 'unstyled'}
            onChange={(e) => {
              menuEditingId === itemId && setFieldValue(`menuData[${index}].name`, e.target.value);
            }}
            data-cy={`menu-item-${itemId}-name-input`}
            name={`menuData[${index}].name`}
            onBlur={handleBlur}
            error={
              touched &&
              touched?.menuData &&
              touched?.menuData[index]?.name &&
              errors &&
              errors?.menuData &&
              //@ts-ignore
              errors?.menuData[index]?.name ? (
                <div data-cy={`menu-item-${itemId}-name-error`}>
                  {(touched &&
                    touched?.menuData &&
                    touched?.menuData[index]?.name &&
                    errors &&
                    errors?.menuData &&
                    //@ts-ignore
                    errors?.menuData[index]?.name) ??
                    ''}
                </div>
              ) : (
                ''
              )
            }
          />
        </td>
        <td data-cy={`menu-item-${itemId}-type`}>{convertEnumToString(type)}</td>
        <td data-cy={`menu-item-${itemId}-price`}>
          <TextInput
            value={type === SandboxMenuDataType.MENU ? price : price.replace(/^0+/, '')}
            variant={menuEditingId === itemId ? 'default' : 'unstyled'}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) return;
              menuEditingId === itemId &&
                setFieldValue(`menuData[${index}].price`, e.target.value.trim());
            }}
            data-cy={`menu-item-${itemId}-price-input`}
            disabled={menuEditingId === itemId && type === SandboxMenuDataType.MENU}
            name={`menuData[${index}].price`}
            onBlur={handleBlur}
            error={
              touched &&
              touched?.menuData &&
              touched?.menuData[index]?.price &&
              errors &&
              errors?.menuData &&
              //@ts-ignore
              errors?.menuData[index]?.price ? (
                <div data-cy={`menu-item-${itemId}-price-error`}>
                  {(touched &&
                    touched?.menuData &&
                    touched?.menuData[index]?.price &&
                    errors &&
                    errors?.menuData &&
                    //@ts-ignore
                    errors?.menuData[index]?.price) ??
                    ''}
                </div>
              ) : (
                ''
              )
            }
          />
        </td>
        <td data-cy={`menu-item-${itemId}-actions`}>
          {menuEditingId === itemId ? (
            <UnstyledButton
              data-cy={`menu-item-${itemId}-edit`}
              onClick={() => dispatch(sandboxActionCreator.changeEditingMenuId(null))}
              disabled={isDisabledIcons(index)}
            >
              <IconCheck
                size={20}
                color={isDisabledIcons(index) ? 'gray' : theme.colors.grubtech[0]}
              />
            </UnstyledButton>
          ) : (
            <UnstyledButton
              data-cy={`menu-item-${itemId}-edit`}
              onClick={() => dispatch(sandboxActionCreator.changeEditingMenuId(itemId))}
              disabled={isDisabledIcons(index) || menuEditingId}
            >
              <IconEdit
                size={20}
                color={isDisabledIcons(index) || menuEditingId ? 'gray' : theme.colors.grubtech[0]}
              />
            </UnstyledButton>
          )}
        </td>
      </tr>
    ));
  }, [
    menuEditingId,
    dispatch,
    values,
    setFieldValue,
    theme.colors.grubtech,
    touched,
    errors,
    handleBlur,
  ]);

  const getPropertyDefinitionValueForReadOnly = useCallback(
    (id: SandboxPropertyId, integration: IntegrationId) => {
      return sandboxPropertyDefinitionValues?.find(
        (sandboxPropertyDefinitionValue) =>
          sandboxPropertyDefinitionValue.id.id === id.id &&
          sandboxPropertyDefinitionValue.integration.id === integration.id,
      )?.value;
    },
    [sandboxPropertyDefinitionValues],
  );

  const getOtherRows = useCallback(() => {
    return values?.sandboxPropertyDefinitions?.map(
      ({ name, description, id, mandatory, value, readOnly, integration }, index) => {
        return (
          <tr data-cy={`other-value-${id.id}-data-row`} key={id.id}>
            <td data-cy={`other-value-${id.id}-name`}>{convertEnumToString(name)}</td>
            <td data-cy={`other-value-${id.id}-description`}>{description}</td>
            <td data-cy={`other-value-${id.id}-value`}>
              {readOnly ? (
                <span>{getPropertyDefinitionValueForReadOnly(id, integration)}</span>
              ) : (
                <TextInput
                  value={value}
                  variant="default"
                  onChange={(e) => {
                    setFieldValue(`sandboxPropertyDefinitions[${index}].value`, e.target.value);
                  }}
                  data-cy={`other-value-${id.id}-value-input`}
                  withAsterisk={mandatory}
                  placeholder={`Enter ${convertEnumToString(name)}`}
                  rightSection={
                    mandatory ? (
                      <IconAsterisk color="red" style={{ height: '10px', width: '10px' }} />
                    ) : undefined
                  }
                  name={`sandboxPropertyDefinitions[${index}].value`}
                  onBlur={handleBlur}
                  error={
                    touched &&
                    touched?.sandboxPropertyDefinitions &&
                    touched?.sandboxPropertyDefinitions[index]?.value &&
                    errors &&
                    errors?.sandboxPropertyDefinitions &&
                    //@ts-ignore
                    errors?.sandboxPropertyDefinitions[index]?.value ? (
                      <div data-cy={`other-value-${id.id}-value-error`}>
                        {(touched &&
                          touched?.sandboxPropertyDefinitions &&
                          touched?.sandboxPropertyDefinitions[index]?.value &&
                          errors &&
                          errors?.sandboxPropertyDefinitions &&
                          //@ts-ignore
                          errors?.sandboxPropertyDefinitions[index]?.value) ??
                          ''}
                      </div>
                    ) : (
                      ''
                    )
                  }
                />
              )}
            </td>
          </tr>
        );
      },
    );
  }, [values, touched, errors, handleBlur, setFieldValue, getPropertyDefinitionValueForReadOnly]);

  const getUpdatedOrganization = () => {
    let currentOrganization: Organization = {
      ...organization,
      countryId: values.countryId,
      cityId: values.cityId,
      address: {
        fields: Object.keys(addressFieldsFormikInstance.values).map((item: string) => ({
          addressFieldId: item,
          value: addressFieldsFormikInstance.values[item],
        })),
        countryAddressFormatId: addressFields.countryAddressFormatId,
      },
      locationCoordinates: values.locationCoordinates,
    };
    return currentOrganization;
  };

  return (
    <PageLayout
      id={'new-sandbox-site-page'}
      sx={newSandBoxSiteStyles(theme)}
      headerSection={
        <PageHeader
          id={'new-sandbox-site-page'}
          title={'Create a new sandbox site'}
          backText="Sandbox"
          onClickBack={onClickBack}
        />
      }
    >
      <LoadingOverlay
        visible={
          createSandbox.isLoading ||
          isLoadingSanboxData ||
          isLoadingGetAllSandboxPropertyDefinitionValue ||
          isLoadingCountries ||
          isLoadingCities ||
          isLoadingOrganization ||
          updateOrganization.isLoading ||
          isRefetchingCities
        }
      />

      <Paper shadow="none" className="retry-message-paper">
        {retrySandboxCreation && (
          <Alert icon={<IconAlertCircle size={18} />} color="orange">
            Only the failed steps will be retried. Therefore, if the menu and other values have
            already been created successfully, they will not be overwritten again.
          </Alert>
        )}
      </Paper>

      <Paper shadow="xs" className="site-details-paper">
        <Group position="left" className="site-details-group">
          <TextInput
            label="Sandbox Email"
            required
            value={values.email}
            error={
              touched.email &&
              errors.email && <div data-cy="create-sandbox-email-error">{errors.email}</div>
            }
            name="email"
            onChange={(e) => {
              setFieldValue('email', e.target.value);
            }}
            data-cy="email"
            onBlur={(e) => {
              handleBlur(e);
            }}
            description={'Sandbox will be created using this email.'}
          />
        </Group>
        <SimpleGrid cols={2}>
          <Select
            searchable
            label="Country"
            withAsterisk
            placeholder="Select country"
            data={getCountryList()}
            value={values.countryId}
            name="countryId"
            onChange={(value) => {
              addressFieldsFormikInstance.resetForm();
              removeAddressFields();
              getAddressFieldsByCountry.mutate(value || '');
              setFieldValue('countryId', value);
              setFieldValue('cityId', '');
              setFieldValue('locationCoordinates', {
                latitude: 0,
                longitude: 0,
              });
            }}
            onBlur={handleBlur}
            error={touched?.countryId && errors?.countryId}
          />
          <Select
            label="City"
            withAsterisk
            placeholder="Select city"
            data={
              cities?.map((city) => {
                return {
                  value: city.id,
                  label: city.name,
                };
              }) ?? []
            }
            value={values.cityId}
            name="cityId"
            onChange={(value) => {
              setFieldValue('cityId', value);
              setFieldValue('locationCoordinates', {
                latitude: cities.find((city) => city.id === value)?.cityCoordinates?.latitude,
                longitude: cities.find((city) => city.id === value)?.cityCoordinates?.longitude,
              });
            }}
            onBlur={handleBlur}
            error={touched?.cityId && errors?.cityId}
          />
        </SimpleGrid>
        <SimpleGrid cols={2}>
          <TextInput
            placeholder="latitude"
            label="Latitude"
            value={values.locationCoordinates.latitude}
            disabled
          />
          <TextInput
            placeholder="longitude"
            label="Longitude"
            value={values.locationCoordinates.longitude}
            disabled
          />
        </SimpleGrid>

        {getAddressBuilder()}
      </Paper>

      <Paper shadow="xs" className="section-paper">
        <Group position="center" className="section-group">
          <div className="section-title">
            <Title order={3} data-cy="section-title">
              Menu
            </Title>
            <Text size={'sm'}>
              Here is a pre-set menu with all item types included. You can customise the items after
              the site is created.
            </Text>
          </div>
          <div className="section-container">
            <Table className="menu-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
              <thead>
                <tr>
                  <th>Item ID</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{menuRows()}</tbody>
            </Table>
          </div>
        </Group>
      </Paper>

      {(sandboxCreationData?.sandboxPropertyDefinitions ?? [])?.length > 0 && (
        <Paper shadow="xs" className="section-paper">
          <Group position="center" className="section-group">
            <div className="section-title">
              <Title order={3} data-cy="section-title">
                Other Values
              </Title>
            </div>
            <div className="section-container">
              <Table className="menu-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>description</th>
                    <th>value</th>
                  </tr>
                </thead>
                <tbody>{getOtherRows()}</tbody>
              </Table>
            </div>
          </Group>
        </Paper>
      )}
      <Paper shadow="xs" className="action-paper">
        <Alert icon={<IconAlertCircle size={18} />} color="orange">
          It takes 5 minutes for a new sandbox site to be activated. Please allow this time before
          placing an test order through a new site.
        </Alert>
        <Button
          className="create-site-button"
          disabled={!isValid || !addressFieldsFormikInstance.isValid}
          onClick={onClickSubmit}
          data-cy="create-site-button"
        >
          Create sandbox site
        </Button>
      </Paper>
    </PageLayout>
  );
};

export default NewSandboxSite;
