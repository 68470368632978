import { UserDataStore } from '../../models/auth';
import { NormalLoginFlow } from '../../models/user';
import { AuthenticationAction } from '../payloadModels/authentication';
import { AuthenticationActionTypes } from '../types';

export type AuthenticationState = {
  isAuthenticated: boolean;
  userData: UserDataStore;
  normalLoginFlowStep: NormalLoginFlow;
  isFromDocumentationLogin: boolean;
};

const initialState: AuthenticationState = {
  isAuthenticated: false,
  userData: {
    userId: '',
    organizationId: '',
    email: '',
    hasActiveApi: false,
    name: '',
  } as UserDataStore,
  normalLoginFlowStep: NormalLoginFlow.EMAIL_VERIFIED,
  isFromDocumentationLogin: false,
};

export const authenticationReducer = (
  state = initialState,
  action: AuthenticationAction,
): AuthenticationState => {
  switch (action.type) {
    case AuthenticationActionTypes.CHANGE_AUTH_DATA:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userData: action.payload.userData,
      };
    case AuthenticationActionTypes.RESET_USER_DATA:
      return {
        ...state,
        isAuthenticated: false,
        userData: {
          userId: '',
          organizationId: '',
          email: '',
          hasActiveApi: false,
          name: '',
        } as UserDataStore,
      };
    case AuthenticationActionTypes.CHANGE_NORMAL_LOGIN_FLOW_STEP:
      return {
        ...state,
        normalLoginFlowStep: action.payload as NormalLoginFlow,
      };
    case AuthenticationActionTypes.TOGGLE_IS_FROM_DOCUMENTAION_LOGIN:
      return {
        ...state,
        isFromDocumentationLogin: action.payload as boolean,
      };
    default:
      return state;
  }
};
