import { Loader, Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { JsonViewer } from '@textea/json-viewer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { testFrameworkActionCreator } from '../../../../../redux/actions';
import { RootState } from '../../../../../redux/common';
import { TestFrameworkState } from '../../../../../redux/reducers';
import { testFrameworkService } from '../../../../../service';
import { invocationModlaStyles } from './styles';

type InvocationModalProps = {
  id: string;
  opened: boolean;
};

const InvocationModal: FC<InvocationModalProps> = ({ opened }) => {
  const dispatch = useDispatch();
  const { selectedInvocationId } = useSelector<RootState, TestFrameworkState>(
    (state: RootState) => {
      return state.testFramework;
    },
  );

  const { data, isLoading } = useQuery({
    queryKey: [`get-invocation-by-id-${selectedInvocationId}`, selectedInvocationId],
    queryFn: () => testFrameworkService.getInvocationById(selectedInvocationId ?? ''),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: selectedInvocationId !== undefined,
  });

  const genrateData = () => {
    let copy = { ...data };
    const requestBody = data?.request?.requestBody;
    const responseValue = data?.response?.value;
    if (requestBody && data) {
      try {
        const jsonStr = JSON.parse(`${requestBody}`);
        copy = { ...copy, request: { ...copy.request, requestBody: jsonStr } };
      } catch (error) {
        console.log(error);
      }
    }
    if (responseValue && data) {
      try {
        const jsonStr2 = JSON.parse(`${responseValue}`);
        copy = { ...copy, response: { ...copy.response, value: jsonStr2 } };
      } catch (error) {
        console.log(error);
      }
    }
    return copy;
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        dispatch(testFrameworkActionCreator.changeSelectedInvocationId(undefined));
      }}
      size={'xl'}
      title={'Payload'}
      sx={invocationModlaStyles()}
    >
      {isLoading && (
        <div className="loader-wrapper">
          <Loader />
        </div>
      )}
      {data ? (
        <JsonViewer
          value={genrateData()}
          editable={false}
          theme={'dark'}
          indentWidth={3}
          highlightUpdates={true}
        />
      ) : null}
    </Modal>
  );
};

export default InvocationModal;
