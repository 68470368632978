import { LoaderAction } from '../payloadModels/loader';
import { LoaderActionTypes } from '../types';

export type LoaderState = {
  isLoading: boolean;
};

const initialState: LoaderState = {
  isLoading: false,
};

export const loaderReducer = (state = initialState, action: LoaderAction): LoaderState => {
  switch (action.type) {
    case LoaderActionTypes.CHANGE_LOADER_STATUS:
      return { ...state, isLoading: action.payload as boolean };
    default:
      return state;
  }
};
