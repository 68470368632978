import { webhookAxios } from '../config/axios.config';
import { WebHookDefinition, WebHookValue } from '../models/webhook';

const webhookGoLiveService = {
  getWebHookDefinitions: async (integrationId: string): Promise<WebHookDefinition[]> => {
    const { data } = await webhookAxios.get(`/go-live/definitions/${integrationId}`);
    return data;
  },
  persistWebHookValues: async (payload: {
    integrationId: string;
    partnerId: string;
    projectId: string;
    values: WebHookValue[];
  }): Promise<void> => {
    await webhookAxios.post(
      `/go-live/values/${payload.integrationId}/${payload.partnerId}/projects/${payload.projectId}`,
      {
        organizationId: payload.partnerId,
        webHookValues: payload.values,
      },
    );
  },
  getWebHookValues: async (
    integrationId: string,
    partnerId: string,
    projectId: string,
  ): Promise<WebHookValue[]> => {
    const { data } = await webhookAxios.get(
      `/go-live/values/${integrationId}/${partnerId}/projects/${projectId}`,
    );
    return data.webHookValues;
  },
};

export default webhookGoLiveService;
