import { IntegrationId } from './integration';

export type RepoRequest = {
  repoName: string;
  integrationId: IntegrationId;
  projectName: string;
};
export type RepoRequestStatus = {
  projectId: string;
  organizationId: string;
  integration: IntegrationId;
  repositorySlug: string;
  repositoryName: string;
  repositoryStatuses: RepositoryStatus[];
  componentId: string;
  projectName: string;
  environmentServiceIds: { [key: string]: string };
  serverless?: boolean;
};
export type RepositoryStatus = {
  time: string;
  status: RepositoryStatusStep;
};
export enum RepositoryStatusStep {
  REPOSITORY_REQUESTED = 'REPOSITORY_REQUESTED',
  REPOSITORY_REQUESTED_APPROVED = 'REPOSITORY_REQUESTED_APPROVED',
  REPOSITORY_REQUESTED_REJECTED = 'REPOSITORY_REQUESTED_REJECTED',
  REPOSITORY_CREATED = 'REPOSITORY_CREATED',
}
