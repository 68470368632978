export type TestCaseDefinitionSummaryDto = {
  id: string;
  name: string;
  documentationUrl: string;
  testCaseDescription: TestCaseDescriptionDto;
  steps: TestStepDefinitionSummaryDto[];
  enabled: boolean;
  preRestartMessage: TestCaseMessageDto;
};

export type TestCaseMessageDto = {
  messageType: TestCaseMessageType;
  title: string;
  message: string;
  supportsDoNotShowAgain: boolean;
};

export enum TestCaseMessageType {
  INFO = 'INFO',
  CONFIRMATION = 'CONFIRMATION',
}

export type TestStepDefinitionSummaryDto = {
  id: string;
  description: string;
  type: TestStepType;
  documentationUrl: string | null;
  subSteps: Array<TestSubStepDefinitionSummaryDto>;
};

export type TestSubStepDefinitionSummaryDto = {
  id: string;
  description: string;
};

export type TestCaseDescriptionDto = {
  testGuideSteps: string[];
  description: string;
  note: TestStepType;
};

export enum TestStepType {
  BEGIN_TEST_CASE_ACTION = 'BEGIN_TEST_CASE_ACTION',
  EXECUTABLE_ACTION = 'EXECUTABLE_ACTION',
  UPLOAD_IMAGE_ACTION = 'UPLOAD_IMAGE_ACTION',
  USER_INPUT_ACTION = 'USER_INPUT_ACTION',
  INSTRUCTION = 'INSTRUCTION',
  VERIFICATION = 'VERIFICATION',
}

export type TestCaseExecutionDto = {
  testCaseId: string;
  instanceId: string;
  currentStepsWithStatuses: TestStepRuntimeStatusDto[];
  activeTestSteps: ActiveTestStepsDto;
  validationErrors: TestStepValidationError[];
  overallStatus: TestCaseStatus;
  logs: TestCaseLogs | undefined;
};

export type TestCaseLogs = {
  logs: { logLevel: string; message: string; time: string; metaData: { [key: string]: string } }[];
};

export type TestStepRuntimeStatusDto = {
  stepId: string;
  startedAt: string;
  deadLine: string;
  status: TestCaseStatus;
  description: string;
};

export type ActiveTestStepsDto = {
  steps: Array<
    | ExecutableActionStepExecutionDto
    | InstructionStepExecutionDto
    | UploadImageStepExecutionDto
    | UserInputStepExecutionDto
    | VerificationStepExecutionDto
  >;
};

export type TestStepExecutionDto = {
  id: string;
  name: string;
  description: string;
  completionDeadline: string;
  type: TestStepType;
};

export interface UploadImageStepExecutionDto extends TestStepExecutionDto {
  images: ImageDefinition[];
}

export interface UserInputStepExecutionDto extends TestStepExecutionDto {
  inputs: UserInputDefinition[];
}

export interface ExecutableActionStepExecutionDto extends TestStepExecutionDto {
  buttonDisplayName: string;
}

export interface InstructionStepExecutionDto extends TestStepExecutionDto {}

export interface VerificationStepExecutionDto extends TestStepExecutionDto {}

export type ImageDefinition = {
  id: string;
  description: string;
};

export type UserInputDefinition = {
  id: string;
  description: string;
  type: string;
};

export type TestStepValidationError = {
  message: string;
};

export enum TestCaseStatus {
  PENDING = 'PENDING',
  TIMED_OUT = 'TIMED_OUT',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  SKIPPED = 'SKIPPED',
}

export type NextTestStepsDto = {
  steps: TestStepDto;
};

export type TestStepDto = {
  id: string;
  name: string;
  description: string;
  completionDeadline: string;
};

export type ActionRuntimeInput = {
  payload: { [key: string]: string };
};

export type markActionAsDonePayload = {
  instanceId: string;
  stepId: string;
  input: ActionRuntimeInput;
};

export type triggerActionPayload = Omit<markActionAsDonePayload, 'input'>;

export type beginTestCasePayload = {
  instanceId: string;
  flowId: string;
  subFlowId: string;
  testCaseId: string;
};

export type TestSuiteDefinitionSummaryDto = {
  id: string;
  name: string;
  integration: string;
  flows: TestFlowDefinitionSummaryDto[];
};

export type TestFlowDefinitionSummaryDto = {
  id: string;
  name: string;
  subFlows: TestSubFlowDefinitionSummaryDto[];
};

export type TestSubFlowDefinitionSummaryDto = {
  id: string;
  name: string;
  testCases: TestCaseDefinitionSummaryDto[];
};

export type TestSuiteInstanceDto = {
  testSuiteId: string;
  instanceId: string;
  organizationId: string;
  projectId: string;
  startedAt: string;
  overallStatus: TestCaseStatus;
  testCaseInstances: TestCaseInstanceDto[];
  latest: boolean;
  submittedForAdminApproval: boolean;
  integrationType: string;
};

export type TestCaseInstanceDto = {
  flowId: string;
  subFlowId: string;
  testCaseId: string;
  instanceId: string;
  status: TestCaseStatus;
  approvalStatus: ApprovalStatus;
};

export enum ApprovalStatus {
  NOT_SUBMITTED_FOR_APPROVAL = 'NOT_SUBMITTED_FOR_APPROVAL',
  SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NO_MANUAL_APPROVAL_NEEDED = 'NO_MANUAL_APPROVAL_NEEDED',
}

export type EndpointInvocationLog = {
  endpoint: string;
  httpMethod: string;
  request: any;
  response: any;
  endpointType: any;
  basicVerificationInfo: any;
  partnerId: string;
  integrationId: string;
};

export type TestCaseApprovalStatusDto = {
  testCaseId: string;
  testCaseInstanceId: string;
  approvalStatusByStepId: Record<string, TestStepApprovalStatus>;
  status: ApprovalStatus;
};

export type TestStepApprovalStatus = {
  stepId: string;
  approvalStatusBySubStepId: Record<string, TestSubStepApprovalStatus>;
  status: ApprovalStatus;
};

export type TestSubStepApprovalStatus = {
  subStepId: string;
  status: ApprovalStatus;
  adminComment: string;
};

export type TestSuiteAdminApprovalDto = {
  suiteId: string;
  suiteInstanceId: string;
  organizationId: string;
  projectId: string;
  integrationType: string;
  testCaseInputByTestCaseId: Record<string, TestCaseInput>;
  approvalStatusByTestCaseId: Record<string, TestCaseApprovalStatus>;
  status: TestSuiteApprovalStatus;
};

export enum TestSuiteApprovalStatus {
  SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL',
  ADMIN_APPROVED = 'ADMIN_APPROVED',
  ADMIN_REJECTED = 'ADMIN_REJECTED',
  CANCELLED = 'CANCELLED',
}

export type TestCaseInput = {
  testCaseId: string;
  testCaseInstanceId: string;
  testStepInputs: Array<TestStepInput>;
};

export type TestStepInput = {
  stepId: string;
  inputBySubStepId: Record<string, string>;
};

export type TestCaseApprovalStatus = {
  testCaseId: string;
  testCaseInstanceId: string;
  approvalStatusByStepId: Record<string, TestStepApprovalStatus>;
  status: ApprovalStatus;
};
