import PageHeader from '../../components/PageHeader/PageHeader';
import PageLayout from '../../components/PageLayout/PageLayout';
import { Grid, Box, useMantineTheme, LoadingOverlay, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { manageApiAccessStyles } from './style';
import PartnerPlatformCard from './APIAccessCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import integratioService from '../../service/integration.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { integrationActionCreator, testFrameworkActionCreator } from '../../redux/actions';
import { AuthenticationState, OrganizationState } from '../../redux/reducers';
import { notifications } from '@mantine/notifications';
import ndaService from '../../service/nda.service';
import { ndaActionCreator } from '../../redux/actions/nda';
import { NdaStatus } from '../../models/nda';
import NDADocumentPage from './NDADocumentPage/NDADocumentPage';
import { IconArrowNarrowRight, IconDeviceFloppy } from '@tabler/icons-react';
import { SubStatusElement } from '../../components/Card/Card';
import organizationService from '../../service/organizations.service';
import { organizationActionCreator } from '../../redux/actions/organization';
import { IntegrationId, PartnerIntegrationStatus } from '../../models';
import React, { useEffect } from 'react';
import { projectService, testFrameworkService } from '../../service';
import { Project } from '../../models/Project';
import { useProjectContext } from '../../context/ProjectContext';

const ManageApiAccess = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { setActiveProject } = useProjectContext();
  useEffect(() => {
    if (!projectId) {
      navigate('/manage-projects');
    }
  }, []);

  const orderApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/createorder',
    },
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/upload-menu',
    },
  ];
  const deliveryApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Delivery API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/docs/overview-delivery',
    },
  ];
  const posApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://app.swaggerhub.com/apis-docs/GrubTech/menu-api/1.5.0',
    },
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink:
        'https://app.swaggerhub.com/apis-docs/GrubTech/pos-webhook/1.5.0#/order/createOrder',
    },
    {
      text: 'Order Statuses Update API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink:
        'https://app.swaggerhub.com/apis-docs/GrubTech/grubtech-order_status_update_api/1.0.0',
    },
  ];

  const getApiSubStatusElementsById = (id: string): SubStatusElement[] => {
    switch (id) {
      case 'ORDER':
        return orderApiSubStatusElements;
      case 'DELIVERY':
        return deliveryApiSubStatusElements;
      case 'POS':
        return posApiSubStatusElements;
      default:
        return [];
    }
  };

  // const { integrationDefinitions } = useSelector<RootState, IntegrationState & { activatedIntegrations: PartnerIntegrationStatus[] }>(
  //   (state: RootState) => {
  //     return state.integration;
  //   },
  // );
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { organization } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { data: ndaStatus, isLoading: isLoadingNdaStatus } = useQuery({
    queryKey: ['get-nda-status', userData.organizationId],
    queryFn: () => ndaService.getNdaStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(ndaActionCreator.changeNdaStatus(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: integrationDefinitions, isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions', userData?.organizationId, projectId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId, projectId!),
    enabled: projectId ? true : false,
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
      return data;
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: activatedIntegrations, isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration', userData.organizationId],
    queryFn: () => integratioService.getActivatedIntegrations(userData.organizationId, projectId!),
    enabled: projectId ? true : false,
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
      data.forEach(async ({ integration: { id } }) => {
        await getTestSuitesByIntegration.mutate(id);
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getTestSuitesByIntegration = useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getTestSuitesByIntegration(integartion, projectId!);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const { data: integrationSummaries, isLoading: isLoadingIntegrationSummaries } = useQuery({
    queryKey: ['get-integration-summaries', userData.organizationId],
    queryFn: () =>
      integratioService.getIntegrationParameterSummary(userData.organizationId, projectId!),
    enabled: projectId ? true : false,
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: projectDetails, isLoading: isLoadingProjects } = useQuery<Project>({
    queryKey: ['get-project-details', userData.organizationId],
    queryFn: () => projectService.getProjectById(projectId!),
    enabled: projectId ? true : false,
    onSuccess: (data) => {
      if (data && data.id) {
        setProjectName(data.name);
        setActiveProject(data); // Set the selected project as the active project
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getIsCustomizedDisabled = (integrationId: IntegrationId) => {
    if (!organization.enableParameterDefinitions) {
      return true;
    }
    const result = integrationSummaries?.find(
      (integrationSummary) => integrationSummary.integration.id === integrationId.id,
    )?.isDisabled;
    if (result === undefined) {
      return true;
    }
    return result;
  };

  const [isEditing, setIsEditing] = React.useState(false);
  const [projectName, setProjectName] = React.useState(projectDetails?.name || '');

  const handleSaveProjectName = async () => {
    setIsEditing(false);
    const trimmedProjectName = projectName.trim();
    if (/\s/.test(trimmedProjectName)) {
      notifications.show({
        title: 'Invalid Project Name',
        message: 'Project name should not contain spaces.',
        autoClose: 2000,
        color: 'red',
      });
      setProjectName(projectDetails?.name || ''); // Reset to default name
      return;
    }
    setProjectName(trimmedProjectName);
    if (projectDetails && projectDetails.id) {
      try {
        await projectService.updateProject(projectDetails.id, projectDetails.organizationId, {
          projectName: trimmedProjectName,
        });
        setActiveProject(null); // Reset the active project
      } catch (error: any) {
        notifications.show({
          title: error.name ?? 'Something went wrong',
          message: error.message ?? 'Something went wrong',
          autoClose: 2000,
          color: 'red',
        });
        setProjectName(projectDetails?.name || ''); // Reset to default name on error
      }
    }
  };

  if (ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED) {
    return (
      <PageLayout
        id={'manage-api-access-page'}
        sx={manageApiAccessStyles(theme)}
        headerSection={
          <PageHeader
            id={'manage-api-access-page'}
            title={'Project Dashboard'}
            backText={userData.hasActiveApi ? 'Dashboard' : ''}
            onClickBack={() => navigate('/')}
          />
        }
      >
        <LoadingOverlay
          visible={
            isLoadingProjects ||
            isLoadingActivatedIntegrations ||
            isLoadingIntegrationDefinitions ||
            isLoadingNdaStatus ||
            isLoadingOrganization ||
            isLoadingIntegrationSummaries
          }
        />
        <Box>
          <Grid className="main-container">
            {integrationDefinitions &&
              projectId &&
              integrationDefinitions
                .filter(
                  ({ id }) =>
                    activatedIntegrations &&
                    activatedIntegrations.some(
                      (
                        integration: PartnerIntegrationStatus,
                        index: number,
                        array: PartnerIntegrationStatus[],
                      ) => integration.integration.id === id.id,
                    ),
                )
                .map(({ id }) => (
                  <Grid.Col span={6} key={id.id}>
                    <Text className="main-title">
                      {isEditing ? (
                        <>
                          <input
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            onBlur={() => handleSaveProjectName()}
                            autoFocus
                          />{' '}
                          <IconDeviceFloppy onClick={() => handleSaveProjectName()} />
                        </>
                      ) : (
                        <>
                          {projectName}
                          {/* <IconPencil onClick={() => setIsEditing(true)} /> */}
                        </>
                      )}
                    </Text>
                    <PartnerPlatformCard
                      id={id.id}
                      key={id.id}
                      availableApiSuiteElements={getApiSubStatusElementsById(id.id)}
                      isCustomizedParamsDisabled={getIsCustomizedDisabled(id)}
                      projectId={projectId}
                    />
                  </Grid.Col>
                ))}
          </Grid>
        </Box>
      </PageLayout>
    );
  }
  return <NDADocumentPage />;
};

export default ManageApiAccess;
