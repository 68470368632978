import { MantineTheme, Sx } from '@mantine/core';

export const testLinkSectionWrapperStyles = (theme: MantineTheme): Sx => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.75rem',
    borderRadius: '8px',
    background: '#FFF',
    marginBottom: '1rem',

    '.title': {
      color: theme.colors.grubtech[4],
      margin: '0 0 0.75rem 0.25rem',
    },

    '.custom-navlink:not(:last-child)': {
      marginBottom: '0.35rem',
    },
  };
};
