import { TextInput } from '@mantine/core';
import { FormikProps } from 'formik';
import { FC, ReactNode } from 'react';

import { AddressBuilderField } from '../../../models';

type AddressBuilderProps = {
  fields: AddressBuilderField[];
  formikInstance: FormikProps<any>;
};

const AddressBuilder: FC<AddressBuilderProps> = ({ fields, formikInstance }) => {
  const renderFields = (fields: AddressBuilderField[]): ReactNode => {
    return fields.map((field: AddressBuilderField) => {
      return (
        <TextInput
          id={field.name}
          key={field.name}
          label={field.label}
          name={field.name}
          value={formikInstance.values[field.name]}
          onChange={(e) => formikInstance.setFieldValue(field.name.toString(), e.target.value)}
          onBlur={formikInstance.handleBlur}
          withAsterisk={!field.optional}
          //@ts-ignore
          error={formikInstance.touched[field.name] && formikInstance.errors[field.name]}
        />
      );
    });
  };

  return <>{renderFields(fields)}</>;
};

export default AddressBuilder;
