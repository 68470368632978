import { integrationAxios } from '../config/axios.config';
import {
  ActivateIntegrationPayload,
  Parameter,
  PartnerIntegrationStatus,
} from '../models/integration';

const integrationGoLiveService = {
  activateIntegration: async (
    payload: ActivateIntegrationPayload,
  ): Promise<PartnerIntegrationStatus> => {
    const { data } = await integrationAxios.put(
      `/go-live/activate/${payload.organizationId}/projects/${payload.projectId}/${payload.integration.id}`,
      payload,
    );
    return data;
  },

  getActivatedIntegrations: async (
    partnerId: string,
    projectId: string,
  ): Promise<PartnerIntegrationStatus[]> => {
    const { data } = await integrationAxios.get(
      `/go-live/activated/${partnerId}/projects/${projectId}`,
    );
    return data;
  },

  getIntegrationParameters: async (
    organizationId: string,
    projectId: string,
    integrationId: string | undefined,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.get(
      `/go-live/params/${organizationId}/projects/${projectId}/${integrationId}`,
    );
    return data;
  },

  createUpdateIntegrationParameters: async (
    organizationId: string,
    parameters: Parameter,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.put(`/go-live/params/${organizationId}`, parameters);
    return data;
  },
};

export default integrationGoLiveService;
