import { Box, LoadingOverlay, MantineProvider } from '@mantine/core';
import Layout from './components/Layout';
import { Notifications, notifications } from '@mantine/notifications';
import { getCustomTheme } from './theme';
import { Amplify, Auth } from 'aws-amplify';
import { amplifyConfig } from './config/amplify.config';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticationActionCreator,
  integrationActionCreator,
  loaderActionCreator,
  testFrameworkActionCreator,
} from './redux/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { userService } from './service';
import { AuthenticationState, LoaderState } from './redux/reducers';
import { RootState } from './redux/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import useHealthCheckStatus from './hooks/useHealthCheckStatus';
import { ToastContainer } from 'react-toastify';
import useMaintenanceCheckStatus from './hooks/useMaintenanceCheckStatus';
import documentationService from './service/documentation.service';
import { ProjectProvider } from './context/ProjectContext';
import integratioService from './service/integration.service';

function App() {
  useHealthCheckStatus();
  useMaintenanceCheckStatus();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allowedRedirectPaths = [
    '/login',
    '/documentation-login',
    '/signup',
    '/otp-verification',
    '/facebook-data-deletion-instructions',
  ];
  const location = useLocation();
  Amplify.configure(amplifyConfig);

  const { isLoading } = useSelector<RootState, LoaderState>((state: RootState) => {
    return state.loader;
  });

  const { userData, isFromDocumentationLogin } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => {
      return state.authentication;
    },
  );

  useEffect(() => {
    dispatch(testFrameworkActionCreator.resetTestSuiteDefinitionData(null));
    // eslint-disable-next-line
  }, []);

  const { isRefetching: isLoadingIntegrationDefinitions, refetch } = useQuery({
    queryKey: ['get-integration-definitions-api-credentials', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId, ''),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: false,
  });

  const documentationAuthRequest = useMutation({
    mutationFn: documentationService.getDocumentationAuth,
    onSuccess: (data) => {
      dispatch(authenticationActionCreator.changeIsFromDocumentationLogin(false));
      if (!!data && data?.authToken) {
        window.location.href = `https://grubtech.readme.io?auth_token=${data.authToken}`;
        return;
      }
      window.location.href = `https://grubtech.readme.io`;
    },
    onError: (error: any) => {
      dispatch(authenticationActionCreator.changeIsFromDocumentationLogin(false));
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
      window.location.href = `https://grubtech.readme.io`;
    },
  });

  useEffect(() => {
    dispatch(loaderActionCreator.changeLoaderStatus(true));

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        if (!currentUser) {
          if (allowedRedirectPaths.includes(location.pathname)) {
            navigate(location.pathname);
          } else {
            navigate('/login');
            dispatch(loaderActionCreator.changeLoaderStatus(false));
          }
        } else {
          const decoded: any = jwt_decode(
            currentUser.getSignInUserSession()?.getIdToken().getJwtToken(),
          );

          if (isFromDocumentationLogin) {
            documentationAuthRequest.mutate(decoded?.email);
            return;
          }

          userService
            .getUserByEmail(decoded?.email)
            .then((res) => {
              if (!res.organizationId || !res.hasActiveApi) {
                dispatch(
                  authenticationActionCreator.changeAuthData({
                    isAuthenticated: true,
                    userData: {
                      userId: res.id ? res.id : decoded.user_id,
                      organizationId: res?.organizationId ?? null,
                      email: decoded?.email,
                      hasActiveApi: res.hasActiveApi,
                      name: res.name ?? '',
                    },
                  }),
                );
                navigate('/get-started');
                dispatch(loaderActionCreator.changeLoaderStatus(false));
                refetch();
                return;
              }
              dispatch(
                authenticationActionCreator.changeAuthData({
                  isAuthenticated: true,
                  userData: {
                    userId: res.id ? res.id : decoded.user_id,
                    organizationId: res?.organizationId,
                    email: decoded?.email,
                    hasActiveApi: res.hasActiveApi,
                    name: res.name ?? '',
                  },
                }),
              );

              if (location.pathname !== allowedRedirectPaths[1]) {
                dispatch(loaderActionCreator.changeLoaderStatus(false));
                navigate(
                  allowedRedirectPaths.includes(location.pathname)
                    ? '/'
                    : location.pathname.toString(),
                );
                refetch();
              }
            })
            .catch(() => {
              dispatch(loaderActionCreator.changeLoaderStatus(false));
              refetch();
            });
        }
      })
      .catch((error) => {
        dispatch(loaderActionCreator.changeLoaderStatus(false));
        if (location.pathname === allowedRedirectPaths[3]) {
          return navigate(allowedRedirectPaths[3]);
        }
        if (location.pathname === allowedRedirectPaths[1]) {
          dispatch(loaderActionCreator.changeLoaderStatus(false));
          return;
        } else {
          navigate('/login');
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ProjectProvider>
      <MantineProvider withNormalizeCSS withGlobalStyles theme={getCustomTheme()}>
        <Notifications autoClose={2000} />
        <ToastContainer hideProgressBar style={{ width: '100%' }} limit={1} newestOnTop={true} />
        <LoadingOverlay visible={isLoading || isLoadingIntegrationDefinitions} />
        <Box sx={{ height: '100vh' }} className={'App'}>
          <Layout />
        </Box>
      </MantineProvider>
    </ProjectProvider>
  );
}

export default App;
