import { userOnboardingAxios } from '../config/axios.config';
import { UserOnBoardingProgress } from '../models/auth';

const userOnboardingService = {
  verifyEmail: async (email: string): Promise<UserOnBoardingProgress> => {
    const { data } = await userOnboardingAxios.post(`/submission/email/${email}`);
    return data;
  },
  verifyOtp: async (payload: UserOnBoardingProgress): Promise<UserOnBoardingProgress> => {
    const { data } = await userOnboardingAxios.post(`/verification/otp`, payload);
    return data;
  },
  submitUserDataandPsw: async (
    payload: UserOnBoardingProgress,
  ): Promise<UserOnBoardingProgress> => {
    const { data } = await userOnboardingAxios.post(`/submission/user-info`, payload);
    return data;
  },
  getOnboardingProgress: async (email: any): Promise<UserOnBoardingProgress> => {
    const { data } = await userOnboardingAxios.get(`/current-on-boarding-progress/${email}`);
    return data;
  },
};

export default userOnboardingService;
