import { Sx, MantineTheme } from '@mantine/core';

export const appStoreDetailsStyles = (theme: MantineTheme): Sx => {
  return {
    '.tabs': {
      marginTop: theme.spacing.md,
      height: '90%',
    },
    '.active-tab': {
      background: `${theme.colors.grubtech[0]} !important`,
      color: '#fff !important',
      fontWeight: 'bold',
      ':hover': {
        background: `${theme.colors.grubtech[1]} !important`,
      },
    },
    '.tab': {
      padding: '8px 16px',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.sm,
      height: '38px',
      marginRight: '-1px !important',
      border: '1px solid #edeef0',
      ':hover': {
        borderBottom: '1px solid #edeef0 !important',
      },
    },
    '.tab-container': {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    '.app-store-details-container': {
      padding: '1.5rem',
    },
  };
};
