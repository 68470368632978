import { projectAxios } from '../config/axios.config';
import { SandboxPropertyValue, SandboxPropertyValueValidation } from '../models';
import { Project, ProjectSummery } from '../models/Project';
const projectService = {
  getAllProjectsByOrganizationId: async (
    organizationId: string,
    page: number,
    pageSize: number,
    signal: AbortSignal,
    integrationType?: string,
    searchQuery?: string,
    keyword?: string,
  ): Promise<ProjectSummery[]> => {
    try {
      const params = {
        page,
        size: pageSize,
        sort: 'id,asc',
        integrationType,
        searchQuery,
        keyword,
      };
      const { data } = await projectAxios.get(`/projects/byOrganization/${organizationId}`, {
        params,
        signal,
      });
      return data;
    } catch (error) {
      console.error('Error fetching projects by organization ID:', error);
      throw error;
    }
  },

  updatePropertyValue: async (payload: {
    organizationId: string;
    propertyValues: SandboxPropertyValue[];
  }): Promise<SandboxPropertyValueValidation[]> => {
    const { data } = await projectAxios.put(
      `/update-property-value/${payload.organizationId}`,
      payload.propertyValues,
    );
    return data;
  },
  getProjectById: async (projectId: string): Promise<Project> => {
    const { data } = await projectAxios.get(`/projects/${projectId}`);
    return data;
  },
  updateProject: async (
    projectId: string,
    organizationId: string,
    projectName: any,
  ): Promise<Project> => {
    const { data } = await projectAxios.put(
      `/projects/${organizationId}/${projectId}`,
      projectName,
    );
    return data;
  },
};

export default projectService;
