import { MantineTheme, Sx } from '@mantine/core';

export const socialButtonStyles = (theme: MantineTheme): Sx => {
  return {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing.sm,
    borderRadius: theme.spacing.sm,
    border: 'none',
    backgroundColor: theme.colors.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    ':active': {
      transform: 'translateY(2px)',
    },
    '.socialSignButton__icon': {
      width: theme.spacing.xl,
      height: theme.spacing.xl,
    },
  };
};
