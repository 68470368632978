import { MantineTheme, Sx } from '@mantine/core';

export const customLinkStyles = (theme: MantineTheme, isDisabled?: boolean): Sx => {
  return {
    '.custom-link': {
      textDecoration: 'none',
      color: isDisabled ? theme.colors.grubtech[4] : theme.colors.grubtech[0],

      '&:hover': {
        color: isDisabled ? theme.colors.grubtech[4] : theme.colors.grubtech[1],
      },
    },
  };
};
