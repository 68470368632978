import {
  Box,
  Center,
  Flex,
  LoadingOverlay,
  SegmentedControl,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FC, useState } from 'react';
import { integrationParametersStyles } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';
import { useMutation, useQuery } from '@tanstack/react-query';
import integratioService from '../../../service/integration.service';
import { notifications } from '@mantine/notifications';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../../../components/PageLayout';
import PageHeader from '../../../components/PageHeader';
import { EnvType, IntegrationDefinition, Parameter } from '../../../models';
import { IconLock } from '@tabler/icons-react';
import Parameters from '../Parameters/Parameters';
import { webhookService } from '../../../service';
import integrationGoLiveService from '../../../service/integrationGoLive.service';

const IntegrationParameters: FC = () => {
  const theme = useMantineTheme();
  const { projectId, integrationId } = useParams();
  const [selectedEnvType, setSelectedEnvType] = useState<EnvType>(EnvType.SANDBOX);
  const navigate = useNavigate();

  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { data: sandboxParameters, isLoading: isLoadingParameters } = useQuery({
    queryKey: ['get-integration-parameters', userData.organizationId],
    queryFn: () =>
      integratioService.getIntegrationParameters(
        userData.organizationId,
        projectId,
        integrationId?.toUpperCase(),
      ),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: prodParameters, isLoading: isLoadingProdParameters } = useQuery({
    queryKey: ['get-integration-parameters-prod', userData.organizationId],
    queryFn: () =>
      integrationGoLiveService.getIntegrationParameters(
        userData.organizationId,
        projectId,
        integrationId?.toUpperCase(),
      ),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const saveSandboxParameters = useMutation({
    mutationFn: (payload: Parameter) => {
      return integratioService.createUpdateIntegrationParameters(
        userData.organizationId,
        payload,
        projectId,
      );
    },
    onSuccess: () => {
      notifications.show({
        title: 'Updated Successfully',
        message: 'Definition settings were updated successfully',
        color: 'green',
      });
      navigate(`/projects/${projectId}/definition-settings`);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const saveProdParameters = useMutation({
    mutationFn: (payload: Parameter) => {
      return integrationGoLiveService.createUpdateIntegrationParameters(
        userData.organizationId,
        payload,
      );
    },
    onSuccess: () => {
      notifications.show({
        title: 'Updated Successfully',
        message: 'Definition settings were updated successfully',
        color: 'green',
      });
      navigate(`/projects/${projectId}/definition-settings`);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const {
    data: isProductionEnabledForIntegration,
    isLoading: isLoadingCheckProductionEnabledByIntegration,
  } = useQuery({
    queryKey: [
      `check-is-production-enabled-by-integration-${integrationId}`,
      userData.organizationId,
    ],
    queryFn: () =>
      webhookService.checkIsProductionEnabledByIntegration(
        currentIntegrationID,
        userData.organizationId,
        projectId,
      ),
    onSuccess: (data) => {},
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { integrationDefinitions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => {
      return state.integration;
    },
  );

  const currentIntegrationID = integrationId!.toUpperCase();

  const currentIntegrationDefinition =
    integrationDefinitions?.find((integration) => integration.id.id === currentIntegrationID) ??
    ({} as IntegrationDefinition);

  const getParametersSection = () => {
    return (
      <Parameters
        key={EnvType.SANDBOX}
        parameters={sandboxParameters}
        saveParameters={saveSandboxParameters}
        envType={EnvType.SANDBOX}
        isConfirmationRequired={false}
      />
    );
  };

  const getParametersSectionForProduction = () => {
    return (
      <Parameters
        key={EnvType.PRODUCTION}
        parameters={prodParameters}
        saveParameters={saveProdParameters}
        envType={EnvType.PRODUCTION}
        isConfirmationRequired={true}
      />
    );
  };

  const getSegmentedControl = () => {
    return [
      {
        value: EnvType.SANDBOX,
        label: (
          <Center>
            <Text
              size="sm"
              weight={selectedEnvType === EnvType.SANDBOX ? 'bold' : 'normal'}
              className={selectedEnvType === EnvType.SANDBOX ? 'segmented-control-lbl-active' : ''}
              data-cy="segmented-control-sandbox"
            >
              For sandbox
            </Text>
          </Center>
        ),
      },
      {
        value: EnvType.PRODUCTION,
        label: (
          <Center>
            {!isProductionEnabledForIntegration && <IconLock size={14} />}
            <Text
              size="sm"
              ml={10}
              weight={selectedEnvType === EnvType.PRODUCTION ? 'bold' : 'normal'}
              data-cy="segmented-control-production"
              className={
                selectedEnvType === EnvType.PRODUCTION ? 'segmented-control-lbl-active' : ''
              }
            >
              For production
            </Text>
          </Center>
        ),
        disabled: !isProductionEnabledForIntegration,
      },
    ];
  };

  return (
    <PageLayout
      id={'integration-parameters-page'}
      sx={integrationParametersStyles(theme)}
      headerSection={
        <PageHeader
          id={'integration-parameters-page'}
          title={currentIntegrationDefinition?.name ?? ''}
          backText="Back"
          onClickBack={() => navigate(`/projects/${projectId}/definition-settings`)}
        />
      }
    >
      <LoadingOverlay
        visible={
          isLoadingParameters ||
          isLoadingCheckProductionEnabledByIntegration ||
          isLoadingProdParameters
        }
      />
      <Box>
        <Flex direction="row" justify="space-between" align="center">
          <SegmentedControl
            value={selectedEnvType}
            onChange={(value) => setSelectedEnvType(EnvType[value as keyof typeof EnvType])}
            data={getSegmentedControl()}
            size="md"
            className="parameters-segmented-control"
          />
        </Flex>
        {selectedEnvType === EnvType.SANDBOX
          ? getParametersSection()
          : getParametersSectionForProduction()}
      </Box>
    </PageLayout>
  );
};

export default IntegrationParameters;
