import { IntegrationId } from '../../models';
import { AppStoreDetails, IntegrationWithGoLiveStatus } from '../../models/appStoreDetails';
import { AppStoreDetailsAction } from '../payloadModels/appStoreDetails';
import { AppStoreDetailsActionTypes } from '../types/appStoreDetails';

export type AppStoreDetailsState = {
  readyToGoLiveIntegrations: IntegrationId[];
  appStoreDetails: AppStoreDetails;
  integrationsWithGoLiveStatuses: IntegrationWithGoLiveStatus[];
};

const initialState: AppStoreDetailsState = {
  readyToGoLiveIntegrations: [],
  appStoreDetails: {} as AppStoreDetails,
  integrationsWithGoLiveStatuses: [],
};

export const appStoreDetailsReducer = (
  state = initialState,
  action: AppStoreDetailsAction,
): AppStoreDetailsState => {
  switch (action.type) {
    case AppStoreDetailsActionTypes.CHANGE_READY_TO_GO_LIVE_INTEGRATIONS:
      return { ...state, readyToGoLiveIntegrations: action.payload as IntegrationId[] };
    case AppStoreDetailsActionTypes.CHANGE_APP_STORE_DETAILS:
      return { ...state, appStoreDetails: action.payload as AppStoreDetails };
    case AppStoreDetailsActionTypes.CHANGE_INTEGRATIONS_WITH_GO_LIVE_STATUSES:
      return {
        ...state,
        integrationsWithGoLiveStatuses: action.payload as IntegrationWithGoLiveStatus[],
      };
    default:
      return state;
  }
};
