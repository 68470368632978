import { Button, Modal, useMantineTheme } from '@mantine/core';
import { appStoreConfirmationDialogStyles } from './style';
import { FC } from 'react';

type AppStoreConfirmationDialogProps = {
  opened: boolean;
  close: () => void;
  handleConfirm: () => void;
};

const AppStoreConfirmationDialog: FC<AppStoreConfirmationDialogProps> = ({
  opened,
  close,
  handleConfirm,
}) => {
  const theme = useMantineTheme();

  return (
    <Modal
      sx={appStoreConfirmationDialogStyles(theme)}
      opened={opened}
      onClose={close}
      title="Are you sure?"
      id={'app-store-details-confirmation-dialog'}
      centered
    >
      <div className="description">
        You won’t be able to make changes to these details and webhook details once you submit the
        go-live request. Please check the details you have entered before making the request to the
        Grubtech administrator.
      </div>
      <div className="button-wrapper">
        <Button onClick={close} variant="outline">
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </div>
    </Modal>
  );
};

export default AppStoreConfirmationDialog;
