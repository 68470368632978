import { SandboxCreationFormData, SandboxSummaryDto } from '../../models';
import { SandboxAction } from '../payloadModels/sandbox';
import { SandboxActionTypes } from '../types';

export type SandboxState = {
  sandboxCreationData: SandboxCreationFormData | null;
  menuEditingId: string | null;
  sandboxSummaryData: SandboxSummaryDto[];
  newSandboxCreated: boolean;
  retrySandboxCreation: boolean;
  sandboxEditingId: string | null;
};

const initialState: SandboxState = {
  sandboxCreationData: null,
  menuEditingId: null,
  sandboxSummaryData: [],
  newSandboxCreated: false,
  retrySandboxCreation: false,
  sandboxEditingId: null,
};

export const sandboxReducer = (state = initialState, action: SandboxAction): SandboxState => {
  switch (action.type) {
    case SandboxActionTypes.CHANGE_MENU_DATA:
      return { ...state, sandboxCreationData: action.payload as SandboxCreationFormData };
    case SandboxActionTypes.CHANGE_EDITING_MENU_ID:
      return { ...state, menuEditingId: action.payload as string };
    case SandboxActionTypes.CHANGE_SANDBOX_SUMMARY_DATA:
      return { ...state, sandboxSummaryData: action.payload as SandboxSummaryDto[] };
    case SandboxActionTypes.NEW_SANDBOX_CREATED:
      return { ...state, newSandboxCreated: action.payload as boolean };
    case SandboxActionTypes.RETRY_SANDBOX_CREATION:
      return { ...state, retrySandboxCreation: action.payload as boolean };
    case SandboxActionTypes.TOGGLE_IS_EDITING_SANDBOX:
      return { ...state, sandboxEditingId: action.payload as string | null };
    default:
      return state;
  }
};
