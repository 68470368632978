import { Box, Paper, Title, useMantineTheme } from '@mantine/core';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TestCaseStatus, TestSuiteApprovalStatus } from '../../../../models';
import { RootState } from '../../../../redux/common';
import { TestFrameworkState } from '../../../../redux/reducers';
import { statusStyles } from './style';

const Status: React.FC = () => {
  const theme = useMantineTheme();

  const { testSuiteInstanceData, testSubmittedDetails } = useSelector<
    RootState,
    TestFrameworkState
  >((state: RootState) => {
    return state.testFramework;
  });

  const getText = useCallback(() => {
    if (testSubmittedDetails?.status === TestSuiteApprovalStatus.ADMIN_APPROVED) {
      return 'UAT completed';
    }

    if (testSubmittedDetails?.status === TestSuiteApprovalStatus.ADMIN_REJECTED) {
      return 'Resubmission required';
    }

    if (testSuiteInstanceData?.submittedForAdminApproval) {
      return 'Submitted for review';
    }

    switch (testSuiteInstanceData?.overallStatus) {
      case TestCaseStatus.SUCCESS:
        return 'UAT in progress';
      case TestCaseStatus.PENDING:
        return 'UAT in progress';
      case TestCaseStatus.FAILED:
        return 'UAT in progress';
      case TestCaseStatus.CANCELLED:
        return 'UAT in progress';
      case TestCaseStatus.TIMED_OUT:
        return 'UAT in progress';
      default:
        return 'UAT Pending';
    }
  }, [testSuiteInstanceData, testSubmittedDetails]);

  return (
    <Box sx={statusStyles(theme, testSuiteInstanceData?.overallStatus)}>
      <Paper shadow="xs" className="status-paper">
        <Title order={3} data-cy="status-title">
          Status
        </Title>
        <div className="status">{getText()}</div>
      </Paper>
    </Box>
  );
};

export default Status;
