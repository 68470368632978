import {
  Box,
  Center,
  Flex,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  useMantineTheme,
  Text,
  Grid,
  Button,
} from '@mantine/core';
import { FC, useState } from 'react';
import { deploymentAndReleaseStyles } from './style';
import { EnvType, IntegrationActivationStatus } from '../../../models';
import { useQuery } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import DeploymentTable from './DeploymentTable/DeploymentTable';
import deploymentService from '../../../service/deployment.service';
import { Environment } from '../../../models/deployment';
import { IconLock } from '@tabler/icons-react';
import ProductionDeployment from './ProductionDeployment/ProductionDeployment';
import { RootState } from '../../../redux/common';
import { IntegrationState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';

export interface DeploymentAndReleaseProps {
  repositoryUrl: string;
  componentId: string;
  environmentServiceIds: { [key: string]: string };
}
const DeploymentAndRelease: FC<DeploymentAndReleaseProps> = ({
  repositoryUrl,
  componentId,
  environmentServiceIds,
}) => {
  const theme = useMantineTheme();

  const [selectedEnvType, setSelectedEnvType] = useState<EnvType>(EnvType.SANDBOX);
  const [environment, setEnvironment] = useState<Environment[]>([]);
  const [isProdDeploymentDialogOpen, setIsProdDeploymentDialogOpen] = useState(false);

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { isLoading: isLoadingEnvs } = useQuery({
    queryKey: [`load-all-env`],
    queryFn: () => deploymentService.getEnvironments(),
    onSuccess: (data) => {
      setEnvironment(data);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const isUATCompleted = () => {
    return activatedIntegrations.some((integration) =>
      integration.statusUpdates.some(
        (status) => status.status === IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
    );
  };
  return (
    <Box sx={deploymentAndReleaseStyles(theme)}>
      <LoadingOverlay visible={isLoadingEnvs} />
      <Paper shadow="xs" className="deployment-setting-paper">
        <Grid className="env-pannel-box">
          <Flex direction="row" justify="space-between" align="center" className="segment">
            <SegmentedControl
              p={0}
              value={selectedEnvType}
              onChange={(value) => setSelectedEnvType(EnvType[value as keyof typeof EnvType])}
              data={[
                {
                  value: EnvType.SANDBOX,
                  label: (
                    <Center>
                      <Text
                        size="sm"
                        weight={selectedEnvType === EnvType.SANDBOX ? 'bold' : 'normal'}
                        className={
                          selectedEnvType === EnvType.SANDBOX ? 'segmented-control-lbl-active' : ''
                        }
                        data-cy="segmented-control-sandbox"
                      >
                        For sandbox
                      </Text>
                    </Center>
                  ),
                },
                {
                  value: EnvType.PRODUCTION,
                  label: (
                    <Center>
                      {!isUATCompleted() && <IconLock size={14} />}
                      <Text
                        size="sm"
                        data-cy="segmented-control-production"
                        weight={selectedEnvType === EnvType.PRODUCTION ? 'bold' : 'normal'}
                        className={
                          selectedEnvType === EnvType.PRODUCTION
                            ? 'segmented-control-lbl-active'
                            : ''
                        }
                      >
                        For production
                      </Text>
                    </Center>
                  ),
                  disabled: !isUATCompleted(),
                },
              ]}
              size="md"
            />
            {selectedEnvType === EnvType.PRODUCTION && (
              <Button
                mr={4}
                size="sm"
                variant="filled"
                className="prod_deployment_btn"
                onClick={() => setIsProdDeploymentDialogOpen(true)}
              >
                Request For Prod Deployment
              </Button>
            )}
          </Flex>
        </Grid>
        {selectedEnvType === EnvType.SANDBOX ? (
          <DeploymentTable
            id="sandbox"
            env={
              environment.find((env) => env.environmentId === process.env.REACT_APP_OPS_ENV_STG) ||
              null
            }
            componentId={componentId}
          />
        ) : (
          <ProductionDeployment
            id="prod"
            env={
              environment.find((env) => env.environmentId === process.env.REACT_APP_OPS_ENV_STG) ||
              null
            }
            prodEnv={
              environment.find((env) => env.environmentId === process.env.REACT_APP_OPS_ENV_PROD) ||
              null
            }
            isProdDeploymentDialogOpen={isProdDeploymentDialogOpen}
            onCloseProdDeploymentDialog={() => {
              setIsProdDeploymentDialogOpen(false);
            }}
            repositoryUrl={repositoryUrl}
            componentId={componentId}
            isProdComponentCreated={
              environmentServiceIds !== null
                ? Object.keys(environmentServiceIds).includes(
                    process.env.REACT_APP_OPS_ENV_PROD ?? '',
                  )
                : false
            }
          />
        )}
      </Paper>
    </Box>
  );
};
export default DeploymentAndRelease;
