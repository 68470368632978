import { Sx, MantineTheme } from '@mantine/core';
import { TestCaseStatus } from '../../models';

export const snackbarStyles = (
  theme: MantineTheme,
  type: TestCaseStatus | undefined,
  isWithTitle: boolean,
): Sx => {
  return {
    display: 'flex',
    width: '100%',
    height: isWithTitle ? '62px' : '42px',
    flexDirection: 'row',
    padding: '20px 20px 20px 14px',
    alignItems: 'center',
    gap: '6px',
    background:
      type === TestCaseStatus.SUCCESS
        ? theme.colors.statusBackground[0]
        : type === TestCaseStatus.PENDING
        ? theme.colors.statusBackground[1]
        : type === TestCaseStatus.TIMED_OUT
        ? theme.colors.statusBackground[2]
        : theme.colors.statusBackground[3],
    borderRadius: '4px',

    '.icon': {
      marginTop: '-2px',
      color:
        type === TestCaseStatus.SUCCESS
          ? theme.colors.status[0]
          : type === TestCaseStatus.PENDING
          ? theme.colors.status[1]
          : type === TestCaseStatus.TIMED_OUT
          ? theme.colors.status[2]
          : theme.colors.status[3],
      img: {
        width: '25px',
        height: '25px',
      },
    },
    '.description-content': {
      display: 'flex',
      flexDirection: 'column',
      '.description': {
        whiteSpace: 'nowrap',
        color:
          type === TestCaseStatus.SUCCESS
            ? theme.colors.status[0]
            : type === TestCaseStatus.PENDING
            ? theme.colors.status[1]
            : type === TestCaseStatus.TIMED_OUT
            ? theme.colors.status[2]
            : theme.colors.status[3],
      },
      '.title': {
        whiteSpace: 'nowrap',
        fontSize: '14px',
        fontWeight: 700,
        color:
          type === TestCaseStatus.SUCCESS
            ? theme.colors.status[0]
            : type === TestCaseStatus.PENDING
            ? theme.colors.status[1]
            : type === TestCaseStatus.TIMED_OUT
            ? theme.colors.status[2]
            : theme.colors.status[3],
      },
    },
    '.document-url-icon': {
      display: 'flex',
      marginLeft: 'auto',
      color: theme.colors.font[3],
      cursor: 'pointer',
    },
  };
};
