import { Box, Grid, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/PageLayout';
import PageHeader from '../../components/PageHeader';
import { definitionSettingsStyles } from './style';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';
import integratioService from '../../service/integration.service';
import { useQuery } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import DefinitionSettingsCard from './DefinitionSettingsCard/DefinitionSettingsCard';
import { useNavigate, useParams } from 'react-router-dom';

const DefinitionSettings: FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { data: integrationSummaries, isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-integration-summaries', userData.organizationId],
    queryFn: () =>
      integratioService.getIntegrationParameterSummary(userData.organizationId, projectId),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return (
    <PageLayout
      id={'definition-settings-page'}
      sx={definitionSettingsStyles(theme)}
      headerSection={
        <PageHeader
          id={'definition-settings-page'}
          title={'Definition settings'}
          description={
            'Manage your integration related global configurations details and custom parameters required for your integrations here.'
          }
          backText="Back"
          onClickBack={() => navigate(`/projects/${projectId}/dashboard`)}
        />
      }
    >
      <LoadingOverlay visible={isLoadingActivatedIntegrations} />
      <Box>
        <Grid className="main-container">
          {integrationSummaries?.map((integrationSummary) => (
            <Grid.Col span={4} key={integrationSummary.integration.id}>
              <DefinitionSettingsCard projectId={projectId} summary={integrationSummary} />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    </PageLayout>
  );
};

export default DefinitionSettings;
