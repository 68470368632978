import { useMantineTheme } from '@mantine/core';
import { fbDataDeletionInstructionsStyles } from './styles';
import PageLayout from '../../../components/PageLayout';

const FBDataDeletionInstructions = () => {
  const theme = useMantineTheme();

  return (
    <PageLayout id={'sandbox-sites-page'} sx={fbDataDeletionInstructionsStyles(theme)}>
      <div className="deletion-instructions-container">
        <div className="deletion-instructions">
          <h1 className="title">Data Deletion Instructions</h1>
          <div className="text">
            <p>Dear valued users,</p>
            <p>
              If you wish to delete your data, please send an email to{' '}
              <a href="mailto:hello@grubtech.com?subject=Data%20Deletion%20Request">
                hello@grubtech.com
              </a>{' '}
              with the subject line <b>"Data Deletion Request"</b> asking to delete your data.
            </p>
            <p>Thank you for your understanding.</p>
            <p>Sincerely,</p>
            <p>Grbtech Team</p>
            <p>https://www.grubtech.com</p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FBDataDeletionInstructions;
