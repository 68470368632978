import { MantineTheme, Sx } from '@mantine/core';

export const newProjectStyles = (theme: MantineTheme): Sx => {
  return {
    '.site-details-paper': {
      padding: theme.spacing.xl,
      marginTop: theme.spacing.sm,
      borderRadius: theme.radius.md,

      '.site-details-group': {
        display: 'flex',
        alignItems: 'start',

        '.mantine-InputWrapper-root': {
          width: '100%',
        },

        '.site-details-title': {
          width: '25%',
        },

        '.site-details-container': {
          width: '70%',

          '.site-details-helper-text': {
            fontSize: theme.fontSizes.xs,
            color: '#E57400',
            marginTop: `-${theme.spacing.xl}`,
            marginBottom: theme.spacing.xl,
          },

          '.site-details-input': {
            width: '100%',
            marginBottom: theme.spacing.xl,
            marginRight: theme.spacing.xl,
          },

          '.default-settings-container': {
            width: '100%',
            fontSize: theme.fontSizes.sm,

            '.default-settings-title': {
              marginBottom: theme.spacing.xs,
              fontWeight: 'bold',
            },

            '.default-settings-text': {
              width: 170,
              marginBottom: theme.spacing.xs,
            },
          },
        },
      },
    },
    '.api-access-card-column': {
      '.selected': {
        borderColor: theme.colors.blue[5],
      },
    },

    '.retry-message-paper': {
      padding: 0,
      marginTop: theme.spacing.xs,
      marginRight: theme.spacing.xs,
    },

    '.section-paper': {
      padding: theme.spacing.xl,
      marginTop: theme.spacing.xl,

      '.section-group': {
        display: 'flex',
        alignItems: 'start',

        '.section-title': {
          width: '25%',
        },

        '.section-container': {
          width: '70%',

          '.menu-table': {
            width: '100%',
            marginBottom: theme.spacing.xl,

            thead: {
              backgroundColor: '#f9fafa',
            },

            th: {
              fontWeight: 'bold',
              color: 'black',
            },
          },
        },
      },
    },

    '.action-paper': {
      padding: theme.spacing.xl,
      marginTop: theme.spacing.xl,

      '.mantine-Alert-message': {
        color: '#E57400',
      },

      '.create-site-button': {
        width: 200,
        marginTop: theme.spacing.md,
      },
    },
  };
};
