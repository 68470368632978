import { useEffect, useState } from 'react';
import { PAGE_SIZE_20 } from '../utils/constants';

export type listTypes = any[];

const usePagination = (resetDependencyArray: any[] = [], customPageSize?: number) => {
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [listOfData, setListOfData] = useState<listTypes>([]);
  const pageSize: number = customPageSize ?? PAGE_SIZE_20;

  useEffect(() => {
    resetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetDependencyArray]);

  const increamentPage = () => {
    setPage((prev) => ++prev);
  };

  const resetPage = () => {
    setPage(0);
  };

  const toggleHasMore = (flag: boolean) => {
    setHasMore(flag);
  };

  const appendData = (data: listTypes) => {
    if (!data || data.length <= 0 || data.length < pageSize) {
      toggleHasMore(false);
    } else {
      toggleHasMore(true);
    }
    setListOfData((prev) => [...prev, ...data]);
  };

  const resetData = () => {
    setPage(0);
    setListOfData([]);
    toggleHasMore(true);
  };

  const updatePage = (page: number) => {
    setPage(page);
  };

  const updateList = (data: listTypes) => {
    setListOfData(data);
  };

  return [
    listOfData,
    page,
    pageSize,
    hasMore,
    totalPages,
    setTotalPages,
    updatePage,
    updateList,
    appendData,
    increamentPage,
    resetData,
    resetPage,
    toggleHasMore,
  ] as const;
};

export default usePagination;
