import { combineReducers } from 'redux';
import {
  apiKeyReducer,
  authenticationReducer,
  integrationReducer,
  webhookReducer,
  loaderReducer,
  sandboxReducer,
  organizationReducer,
  filterReducer,
  testFrameworkReducer,
  ndaReducer,
  appStoreDetailsReducer,
} from './reducers';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  integration: integrationReducer,
  apiKey: apiKeyReducer,
  webhook: webhookReducer,
  loader: loaderReducer,
  sandbox: sandboxReducer,
  organization: organizationReducer,
  filterData: filterReducer,
  testFramework: testFrameworkReducer,
  nda: ndaReducer,
  appStoreDetails: appStoreDetailsReducer,
});

export default rootReducer;
