import { MantineTheme, Sx } from '@mantine/core';

export const logsStyles = (theme: MantineTheme): Sx => {
  return {
    '.logs-env-select': {
      width: 225,
      '.mantine-Select-input': {
        backgroundColor: theme.colors.grubtech[0],
        color: 'white',
        fontWeight: 'bold',
      },
      '.mantine-Select-rightSection': {
        color: 'white',
      },
    },
    '.logs-filters': {
      marginTop: theme.spacing.xl,
      '.logs-search': {
        width: 450,
      },

      '.logs-select': {
        color: theme.colors.grubtech[0],

        '.mantine-Select-input': {
          color: theme.colors.grubtech[0],

          '&:hover': {
            color: theme.colors.grubtech[1],
            borderColor: theme.colors.grubtech[1],
          },
        },
      },
    },
    '.logs-paper': {
      marginTop: theme.spacing.xl,

      '.logs-table': {
        thead: {
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        },
        '.reset-btn': {
          color: theme.colors.red[6],

          '&:hover': {
            color: theme.colors.red[8],
          },
        },
      },
    },
  };
};
