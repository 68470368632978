import { FC } from 'react';
import { Box, useMantineTheme, Text } from '@mantine/core';
import { socialButtonStyles } from './styles';
import { facebookLogo, googleLogo } from '../../assets/images';

export enum SocialType {
  FACEBOOK,
  GOOGLE,
}

export type SocialSignButtonProps = {
  id?: string;
  text: string;
  onClick: () => void;
  socialType: SocialType;
};

const socialButtonIcon = {
  [SocialType.FACEBOOK]: facebookLogo,
  [SocialType.GOOGLE]: googleLogo,
};

const SocialSignButton: FC<SocialSignButtonProps> = ({ id, socialType, text, onClick }) => {
  const theme = useMantineTheme();

  return (
    <Box
      id={id ?? ''}
      sx={socialButtonStyles(theme)}
      onClick={onClick}
      className="socialSignButton__container"
      component="button"
    >
      <img src={socialButtonIcon[socialType]} className="socialSignButton__icon" alt={text} />

      <Text fw={500} color={theme.colors.gray[7]}>
        {text}
      </Text>
    </Box>
  );
};

export default SocialSignButton;
