import { LoadingOverlay, Tabs, useMantineTheme } from '@mantine/core';
import PageLayout from '../../components/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import { organizationStyles } from './style';
import TeamPage from './TeamPage/TeamPage';
import DetailPage from './DetailsPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState, OrganizationState } from '../../redux/reducers';
import { useNavigate, useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { organizationActionCreator } from '../../redux/actions/organization';
import organizationService from '../../service/organizations.service';
import { useProjectContext } from '../../context/ProjectContext';

const Organisation = () => {
  const theme = useMantineTheme();
  const { tabValue } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setActiveProject } = useProjectContext();
  setActiveProject(null);
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { organization } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return (
    <PageLayout
      id={'organisation-page'}
      sx={organizationStyles(theme)}
      headerSection={<PageHeader id={'organisation-page'} title={organization.name} />}
      isTabs={true}
    >
      <LoadingOverlay visible={isLoadingOrganization} />
      <Tabs
        defaultValue="team"
        className="organisation-tabs"
        value={userData.organizationId === null ? 'details' : tabValue}
        onTabChange={(value) => navigate(`/organisation/${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="team" data-cy="team-tab">
            Team
          </Tabs.Tab>

          <Tabs.Tab value="details" data-cy="details-tab">
            Details
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="team" pt="xs">
          <TeamPage id={'team-page'} />
        </Tabs.Panel>

        <Tabs.Panel value="details" pt="xs">
          <DetailPage id={'detail-page'} />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

export default Organisation;
