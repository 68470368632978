import {
  TestCaseApprovalStatusDto,
  TestCaseDefinitionSummaryDto,
  TestCaseExecutionDto,
  TestCaseLogs,
  TestSuiteAdminApprovalDto,
  TestSuiteDefinitionSummaryDto,
  TestSuiteInstanceDto,
} from '../../models';
import { actionCreator } from '../common';
import { TestFrameworkActionTypes } from '../types';

export const testFrameworkActionCreator = {
  changeTestDefinitionData: actionCreator<TestCaseDefinitionSummaryDto[]>(
    TestFrameworkActionTypes.CHANGE_ALL_TEST_DEFINITION_DATA,
  ),
  changeTestCaseExecutionData: actionCreator<TestCaseExecutionDto | null>(
    TestFrameworkActionTypes.CHANGE_TEST_CASE_EXECUTION_DATA,
  ),
  changeSelectedFlow: actionCreator<string | null>(TestFrameworkActionTypes.CHANGE_SELECTED_FLOW),
  changeSelectedSubFlow: actionCreator<string | null>(
    TestFrameworkActionTypes.CHANGE_SELECTED_SUB_FLOW,
  ),
  changeselectedTestCase: actionCreator<string | null>(
    TestFrameworkActionTypes.CHANGE_SELECTED_TEST_CASE,
  ),
  changeSelectedTestSuite: actionCreator<string>(
    TestFrameworkActionTypes.CHNAGE_SELECTED_TEST_SUITE,
  ),
  changeTestSuiteDefinitionData: actionCreator<TestSuiteDefinitionSummaryDto[]>(
    TestFrameworkActionTypes.CHANGE_TEST_SUITE_DEFINITION,
  ),
  changeTestSuiteIntanceData: actionCreator<TestSuiteInstanceDto | null>(
    TestFrameworkActionTypes.CHANGE_TEST_SUITE_INSTANCE_DATA,
  ),
  resetTestSuiteDefinitionData: actionCreator<null>(
    TestFrameworkActionTypes.RESET_TEST_SUITE_DEFINITION,
  ),
  changeIsTestCaseBegin: actionCreator<boolean>(TestFrameworkActionTypes.CHANGE_IS_TEST_CASE_BEGIN),
  changeSelectedInvocationId: actionCreator<string | undefined>(
    TestFrameworkActionTypes.CHANGE_INVOCATION_ID,
  ),
  changeTestCaseApprovalStatus: actionCreator<TestCaseApprovalStatusDto | null>(
    TestFrameworkActionTypes.CHANGE_TEST_CASE_APPROVAL_STATUS,
  ),
  changeTestSubmittedDetails: actionCreator<TestSuiteAdminApprovalDto | null>(
    TestFrameworkActionTypes.CHANGE_TEST_SUBMITED_DETAILS,
  ),
  clearTestCaseLogs: actionCreator<TestCaseLogs['logs']>(
    TestFrameworkActionTypes.CLEAR_TEST_CASE_LOGS,
  ),
  toggleRefetchEnable: actionCreator<boolean>(TestFrameworkActionTypes.TOGGLE_REFETCH_ENABLE),
};
