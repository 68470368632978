import { adminPortalAxios } from '../config/axios.config';
import { HealthStatus, TimePeriod } from '../models/healthStatus';

const healthStatusService = {
  fetchLatestHealthStatus: async (
    payload: TimePeriod,
  ): Promise<{ healthStatuses: HealthStatus[] }> => {
    const data = await adminPortalAxios.post(`/health-status`, payload);
    return data.data;
  },
};

export default healthStatusService;
