import { Box, Button, Text, Title, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';

import { checkmark } from '../../../assets/images';
import { signSectionStyles } from '../../../components/LoginPagesLayout/styles';
import { forgotPasswordStyles } from './styles';

const ForgotPasswordSent = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <LoginPagesLayout
      sx={{ ...signSectionStyles(theme), ...forgotPasswordStyles(theme) }}
      id={'forgotPasswordSent-page'}
    >
      <Box className="signPage__container">
        <Box className="signPage__content">
          <img
            src={checkmark}
            alt="email-sent-icon"
            className="forgotPasswordPage__checkMarkIcon"
          />
          <Title size={52} color={theme.colors.dark[9]}>
            We've sent a link
          </Title>
          <Text size={20} color={theme.colors.gray[6]}>
            An email has been sent with a link for you to change your password.
          </Text>

          <Box className="signPage__emailSection">
            <Button
              onClick={() => {
                navigate('/login');
              }}
              size="lg"
              disabled={false}
              data-cy="forgot-password-submit-button"
            >
              Back
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default ForgotPasswordSent;
