import { useMantineTheme, Box, Flex, Text, Radio, Divider } from '@mantine/core';
import PageLayout from '../../../components/PageLayout/PageLayout';
import { setupOrganizationStyles } from './style';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import CreateOrganization from '../CreateOrganization/CreateOrganization';
import JoinOrganization from '../JoinOrganization/JoinOrganization';

const SetupOrganization = () => {
  const theme = useMantineTheme();

  const [radioValue, setRadioValue] = useState('');
  const navigate = useNavigate();

  const renderBottomContent = () => {
    switch (radioValue) {
      case 'create':
        return <CreateOrganization />;
      case 'join':
        return <JoinOrganization />;
      default:
        return null;
    }
  };

  return (
    <PageLayout
      id={'setup-organization-page'}
      sx={setupOrganizationStyles(theme)}
      headerSection={
        <PageHeader
          id={'setup-organization-page'}
          title={'Set up your organisation'}
          backText="Back"
          onClickBack={() => navigate('/get-started')}
        />
      }
    >
      <Box className="content">
        <Flex direction={'column'}>
          <Text className="question-text">How would you like to join?</Text>
          <Flex direction={'column'} className="content-container">
            <Radio.Group name="favoriteFramework" onChange={setRadioValue} value={radioValue}>
              <Flex className="radio-container">
                <Radio value="create" className="radio-option" />
                <Flex direction={'column'}>
                  <Text className="main-text">Create a new organisation</Text>
                  <Text className="description-text">
                    If this is the first time your company is using the Developer portal, you'll
                    need to create a new organisation.
                  </Text>
                </Flex>
              </Flex>
              <Divider color="#e6e6e6" className="divider" />
              <Flex className="radio-container">
                <Radio value="join" className="radio-option" />
                <Flex direction={'column'}>
                  <Text className="main-text">Join an existing organisation </Text>
                  <Text className="description-text">
                    If your company already has an existing organisation on the Developer portal,
                    you'll need an invitation code in order to join.{' '}
                  </Text>
                </Flex>
              </Flex>
            </Radio.Group>
          </Flex>
        </Flex>
      </Box>
      {renderBottomContent()}
    </PageLayout>
  );
};

export default SetupOrganization;
