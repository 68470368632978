import { MantineTheme, Sx } from '@mantine/core';

export const instructionStyles = (theme: MantineTheme): Sx => {
  return {
    '.instruction-paper': {
      backgroundColor: theme.colors.gray[0],
      padding: theme.spacing.xl,
      marginBottom: theme.spacing.md,

      '.instruction-content': {
        width: '100%',
        '.instruction-description': {
          fontSize: theme.fontSizes.sm,
          marginTop: theme.spacing.sm,
          whiteSpace: 'pre-line',
        },

        '.mantine-List-item': {
          color: theme.colors.gray[8],
        },

        '.instruction-description-text': {
          marginBottom: theme.spacing.xs,
          color: theme.colors.gray[8],
        },

        '.instruction-links': {
          marginTop: theme.spacing.md,
          display: 'flex',
          flexDirection: 'column',

          button: {
            width: 'fit-content',
            '&.red': {
              background: theme.colors.grubtech[8],
              ':disabled': {
                background: theme.colors.gray[2],
              },
            },
          },
        },
      },
    },
  };
};
