import { Sx } from '@mantine/core';

export const pageLayoutStyles = (sx = {}, isTabs: boolean): Sx => {
  return {
    ...sx,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',

    '.header-section': {
      zIndex: 1,
    },

    '.pagelayout-content': {
      paddingLeft: isTabs ? 'unset' : '1rem',
      paddingRight: isTabs ? 'unset' : '1rem',
      maxHeight: 'calc(100vh - 140px)',
    },
  };
};
