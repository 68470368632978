import { MantineTheme, Sx } from '@mantine/core';

export const inviteModalStyles = (theme: MantineTheme): Sx => {
  return {
    '.title-divider': {
      marginBottom: '24px',
    },
    '.generated-code-box': {
      background: '#ebfffe',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #e1e3e3',
      padding: '16px',
      marginTop: '24px',
    },
    '.warning-text': {
      background: '#f0f2f2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
      marginTop: '16px',
      marginBottom: '40px',
    },
    '.info-icon': {
      alignSelf: 'flex-start',
      marginRight: '8px',
      marginBottom: '24px',
    },
    '.button-divider': {
      marginBottom: '24px',
    },
  };
};
