import { useState } from 'react';
import { Box, Text, Tooltip } from '@mantine/core';
import { IconAlertCircleFilled, IconLock } from '@tabler/icons-react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { testFrameworkActionCreator } from '../../../redux/actions';

interface SideBarLinkProps {
  to: string;
  item: any;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  disabledText?: string;
  isWarning?: boolean;
  warningText?: string;
}

const SideBarLink: React.FC<SideBarLinkProps> = ({
  to,
  item,
  disabled = false,
  hidden = false,
  onClick,
  disabledText,
  isWarning,
  warningText,
}) => {
  const resolvedPath = useResolvedPath(to);
  const location = useLocation();
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const isSubLinkMatch = item.subLinks?.includes(location.pathname);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSubmenu = () => {
    setIsOpen(!isOpen);
  };

  return !hidden ? (
    <Box>
      <Box
        onClick={item.submenu ? toggleSubmenu : undefined}
        className={`sidebar-link ${match || isSubLinkMatch ? 'sidebar-link-active' : ''} ${
          disabled ? 'sidebar-link-disabled' : ''
        }`}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <Link
          to={item.link}
          key={item.label}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            } else if (onClick) {
              onClick();
            }
          }}
          data-cy={`${item.label}-link`}
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
          }}
          target={item.external ? '_blank' : '_self'}
          rel={item.external ? 'noopener noreferrer' : undefined}
        >
          <item.icon className="sidebar-link-icon" stroke={2} size={20} />
          <span>{item.label}</span>
          {item.externalIcon && (
            <item.externalIcon
              className="sidebar-link-icon"
              stroke={2}
              size={20}
              style={{ marginLeft: '8px' }} // Adjust the value as needed
            />
          )}
        </Link>

        {disabled && (
          <Tooltip
            label={disabledText ? disabledText : 'Please create an organisation first'}
            color="gray"
          >
            <IconLock size={20} className="sidebar-link-lock" />
          </Tooltip>
        )}

        {isWarning && (
          <Tooltip label={warningText ? warningText : ''} color="gray">
            <IconAlertCircleFilled size={20} className="sidebar-link-warning" />
          </Tooltip>
        )}
      </Box>

      {item.submenu && (
        <Box pl={20}>
          <Text className="sidebar-section-title">Configure</Text>

          {item.submenu.map((submenuItem: any) => (
            <SideBarLink
              key={submenuItem.label}
              to={submenuItem.link}
              item={submenuItem}
              disabled={submenuItem.disabled}
              disabledText={submenuItem.disabledText}
              isWarning={submenuItem.isWarning}
              warningText={submenuItem.warningText}
            />
          ))}
        </Box>
      )}

      {item.submenu2 && (
        <Box pl={20}>
          <Text className="sidebar-section-title">UAT</Text>

          {item.submenu2.map((submenuItem: any) => (
            <SideBarLink
              key={submenuItem.label}
              to={submenuItem.link}
              item={submenuItem}
              disabled={submenuItem.disabled}
              disabledText={submenuItem.disabledText}
              isWarning={submenuItem.isWarning}
              warningText={submenuItem.warningText}
              onClick={() => {
                if (submenuItem.link.includes('/uat')) {
                  dispatch(testFrameworkActionCreator.changeSelectedTestSuite(submenuItem.id));
                  dispatch(testFrameworkActionCreator.changeSelectedFlow(null));
                  dispatch(testFrameworkActionCreator.changeSelectedSubFlow(null));
                  dispatch(testFrameworkActionCreator.changeselectedTestCase(null));
                  dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
                  dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
                }
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  ) : null;
};

export default SideBarLink;
