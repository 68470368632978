import { MantineTheme, Sx } from '@mantine/core';

export const teamPageStyles = (theme: MantineTheme): Sx => {
  return {
    '.content': {
      background: 'white',
      borderRadius: theme.radius.md,
      padding: theme.spacing.xl,

      '.header': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '.title': {
          marginBottom: theme.spacing.xl,
        },
      },

      '.filter': {
        marginBottom: theme.spacing.xl,
      },
    },
  };
};
