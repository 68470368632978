import { FC, useEffect, useState } from 'react';
import { Grid, Select } from '@mantine/core';
import { IconWebhook } from '@tabler/icons-react';
import { AUTHENTICATION_TYPES } from '../../constants/webhookConstants';
import {
  AuthFormSave,
  AuthTypes,
  BasicAuthValue,
  OAuthValue,
  WebHookValue,
  XApiValue,
} from '../../models';
import BasicAuthFields from './BasicAuthFields';
import XAPIField from './XAPIField';
import OAuthFields from './OAuthFields';
import NoneAuthFields from './NoneAuthFields';
import { RootState } from '../../redux/common';
import { IntegrationState } from '../../redux/reducers';
import { useSelector } from 'react-redux';

type WebHookAuthenticationSelectProps = {
  onSave: (values: AuthFormSave) => void;
  webHookValue: WebHookValue | undefined;
  isParentValid: boolean;
  isParentDisabled?: boolean;
};

const WebHookAuthenticationSelect: FC<WebHookAuthenticationSelectProps> = ({
  onSave,
  webHookValue,
  isParentValid,
  isParentDisabled,
}) => {
  const [selectedAuthentication, setSelectedAuthentication] = useState<AuthTypes | undefined>();

  const { selectedProject } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  useEffect(() => {
    if (webHookValue?.authType) {
      setSelectedAuthentication(webHookValue?.authType);
    } else {
      setSelectedAuthentication(AuthTypes.NONE);
    }
  }, [webHookValue?.authType]);

  const getAuthValues = (
    authValue:
      | { basicAuthValue: BasicAuthValue }
      | { oauthValue: OAuthValue }
      | { specialApiKeyValue: XApiValue }
      | undefined,
    specialApiKey = false,
  ) => {
    return {
      oauthValue: null,
      specialApiKeyValue: null,
      basicAuthValue: null,
      specialApiKey,
      authType: selectedAuthentication!,
      ...authValue,
    };
  };

  const authenticationForm = {
    [AuthTypes.BASIC_AUTH]: (
      <BasicAuthFields
        onSave={(values) => onSave(getAuthValues({ basicAuthValue: values }))}
        defaultValues={webHookValue?.basicAuthValue ?? null}
        isParentValid={isParentValid && !!selectedAuthentication}
        isDisabled={isParentDisabled}
      />
    ),
    [AuthTypes.X_API_KEY]: (
      <XAPIField
        onSave={(values) => onSave(getAuthValues({ specialApiKeyValue: values }, true))}
        defaultValues={webHookValue?.specialApiKeyValue ?? null}
        isParentValid={isParentValid && !!selectedAuthentication}
        isDisabled={isParentDisabled}
      />
    ),
    [AuthTypes.O_AUTH_2]: (
      <OAuthFields
        onSave={(values) => onSave(getAuthValues({ oauthValue: values }))}
        defaultValues={webHookValue?.oauthValue ?? null}
        isParentValid={isParentValid && !!selectedAuthentication}
        isDisabled={isParentDisabled}
      />
    ),
    [AuthTypes.NONE]: (
      <NoneAuthFields
        onSave={() => onSave(getAuthValues(undefined))}
        isParentValid={isParentValid && !!selectedAuthentication}
        isDisabled={isParentDisabled}
      />
    ),
  };

  return (
    <Grid gutter={'xl'}>
      <Grid.Col span={10}>
        <Select
          name="method"
          className="oAuth-fields__input"
          placeholder="Select Authentication Type"
          data={AUTHENTICATION_TYPES}
          icon={<IconWebhook />}
          onChange={(selected) => {
            if (selected) {
              setSelectedAuthentication(selected as AuthTypes);
            }
          }}
          data-cy="webhook-urls-select-input-authenticationTypes"
          value={selectedAuthentication}
          disabled={isParentDisabled || selectedProject?.enableParameterDefinitions}
        />
      </Grid.Col>
      {selectedAuthentication ? authenticationForm[selectedAuthentication] : null}
    </Grid>
  );
};
export default WebHookAuthenticationSelect;
