import { commonAxios } from '../config/axios.config';
import { City, Country } from '../models';

const commonService = {
  getCitiesByCountryId: async (countryId: string): Promise<City[]> => {
    const { data } = await commonAxios.get(`/city/${countryId}`);
    return data;
  },
  getAllCountries: async (): Promise<Country[]> => {
    const { data } = await commonAxios.get('/all-countries');
    return data;
  },
};

export default commonService;
