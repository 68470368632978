import { Sx, MantineTheme } from '@mantine/core';

export const sandBoxSitesCardStyles = (theme: MantineTheme): Sx => {
  return {
    '.sandbox-sites-card': {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: '100%',
      border: '1px solid #EAEAEA',

      '.header-section': {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '24px',
      },

      '.sandbox-sites-card-header': {
        marginBottom: theme.spacing.md,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },

      '.sandbox-sites-card-section': {
        marginBottom: theme.spacing.md,
        fontSize: theme.fontSizes.sm,
        width: '100%',

        '.sandbox-sites-card-section-label': {
          color: theme.colors.gray[6],
          marginRight: theme.spacing.md,
        },

        '.sandbox-sites-card-section-value': {
          color: theme.colors.gray[9],
        },

        '.mantine-Alert-message': {
          color: theme.colors.orange,
        },
      },

      '.description-values': {
        width: '100%',
        '.content': {
          marginBottom: '12px',
          '.title': {
            fontWeight: 500,
          },
        },
      },

      '.sandbox-sites-card-edit-button': {
        marginTop: 'auto',
      },
    },
  };
};
