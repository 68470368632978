import { FC } from 'react';
import { Button, Grid } from '@mantine/core';

type NoneAuthFieldsProps = {
  onSave: () => void;
  isParentValid: boolean;
  isDisabled?: boolean;
};

const NoneAuthFields: FC<NoneAuthFieldsProps> = ({ onSave, isParentValid, isDisabled }) => {
  return (
    <>
      <Grid.Col span={1} offset={1}>
        <Button
          className="webhooks-button"
          disabled={!isParentValid || isDisabled}
          onClick={() => {
            onSave();
          }}
          data-cy="webhook-urls-basicAuth-save-button"
          size="sm"
        >
          Save
        </Button>
      </Grid.Col>
    </>
  );
};
export default NoneAuthFields;
