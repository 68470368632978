import { MantineTheme, Sx } from '@mantine/core';

export const otpStyles = (theme: MantineTheme): Sx => {
  return {
    '.otpPage__emailDetailsContainer': {
      marginBottom: theme.spacing.xl,
    },
    '.otpPage__verificationSection': {
      marginTop: theme.spacing.xl,
      marginBottom: '48px',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing.sm,
    },
  };
};
