import { adminPortalAxios, testFrameworkAxios } from '../config/axios.config';
import {
  EndpointInvocationLog,
  TestCaseApprovalStatusDto,
  TestCaseExecutionDto,
  TestSuiteAdminApprovalDto,
  TestSuiteDefinitionSummaryDto,
  TestSuiteInstanceDto,
  beginTestCasePayload,
  markActionAsDonePayload,
  triggerActionPayload,
} from '../models';

const testFrameworkService = {
  markActionAsDone: async (payload: markActionAsDonePayload): Promise<TestCaseExecutionDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-cases/${payload.instanceId}/${payload.stepId}/markAsDone`,
      payload.input,
    );
    return data;
  },
  triggerAction: async (payload: triggerActionPayload): Promise<TestCaseExecutionDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-cases/${payload.instanceId}/${payload.stepId}/trigger`,
    );
    return data;
  },
  pollForUpdates: async (instanceId: string): Promise<TestCaseExecutionDto> => {
    const { data } = await testFrameworkAxios.get(`/test-cases/${instanceId}/updates`);
    return data;
  },
  getTestSuitesByIntegration: async (
    integration: string,
    projectId: string,
  ): Promise<TestSuiteDefinitionSummaryDto[]> => {
    const { data } = await testFrameworkAxios.get(
      `/test-suite-definitions/byType/${integration}/projects/${projectId}`,
    );
    return data;
  },
  getLatestInstanceOfSuite: async (
    suiteId: string,
    projectId: string,
  ): Promise<TestSuiteInstanceDto> => {
    const { data } = await testFrameworkAxios.get(
      `/test-suites/projects/${projectId}/${suiteId}/instances/latest`,
    );
    return data;
  },
  beginTestSuite: async (suiteId: string, projectId: string): Promise<TestSuiteInstanceDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-suites/projects/${projectId}/${suiteId}/begin-test-suite`,
    );
    return data;
  },
  beginTestCase: async (payload: beginTestCasePayload): Promise<TestSuiteInstanceDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-suites/instances/${payload.instanceId}/${payload.flowId}/${payload.subFlowId}/${payload.testCaseId}/begin-test-case`,
    );
    return data;
  },
  cancelTestCase: async (instanceId: string): Promise<TestCaseExecutionDto> => {
    const { data } = await testFrameworkAxios.post(`/test-cases/${instanceId}/cancel`);
    return data;
  },
  getInvocationById: async (invocationId: string): Promise<EndpointInvocationLog> => {
    const { data } = await testFrameworkAxios.get(`/endpoints/invocations/${invocationId}`);
    return data;
  },
  imageUpload: async (url: string, file: File, contentType: any): Promise<TestSuiteInstanceDto> => {
    const { data } = await testFrameworkAxios.put(url, file, {
      headers: { 'Content-Type': contentType },
    });
    return data;
  },
  submitForApproval: async (instanceId: string): Promise<TestSuiteInstanceDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-suites/instances/${instanceId}/submit-for-approval`,
    );
    return data;
  },
  getTestCaseApprovalStatus: async (
    testSuiteInstanceId: string,
    testCaseId: string,
    testCaseInstanceId: string,
  ): Promise<TestCaseApprovalStatusDto> => {
    const { data } = await testFrameworkAxios.get(
      `/test-suite-approval-status/${testSuiteInstanceId}/${testCaseId}/${testCaseInstanceId}`,
    );
    return data;
  },
  getSubmitedDataByOrganizationIdandIntegrationType: async (
    organizationId: string,
    integrationType: string,
    projectId: string,
  ): Promise<TestSuiteAdminApprovalDto> => {
    const { data } = await adminPortalAxios.get(
      `/test-suite-approvals/byOrganization/${organizationId}/projects/${projectId}/${integrationType}`,
    );
    return data;
  },
  skipTestCase: async (payload: {
    instanceId: string;
    reason: string;
  }): Promise<TestCaseExecutionDto> => {
    const { data } = await testFrameworkAxios.post(
      `/test-cases/${payload.instanceId}/skip`,
      payload.reason,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return data;
  },
  uploadFile: async (payload: {
    organizationId: string;
    testCaseInstanceId: string;
    stepId: string;
    imageDefinitionId: string;
    file: File;
  }): Promise<void> => {
    const { organizationId, testCaseInstanceId, stepId, imageDefinitionId, file } = payload;
    const formData = new FormData();
    formData.append('file', file);
    await testFrameworkAxios.post(
      `/utility/upload-image/${organizationId}/${testCaseInstanceId}/${stepId}/${imageDefinitionId}`,
      formData,
    );
    return;
  },
};

export default testFrameworkService;
