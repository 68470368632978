import { MantineTheme, Sx } from '@mantine/core';

export const pageHeaderStyles = (theme: MantineTheme): Sx => {
  return {
    width: '100%',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.sm,
    boxShadow:
      '0px 4px 8px 0px rgba(32, 35, 45, 0.03), 0px 2px 4px 0px rgba(32, 35, 45, 0.02), 0px 1px 2px 0px rgba(32, 35, 45, 0.01)',

    '.back': {
      fontSize: theme.fontSizes.sm,
      color: theme.colors.grubtech[1],
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: `calc(${theme.spacing.xs} / 3)`,
    },
    '.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '24px',
    },
    '.description': {
      fontSize: theme.fontSizes.sm,
      marginTop: `${theme.spacing.xs}`,
    },
  };
};
