import { documentationAxios } from '../config/axios.config';

const documentationService = {
  getDocumentationAuth: async (userEmail: string): Promise<any> => {
    const { data } = await documentationAxios.get(`/authToken/byEmail/${userEmail}`);
    return data;
  },
};

export default documentationService;
