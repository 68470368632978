import { IntegrationId } from './integration';
import { Endpoints } from './webhook';

export type ApiKey = {
  id: string;
  organizationId: string;
  projectId: string;
  integration: IntegrationId;
  apiSuiteId: ApiSuiteId;
  apiKeyName: string;
  referenceName: string;
  apiKeyId: string;
  apiKeyValue: string;
  type: string;
};

export type ApiKeyGenerationRequest = {
  integration: IntegrationId;
  organizationId: string;
  apiSuiteId: ApiSuiteId;
  projectId: string;
};

export type ApiSuiteDefinition = {
  id: ApiSuiteId;
  name: string;
  description: string;
  integration: IntegrationId;
  baseUrl: string;
  endpoints: Endpoints;
  usagePlan: string;
};

export type ApiSuiteId = {
  id: string;
};

export enum ApiSubStatus {
  ORDER = 'ORDER',
  DELIVERY = 'DELIVERY',
  POS = 'POS',
}
