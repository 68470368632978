import { useState } from 'react';
import {
  useMantineTheme,
  LoadingOverlay,
  Paper,
  Flex,
  Button,
  Text,
  Table,
  TextInput,
  NativeSelect,
  Box,
  Grid,
  Pagination,
  ActionIcon,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';
import { notifications } from '@mantine/notifications';
import ndaService from '../../service/nda.service';
import { ndaActionCreator } from '../../redux/actions/nda';
import { NdaStatus } from '../../models/nda';
import { tableNavigate } from '../../assets/images';
import NDADocumentPage from '../ManageApiAccess/NDADocumentPage/NDADocumentPage';
import { IconFolderPlus, IconPlus, IconSearch } from '@tabler/icons-react';
import organizationService from '../../service/organizations.service';
import { organizationActionCreator } from '../../redux/actions/organization';
import { projectService } from '../../service';
import integratioService from '../../service/integration.service';
import { integrationActionCreator, testFrameworkActionCreator } from '../../redux/actions';
import { useProjectContext } from '../../context/ProjectContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import usePagination from '../../hooks/usePagination';
import { ProjectSummery } from '../../models/Project';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageLayout from '../../components/PageLayout/PageLayout';
import { manageProjectsStyles } from './style';

const ManageProjects = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setActiveProject } = useProjectContext();
  setActiveProject(null);

  const { integrationDefinitions } = {
    integrationDefinitions: [
      { value: '', name: 'All Types' },
      { value: 'DELIVERY', name: 'DELIVERY' },
      { value: 'ORDER', name: 'ORDER' },
      { value: 'POS', name: 'POS' },
    ],
  };
  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const { data: ndaStatus, isLoading: isLoadingNdaStatus } = useQuery({
    queryKey: ['get-nda-status', userData.organizationId],
    queryFn: () => ndaService.getNdaStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(ndaActionCreator.changeNdaStatus(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId, ''),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const convertEnumToString = (value: string) => {
    const str = value
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/api/g, 'API')
      .replace(/web hooks/g, 'webhooks')
      .replace(/contract/g, 'NDA');
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [integrationType, setIntegrationType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [keyword] = useState('');

  const [listOfData, page, pageSize, hasMore, totalPages, setTotalPages, updatePage, updateList] =
    usePagination();

  const { isFetching } = useQuery({
    queryKey: [
      'all-projects',
      userData.organizationId,
      page,
      pageSize,
      integrationType,
      searchQuery,
      keyword,
    ],
    queryFn: ({ signal = new AbortController().signal }) =>
      projectService.getAllProjectsByOrganizationId(
        userData.organizationId,
        page,
        pageSize,
        signal,
        integrationType,
        searchQuery,
        keyword,
      ),
    onSuccess: (data: ProjectSummery) => {
      setTotalPages(data.totalPages);
      updateList(data.content);
      dispatch({ type: 'GET_PROJECTS', payload: data.content });
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData([]));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const onCreateProjectClick = () => {
    navigate('/manage-projects/new');
  };

  const isCreateDisabled = () => {
    return false;
  };

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  if (ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED) {
    return (
      <PageLayout
        id={'manage-project-page'}
        sx={manageProjectsStyles(theme)}
        headerSection={
          <PageHeader
            id={'manage-project-page'}
            title={'Manage Projects'}
            backText={userData.hasActiveApi ? 'Dashboard' : ''}
            onClickBack={() => navigate('/')}
          />
        }
      >
        <LoadingOverlay
          visible={
            isLoadingNdaStatus ||
            isLoadingOrganization ||
            isFetching ||
            isLoadingIntegrationDefinitions ||
            isFetching
          }
        />
        <Box sx={manageProjectsStyles(theme)}></Box>

        <Paper shadow="xs" className="sandbox-sites-paper" id="table-container-manage-projects">
          {listOfData?.length === 0 && integrationType === '' && searchQuery === '' ? (
            <Flex
              className="sandbox-sites-empty-state"
              direction="column"
              align="center"
              justify="center"
              gap={20}
            >
              <IconFolderPlus color={'gray'} size={48} />
              <Text weight={'bold'} data-cy="no-sandbox-text">
                You haven't created any project yet
              </Text>
              <Button
                leftIcon={<IconPlus size={16} />}
                onClick={onCreateProjectClick}
                data-cy="create-new-site-button-empty-state"
                disabled={isCreateDisabled()}
              >
                Create Project
              </Button>
            </Flex>
          ) : (
            <>
              <Grid mt={20} style={{ width: '100%', justifyContent: 'space-between' }}>
                <Grid.Col md={2}>
                  <NativeSelect
                    value={integrationType}
                    onChange={(event) => setIntegrationType(event.currentTarget.value)}
                    className="integration-type-dropdown"
                    data={integrationDefinitions.map((definition) => ({
                      value: definition.value,
                      label: definition.name,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col md={4}>
                  <TextInput
                    type="text"
                    placeholder="Search By Project Name"
                    className="search-input"
                    icon={<IconSearch size={14} stroke={1.5} />}
                    style={{ marginLeft: '10px' }}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.currentTarget.value)}
                  />
                </Grid.Col>

                <Grid.Col md={6}>
                  <Flex justify="end" className="button-container">
                    <Button
                      leftIcon={<IconPlus size={16} />}
                      onClick={onCreateProjectClick}
                      data-cy="create-new-site-button-empty-state"
                      disabled={isCreateDisabled()}
                    >
                      Create Project
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
              <InfiniteScroll
                dataLength={listOfData?.length || 0}
                next={() => {}}
                hasMore={hasMore}
                loader={<></>}
                scrollableTarget="table-container-manage-projects"
              >
                <Table className="table-container" verticalSpacing={'md'} horizontalSpacing={'md'}>
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th>Integration Suite</th>
                      <th>Stage</th>
                      <th>Create Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfData?.map((project: any) => (
                      <tr key={project.id}>
                        <td>{project.name}</td>
                        <td>{project.integrationId}</td>
                        <td>{convertEnumToString(project?.partnerIntegrationStatuses)}</td>
                        <td>
                          {project.createdAt && !isNaN(Date.parse(project.createdAt.toString()))
                            ? new Date(project.createdAt.toString()).toLocaleDateString()
                            : 'Invalid Date'}
                        </td>
                        <td>
                          <ActionIcon
                            variant="transparent"
                            onClick={() => {
                              navigate(`/projects/${project.id}/dashboard`);
                            }}
                          >
                            <img src={tableNavigate} alt="icon-click" />
                          </ActionIcon>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </>
          )}
        </Paper>
        {listOfData?.length > 0 && (
          <Flex justify="center" align="center">
            <Pagination
              className=""
              total={totalPages}
              withControls={false}
              onChange={(page) => updatePage(--page)}
              siblings={2}
            />
          </Flex>
        )}
      </PageLayout>
    );
  }
  return <NDADocumentPage />;
};

export default ManageProjects;
