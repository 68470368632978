import { FC, ReactNode } from 'react';
import { BackgroundImage, Box, Grid, Sx, useMantineTheme } from '@mantine/core';
import { loginPagesLayoutStyles } from './styles';
import { gtLogoSmall, loginlogoNew } from '../../assets/images';
import LanguageChanger from '../LanguageChanger/LanguageChanger';

export type LoginPagesLayoutProps = {
  id: string;
  children: ReactNode;
  sx?: Sx;
};

const LoginPagesLayout: FC<LoginPagesLayoutProps> = ({ id, children, sx }) => {
  const theme = useMantineTheme();

  return (
    <Box sx={loginPagesLayoutStyles(sx, theme)} id={id}>
      <Grid>
        <Grid.Col span={5} className="contents">
          {id !== 'login-page' && (
            <img className="signPage__logo" alt="grubtech-logo" src={gtLogoSmall} />
          )}
          {children}
        </Grid.Col>
        <Grid.Col span={7} className="logo">
          <BackgroundImage src={loginlogoNew} className="login-backgroundImage" />
        </Grid.Col>
      </Grid>
      <LanguageChanger />
    </Box>
  );
};

export default LoginPagesLayout;
