import { FC } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconAlertTriangle, IconX } from '@tabler/icons-react';
import { confirmationDialogStyles } from './styles';

export type ConfirmationDialogProps = {
  id: string;
  opened: boolean;
  close: () => void;
  title?: string;
  contentText?: string;
  onClickCancel?: () => void;
  onClickConfirm?: () => void;
  onClickDoNotShow?: React.ChangeEventHandler<HTMLInputElement>;
  isDoNotShowChecked?: boolean;
  showDoNotShowCheckbox?: boolean;
  variant?: 'primary' | 'warning' | 'danger';
  confirmText?: string;
  cancelText?: string;
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  id,
  opened,
  close,
  title = 'Are You Sure?',
  contentText = 'Are you really sure that you want to confirm this?',
  onClickConfirm,
  onClickCancel,
  onClickDoNotShow,
  isDoNotShowChecked,
  showDoNotShowCheckbox,
  variant = 'primary',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  const theme = useMantineTheme();

  const variantIcon = {
    primary: <IconAlertTriangle width={32} height={32} color="white" />,
    danger: <IconX width={32} height={32} color="white" />,
    warning: <IconAlertTriangle width={32} height={32} color="white" />,
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      id={id}
      withCloseButton={false}
      size={'550px'}
      centered
      sx={confirmationDialogStyles(theme)}
    >
      <Flex
        className="confirmationDialog__container"
        direction={'column'}
        justify={'center'}
        align={'center'}
      >
        <Box className={`confirmationDialog__iconBox confirmationDialog__iconBox--${variant}`}>
          {variantIcon[variant]}
        </Box>
        <Flex className="confirmationDialog__textContainer" direction={'column'} align={'center'}>
          <Title order={3}>{title}</Title>
          <Text fz={'sm'} pt={12} pb={24}>
            {contentText}
          </Text>
        </Flex>
        {!!onClickDoNotShow && showDoNotShowCheckbox ? (
          <>
            <Box className="confirmationDialog__checkBox">
              <Checkbox
                label="Do not show again"
                onChange={onClickDoNotShow}
                checked={isDoNotShowChecked}
              />
            </Box>
            <Divider my="md" className="confirmationDialog__divider" />
          </>
        ) : null}
        <Flex
          gap={32}
          className={`confirmationDialog__buttons confirmationDialog__buttons--${variant}`}
        >
          <Button size="lg" variant="outline" onClick={onClickCancel}>
            {cancelText}
          </Button>
          <Button size="lg" onClick={onClickConfirm} color={'red'} variant="filled">
            {confirmText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ConfirmationDialog;
