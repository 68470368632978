import { apiKeyAxios } from '../config/axios.config';
import { ApiKey, ApiKeyGenerationRequest } from '../models/apiKey';

const apikeyGoLiveService = {
  getApiKeysbyIntegrationAndOrganizationId: async (
    integrationId: string,
    organizationId: string,
    projectId: string,
  ): Promise<ApiKey[]> => {
    const { data } = await apiKeyAxios.get(
      `/go-live/keys/${integrationId}/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
  generateApiKey: async (payload: ApiKeyGenerationRequest, projectId: string): Promise<ApiKey> => {
    const { data } = await apiKeyAxios.post(`/go-live/keys/projects/${projectId}`, payload);
    return data;
  },
};

export default apikeyGoLiveService;
