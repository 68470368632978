export enum NdaStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export type DataWithFileName = {
  data: ArrayBuffer;
  fileName: string;
};

export type NdaDetails = {
  ndaStatus: string;
  ndaSubmittedAt: string;
  ndaApprovedAt: string;
};
