import { Box } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { pageLayoutStyles } from './styles';
import { Sx } from '@mantine/core';

export type PageLayoutProps = {
  id: string;
  children: ReactNode;
  sx?: Sx;
  headerSection?: ReactNode;
  isTabs?: boolean;
};

const PageLayout: FC<PageLayoutProps> = ({ id, children, sx, headerSection, isTabs = false }) => {
  return (
    <Box id={id} sx={pageLayoutStyles(sx, isTabs)}>
      <div className="header-section">{headerSection}</div>
      <div className="pagelayout-content">{children}</div>
    </Box>
  );
};

export default PageLayout;
