import { useQuery } from '@tanstack/react-query';
import maintenanceStatusService from '../service/maintenanceStatus.service';
import { warningToast } from '../utils/toastUtils';

const useMaintenanceCheckStatus = () => {
  useQuery({
    queryKey: ['get-maintenance-status'],
    queryFn: () => maintenanceStatusService.fetchLatestMainteanceStatus(),
    onSuccess: (data) => {
      if (data.length > 0) {
        warningToast({
          title: 'Ongoing System Update',
          message:
            'Apologies for any service disruptions, we are aware and investigating the issue. Once resolved, the notification will no longer be displayed. Thank you for your patience and understanding.',
        });
      }
    },
    onError: (error: any) => {},
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });
};

export default useMaintenanceCheckStatus;
