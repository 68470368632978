import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  Box,
  Button,
  LoadingOverlay,
  PasswordInput,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { object as validateObject, string as validateString } from 'yup';

import { NormalLoginFlow } from '../../../models/user';
import { authenticationActionCreator, loaderActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';

import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';
import SocialSignButton, {
  SocialType,
} from '../../../components/SocialLoginButton/SocialSignButton';
import WelcomeHeader from '../../../components/WelcomeHeader';

import { useMutation } from '@tanstack/react-query';
import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import documentationService from '../../../service/documentation.service';
import { userService } from '../../../service';

const DocumentationLogin = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const dispatch = useDispatch();
  const [visible, { toggle }] = useDisclosure(false);

  const { values, touched, errors, handleBlur, handleChange, isValid } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: () => {},
    validationSchema: validateObject().shape({
      email: validateString().trim().required('Email required').email('Must be valid email'),
      password: validateString().trim().required('Password required'),
    }),
    validateOnMount: true,
  });

  useEffect(() => {
    authenticationActionCreator.changeNormalLoginFlow(NormalLoginFlow.EMAIL_VERIFIED);
  }, []);

  const handleGoogleLogin = () => {
    dispatch(authenticationActionCreator.changeIsFromDocumentationLogin(true));
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      .then(console.log)
      .catch(console.log);
  };

  useEffect(() => {
    if (!!userData?.email?.trim()) {
      documentationAuthRequest.mutate(userData.email);
    }
  }, []);

  const documentationAuthRequest = useMutation({
    mutationFn: documentationService.getDocumentationAuth,
    onSuccess: (data) => {
      if (!!data && data?.authToken) {
        window.location.href = `https://grubtech.readme.io?auth_token=${data.authToken}`;
        return;
      }
      window.location.href = `https://grubtech.readme.io`;
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
      window.location.href = `https://grubtech.readme.io`;
    },
  });

  const { normalLoginFlowStep } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => {
      return state.authentication;
    },
  );

  const handleLogin = async () => {
    try {
      if (normalLoginFlowStep === NormalLoginFlow.INITIAL) {
        dispatch(authenticationActionCreator.changeNormalLoginFlow(NormalLoginFlow.EMAIL_VERIFIED));
      }
      dispatch(loaderActionCreator.changeLoaderStatus(true));
      const currentUser = await Auth.signIn(values.email, values.password);

      if (!currentUser) {
        navigate('/documentation-login');
        dispatch(loaderActionCreator.changeLoaderStatus(false));
      } else {
        const decoded: any = jwt_decode(
          currentUser.getSignInUserSession()?.getIdToken().getJwtToken(),
        );
        dispatch(loaderActionCreator.changeLoaderStatus(false));
        documentationAuthRequest.mutate(decoded.email);
        userService
          .getUserByEmail(decoded?.email)
          .then((res) => {
            if (res.organizationId === null || !res.hasActiveApi) {
              dispatch(
                authenticationActionCreator.changeAuthData({
                  isAuthenticated: true,
                  userData: {
                    userId: res?.id,
                    organizationId: res?.organizationId ?? null,
                    email: decoded?.email,
                    hasActiveApi: res.hasActiveApi,
                    name: res.name ?? '',
                  },
                }),
              );
              //   navigate('/get-started');
              dispatch(loaderActionCreator.changeLoaderStatus(false));
              return;
            }
            dispatch(
              authenticationActionCreator.changeAuthData({
                isAuthenticated: true,
                userData: {
                  userId: res?.id,
                  organizationId: res?.organizationId,
                  email: decoded?.email,
                  hasActiveApi: res.hasActiveApi,
                  name: res.name ?? '',
                },
              }),
            );
            dispatch(loaderActionCreator.changeLoaderStatus(false));
            // navigate('/');
          })
          .catch(() => {
            dispatch(loaderActionCreator.changeLoaderStatus(false));
          });
      }
    } catch (error: any) {
      dispatch(loaderActionCreator.changeLoaderStatus(false));
      notifications.show({
        title: error.message ?? 'Something went wrong',
        message: '',
        color: 'red',
      });
      navigate('/documentation-login');
    }
  };

  return (
    <LoginPagesLayout sx={signSectionStyles(theme)} id={'login-page'}>
      <LoadingOverlay visible={documentationAuthRequest.isLoading} />
      <Box className="signPage__container">
        <Box className="signPage__content">
          <WelcomeHeader lowerBlueMessage="Developer Portal Documentation" />
          <Box className="signPage__emailSection">
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Email"
                size="md"
                value={values.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched['email'] && errors['email']}
                data-cy="sign-in-email-field"
                sx={signTextBoxStyles(theme)}
              />
            </Box>

            {normalLoginFlowStep === NormalLoginFlow.EMAIL_VERIFIED && (
              <>
                <Box className="signPage__input">
                  <PasswordInput
                    variant="unstyled"
                    placeholder="Password"
                    size="md"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched['password'] && errors['password']}
                    visible={visible}
                    onVisibilityChange={toggle}
                    data-cy="sign-in-password-field"
                    sx={signTextBoxStyles(theme)}
                  />
                </Box>
                {/* <div className="navigation-text-container">
                  <NavigationText
                    to="/forgot-password"
                    navigationText="Forgot password?"
                    align="end"
                  />
                </div> */}
              </>
            )}
            {normalLoginFlowStep === NormalLoginFlow.INITIAL && (
              <Button
                onClick={handleLogin}
                size="lg"
                disabled={errors['email'] ? true : false}
                data-cy="sign-in-verify-email-buttton"
                className="signPage__verifyButton"
              >
                Verify Email
              </Button>
            )}
            {normalLoginFlowStep === NormalLoginFlow.EMAIL_VERIFIED && (
              <>
                <Button
                  onClick={handleLogin}
                  size="lg"
                  disabled={!isValid}
                  data-cy="sign-in-submit-button"
                >
                  Login
                </Button>

                <Text className="signPage__orText" align="center">
                  or
                </Text>
                <Box className="signPage__socialButtons">
                  <SocialSignButton
                    socialType={SocialType.GOOGLE}
                    data-cy="sign-in-google-button"
                    text="Log In with Google"
                    onClick={handleGoogleLogin}
                  />
                  {/* <SocialSignButton
                    socialType={SocialType.FACEBOOK}
                    data-cy="sign-in-facebook-button"
                    text="Log In with Facebook"
                    onClick={handleFacebookLogin}
                  /> */}
                  {/* <NavigationText
                    to={'/signup'}
                    questionText={`Didn’t have an account?`}
                    navigationText={`Sign Up`}
                  /> */}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default DocumentationLogin;
