import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { string as validateString, object as validateObject } from 'yup';
import { Button, LoadingOverlay, TextInput, useMantineTheme, Box, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';
import WelcomeHeader from '../../../components/WelcomeHeader';
import NavigationText from '../../../components/NavigationText';
import SocialSignButton, {
  SocialType,
} from '../../../components/SocialLoginButton/SocialSignButton';

import userOnboardingService from '../../../service/userOnboarding.service';
import { OnBoardingProgressState } from '../../../models';
import { persistor } from '../../../redux/store';

import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import { useState } from 'react';

const Signup = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [signUpError, setSignUpError] = useState('');

  const { values, errors, setFieldValue, isValid, resetForm, dirty } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validateObject().shape({
      email: validateString()
        .trim('Email is required!')
        .required('Email is required!')
        .email('Invalid email address!'),
    }),
    onSubmit: () => {},
  });

  const submitEmail = useMutation({
    mutationFn: (email: string) => {
      return userOnboardingService.verifyEmail(email);
    },
    onSuccess: async (data: any) => {
      if (data.onBoardingProgressState === OnBoardingProgressState.EMAIL_SUBMITTED) {
        navigate('/otp-verification', { state: { progress: data } });
      } else if (data.onBoardingProgressState === OnBoardingProgressState.EMAIL_VERIFIED) {
        navigate(`/signup-details`, { state: { progress: data } });
      } else if (data.errorCode) {
        const notificationTitle = data?.message?.includes('Please provide a unique email')
          ? 'Please provide a unique email. Entered email address is already in use.'
          : data?.message ?? 'Something went wrong';
        setSignUpError(notificationTitle);
        // notifications.show({
        //   title: notificationTitle,
        //   message: '',
        //   color: 'red',
        // });
      } else {
        persistor.purge();
        await Auth.signOut()
          .then(async (data) => {
            await persistor.purge();
            await persistor.flush();
            await persistor.pause();
            window.location.reload();
            navigate(`/login`, { state: { progress: data } });
          })
          .catch((e) => {
            console.error(e);
          });
        navigate(`/login`, { state: { progress: data } });
      }
      resetForm();
    },
    onError: (error: any) => {
      setSignUpError(error.name ?? 'Something went wrong');
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const handleGoogleSignUp = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      .then(console.log)
      .catch(console.log);
  };

  // const handleFacebookSignUp = () => {
  //   Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
  //     .then(console.log)
  //     .catch(console.log);
  // };

  return (
    <LoginPagesLayout sx={signSectionStyles(theme)} id={'signup-page'}>
      <LoadingOverlay visible={submitEmail.isLoading} />
      <Box className="signPage__container">
        <Box className="signPage__content">
          <WelcomeHeader />
          <Box className="signPage__emailSection">
            {signUpError ? (
              <Text color={theme.colors.red[6]} fz={theme.fontSizes.sm}>
                {signUpError}
              </Text>
            ) : null}
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Email"
                size="md"
                required
                value={values.email}
                error={dirty && errors.email}
                name="email"
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                  setSignUpError('');
                }}
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <Button
              size="lg"
              disabled={!isValid || values?.email?.length <= 0}
              onClick={() => {
                submitEmail.mutate(values.email);
              }}
            >
              Verify email
            </Button>
            <Text className="signPage__orText" align="center">
              or
            </Text>
            <Box className="signPage__socialButtons">
              <SocialSignButton
                socialType={SocialType.GOOGLE}
                data-cy="sign-in-google-button"
                text="Sign Up with Google"
                onClick={handleGoogleSignUp}
              />
              {/* <SocialSignButton
                socialType={SocialType.FACEBOOK}
                data-cy="sign-in-facebook-button"
                text="Sign Up with Facebook"
                onClick={handleFacebookSignUp}
              /> */}
              <NavigationText
                to={'/login'}
                questionText={`Already have an account?`}
                navigationText={`Log in`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default Signup;
