import { MantineTheme, Sx } from '@mantine/core';

export const appStoreConfirmationDialogStyles = (theme: MantineTheme): Sx => {
  return {
    '.mantine-Modal-title': {
      fontSize: '24px',
      fontWeight: 600,
    },

    '.description': {
      fontSize: '16px',
    },

    '.button-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '12px 0 0 0',
    },
  };
};
