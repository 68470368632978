import { Tabs } from '@mantine/core';
import PageLayout from '../../components/PageLayout';
import PageHeader from '../../components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Metrics from './Metrics';
import Logs from './Logs';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { tabValue } = useParams();

  return (
    <PageLayout
      id={'dashboard-page'}
      headerSection={<PageHeader id={'dashboard-page'} title={'Dashboard'} />}
      isTabs={true}
    >
      <Tabs
        color="teal"
        defaultValue="metrics"
        value={tabValue}
        onTabChange={(value) => navigate(`/dashboard/${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="metrics" data-cy="metrics-tab">
            Metrics
          </Tabs.Tab>
          <Tabs.Tab value="logs" data-cy="logs-tab">
            Logs
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="metrics" pt="xs">
          <Metrics />
        </Tabs.Panel>

        <Tabs.Panel value="logs" pt="xs">
          <Logs />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

export default Dashboard;
