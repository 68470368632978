import { MantineTheme, Sx } from '@mantine/core';

export const layoutStyles = (theme: MantineTheme, isPublicPath: boolean): Sx => {
  return {
    width: '100% !important',
    height: '100vh !important',
    display: 'flex',
    flexDirection: 'row',

    '.main': {
      width: '100%',
      background: theme.colors.grubtech[2],
    },
  };
};
