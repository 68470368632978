import { MantineTheme, Sx } from '@mantine/core';

export const uatStyles = (theme: MantineTheme): Sx => {
  return {
    '.production-request-button': {
      marginRight: '1rem',
      height: '34px',
    },
    '.configuration-button': {
      marginLeft: 'auto',
      height: '34px',
      color: theme.colors.grubtech[0],
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%' /* 18px */,
      textDecorationLine: 'underline',

      '.mantine-Button-leftIcon': {
        color: theme.colors.grubtech[0],
      },

      ':disabled': {
        background: theme.colors.white[0],
        color: theme.colors.gray[5],
        '.mantine-Button-leftIcon': {
          color: theme.colors.gray[5],
        },
      },

      '.mantine-Button-icon': {
        marginRight: '0.2rem',
      },
      ':hover': {
        background: 'transparent',
      },
    },
  };
};
