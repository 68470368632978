import { FC } from 'react';
import { Grid, TextInput } from '@mantine/core';

type ParameterKeyValueInputProps = {
  id: string;
  keyVal: string;
  valueVal: string;
  onKeyChange: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange: React.ChangeEventHandler<HTMLInputElement>;
  isDisabled?: boolean;
};

const ParameterKeyValueInput: FC<ParameterKeyValueInputProps> = ({
  keyVal,
  valueVal,
  onKeyChange,
  onValueChange,
  id,
  isDisabled,
}) => {
  return (
    <Grid gutter={10}>
      <Grid.Col span={6}>
        <TextInput
          placeholder="Key"
          className="oAuth-fields__input"
          value={keyVal}
          onChange={onKeyChange}
          data-cy={`webhook-urls-oAuthKey-${id}`}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          placeholder="Value"
          className="oAuth-fields__input"
          value={valueVal}
          onChange={onValueChange}
          data-cy={`webhook-urls-oAuthVal-${id}`}
          disabled={isDisabled}
        />
      </Grid.Col>
    </Grid>
  );
};

export default ParameterKeyValueInput;
