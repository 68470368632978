import { MantineTheme, Sx } from '@mantine/core';

export const metricsStyles = (theme: MantineTheme): Sx => {
  return {
    '.metrics-env-select': {
      width: 225,
      '.mantine-Select-input': {
        backgroundColor: theme.colors.grubtech[0],
        color: 'white',
        fontWeight: 'bold',
      },
      '.mantine-Select-rightSection': {
        color: 'white',
      },
    },
    '.metrics-paper': {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
      marginTop: theme.spacing.xl,
    },
    '.metrics-error': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing.md,

      '.mantine-Alert-root': {
        width: '100%',
      },
    },
  };
};
