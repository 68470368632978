import { Box, Button, PasswordInput, TextInput, Title, useMantineTheme } from '@mantine/core';
import { useFormik } from 'formik';
import { object as validateObject, string as validateString } from 'yup';
import BackText from '../../../components/BackText';
import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';
import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import { Auth } from 'aws-amplify';
import { notifications } from '@mantine/notifications';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { values, isValid, touched, errors, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      validationCode: '',
      password: '',
    },
    validationSchema: validateObject().shape({
      validationCode: validateString()
        .trim('validation code required')
        .required('validation code required'),
      password: validateString()
        .trim()
        .required('Password is required!')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/,
          'Password must be at least 8 characters, contain at least one lowercase character, one uppercase character, and one number or symbol',
        ),
    }),
    onSubmit: (e) => {
      console.log('lol');
    },
    validateOnMount: true,
  });

  const handleSubmit = () => {
    Auth.forgotPasswordSubmit(userData.email, values.validationCode, values.password)
      .then((e) => {
        navigate('/login');
      })
      .catch((error) => {
        notifications.show({
          title: error.name ?? 'Something went wrong',
          message: error.message ?? 'Something went wrong',
          autoClose: 2000,
          color: 'red',
        });
      });
  };

  return (
    <LoginPagesLayout sx={{ ...signSectionStyles(theme) }} id={'reset-password-page'}>
      <Box className="signPage__container">
        <Box className="signPage__content">
          <BackText to="/forgot-password" />
          <Title size={52} color={theme.colors.dark[9]}>
            Reset Password
          </Title>

          <Box className="signPage__emailSection">
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Validation code"
                size="md"
                value={values.validationCode}
                name="validationCode"
                onChange={(e) => {
                  setFieldValue('validationCode', e.target.value);
                }}
                onBlur={handleBlur}
                error={touched.validationCode && errors.validationCode}
                data-cy="reset-password-validation-code-field"
                sx={signTextBoxStyles(theme)}
                autoComplete="new-password"
              />
            </Box>
            <Box className="signPage__input">
              <PasswordInput
                variant="unstyled"
                placeholder="Password"
                size="md"
                required
                value={values.password}
                error={touched.password && errors.password}
                name="password"
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                }}
                onBlur={handleBlur}
                sx={signTextBoxStyles(theme)}
                data-cy="password-field"
                autoComplete="new-password"
              />
            </Box>
            <Button
              onClick={handleSubmit}
              size="lg"
              disabled={!isValid}
              data-cy="forgot-password-submit-button"
              mt={
                touched.password &&
                errors.password ===
                  'Password must be at least 8 characters, contain at least one lowercase character, one uppercase character, and one number or symbol'
                  ? '2rem'
                  : '0rem'
              }
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default ForgotPassword;
