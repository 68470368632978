import { MantineTheme, Sx } from '@mantine/core';

export const cardStyles = (theme: MantineTheme): Sx => {
  return {
    // '.content': {
    //   height: '93%',
    // },
    '.title': {
      marginBottom: theme.spacing.xs,
    },
    '.definition': {
      marginBottom: theme.spacing.xl,
      fontSize: theme.fontSizes.sm,
    },
    '.status': {
      marginBottom: theme.spacing.xl,
    },
    '.status-title': {
      marginBottom: theme.spacing.xs,
    },
    '.sub-status': {
      marginBottom: theme.spacing.xl,
    },
    '.sub-status-element': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.fontSizes.sm,
      marginBottom: theme.spacing.xs,
    },
    '.sub-status-element-text': {
      marginRight: theme.spacing.xs,
      fontSize: theme.fontSizes.sm,
    },
    '.sub-status-element-icon-container': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    '.button-section': {
      padding: `0 ${theme.spacing.md}`,
      marginBottom: '16px',
      display: 'flex',
      justifyContent: 'center',
    },
    '.read-more-section': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors.grubtech[0],
      cursor: 'pointer',
    },
    '.read-more-text': {
      marginRight: theme.spacing.xs,
      fontSize: theme.fontSizes.sm,
    },
  };
};
