import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { TestFrameworkState } from '../redux/reducers';
import { TestCaseDefinitionSummaryDto } from '../models';

const useSelectedTestDefinition = () => {
  const [selectedDefinitionData, setSelectedDefinitionData] = useState<
    TestCaseDefinitionSummaryDto | undefined
  >(undefined);

  const {
    selectedTestCase,
    testSuiteDefinitions,
    selectedFlow,
    selectedSubFlow,
    selectedTestSuite,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  useEffect(() => {
    const testCase = testSuiteDefinitions
      ?.find((suite) => suite.id === selectedTestSuite)
      ?.flows?.find((flow) => flow.id === selectedFlow)
      ?.subFlows?.find((subflow) => subflow.id === selectedSubFlow)
      ?.testCases?.find((testCase) => testCase.id === selectedTestCase);
    setSelectedDefinitionData(testCase);
  }, [selectedTestCase, testSuiteDefinitions, selectedFlow, selectedSubFlow, selectedTestSuite]);

  return selectedDefinitionData;
};

export default useSelectedTestDefinition;
