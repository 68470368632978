import { MantineThemeOverride } from '@mantine/core';

export const getCustomTheme = (): MantineThemeOverride => {
  return {
    fontFamily: `'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`,
    loader: 'bars',
    colors: {
      grubtech: [
        '#243ffa',
        '#2639bf',
        '#f8f9fd',
        '#f5f6fa',
        '#7A7A7A',
        '#BFBFBF',
        '#e4e8ff',
        '#A8ABB4',
        '#F70F00',
      ],
      white: ['#FFF'],
      black: ['#000000'],
      font: ['#414042', '#E4E8FF', '#ACAEB9', '#919191', '#B61D4C', '#E87F15', '#20232D'],
      background: ['#414042', '#FFF9F3', '#EBECF2'],
      status: ['#76C150', '#5451CC', '#FF9D2B', '#EC5454', '#2760B6'],
      statusBackground: ['#EBF1E8', '#E9EDF5', '#ff9d2b14', '#F5E9E9', '#2760b614'],
      border: ['#DEDFE7', '#E6668D'],
      toastColor: ['#fcc419', '#fa5252', '#243FFA'],
    },
    components: {
      Button: {
        variants: {
          filled: (theme) => ({
            root: {
              fontWeight: 700,
              backgroundColor: theme.colors.grubtech[0],

              '&:hover': {
                backgroundColor: theme.colors.grubtech[1],
              },
            },
          }),
          outline: (theme) => ({
            root: {
              color: theme.colors.grubtech[0],
              border: `1px solid ${theme.colors.grubtech[0]}`,

              ':hover': {
                color: theme.colors.grubtech[1],
                border: `1px solid ${theme.colors.gray[4]}`,
                backgroundColor: 'unset',
              },
            },
          }),
        },
      },
      Tabs: {
        styles: (theme) => ({
          tabsList: {
            marginBottom: '16px',
            width: '100%',
            background: theme.colors.white[0],
            height: '46px',
            paddingLeft: '1rem',
            borderRadius: '4px',
            borderBottom: '1px solid #EEE',
            alignItems: 'center',
          },
          tab: {
            height: '100%',
            color: 'gray',
            padding: '0',
            marginRight: '24px',

            '&:hover': {
              borderBottom: '0 !important',
              color: theme.colors.grubtech[0],
              backgroundColor: 'transparent',
            },

            '&[data-active]': {
              borderBottom: `2px solid ${theme.colors.grubtech[0]} !important`,
              color: 'black',
              fontWeight: 'bold',
            },
          },
          panel: {
            marginLeft: '1rem',
            marginRight: '1rem',
          },
        }),
      },
      SegmentedControl: {
        styles: (theme) => ({
          controlActive: {
            backgroundColor: theme.colors.grubtech[0],

            '&:hover': {
              backgroundColor: theme.colors.grubtech[1],
            },
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            marginBottom: '8px',
            fontWeight: 'bold',
          },
        }),
      },
      Select: {
        styles: (theme) => ({
          label: {
            marginBottom: '8px',
            fontWeight: 'bold',
          },
        }),
      },
      Anchor: {
        styles: (theme) => ({
          root: {
            color: theme.colors.grubtech[0],
            textDecoration: 'none !important',

            '&:hover': {
              color: theme.colors.grubtech[1],
            },
          },
        }),
      },
      DatePickerInput: {
        styles: (theme) => ({
          input: {
            backgroundColor: theme.colors.grubtech[0],
            color: 'white !important',
            fontWeight: 'bold',
          },
          placeholder: {
            color: 'white !important',
            fontWeight: 'bold',
          },
        }),
      },
      Pagination: {
        styles: (theme) => ({
          control: {
            '&[data-active]': {
              background: theme.colors.grubtech[0],
            },
            '&:hover': {
              background: `${theme.colors.grubtech[0]} !important`,
              color: theme.colors.white[0],
            },
          },
        }),
      },
      Checkbox: {
        styles: (theme) => ({
          input: {
            '&:checked:not(:disabled)': {
              backgroundColor: theme.colors.grubtech[0],
              borderColor: theme.colors.grubtech[0],
            },
          },
        }),
      },
    },
  };
};
