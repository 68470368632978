import {
  Capability,
  IntegrationDefinition,
  IntegrationId,
  PartnerIntegrationStatus,
} from '../../models/integration';
import { Project } from '../../models/Project';
import { IntegartionAction } from '../payloadModels/integration';
import { IntegrationActionTypes } from '../types';

export type IntegrationState = {
  integrationDefinitions: IntegrationDefinition[];
  activatedIntegrations: PartnerIntegrationStatus[];
  pdfPageCount: number;
  isAgreeToTermsAndConditions: boolean;
  capability: Capability | null;
  activationFlowId: IntegrationId | null;
  selectedProject: Project | null;
};

const initialState: IntegrationState = {
  integrationDefinitions: [],
  activatedIntegrations: [],
  pdfPageCount: 0,
  isAgreeToTermsAndConditions: false,
  capability: null,
  activationFlowId: null,
  selectedProject: null,
};

export const integrationReducer = (
  state = initialState,
  action: IntegartionAction,
): IntegrationState => {
  switch (action.type) {
    case IntegrationActionTypes.CHANGE_INTEGRATION_DEFINITIONS:
      return { ...state, integrationDefinitions: action.payload as IntegrationDefinition[] };
    case IntegrationActionTypes.CHANGE_ACTIVATED_INTEGRATIONS:
      return { ...state, activatedIntegrations: action.payload as PartnerIntegrationStatus[] };
    case IntegrationActionTypes.CHANGE_UPDATE_ACTIVATED_INTEGRATIONS:
      return {
        ...state,
        activatedIntegrations: [
          ...state.activatedIntegrations,
          action.payload,
        ] as PartnerIntegrationStatus[],
      };
    case IntegrationActionTypes.CHANGE_IS_AGREE_TO_CONDITIONS:
      return { ...state, isAgreeToTermsAndConditions: action.payload as boolean };
    case IntegrationActionTypes.CHNAGE_INTEGRATION_PDF_PAGES_COUNT:
      return { ...state, pdfPageCount: action.payload as number };
    case IntegrationActionTypes.CHANGE_CAPABILITY:
      return { ...state, capability: action.payload as Capability };
    case IntegrationActionTypes.CHANGE_ACTIVATING_FLOW:
      return { ...state, activationFlowId: action.payload as IntegrationId | null };
    case IntegrationActionTypes.CHANGE_ACTIVATED_PROJECT:
      return { ...state, selectedProject: action.payload as Project };
    default:
      return state;
  }
};
