import { MantineTheme, Sx } from '@mantine/core';

export const partnerPlatformCardStyles = (theme: MantineTheme): Sx => {
  return {
    '.activated-buttons': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },

    '.status-list': {
      li: {
        '.mantine-List-itemWrapper': {
          width: '100%',

          'span:last-child': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            '.text-description': {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              '.link-for-pages': {
                width: '50px',
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                cursor: 'pointer',
                // color: theme.colors.black[0],
              },
            },
          },
        },
      },
    },
    '.activationStatus__text-container': {
      width: '100%',
      '.activationStatus__text-date': {
        width: '100px',
      },
    },
  };
};
