import { MantineTheme, Sx } from '@mantine/core';

export const stepHeaderStyles = (theme: MantineTheme): Sx => {
  return {
    marginBottom: '12px',
    display: 'flex',
    padding: '16px 16px 24px 16px',
    flexDirection: 'column',
    gap: '16px',
    flexShrink: 0,
    borderRadius: '8px',
    background: theme.colors.white[0],
    boxShadow:
      '0px 4px 8px 0px rgba(32, 35, 45, 0.03), 0px 2px 4px 0px rgba(32, 35, 45, 0.02), 0px 1px 2px 0px rgba(32, 35, 45, 0.01)',

    '.step-header-go-to-doc-link': {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: theme.colors.grubtech[0],
      marginRight: '0.5rem',
      cursor: 'pointer',
    },
    '.step-external-link-icon': {
      marginRight: '4px',
    },
    '.go-to-docs-text': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      textDecorationColor: theme.colors.grubtech[0],
      color: theme.colors.grubtech[0],
    },
    '.step-header-text': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      color: theme.colors.grubtech[0],
      gap: '3px',

      '.scenario': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
        alignItems: 'center',
      },
      '.title': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
      },
    },
    '.description': {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      // marginTop: '7px',
      color: theme.colors.font[0],
    },
    '.begin-scenario': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.test-guide': {
      marginTop: '1rem',
    },
    ol: {
      margin: 0,
      marginBottom: '1rem',
    },
    '.description ul li': {
      'list-style-type': 'disc',
    },
  };
};
