import { MantineTheme, Sx } from '@mantine/core';

export const skipConfirmationModalStyles = (theme: MantineTheme): Sx => {
  return {
    '.mantine-Modal-header': {
      display: 'none',
    },
    '.mantine-Modal-body': {
      padding: '24px 32px !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',

      img: {
        width: '73.5px',
        height: '73.5px',
        margin: '0 auto',
        marginBottom: '27.5px',
      },

      '.text-info': {
        color: theme.colors.font[6],
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
      },
      '.mantine-InputWrapper-root': {
        width: '100%',
        marginTop: '15px',
      },
      '.devider': {
        height: '1px',
        width: '100%',
        backgroundColor: theme.colors.background[2],
        margin: '23px 0 20px 0',
      },
      '.button-container': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '32px',
        button: {
          width: 'calc(50% - 16px)',
        },
      },
    },
  };
};
