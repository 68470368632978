import { Box, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogsContainer from '../../../../components/LogsContainer';
import useSelectedTestDefinition from '../../../../hooks/useSelectedTestDefinition';
import { TestCaseApprovalStatusDto, TestCaseStatus, TestStepType } from '../../../../models';
import { testFrameworkActionCreator } from '../../../../redux/actions';
import { RootState } from '../../../../redux/common';
import { TestFrameworkState } from '../../../../redux/reducers';
import { testFrameworkService } from '../../../../service';
import InvocationModal from './InvocationModal';
import Step from './Step';
import StepHeader from './StepHeader';
import { stepStyles } from './style';

interface StepProps {
  scenarioId: string;
  projectId: string;
}

const TestStepContent: React.FC<StepProps> = ({ scenarioId, projectId }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const selectedTextScenarioData = useSelectedTestDefinition();
  const headerRef = useRef(null);
  const logsRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [logsHeight, setLogsHeight] = useState(0);
  const [headerWidth, setHeaderWidth] = useState(0);

  const { selectedTestCase, testCaseExecutionData, selectedInvocationId, testSuiteInstanceData } =
    useSelector<RootState, TestFrameworkState>((state: RootState) => {
      return state.testFramework;
    });

  const { isRefetching: isRefetchingGetTestCaseApprovalStatus } = useQuery({
    queryKey: [
      `get-test-case-approval-status`,
      testSuiteInstanceData?.instanceId ?? '',
      selectedTestCase ?? '',
      testCaseExecutionData?.instanceId ?? '',
    ],
    queryFn: () =>
      testFrameworkService.getTestCaseApprovalStatus(
        testSuiteInstanceData?.instanceId ?? '',
        selectedTestCase ?? '',
        testCaseExecutionData?.instanceId ?? '',
      ),
    onSuccess: (data: TestCaseApprovalStatusDto) => {
      dispatch(testFrameworkActionCreator.changeTestCaseApprovalStatus(data));
    },
    onError: (error: any) => {
      // notifications.show({
      //   title: error.name ?? 'Something went wrong',
      //   message: error.message ?? 'Something went wrong',
      //   autoClose: 2000,
      //   color: 'red',
      // });
    },
    enabled:
      testSuiteInstanceData?.submittedForAdminApproval && testCaseExecutionData?.instanceId
        ? true
        : false,
  });

  useEffect(() => {
    if (testCaseExecutionData?.overallStatus === TestCaseStatus.PENDING) {
      dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(true));
      return;
    }
    dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(false));
    // eslint-disable-next-line
  }, [testCaseExecutionData]);

  useEffect(() => {
    if (!headerRef.current || !logsRef.current) {
      return;
    }

    const resizeObserverHeader = new ResizeObserver(() => {
      if ((headerRef?.current as any)?.offsetHeight !== headerHeight) {
        setHeaderHeight((headerRef?.current as any)?.offsetHeight);
      }
      if ((headerRef?.current as any)?.offsetWidth !== headerWidth) {
        setHeaderWidth((headerRef?.current as any)?.offsetWidth);
      }
    });

    const resizeObserverLogs = new ResizeObserver(() => {
      if ((logsRef?.current as any)?.offsetHeight !== logsHeight) {
        setLogsHeight((logsRef?.current as any)?.offsetHeight);
      }
    });

    resizeObserverHeader.observe(headerRef.current);
    resizeObserverLogs.observe(logsRef.current);

    return function cleanup() {
      resizeObserverHeader.disconnect();
      resizeObserverLogs.disconnect();
    };
    // eslint-disable-next-line
  }, [headerRef.current, logsRef.current]);

  return (
    <Box sx={stepStyles(theme, headerHeight, logsHeight, headerWidth)} id={scenarioId}>
      <LoadingOverlay visible={isRefetchingGetTestCaseApprovalStatus} />
      <InvocationModal
        id={selectedInvocationId ?? ''}
        opened={selectedInvocationId !== undefined}
      />
      <Box className="step-paper">
        <div ref={headerRef}>
          <StepHeader id={`header-${scenarioId}`} />
        </div>

        <div className="steps-container">
          {selectedTextScenarioData?.steps
            .filter((step) => step.type !== TestStepType.BEGIN_TEST_CASE_ACTION)
            .map((step) => {
              if (step.type === TestStepType.VERIFICATION) {
                return null;
              }
              return <Step projectId={projectId} key={step.id} stepId={step.id} />;
            })}
        </div>

        {selectedTestCase === scenarioId && (
          <div className="logs-container" ref={logsRef}>
            <LogsContainer id={`${scenarioId}-logs`} messages={testCaseExecutionData?.logs} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default TestStepContent;
