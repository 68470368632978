import { MantineTheme, Sx } from '@mantine/core';

export const customDialogBoxStyle = (theme: MantineTheme): Sx => {
  return {
    '.customDialog__container': {
      margingTop: 0,
      height: '100%',
      '.customDialog__icon': {
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.grubtech[0],
      },
      '.customDialog__content': {
        width: '100%',
      },
    },
  };
};
