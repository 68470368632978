import { FC } from 'react';
import { string as validateString, object as validateObject } from 'yup';
import { Button, Grid, TextInput } from '@mantine/core';
import { IconWebhook } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { BasicAuthValue } from '../../models';

type BasicAuthFieldsProps = {
  onSave: (values: BasicAuthValue) => void;
  defaultValues: BasicAuthValue | null;
  isParentValid: boolean;
  isDisabled?: boolean;
};

const BasicAuthFields: FC<BasicAuthFieldsProps> = ({
  onSave,
  defaultValues,
  isParentValid,
  isDisabled,
}) => {
  const { values, touched, handleChange, handleBlur, errors, isValid } = useFormik({
    initialValues: defaultValues ?? { userName: '', password: '' },
    onSubmit: (values) => {
      //
    },
    validateOnMount: true,
    validationSchema: validateObject({
      userName: validateString().required('User name is required'),
      password: validateString().required('Password is required'),
    }),
  });

  return (
    <>
      <Grid.Col span={10}>
        <TextInput
          name="userName"
          placeholder="User Name"
          icon={<IconWebhook />}
          value={values.userName}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-basicAuth-userName`}
          error={touched.userName && errors.userName}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <TextInput
          name="password"
          type="password"
          placeholder="Password"
          icon={<IconWebhook />}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-basicAuth-passwordd`}
          error={touched.password && errors.password}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={1} offset={1}>
        <Button
          className="webhooks-button"
          disabled={!isValid || !isParentValid || isDisabled}
          onClick={() => {
            onSave(values);
          }}
          data-cy="webhook-urls-basicAuth-save-button"
          size="sm"
        >
          Save
        </Button>
      </Grid.Col>
    </>
  );
};
export default BasicAuthFields;
