import { FC, ReactNode } from 'react';
import { Box, Title, useMantineTheme, Text, LoadingOverlay } from '@mantine/core';
import { cardStyles } from './style';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import documentationService from '../../service/documentation.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';

export type SubStatusElement = {
  text: string;
  iconText?: string;
  icon: JSX.Element;
  iconLink: string;
};

export type CardProps = {
  id: string;
  title: string;
  definition: string;
  statusTitle?: string;
  statusContent?: ReactNode;
  subStatusTitle: string;
  subStatusElements: SubStatusElement[];
  button: ReactNode;
};

const Card: FC<CardProps> = ({
  id,
  title,
  definition,
  statusTitle,
  statusContent,
  subStatusTitle,
  subStatusElements,
  button,
}) => {
  const theme = useMantineTheme();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const documentationAuthRequest = useMutation({
    mutationFn: (payload: { email: string; url: string }) => {
      return documentationService.getDocumentationAuth(payload.email);
    },
    onSuccess: (data, initialValues) => {
      if (!!data && data?.authToken) {
        window.open(`${initialValues.url}?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getIconClickFn = (url: string) => {
    return () => documentationAuthRequest.mutate({ email: userData.email, url });
  };

  return (
    <Box sx={cardStyles(theme)} id={id} className="content">
      <LoadingOverlay id={'api-acces-card'} visible={documentationAuthRequest.isLoading} />
      <Title order={3} className="title" data-cy={title}>
        {title}
      </Title>
      <Text className="definition">{definition}</Text>
      {statusTitle && statusContent && (
        <div className="status">
          <Title order={6} className="status-title">
            {statusTitle}
          </Title>
          <div className="status-content">{statusContent}</div>
        </div>
      )}
      <div className="sub-status">
        <Title order={6} className="status-title">
          {subStatusTitle}
        </Title>
        <div className="sub-status-content">
          {subStatusElements.map((subStatusElement) => (
            <div key={subStatusElement.text}>
              <div className="sub-status-element">
                {subStatusElement.text}
                <div
                  className="sub-status-element-icon-container"
                  onClick={getIconClickFn(subStatusElement.iconLink)}
                >
                  {subStatusElement.iconText && (
                    <span className="sub-status-element-text">{subStatusElement.iconText}</span>
                  )}
                  {subStatusElement.icon}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="button-section">{button}</div>
    </Box>
  );
};

export default Card;
