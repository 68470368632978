import { useSelector } from 'react-redux';
import PageLayout from '../../../components/PageLayout';
import { RootState } from '../../../redux/common';
import { IntegrationState } from '../../../redux/reducers';
import IntegrationSection from './IntegrationSection';
import GeneratedApiKey from '../GeneratedApiKey';
import { useDisclosure } from '@mantine/hooks';
import { FC } from 'react';
import { ApiSubStatus } from '../../../models';

type SandboxPageNewProp = {
  projectId: string;
};

const SandboxPageNew: FC<SandboxPageNewProp> = ({ projectId }) => {
  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const [
    generatedApiKeyModalOpened,
    { open: openGeneratedApiKeyModal, close: closeGeneratedApiKeyModal },
  ] = useDisclosure(false);

  return (
    <PageLayout id={'api-credentials-sandbox'}>
      <GeneratedApiKey
        id="generated-api-key-modal"
        opened={generatedApiKeyModalOpened}
        close={() => {
          closeGeneratedApiKeyModal();
        }}
      />
      {activatedIntegrations.map(({ integration }) => {
        if (!Object.values(ApiSubStatus).includes(integration.id as ApiSubStatus)) {
          return null;
        }
        return (
          <IntegrationSection
            integrationId={integration.id as ApiSubStatus}
            projectId={projectId}
            key={integration.id}
            openGenerated={openGeneratedApiKeyModal}
          />
        );
      })}
    </PageLayout>
  );
};

export default SandboxPageNew;
