import { MantineTheme, Sx } from '@mantine/core';

export const releaseNotesStyles = (theme: MantineTheme): Sx => {
  return {
    '.release-notes-paper': {
      padding: theme.spacing.xl,

      '.release-notes-image': {
        margin: `0 auto ${theme.spacing.xl} auto`,
      },

      '.release-notes-text': {
        fontSize: theme.fontSizes.sm,
        margin: `${theme.spacing.lg} 0`,
      },
    },
  };
};
