import { WebHookDefinition, WebHookValue } from '../../models/webhook';
import { actionCreator } from '../common';
import { WebhookActionTypes } from '../types';

export const webhookActionCreator = {
  changeWebhookDefinitions: actionCreator<WebHookDefinition[]>(
    WebhookActionTypes.CHANGE_WEBHOOK_DEFINITIONS,
  ),
  changeWebhookvalues: actionCreator<WebHookValue[]>(WebhookActionTypes.CHANGE_WEBHOOK_VALUES),
  changeCheckIsProductionEnabled: actionCreator<Boolean>(
    WebhookActionTypes.CHANGE_CHECK_IS_PRODUCTION_ENABLED,
  ),
  changeWebhookvaluesForProduction: actionCreator<WebHookValue[]>(
    WebhookActionTypes.CHANGE_WEBHOOK_VALUES,
  ),
};
