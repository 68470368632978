import { Box, useMantineTheme } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { testLinkSectionWrapperStyles } from './style';

type TestLinkSectionWrapperProps = {
  id: string;
  title?: string;
  children: ReactNode;
};

const TestLinkSectionWrapper: FC<TestLinkSectionWrapperProps> = ({ id, title, children }) => {
  const theme = useMantineTheme();

  return (
    <Box id={id} sx={testLinkSectionWrapperStyles(theme)}>
      <div className="title">{title}</div>
      <div>{children}</div>
    </Box>
  );
};

export default TestLinkSectionWrapper;
