import { Box, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card, { SubStatusElement } from '../../../components/Card/Card';
import { ActivateIntegrationPayload } from '../../../models/integration';
import {
  authenticationActionCreator,
  integrationActionCreator,
  testFrameworkActionCreator,
} from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';
import { testFrameworkService } from '../../../service';
import integratioService from '../../../service/integration.service';
import { projectTypeCardStyles } from './style';
import { useProjectContext } from '../../../context/ProjectContext';

type ProjectTypeCardProps = {
  id: string;
  availableApiSuiteElements: SubStatusElement[];
  onClick: any;
  isSelected: boolean;
};

const ProjectTypeCard: FC<ProjectTypeCardProps> = ({
  id,
  availableApiSuiteElements,
  onClick,
  isSelected,
}) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { activeProject = { id: '1', name: 'Test Project' } }: any = useProjectContext();
  const { integrationDefinitions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => {
      return state.integration;
    },
  );
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const getTestSuitesByIntegration = useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getTestSuitesByIntegration(integartion, activeProject.id);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const activateIntegration = useMutation({
    mutationFn: (payload: ActivateIntegrationPayload) => {
      return integratioService.activateIntegration(payload);
    },
    onSuccess: (data) => {
      getTestSuitesByIntegration.mutate(data.integration.id);
      dispatch(integrationActionCreator.changeUpdateActivatedIntegration(data));
      dispatch(
        authenticationActionCreator.changeAuthData({
          isAuthenticated: true,
          userData: {
            ...userData,
            hasActiveApi: true,
          },
        }),
      );
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const definitionData = useMemo(
    () => integrationDefinitions.find((definitionData) => definitionData.id.id === id),
    [integrationDefinitions, id],
  );

  return (
    <>
      <Box
        sx={{ ...projectTypeCardStyles(theme), height: '100%' }}
        className={`card-content ${isSelected ? 'selected' : ''}`}
        id={id}
      >
        <LoadingOverlay
          visible={activateIntegration.isLoading || getTestSuitesByIntegration.isLoading}
        />
        <Card
          id="partner-platform-card"
          title={definitionData?.name ?? ''}
          definition={definitionData?.description ?? ''}
          statusTitle="Activation status:"
          statusContent={null}
          subStatusTitle="This Suite includes:"
          subStatusElements={availableApiSuiteElements}
          button={null}
        />
      </Box>
    </>
  );
};

export default ProjectTypeCard;
