import { MantineTheme, Sx } from '@mantine/core';

const fontStylesEndpoint = (theme: MantineTheme): Sx => {
  return {
    color: theme.colors.font[3],
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fonWeight: 400,
    lineHeight: 'normal',
  };
};

export const generatedApiKeyForProductionStyles = (theme: MantineTheme): Sx => {
  return {
    '.generated-api-key-modal': {
      '.modal-alert': {
        marginBottom: theme.spacing.xl,

        '..mantine-Alert-wrapper': {
          marginBottom: theme.spacing.xl,
        },
        '.mantine-Alert-message': {
          color: theme.colors.orange[8],
        },
      },

      '.modal-content': {
        '.content-item': {
          padding: theme.spacing.md,

          '.content-item-title': {
            width: 100,
          },
        },
      },
      '.modal-divider': {
        marginTop: theme.spacing.md,
        marginBottom: theme.spacing.xl,
      },

      '.endpoints': {
        padding: theme.spacing.md,

        '.title': { ...fontStylesEndpoint(theme) },
        '.description': { ...fontStylesEndpoint(theme) },
        '.endpoint': { ...fontStylesEndpoint(theme) },
        '.icon': { ...fontStylesEndpoint(theme), cursor: 'pointer' },
      },

      '.base-url': {
        padding: `0 ${theme.spacing.md}`,
      },
    },
  };
};
