import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, PasswordInput, Title, useMantineTheme } from '@mantine/core';

import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';

import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import { forgotPasswordStyles } from './styles';

const CreateNewPassword = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [isPasswordVisible, togglePasswordVisible] = useState({ pass: false, confirmPass: false });

  return (
    <LoginPagesLayout
      sx={{ ...signSectionStyles(theme), ...forgotPasswordStyles(theme) }}
      id={'forgotPasswordSent-page'}
    >
      <Box className="signPage__container">
        <Box className="signPage__content">
          <Title size={52} color={theme.colors.dark[9]}>
            Create New Password
          </Title>

          <Box className="signPage__emailSection">
            <Box className="signPage__input">
              <PasswordInput
                variant="unstyled"
                placeholder="Password"
                size="md"
                value={''}
                name="password"
                onChange={() => {
                  console.log('');
                }}
                onBlur={() => {
                  console.log('');
                }}
                error={false}
                visible={isPasswordVisible.pass}
                onVisibilityChange={(visible) => {
                  togglePasswordVisible((prev) => ({ ...prev, pass: visible }));
                }}
                data-cy="create-password-field"
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <Box className="signPage__input">
              <PasswordInput
                variant="unstyled"
                placeholder="Password"
                size="md"
                value={''}
                name="password"
                onChange={() => {
                  console.log('');
                }}
                onBlur={() => {
                  console.log('');
                }}
                error={false}
                visible={isPasswordVisible.confirmPass}
                onVisibilityChange={(visible) => {
                  togglePasswordVisible((prev) => ({ ...prev, confirmPass: visible }));
                }}
                data-cy="create-password-confirm-field"
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <Button
              onClick={() => {
                navigate('/login');
              }}
              size="lg"
              disabled={false}
              data-cy="create-password-submit-button"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default CreateNewPassword;
