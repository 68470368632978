import { Box, Flex, Image, Title, useMantineTheme, Paper, Button } from '@mantine/core';
import { instructionStyles } from './style';
import CustomLink from '../../../../components/CustomLink';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/common';
import { SandboxState } from '../../../../redux/reducers';
import { SandboxStatus } from '../../../../models';

interface InstructionProps {
  image: string;
  number: number;
  title: string;
  description: string | React.ReactNode;
  links?: {
    text: string;
    url: string;
    dataCy: string;
    isDisabled?: boolean;
  }[];
  onClickBegin?: () => void;
  buttonText?: string;
  descriptionPartTwo?: string;
  buttonColor?: string;
  isButtonDisabled?: boolean;
}

const Instruction: React.FC<InstructionProps> = ({
  image,
  number,
  title,
  description,
  links,
  onClickBegin,
  buttonText,
  descriptionPartTwo,
  buttonColor,
  isButtonDisabled,
}) => {
  const theme = useMantineTheme();

  const { sandboxSummaryData } = useSelector<RootState, SandboxState>((state: RootState) => {
    return state.sandbox;
  });

  const isSandboxCreated = sandboxSummaryData.some(
    ({ overallStatus }) => overallStatus === SandboxStatus.CREATED,
  );

  return (
    <Box sx={instructionStyles(theme)}>
      <Paper shadow="none" className="instruction-paper">
        <Flex align="center" justify="center" gap={theme.spacing.xl}>
          <Image src={image} alt={title} width={250} />
          <div className="instruction-content">
            <Title order={4}>
              {number}. {title}
            </Title>
            <div className="instruction-description">{description}</div>
            <div className="instruction-links">
              {links &&
                links.map((link) => (
                  <CustomLink
                    to={link.url}
                    text={link.text}
                    key={link.url}
                    dataCy={`instruction-link-${link.dataCy}`}
                    withIcon
                    isDisabled={link.isDisabled}
                  />
                ))}
              {!links && onClickBegin && (
                <Button
                  onClick={onClickBegin}
                  disabled={!isSandboxCreated || isButtonDisabled}
                  className={buttonColor}
                >
                  {buttonText}
                </Button>
              )}
            </div>
            <div className="instruction-description">{descriptionPartTwo}</div>
          </div>
        </Flex>
      </Paper>
    </Box>
  );
};

export default Instruction;
