import { appStoreDetailsAxios } from '../config/axios.config';
import { IntegrationId } from '../models';
import { AppStoreDetails, IntegrationWithGoLiveStatus } from '../models/appStoreDetails';

const appStoreDetailsGoLiveService = {
  getReadyToGoLiveIntegrations: async (
    organizationId: string,
    projectId: string,
  ): Promise<IntegrationId[]> => {
    const { data } = await appStoreDetailsAxios.get(
      `/ready-to-go-live-integrations/${organizationId}/projects/${projectId}`,
    );
    return data;
  },

  getIntegrationsWithGoLiveStatuses: async (
    organizationId: string,
    projectId: string,
  ): Promise<IntegrationWithGoLiveStatus[]> => {
    const { data } = await appStoreDetailsAxios.get(
      `/integration-with-go-live-status/${organizationId}/projects/${projectId}`,
    );
    return data;
  },

  getAppStoreDetails: async (
    organizationId: string,
    projectId: string,
  ): Promise<AppStoreDetails> => {
    const { data } = await appStoreDetailsAxios.get(`/${organizationId}/projects/${projectId}`);
    return data;
  },

  requestToGoLive: async (payload: {
    organizationId: string;
    projectId: string;
    integrationId: string;
  }): Promise<void> => {
    const { data } = await appStoreDetailsAxios.post(
      `/request-to-go-live/${payload.organizationId}/projects/${payload.projectId}/${payload.integrationId}`,
    );
    return data;
  },

  uploadApplicationImage: async (
    organizationId: string,
    projectId: string,
    file: File,
    integrationId: String,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);
    await appStoreDetailsAxios.post(
      `/upload-application-image/${organizationId}/projects/${projectId}/${integrationId}`,
      formData,
    );
    return;
  },

  saveName: async (payload: {
    organizationId: string;
    integration: IntegrationId;
    applicationName: string;
    projectId: string;
  }): Promise<void> => {
    const { data } = await appStoreDetailsAxios.post(
      `/save-name/${payload.organizationId}/projects/${payload.projectId}`,
      {
        applicationName: payload.applicationName,
        integration: payload.integration,
      },
    );
    return data;
  },
};

export default appStoreDetailsGoLiveService;
