import { MantineTheme, Sx } from '@mantine/core';

export const onboardingStyles = (theme: MantineTheme): Sx => {
  return {
    '.onboarding-container': {
      background: '#fff',
      padding: '32px',
    },
    '.onboarding-top-container': {
      width: '100%',
    },
    '.onboarding-definition-container': {
      width: '50%',
    },
    '.onboarding-stepper': {
      width: '100%',
    },
    '.onboarding-step-number': {
      alignSelf: 'flex-end',
      fontWeight: 'bold',
      marginTop: '5px',
    },
    '.onboarding-welcome-text': {
      fontWeight: 'bold',
      fontSize: '28px',
      marginTop: '32px',
      marginBottom: '8px',
    },
    '.onboarding-main-definition': {
      fontSize: '14px',
      marginBottom: '16px',
    },
    '.onboarding-additional-definition': {
      fontSize: '14px',
    },
    '.onboarding-no-setup-image': {
      width: '30%',
      marginLeft: '13%',
    },
    '.onboarding-get-started-text': {
      fontWeight: 'bold',
      fontSize: '18px',
      marginTop: '24px',
      marginBottom: '32px',
    },
    '.onboarding-redirect-card': {
      padding: '16px',
      cursor: 'pointer',
    },
    '.onboarding-redirect-icon': {
      marginRight: '18px',
    },
    '.onboarding-redirect-status-icon': {
      alignSelf: 'flex-end',
      marginLeft: 'auto',
    },
    '.onboarding-setup-completed': {
      padding: '16px',
      cursor: 'unset',
      opacity: '0.5',
    },
  };
};
