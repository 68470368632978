import { MantineTheme, Sx } from '@mantine/core';

export const setupOrganizationStyles = (theme: MantineTheme): Sx => {
  return {
    '.content': {
      width: '100%',
      background: '#fff',
      padding: '24px',
      marginBottom: '24px',
      marginTop: '20px',
    },
    '.question-text': {
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    '.radio-container': {
      alignItems: 'flex-start',
      padding: '16px',
    },
    '.radio-option': {
      marginTop: '2px',
      marginRight: '12px',
    },
    '.main-text': {
      fontSize: '14px',
    },
    '.description-text': {
      fontSize: '14px',
      opacity: '0.5',
    },
    '.content-container': {
      border: '1px solid #e6e6e6',
    },
    '.divider': {
      width: '100%',
    },
    '.bottom-container': {
      background: '#fff',
      padding: '24px',
    },
  };
};
