import { Box, Text, useMantineTheme } from '@mantine/core';
import { FC, useEffect, useRef } from 'react';
import { logsWindowStyles } from './style';
import { TestCaseLogs } from '../../models';
import { useDispatch } from 'react-redux';
import { testFrameworkActionCreator } from '../../redux/actions';

export type LogsWindowProps = {
  messages?: TestCaseLogs;
};

const LogsWindow: FC<LogsWindowProps> = ({ messages }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const logWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logWindowRef?.current) {
      logWindowRef.current.scrollTop = logWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box sx={logsWindowStyles(theme)} className="logsContainer__logs" ref={logWindowRef}>
      {messages?.logs.map(({ time, logLevel, message, metaData }, index) => (
        <Text fz={'sm'} color={theme.colors.font[1]} key={index}>
          {`[${time}] [${logLevel}] [${message}]`}
          {metaData?.['invocationId'] && (
            <span
              className="invocation-id"
              onClick={() =>
                dispatch(
                  testFrameworkActionCreator.changeSelectedInvocationId(metaData?.['invocationId']),
                )
              }
            >{`[View Payload]`}</span>
          )}
        </Text>
      ))}
      {!messages && (
        <Text fz={'sm'} color={theme.colors.font[1]}>
          {`[No Logs Found]`}
        </Text>
      )}
    </Box>
  );
};

export default LogsWindow;
