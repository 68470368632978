import {
  ActionIcon,
  Alert,
  Button,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Modal,
  Text,
  Title,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { IconAlertCircle, IconCheck, IconCopy, IconHelpCircle } from '@tabler/icons-react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Endpoints } from '../../../models';
import { apiKeyActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { APIKeyState } from '../../../redux/reducers';
import { endPointReplacer } from '../../../utils/string';
import { generatedApiKeyStyles } from './style';

type GeneratedApiKeyProps = {
  id: string;
  opened: boolean;
  close: () => void;
};

const GeneratedApiKey: React.FC<GeneratedApiKeyProps> = ({ opened, close }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { newApiCredentialsObject, apiSuiteDefinitions } = useSelector<RootState, APIKeyState>(
    (state: RootState) => {
      return state.apiKey;
    },
  );

  const getApiSuitesBySuiteIdIdAndIntegrationId = useCallback(() => {
    return apiSuiteDefinitions?.find(
      ({ id, integration }) =>
        id?.id === newApiCredentialsObject?.apiSuiteId?.id &&
        integration?.id === newApiCredentialsObject?.integration?.id,
    );
  }, [apiSuiteDefinitions, newApiCredentialsObject]);

  const getEndpoints = useCallback(
    (endPoints: Endpoints) => {
      if (!endPoints?.endpoints || endPoints?.endpoints?.length <= 0) return null;
      return (
        <Grid className="endpoints">
          {endPoints?.endpoints?.map(({ description, endpoint, documentationUrl }, index) => {
            return (
              <>
                <Grid.Col span={2} className="title">
                  {index === 0 && 'EndPoints'}
                </Grid.Col>
                <Grid.Col span={2} className="description">
                  {description}
                </Grid.Col>
                <Grid.Col span={6} className="endpoint">
                  {endPointReplacer(endpoint, endPoints.urlResolvers)}
                </Grid.Col>
                <Grid.Col span={1} className="icon">
                  <IconHelpCircle
                    size={18}
                    onClick={() => {
                      window.open(documentationUrl);
                    }}
                  />
                </Grid.Col>
              </>
            );
          })}
        </Grid>
      );
    },
    // eslint-disable-next-line
    [apiSuiteDefinitions, newApiCredentialsObject],
  );

  return (
    <Modal
      opened={opened}
      onClose={() => {
        dispatch(apiKeyActionCreator.changeNewApiCredentialsObject(null));
        close();
      }}
      title={
        <Title order={3} data-cy="generated-api-key-modal-title">
          Save your new client secret
        </Title>
      }
      size="xl"
      padding={theme.spacing.xl}
      sx={generatedApiKeyStyles(theme)}
      centered
    >
      <div className="generated-api-key-modal">
        <div className="modal-alert">
          <Alert icon={<IconAlertCircle size={theme.fontSizes.xl} />} color="orange">
            You won't be able to see this client secret once you close this window.
          </Alert>
        </div>
        <div className="modal-content">
          <Flex align={'center'} gap={theme.spacing.xl} className="content-item">
            <Text size="sm" className="content-item-title">
              API Key
            </Text>
            <Text size="sm" className="content-item-value" data-cy="client-secret">
              {/* //replace all characters with * except the first 5 and last 5 */}
              {/* {newApiCredentialsObject?.apiKeyValue
                ? `${newApiCredentialsObject?.apiKeyValue.slice(
                    0,
                    5,
                  )}${newApiCredentialsObject?.apiKeyValue
                    .slice(5, -5)
                    .replace(/./g, '*')}${newApiCredentialsObject?.apiKeyValue.slice(-5)}`
                : ''} */}
              {newApiCredentialsObject?.apiKeyValue}
            </Text>
            <CopyButton value={newApiCredentialsObject?.apiKeyValue || ''} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? (
                      <IconCheck size={theme.fontSizes.xl} />
                    ) : (
                      <IconCopy size={theme.fontSizes.xl} data-cy="copy-client-secret-btn1" />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>
        </div>
        <Grid className="base-url">
          <Grid.Col span={2} className="title">
            Base URL
          </Grid.Col>
          <Grid.Col span={8} className="description">
            {getApiSuitesBySuiteIdIdAndIntegrationId()?.baseUrl}
          </Grid.Col>
          <Grid.Col span={1} className="icon">
            <CopyButton
              value={getApiSuitesBySuiteIdIdAndIntegrationId()?.baseUrl ?? ''}
              timeout={2000}
            >
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Grid.Col>
        </Grid>
        {getEndpoints(getApiSuitesBySuiteIdIdAndIntegrationId()?.endpoints ?? ({} as Endpoints))}
        <Divider color={theme.colors.gray[2]} className="modal-divider" />
        <Flex align={'center'} justify={'flex-end'} className="modal-footer">
          <CopyButton value={newApiCredentialsObject?.apiKeyValue || ''}>
            {({ copied, copy }) => (
              <Button
                color={copied ? 'teal' : 'blue'}
                onClick={copy}
                data-cy="copy-client-secret-btn2"
              >
                {copied ? 'Copied API key' : 'Copy API key'}
              </Button>
            )}
          </CopyButton>
        </Flex>
      </div>
    </Modal>
  );
};

export default GeneratedApiKey;
