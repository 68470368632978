import { useMantineTheme, Box, Text, TextInput, Button } from '@mantine/core';
import { joinOrganizationStyles } from './style';
import { useFormik } from 'formik';
import { string as validateString, object as validateObject } from 'yup';
import { notifications } from '@mantine/notifications';
import organizationService from '../../../service/organizations.service';
import { useMutation } from '@tanstack/react-query';
import { userService } from '../../../service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticationActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';

const JoinOrganization = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { values, handleChange, handleBlur, touched, errors, handleSubmit } = useFormik({
    initialValues: { invitationCode: '' },
    onSubmit: (values) => {
      joinForExistingOrganisation.mutate(values.invitationCode);
    },
    validationSchema: validateObject().shape({
      invitationCode: validateString().trim().required('Invitation code required'),
    }),
  });

  const joinForExistingOrganisation = useMutation({
    mutationFn: (invitationCode: string) => {
      return organizationService.joinForExistingOrganisation(invitationCode);
    },
    onSuccess: (data) => {
      if (data.email) getUserByEmail.mutate(data.email);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getUserByEmail = useMutation({
    mutationFn: (email: string) => {
      return userService.getUserByEmail(email);
    },
    onSuccess: (data) => {
      dispatch(
        authenticationActionCreator.changeAuthData({
          isAuthenticated: true,
          userData: {
            ...userData,
            userId: data?.id,
            organizationId: data?.organizationId,
            email: data.email,
            hasActiveApi: true,
          },
        }),
      );
      notifications.show({
        message: 'Assigned to existing organisation successfully',
        color: 'green',
      });
      navigate('/organisation');
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  return (
    <Box className="bottom-container" sx={joinOrganizationStyles(theme)}>
      <Text className="join-title">Join an existing organisation</Text>
      <TextInput
        label="Invitation code"
        placeholder="e.g. 64b7cc88de43025c29d88755"
        value={values.invitationCode}
        name={'invitationCode'}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.invitationCode && errors.invitationCode}
      />
      <Text className="info-text">
        If you don't already have your organisational code, you can ask a member of your team.
      </Text>
      <Button onClick={() => handleSubmit()}>Request to join</Button>
    </Box>
  );
};

export default JoinOrganization;
