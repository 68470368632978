export type BitBucketPermission = {
  id?: string;
  organizationId: string;
  integrationId: string;
  projectId: string;
  accountId?: string;
  name: string;
  email: string;
  accessLevel: AccessLevel;
  requestStatus: RequestStatus;
  requestedPermission: AccessLevel;
  repoName: string;
};
export enum RequestStatus {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EMPTY = 'EMPTY',
}
export enum AccessLevel {
  BITBUCKET_INVITATION = 'BITBUCKET_INVITATION',
  BITBUCKET_JOINED = 'BITBUCKET_JOINED',
  READ = 'READ',
  WRITE = 'WRITE',
  EMPTY = 'EMPTY',
  REVOKE = 'REVOKE',
}
