import { MantineTheme, Sx } from '@mantine/core';

const description: Sx = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
};

const subDescription: Sx = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
};

export const stepStyles = (
  theme: MantineTheme,
  isBegin: boolean,
  isVerifyStep: boolean,
  isLastStep: boolean,
): Sx => {
  return {
    width: '100%',

    '.step-title': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      marginBottom: '0.35rem',

      '.number-description': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',

        '.number': {
          width: '24px',
          height: '24px',
          minHeight: '24px',
          minWidth: '24px',
          borderRadius: '12px',
          background: theme.colors.grubtech[7],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '166%',
          letterSpacing: '0.4px',
          color: theme.colors.white[0],

          '&.success': {
            background: theme.colors.grubtech[0],
          },
        },

        '.description': {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          color: theme.colors.font[0],
        },

        '.title-status': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },

        '.execute-button': {
          marginLeft: 'auto',
        },
      },
    },

    '.content': {
      padding: isVerifyStep ? 'unset' : isBegin ? 'unset' : '0 1.5rem',
      paddingRight: '0.5rem',
      marginLeft: isVerifyStep ? 'unset' : '12px',
      marginTop: isVerifyStep ? 'unset' : '12px',
      borderLeft: isVerifyStep ? 'unset' : isBegin || isLastStep ? 'unset' : '1px solid gray',

      '&.verification': {
        padding: '0',
        margin: '0',
        marginTop: '10px',
        borderLeft: 'unset',
      },
    },

    '.begin-test-case-content': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      margin: '0.5rem 0 1rem 0',
      justifyContent: 'flex-end',
    },

    '.instruction-content': { ...description, minHeight: '16px' },

    '.executable-action-content': {
      minHeight: '16px',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end',
    },

    '.user-input-action-content': {
      minHeight: '16px',
      '.description': description,
      '.text-input-container': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '16px',

        '.mantine-InputWrapper-root': {
          width: '100%',
        },

        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
      },
      '.button-wrapper': {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: '0.5rem',
      },
      '.sub-description': subDescription,
      '.rejected-message': {
        margin: '12px 0',
        width: '100%',
      },
    },
    '.image-upload-content': {
      minHeight: '16px',
      '.description': description,
      '.sub-description': { ...subDescription, marginBottom: '1rem' },
      '.error-content': {
        display: 'flex',
        height: '42px',
        padding: '20px',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '4px',
        border: `0px solid ${theme.colors.border[1]}`,
        background: 'rgba(236, 183, 183, 0.30)',
        marginTop: '7px',
        color: theme.colors.font[4],
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
      },
      '.dropzone-wrapper': {
        maxWidth: '455px',
        minHeight: '78px',

        '.mantine-Dropzone-root': {
          // height: '78px',
          width: 'auto',
          border: `2px dashed ${theme.colors.border[0]}`,

          '&.error': { border: `1.2px dashed ${theme.colors.font[4]}` },
        },

        '.uploaded-image': {
          height: '76px',
          margin: 'auto',
          width: '76px',
        },

        '.image-upload-base': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',

          '.description': {
            color: theme.colors.font[2],
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          },
        },

        '.image-upload-limits': {
          color: theme.colors.font[2],
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '.button-wrapper': {
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        },
      },
      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: '0.5rem',
      },
      '.rejected-message': {
        margin: '12px 0',
      },
    },
    '.verification-content': {
      '.description': description,
    },

    '.next-step-container': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end',
      gap: '10px',
      marginTop: '16px',

      '.skip-button': {
        textDecoration: 'underline',
        gap: 0,
        marginRight: '-15px',
        color: theme.colors.grubtech[0],

        ':disabled': {
          background: theme.colors.white[0],
        },
      },
    },
  };
};
