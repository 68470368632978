import { Button, Modal, useMantineTheme } from '@mantine/core';
import { confirmationGenerateApiKeyStyles } from './styles';
import { FC } from 'react';

type ConfirmationGenerateApiKeyProps = {
  id: string;
  opened: boolean;
  close: () => void;
  handleConfirm: () => void;
};

const ConfirmationGenerateApiKey: FC<ConfirmationGenerateApiKeyProps> = ({
  id,
  opened,
  close,
  handleConfirm,
}) => {
  const theme = useMantineTheme();

  return (
    <Modal
      sx={confirmationGenerateApiKeyStyles(theme)}
      opened={opened}
      onClose={close}
      title="Are you sure?"
      id={id}
      centered
    >
      <div className="description">
        This will generate a new API key by deactivating the previously generated key.
      </div>
      <div className="button-wrapper">
        <Button onClick={close} variant="outline">
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </div>
    </Modal>
  );
};

export default ConfirmationGenerateApiKey;
