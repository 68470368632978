import {
  TestCaseApprovalStatusDto,
  TestCaseExecutionDto,
  TestCaseLogs,
  TestSuiteAdminApprovalDto,
  TestSuiteDefinitionSummaryDto,
  TestSuiteInstanceDto,
} from '../../models';
import { TestFrameworkAction } from '../payloadModels/testFramework';
import { TestFrameworkActionTypes } from '../types';

export type TestFrameworkState = {
  testSuiteDefinitions: TestSuiteDefinitionSummaryDto[];
  testCaseExecutionData: TestCaseExecutionDto | null;
  selectedFlow: string | null;
  selectedSubFlow: string | null;
  selectedTestCase: string | null;
  selectedTestSuite: string;
  testSuiteInstanceData: TestSuiteInstanceDto | null;
  isTestCaseBegin: boolean;
  selectedInvocationId: string | undefined;
  testCaseApprovalStatusData: TestCaseApprovalStatusDto | null;
  testSubmittedDetails: TestSuiteAdminApprovalDto | null;
  clearedTestLogs: TestCaseLogs['logs'];
  refetchEnable: boolean;
};

const initialState: TestFrameworkState = {
  testSuiteDefinitions: [],
  testCaseExecutionData: null,
  selectedFlow: null,
  selectedSubFlow: null,
  selectedTestCase: null,
  selectedTestSuite: '',
  testSuiteInstanceData: null,
  isTestCaseBegin: false,
  selectedInvocationId: undefined,
  testCaseApprovalStatusData: null,
  testSubmittedDetails: null,
  clearedTestLogs: [],
  refetchEnable: true,
};

export const testFrameworkReducer = (
  state = initialState,
  action: TestFrameworkAction,
): TestFrameworkState => {
  switch (action.type) {
    case TestFrameworkActionTypes.CHANGE_TEST_CASE_EXECUTION_DATA:
      return {
        ...state,
        testCaseExecutionData: action.payload as TestCaseExecutionDto | null,
      };

    case TestFrameworkActionTypes.CHANGE_SELECTED_TEST_CASE:
      return {
        ...state,
        selectedTestCase: action.payload as string,
      };
    case TestFrameworkActionTypes.CHNAGE_SELECTED_TEST_SUITE:
      return {
        ...state,
        selectedTestSuite: action.payload as string,
      };
    case TestFrameworkActionTypes.CHANGE_TEST_SUITE_DEFINITION:
      const array = [...(action.payload as TestSuiteDefinitionSummaryDto[])];
      //@ts-ignore
      const unique = [...new Map(array.map((item) => [item['id'], item])).values()];

      let uniqueSorted = [];

      if (unique && unique.length > 0) {
        uniqueSorted = unique.sort((a, b) => (a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0));
      }

      return {
        ...state,
        testSuiteDefinitions: uniqueSorted,
      };
    case TestFrameworkActionTypes.RESET_TEST_SUITE_DEFINITION:
      return {
        ...state,
        testSuiteDefinitions: [],
      };
    case TestFrameworkActionTypes.CHANGE_TEST_SUITE_INSTANCE_DATA:
      return {
        ...state,
        testSuiteInstanceData: action.payload as TestSuiteInstanceDto,
      };
    case TestFrameworkActionTypes.CHANGE_SELECTED_FLOW:
      return {
        ...state,
        selectedFlow: action.payload as string,
      };
    case TestFrameworkActionTypes.CHANGE_SELECTED_SUB_FLOW:
      return {
        ...state,
        selectedSubFlow: action.payload as string,
      };
    case TestFrameworkActionTypes.CHANGE_IS_TEST_CASE_BEGIN:
      return {
        ...state,
        isTestCaseBegin: action.payload as boolean,
      };
    case TestFrameworkActionTypes.CHANGE_INVOCATION_ID:
      return {
        ...state,
        selectedInvocationId: action.payload as string | undefined,
      };
    case TestFrameworkActionTypes.CHANGE_TEST_CASE_APPROVAL_STATUS:
      return {
        ...state,
        testCaseApprovalStatusData: action.payload as TestCaseApprovalStatusDto | null,
      };
    case TestFrameworkActionTypes.CHANGE_TEST_SUBMITED_DETAILS:
      return {
        ...state,
        testSubmittedDetails: action.payload as TestSuiteAdminApprovalDto | null,
      };
    case TestFrameworkActionTypes.CLEAR_TEST_CASE_LOGS:
      return {
        ...state,
        clearedTestLogs: action.payload as any,
      };
    case TestFrameworkActionTypes.TOGGLE_REFETCH_ENABLE:
      return {
        ...state,
        refetchEnable: action.payload as boolean,
      };
    default:
      return state;
  }
};
