import React from 'react';
import { Checkbox, Flex, Text, useMantineTheme } from '@mantine/core';
import { capabilityRowStyles } from './style';

interface CapabilityRowProps {
  id: string;
  capability: string;
  disabled: boolean;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description?: string;
  required: boolean;
}

const CapabilityRow: React.FC<CapabilityRowProps> = ({
  id,
  capability,
  disabled,
  checked,
  onChange,
  description,
  required = false,
}) => {
  const theme = useMantineTheme();
  const getLabel = () => {
    return (
      <Flex direction={'row'} gap={'12px'}>
        {capability} {required && <Text className="asterix">*</Text>}
      </Flex>
    );
  };

  return (
    <Flex direction="column" sx={capabilityRowStyles(theme)}>
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        label={getLabel()}
      />
      <Text className="description">{description}</Text>
    </Flex>
  );
};

export default CapabilityRow;
