import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { IntegrationState } from '../redux/reducers';
import { IntegrationActivationStatus } from '../models';
import { useEffect, useState } from 'react';

const useIsItegrationApprovedForGoLive = () => {
  const [isGoLIveApproved, setIsGoLIveApproved] = useState(false);

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  useEffect(() => {
    if (activatedIntegrations && activatedIntegrations?.length > 0) {
      activatedIntegrations.forEach(({ status }) => {
        if (status.includes(IntegrationActivationStatus.GO_LIVE_APPROVED)) {
          setIsGoLIveApproved(true);
        }
      });
    }
  }, [activatedIntegrations]);

  return isGoLIveApproved;
};

export default useIsItegrationApprovedForGoLive;
