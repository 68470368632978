import { integrationAxios } from '../config/axios.config';
import {
  ActivateIntegrationPayload,
  Capability,
  IntegrationDefinition,
  OrganizationIntegrationParameters,
  Parameter,
  PartnerIntegrationStatus,
} from '../models/integration';

const integratioService = {
  getIntegrations: async (
    organizationId: string,
    projectId: string,
  ): Promise<IntegrationDefinition[]> => {
    if (projectId !== '' && projectId !== undefined) {
      const { data } = await integrationAxios.get(`/${organizationId}/projects/${projectId}`);
      return data;
    } else {
      const { data } = await integrationAxios.get(`/integrationDefinition`);
      return data;
    }
  },
  getActivatedIntegrations: async (
    partnerId: string,
    projectId: string,
  ): Promise<PartnerIntegrationStatus[]> => {
    const { data } = await integrationAxios.get(`/activated/${partnerId}/projects/${projectId}`);
    return data;
  },
  activateIntegration: async (
    payload: ActivateIntegrationPayload,
  ): Promise<PartnerIntegrationStatus> => {
    const { data } = await integrationAxios.put(
      `/activate/${payload.organizationId}/projects/${payload.projectId}/${payload.integration.id}`,
      payload,
    );
    return data;
  },
  getIntegrationCapability: async (
    organizationId: string,
    projectId: string,
  ): Promise<Capability | string> => {
    const { data } = await integrationAxios.get(`/capability/${organizationId}/${projectId}`);
    return data;
  },

  createIntegrationCapability: async (
    organizationId: string,
    integrationId: string,
    capability: Capability,
    projectName: string,
  ): Promise<any> => {
    const { data } = await integrationAxios.post(
      `/capability/${organizationId}/byIntegration/${integrationId}?projectName=${projectName}`, // Updated URL with query parameter
      capability,
    );
    return data;
  },

  updateIntegrationCapability: async (
    organizationId: string,
    integrationId: string,
    capability: Capability,
    projectId: string,
  ): Promise<any> => {
    const { data } = await integrationAxios.put(
      `/capability/${organizationId}/byIntegration/${integrationId}/project/${projectId}`, // Updated URL with query parameter
      capability,
    );
    return data;
  },

  getIntegrationParameterSummary: async (
    organizationId: string,
    projectId: string,
  ): Promise<OrganizationIntegrationParameters[]> => {
    const { data } = await integrationAxios.get(
      `/params/integration-summary/${organizationId}/projects/${projectId}`,
    );
    return data;
  },

  getIntegrationParameters: async (
    organizationId: string,
    projectId: string,
    integrationId: string | undefined,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.get(
      `/params/${organizationId}/projects/${projectId}/${integrationId}`,
    );
    return data;
  },

  createUpdateIntegrationParameters: async (
    organizationId: string,
    parameters: Parameter,
    projectId: string,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.put(
      `/params/${organizationId}/projects/${projectId}`,
      parameters,
    );
    return data;
  },
};

export default integratioService;
