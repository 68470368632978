import { FC } from 'react';
import { Box, useMantineTheme, Title } from '@mantine/core';
import { welcomeHeaderStyles } from './styles';

export type WelcomeHeaderProps = {
  id?: string;
  upperBlackMessage?: string;
  lowerBlueMessage?: string;
};

const WelcomeHeader: FC<WelcomeHeaderProps> = ({
  id,
  upperBlackMessage = 'Welcome to Grubtech',
  lowerBlueMessage = 'Developer Portal',
}) => {
  const theme = useMantineTheme();

  return (
    <Box className="titleSection" sx={welcomeHeaderStyles(theme)}>
      <Title className="titleSection__upperBlackMessage">{upperBlackMessage}</Title>
      <Title className="titleSection__lowerBlueMessage">{lowerBlueMessage}</Title>
    </Box>
  );
};

export default WelcomeHeader;
