import { Box, Button, Flex, LoadingOverlay } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMantineTheme } from '@mantine/styles';
import { IconChevronDown, IconChevronUp, IconNotebook } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import useSelectedTestDefinition from '../../../../../hooks/useSelectedTestDefinition';
import { ApprovalStatus, beginTestCasePayload } from '../../../../../models';
import { testFrameworkActionCreator } from '../../../../../redux/actions';
import { RootState } from '../../../../../redux/common';
import { AuthenticationState, TestFrameworkState } from '../../../../../redux/reducers';
import { testFrameworkService } from '../../../../../service';
import { stepHeaderStyles } from './style';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import documentationService from '../../../../../service/documentation.service';
import parse from 'html-react-parser';

interface StepHeaderProps {
  id: string;
}

const StepHeader: React.FC<StepHeaderProps> = ({ id }) => {
  const theme = useMantineTheme();
  const selectedTextScenarioData = useSelectedTestDefinition();
  const dispatch = useDispatch();

  const {
    selectedTestCase,
    testSuiteDefinitions,
    selectedTestSuite,
    selectedFlow,
    selectedSubFlow,
    testSuiteInstanceData,
    isTestCaseBegin,
    testCaseExecutionData,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const [isDoNotShowChecked, setDoNotShowChecked] = useState(false);

  const [opened, { open, close }] = useDisclosure(!isTestCaseBegin);
  const [isRetryModalOpened, { open: openRetryModal, close: closeRetryModal }] = useDisclosure();

  const indexOfTestCase = testSuiteDefinitions
    ?.find((suite) => suite.id === selectedTestSuite)
    ?.flows?.find((flow) => flow.id === selectedFlow)
    ?.subFlows?.find((subflow) => subflow.id === selectedSubFlow)
    ?.testCases?.filter((testCase) => testCase.enabled === true)
    ?.findIndex((testCase) => testCase.id === selectedTestCase);

  const testCaseInstanceData = testSuiteInstanceData?.testCaseInstances?.find(
    ({ flowId, subFlowId, testCaseId }) =>
      flowId === selectedFlow && subFlowId === selectedSubFlow && testCaseId === selectedTestCase,
  );

  const beginTestCase = useMutation({
    mutationFn: (payload: beginTestCasePayload) => {
      return testFrameworkService.beginTestCase(payload);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(data));
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const documentationAuthRequest = useMutation({
    mutationFn: (payload: { email: string; url: string }) => {
      return documentationService.getDocumentationAuth(payload.email);
    },
    onSuccess: (data, initialValues) => {
      if (!!data && data?.authToken) {
        window.open(`${initialValues.url}?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const handleBeginTestCase = () => {
    const instanceId = testSuiteInstanceData?.instanceId;
    if (instanceId && selectedFlow && selectedSubFlow && selectedTestCase) {
      const payload = {
        instanceId: instanceId,
        flowId: selectedFlow,
        subFlowId: selectedSubFlow,
        testCaseId: selectedTestCase,
      };
      beginTestCase.mutate(payload);
      dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(true));
      close();
      dispatch(testFrameworkActionCreator.clearTestCaseLogs([]));
    }
  };

  const handleRetryTestCase = () => {
    if (isDoNotShowChecked) {
      handleBeginTestCase();
    } else {
      openRetryModal();
    }
  };

  const onConfirmRetry = () => {
    if (isTestCaseInitiallyStarted) {
      let doNotShowData = JSON.parse(localStorage.getItem('doNotShow') ?? '{}');
      doNotShowData = {
        ...doNotShowData,
        [`${userData.userId}.${selectedTestSuite}`]: isDoNotShowChecked,
      };
      localStorage.setItem('doNotShow', JSON.stringify(doNotShowData));
      handleBeginTestCase();
    }
    closeRetryModal();
  };
  const onCloseRetry = () => {
    closeRetryModal();
  };

  const isTestCaseInitiallyStarted = !!testCaseExecutionData;

  return (
    <Box sx={stepHeaderStyles(theme)} id={id}>
      <LoadingOverlay visible={beginTestCase.isLoading || documentationAuthRequest.isLoading} />
      <Flex
        justify="space-between"
        align="center"
        onClick={() => {
          if (!opened) {
            open();
            dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(false));
            return;
          }
          close();
        }}
      >
        <div className="step-header-text">
          <span className="scenario">{`Scenario ${(indexOfTestCase ?? 0) + 1} `}</span>
          <span className="title">{`- ${selectedTextScenarioData?.name}`}</span>
          <div
            className="step-header-go-to-doc-link"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              documentationAuthRequest.mutate({
                email: userData.email,
                url: selectedTextScenarioData?.documentationUrl ?? '',
              });
            }}
          >
            <IconNotebook
              className="step-external-link-icon"
              size={22}
              color={theme.colors.grubtech[0]}
            />
            <span className="go-to-docs-text">Go to docs</span>
          </div>
        </div>
        {opened ? (
          <IconChevronUp size={22} color={theme.colors.grubtech[0]} />
        ) : (
          <IconChevronDown size={22} color={theme.colors.grubtech[0]} />
        )}
      </Flex>
      {opened && (
        <>
          <div className="description">
            {selectedTextScenarioData?.testCaseDescription?.description}
          </div>
          {selectedTextScenarioData?.testCaseDescription?.testGuideSteps && (
            <div>
              <div className="test-guide">
                <b>Test guide</b>
              </div>
              <ul>
                {selectedTextScenarioData?.testCaseDescription?.testGuideSteps.map((step) => {
                  return (
                    <li key={step} className="description">
                      {parse(step)}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {selectedTextScenarioData?.testCaseDescription?.note && (
            <span>
              <b>Note: </b>
              {selectedTextScenarioData?.testCaseDescription?.note}
            </span>
          )}
          <div className="begin-scenario">
            <Button
              onClick={() => {
                if (isTestCaseInitiallyStarted) {
                  handleRetryTestCase();
                } else {
                  handleBeginTestCase();
                }
              }}
              disabled={
                testSuiteInstanceData === null ||
                testCaseInstanceData?.approvalStatus === ApprovalStatus.APPROVED ||
                testCaseInstanceData?.approvalStatus === ApprovalStatus.NO_MANUAL_APPROVAL_NEEDED ||
                testCaseInstanceData?.approvalStatus === ApprovalStatus.SUBMITTED_FOR_APPROVAL
              }
            >
              {isTestCaseInitiallyStarted ? 'Retry Scenario' : 'Begin Scenario'}
            </Button>
          </div>
          <ConfirmationDialog
            opened={isRetryModalOpened}
            close={closeRetryModal}
            id="retry-scenario-dialog"
            title={selectedTextScenarioData?.preRestartMessage?.title ?? 'Are You Sure?'}
            contentText={
              selectedTextScenarioData?.preRestartMessage?.message ??
              'Restarting will clear data and reset the current test scenario'
            }
            onClickDoNotShow={(e) => {
              setDoNotShowChecked(e.target.checked);
            }}
            onClickCancel={onCloseRetry}
            onClickConfirm={onConfirmRetry}
            isDoNotShowChecked={isDoNotShowChecked}
            showDoNotShowCheckbox={
              selectedTextScenarioData?.preRestartMessage?.supportsDoNotShowAgain !== undefined
                ? selectedTextScenarioData?.preRestartMessage?.supportsDoNotShowAgain
                : true
            }
          />
        </>
      )}
    </Box>
  );
};

export default StepHeader;
