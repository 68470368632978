import {
  Box,
  Button,
  Grid,
  TextInput,
  Table,
  Title,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core';
import PageLayout from '../../../components/PageLayout';
import { teamPageStyles } from './styles';
import { FC, useEffect } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import InviteModal from './invite/InviteModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import organizationService from '../../../service/organizations.service';
import { organizationActionCreator } from '../../../redux/actions/organization';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { RootState } from '../../../redux/common';
import { AuthenticationState, FilterState, OrganizationState } from '../../../redux/reducers';
import { SearchPageTypes } from '../../../models';
import { filterActionCreator } from '../../../redux/actions';

export type TeamPageProps = {
  id: string;
};

const TeamPage: FC<TeamPageProps> = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useMantineTheme();

  const { invitationCode } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });
  const { companyUsers } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const { searchTexts } = useSelector<RootState, FilterState>((state: RootState) => {
    return state.filterData;
  });

  useEffect(() => {
    dispatch(
      filterActionCreator.changeSearchText({
        pageKey: SearchPageTypes.ORGANISATION_TEAM_PAGE,
        serachText: '',
      }),
    );
    // eslint-disable-next-line
  }, []);

  const { isLoading: isLoadingUsers } = useQuery({
    queryKey: ['get-users'],
    queryFn: () => organizationService.getCompanyUsers(userData.organizationId),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCompanyUsers(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getInvitationCode = useMutation({
    mutationFn: (organizationId: string) => {
      return organizationService.getInvitationCode(organizationId);
    },
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeInvitationCode(data));
      openInviteModal();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const [inviteModalOpened, { open: openInviteModal, close: closeInviteModal }] =
    useDisclosure(false);

  const rows = companyUsers
    .filter(
      (user) =>
        user.email
          .toLowerCase()
          .includes((searchTexts?.ORGANISATION_TEAM_PAGE ?? '').toLowerCase()) ||
        user?.name
          ?.toLowerCase()
          ?.includes((searchTexts?.ORGANISATION_TEAM_PAGE ?? '').toLowerCase()) ||
        user?.userFederationType
          ?.toString()
          ?.toLowerCase()
          ?.includes((searchTexts?.ORGANISATION_TEAM_PAGE ?? '').toLowerCase()),
    )
    .map((user) => (
      <tr key={user.email}>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.userFederationType}</td>
      </tr>
    ));

  return (
    <PageLayout id={`Organisation-team-page-${id}`} sx={teamPageStyles(theme)}>
      <LoadingOverlay visible={isLoadingUsers} />
      <Box className="content">
        <InviteModal
          id="generate-api-key-modal"
          opened={inviteModalOpened}
          close={closeInviteModal}
          invitationCode={invitationCode}
        />
        <div className="header">
          <Title order={2} className="title" data-cy="team-members-title">
            Team Members
          </Title>
          <Button
            onClick={() => getInvitationCode.mutate(userData.organizationId)}
            data-cy="invite-button"
          >
            Invite
          </Button>
        </div>
        <div className="filter">
          <Grid>
            <Grid.Col span={5}>
              <TextInput
                onChange={(e) => {
                  dispatch(
                    filterActionCreator.changeSearchText({
                      pageKey: SearchPageTypes.ORGANISATION_TEAM_PAGE,
                      serachText: e.target.value,
                    }),
                  );
                }}
                placeholder="Search by Name, Email, User Federation Type"
                icon={<IconSearch size="0.8rem" />}
                value={searchTexts?.ORGANISATION_TEAM_PAGE ?? ''}
              />
            </Grid.Col>
          </Grid>
        </div>
        <div>
          <Table withBorder fontSize="md" verticalSpacing="md">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User Federation Type</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      </Box>
    </PageLayout>
  );
};

export default TeamPage;
