import { MantineTheme, Sx } from '@mantine/core';

const fontStylesEndpoin = (theme: MantineTheme): Sx => {
  return {
    color: theme.colors.font[3],
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fonWeight: 400,
    lineHeight: 'normal',
  };
};

export const webhookSectionForProductionStyles = (theme: MantineTheme): Sx => {
  return {
    padding: `calc(${theme.spacing.xl} * 2)`,
    marginTop: theme.spacing.xl,

    '.button-wrapper': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },

    '.endpoints': {
      marginTop: '0.5rem',

      '.title': { ...fontStylesEndpoin(theme) },
      '.description': { ...fontStylesEndpoin(theme) },
      '.endpoint': { ...fontStylesEndpoin(theme) },
      '.icon': { ...fontStylesEndpoin(theme), cursor: 'pointer' },
    },

    '.webhook-urls-title': {
      width: '15%',
    },

    '.webhook-urls-api-container': {
      width: '80%',

      '.title-main': {
        marginBottom: '0.5rem',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        ':not(:first-child)': {
          marginTop: '3rem',
        },
      },
    },

    '.webhook-urls-text-input': {
      width: '90%',
      marginRight: theme.spacing.xl,

      '&.api-key': {
        marginTop: '1rem',
      },
    },
    '.column-flex': {
      width: '90%',
    },
  };
};
