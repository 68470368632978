import { MantineTheme, Sx } from '@mantine/core';

export const fbDataDeletionInstructionsStyles = (theme: MantineTheme): Sx => {
  return {
    '.deletion-instructions-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    },
    '.deletion-instructions': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing.md,
      padding: theme.spacing.md,
      borderRadius: theme.radius.sm,
      boxShadow: theme.shadows.sm,
      backgroundColor: theme.colors.white,
    },
    '.title': {
      textAlign: 'center',
      marginBottom: theme.spacing.md,
    },
    '.text': {
      lineHeight: 1.5,
      textAlign: 'left', // Aligns text to the left
      color: theme.colors.gray[7],
    },
    '.instructions-list': {
      listStyleType: 'decimal',
      paddingLeft: theme.spacing.md,
    },
    '.list-item': {
      marginBottom: theme.spacing.xs,
    },
  };
};
