import { FC } from 'react';
import { string as validateString, object as validateObject } from 'yup';
import { Button, Grid, Select, TextInput } from '@mantine/core';
import { IconWebhook } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { DATA_PASSING_MEDIUM_VALUES } from '../../constants/webhookConstants';
import { XApiValue } from '../../models';

type XAPIFieldProps = {
  onSave: (values: XApiValue) => void;
  defaultValues: XApiValue | null;
  isParentValid: boolean;
  isDisabled?: boolean;
};

const XAPIField: FC<XAPIFieldProps> = ({ onSave, defaultValues, isParentValid, isDisabled }) => {
  const { values, touched, handleChange, handleBlur, errors, setFieldValue, setTouched, isValid } =
    useFormik({
      initialValues: defaultValues ?? {
        key: '',
        value: '',
        authDataPassingMedium: '',
      },
      onSubmit: (values) => {
        //
      },
      validateOnMount: true,
      validationSchema: validateObject({
        key: validateString().required('API Key is required'),
        value: validateString().required('Value is required'),
        authDataPassingMedium: validateString()
          .oneOf(
            DATA_PASSING_MEDIUM_VALUES.map((item) => item.value),
            'Select a valid option for Data Passing Medium',
          )
          .required('Data Passing Medium is required'),
      }),
    });

  return (
    <>
      <Grid.Col span={10}>
        <TextInput
          name="key"
          placeholder="API Key"
          icon={<IconWebhook />}
          value={values.key}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-xapi-key`}
          error={touched.key && errors.key}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <TextInput
          name="value"
          placeholder="Value"
          icon={<IconWebhook />}
          value={values.value}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy={`webhook-urls-text-input-xapi-key`}
          error={touched.value && errors.value}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={10}>
        <Select
          name="authDataPassingMedium"
          className="oAuth-fields__input"
          placeholder="Select data passing medium"
          data={DATA_PASSING_MEDIUM_VALUES}
          icon={<IconWebhook />}
          onChange={(selected) => {
            setFieldValue('authDataPassingMedium', selected);
          }}
          onBlur={() => {
            setTouched({ ...touched, authDataPassingMedium: true });
          }}
          data-cy="webhook-urls-select-input-data-passing-medium"
          value={values.authDataPassingMedium}
          error={touched.authDataPassingMedium && errors.authDataPassingMedium}
          disabled={isDisabled}
        />
      </Grid.Col>
      <Grid.Col span={1} offset={1}>
        <Button
          className="webhooks-button"
          disabled={!isValid || !isParentValid || isDisabled}
          onClick={() => {
            onSave(values as XApiValue);
          }}
          data-cy="webhook-urls-xapi-save-button"
          size="sm"
        >
          Save
        </Button>
      </Grid.Col>
    </>
  );
};
export default XAPIField;
