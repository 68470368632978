import { Sx, MantineTheme } from '@mantine/core';

export const projectSettingsStyles = (theme: MantineTheme): Sx => {
  return {
    '.child-view': {
      backgroundColor: 'transparent !important',
    },
    '.pagelayout-content': {
      padding: '10px',
    },
    '.setting-pannel': {
      padding: theme.spacing.sm,
    },
  };
};
