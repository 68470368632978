import { Box, NavLink } from '@mantine/core';
import { useMantineTheme } from '@mantine/styles';
import StatusChip from '../../../../components/StatusChip';
import { ApprovalStatus, TestCaseStatus } from '../../../../models';
import { customNavLinksStyles } from './style';

interface CustomNavLinkProps {
  id: string;
  label: string;
  description: string;
  onClick: () => void;
  isActive?: boolean;
  status?: TestCaseStatus | ApprovalStatus;
}

const CustomNavLink: React.FC<CustomNavLinkProps> = ({
  id,
  label,
  description,
  onClick,
  isActive = false,
  status,
}) => {
  const theme = useMantineTheme();

  return (
    <Box sx={customNavLinksStyles(theme)} className="custom-navlink">
      <NavLink
        label={
          <>
            <div className="title">{label}</div>
            <div className="status">
              {status && <StatusChip id={`${id}-status`} type={status} />}
            </div>
          </>
        }
        description={description}
        active={isActive}
        onClick={onClick}
        data-cy={`nav-link-step-${id}`}
      />
    </Box>
  );
};

export default CustomNavLink;
