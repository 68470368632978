import { useMantineTheme, Box, Select, Paper, Alert, Text, Button } from '@mantine/core';
import { metricsStyles } from './style';
import { IconAlertTriangle, IconChevronDown } from '@tabler/icons-react';

const Metrics: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Box sx={metricsStyles(theme)}>
      <Select
        className="metrics-env-select"
        data={[
          { value: 'production', label: 'Environment: Production' },
          { value: 'sandbox', label: 'Environment: Sandbox' },
        ]}
        rightSection={<IconChevronDown size={theme.fontSizes.xl} stroke={3} />}
        defaultValue="production"
        data-cy="metrics-env-select"
      />
      <Paper shadow="xs" className="metrics-paper">
        <div className="metrics-error">
          <Alert icon={<IconAlertTriangle size={theme.fontSizes.lg} stroke={3} />} color="red">
            <Text>No contract has been approved and scenarios passed</Text>
          </Alert>
          <Button variant="outline" data-cy="metrics-retry-button">
            Retry
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default Metrics;
