import { Box, Text, Title, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { pageHeaderStyles } from './styles';
import { IconArrowLeft } from '@tabler/icons-react';

export type PageHeaderProps = {
  id: string;
  title: string;
  backText?: string;
  onClickBack?: () => void;
  description?: string;
};

const PageHeader: FC<PageHeaderProps> = ({ id, title, backText, onClickBack, description }) => {
  const theme = useMantineTheme();
  return (
    <Box sx={pageHeaderStyles(theme)} id={id}>
      {backText && onClickBack && (
        <div className="back" onClick={onClickBack}>
          <IconArrowLeft size={20} />
          <Text data-cy="navigate-back-text">{backText}</Text>
        </div>
      )}
      <Title order={2} className="title" data-cy="page-header-title">
        {title}
      </Title>
      {description && <Text className="description">{description}</Text>}
    </Box>
  );
};

export default PageHeader;
