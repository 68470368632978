export type ContactInfo = {
  name: string;
  email: string;
  telephone: Telephone;
};

export type Telephone = {
  countryCode: string;
  number: number;
};

export type Address = {
  countryAddressFormatId: string;
  fields: AddressFieldWithValue[];
};

export type AddressFieldWithValue = {
  addressFieldId: string;
  value: string;
};

export enum NormalLoginFlow {
  INITIAL = 'INITIAL',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
}
