import { MantineTheme, Sx } from '@mantine/core';

export const bitBucketPermissionStyle = (theme: MantineTheme): Sx => {
  return {
    '.bitbucket-setting-paper': {
      padding: 0,
      border: 0,
      boxShadow: 'none',
      width: '100%',
      backgroundColor: 'transparent !important',
      '.bitbucket-setting-content': {
        marginRight: '2px',
        gap: theme.spacing.xs,
        backgroundColor: theme.colors.white[0],
        '.bitbucket-search-section': {
          padding: theme.spacing.lg,
        },
        '.user-add-button-section': {
          padding: theme.spacing.lg,
          marginRight: 10,
        },
      },
      '.user-permission-table': {
        backgroundColor: theme.colors.white[0],
        marginTop: theme.spacing.md,
        marginRight: '0px',
      },
    },
  };
};
