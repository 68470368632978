export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_SIGN_IN_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_SIGN_OUT_REDIRECT_URL,
      responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
