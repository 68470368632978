export enum OrganizationActionTypes {
  CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION',
  CHANGE_INVITATION_CODE = 'CHANGE_INVITATION_CODE',
  CHANGE_COUNTRIES = 'CHANGE_COUNTRIES',
  CHANGE_ADDRESS_FIELD = 'CHANGE_ADDRESS_FIELD',
  CHANGE_UPDATE_ORGANIZATION = 'CHANGE_UPDATE_ORGANIZATION',
  CHANGE_COMPANY_USERS = 'CHANGE_COMPANY_USERS',
  CHANGE_CREATE_ORGANIZATION = 'CHANGE_CREATE_ORGANIZATION',
  CHANGE_CITIES = 'CHANGE_CITIES',
}
