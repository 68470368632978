export enum APIKeyActionTypes {
  CHANGE_API_KEY_LIST = 'CHANGE_API_KEY_LIST',
  RESET_API_KEY_LIST = 'RESET_API_KEY_LIST',
  RESET_API_SUITE_DEFINITIONS = 'RESET_API_SUITE_DEFINITIONS',
  INSERT_API_KEY = 'INSERT_API_KEY',
  REMOVE_API_KEY = 'REMOVE_API_KEY',
  CHANGE_NEW_API_CREDENTIALS_OBJECT = 'CHANGE_NEW_API_CREDENTIALS_OBJECT',
  CHANGE_API_SUITE_DEFINITIONS = 'CHANGE_API_SUITE_DEFINITIONS',
  CHNAGE_GENERATING_API_SUITE_KEY_ID = 'CHNAGE_GENERATING_API_SUITE_KEY_ID',
  CHANGE_NEW_API_CREDENTIALS_OBJECT_FOR_PRODUCTION = 'CHANGE_NEW_API_CREDENTIALS_OBJECT_FOR_PRODUCTION',
  CHANGE_GENERATING_API_SUITE_KEY_ID_FOR_PRODUCTION = 'CHANGE_GENERATING_API_SUITE_KEY_ID_FOR_PRODUCTION',
  CHANGE_CHECK_IS_PRODUCTION_ENABLED_FOR_API_KEY = 'CHANGE_CHECK_IS_PRODUCTION_ENABLED_FOR_API_KEY',
}
