import { Sx, MantineTheme } from '@mantine/core';
import { ApprovalStatus, TestCaseStatus } from '../../models';

export const statusChipStyles = (
  theme: MantineTheme,
  type?: TestCaseStatus | ApprovalStatus,
): Sx => {
  return {
    display: 'flex',
    padding: ' 2px 5px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '4px',
    background:
      type === TestCaseStatus.SUCCESS ||
      type === TestCaseStatus.TO_BE_REVIEWED ||
      type === ApprovalStatus.APPROVED ||
      type === ApprovalStatus.SUBMITTED_FOR_APPROVAL
        ? theme.colors.statusBackground[0]
        : type === TestCaseStatus.PENDING
        ? theme.colors.statusBackground[2]
        : type === TestCaseStatus.TIMED_OUT
        ? theme.colors.statusBackground[1]
        : type === TestCaseStatus.SKIPPED
        ? theme.colors.statusBackground[4]
        : theme.colors.statusBackground[3],

    '.icon': {
      marginTop: '-2px',
      color:
        type === TestCaseStatus.SUCCESS ||
        type === TestCaseStatus.TO_BE_REVIEWED ||
        type === ApprovalStatus.APPROVED ||
        type === ApprovalStatus.SUBMITTED_FOR_APPROVAL
          ? theme.colors.status[0]
          : type === TestCaseStatus.PENDING
          ? theme.colors.status[2]
          : type === TestCaseStatus.TIMED_OUT
          ? theme.colors.status[1]
          : theme.colors.status[3],
    },
    '.text': {
      whiteSpace: 'nowrap',
      color:
        type === TestCaseStatus.SUCCESS ||
        type === TestCaseStatus.TO_BE_REVIEWED ||
        type === ApprovalStatus.APPROVED ||
        type === ApprovalStatus.SUBMITTED_FOR_APPROVAL
          ? theme.colors.status[0]
          : type === TestCaseStatus.PENDING
          ? theme.colors.status[2]
          : type === TestCaseStatus.TIMED_OUT
          ? theme.colors.status[1]
          : type === TestCaseStatus.SKIPPED
          ? theme.colors.status[4]
          : theme.colors.status[3],
    },
  };
};
