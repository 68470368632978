import { repositoryAxios } from '../config/axios.config';
import { RepoRequest, RepoRequestStatus } from '../models/repository';

const repositoryService = {
  requestRepository: async (
    organizationId: string,
    projectId: string,
    request: RepoRequest,
  ): Promise<any> => {
    const { data } = await repositoryAxios.post(
      `/${organizationId}/projects/${projectId}/repository/create-request`,
      request,
    );
    return data;
  },
  getRepoCreateRequest: async (
    organizationId: string,
    projectId: string,
  ): Promise<RepoRequestStatus> => {
    const { data } = await repositoryAxios.get(
      `/${organizationId}/projects/${projectId}/repository`,
    );
    return data;
  },
};
export default repositoryService;
