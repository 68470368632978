export type SearchData = { [key in SearchPageTypes]: string };

export enum SearchPageTypes {
  API_CREDENTIALS_SUMMARY = 'API_CREDENTIALS_SUMMARY',
  SAND_BOX_SUMMARY = 'SAND_BOX_SUMMARY',
  ORGANISATION_TEAM_PAGE = 'ORGANISATION_TEAM_PAGE',
}

export type SearchTextPaylod = {
  pageKey: SearchPageTypes;
  serachText: string;
};
