import { MantineTheme, Sx } from '@mantine/core';

export const joinOrganizationStyles = (theme: MantineTheme): Sx => {
  return {
    '.join-title': {
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '24px',
    },
    '.info-text': {
      fontSize: '12px',
      opacity: '0.5',
      marginTop: '8px',
      marginBottom: '24px',
    },
  };
};
