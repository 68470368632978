import { SearchData, SearchTextPaylod } from '../../models';
import { FilterAction } from '../payloadModels/filter';
import { FilterActionTypes } from '../types';

export type FilterState = {
  searchTexts: SearchData;
};

const initialState: FilterState = {
  searchTexts: {} as SearchData,
};

export const filterReducer = (state = initialState, action: FilterAction): FilterState => {
  switch (action.type) {
    case FilterActionTypes.CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchTexts: {
          ...state.searchTexts,
          [(action.payload as SearchTextPaylod).pageKey]: (action.payload as SearchTextPaylod)
            .serachText,
        },
      };
    default:
      return state;
  }
};
