import {
  Box,
  Flex,
  Text,
  SegmentedControl,
  Center,
  LoadingOverlay,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { IconLock } from '@tabler/icons-react';
import WebhookSection from '../WebhookSection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';
import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { integrationActionCreator, webhookActionCreator } from '../../../redux/actions';
import integratioService from '../../../service/integration.service';
import { webhookUrlsStyles } from './styles';
import { EnvType } from '../../../models';
import {
  appStoreDetailsGoLiveService,
  organizationGoLiveService,
  webhookService,
} from '../../../service';
import WebhookSectionForProduction from '../WebhookSectionForProduction/WebhookSectionForProduction';
import { appStoreDetailsActionCreator } from '../../../redux/actions/appStoreDetails';
// import CustomLink from '../../../components/CustomLink';

type URLsProps = {
  projectId: string;
};

const URLs: React.FC<URLsProps> = ({ projectId }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const [selectedEnvType, setSelectedEnvType] = useState<EnvType>(EnvType.SANDBOX);
  const [isProductionEnabled, setIsProductionEnabled] = useState<boolean>(false);

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { data: integrationsWithStatuses, isLoading: isLoadingIntegrationWithStatuses } = useQuery({
    queryKey: ['get-integration-with-go-live-status', userData.organizationId],
    queryFn: () =>
      appStoreDetailsGoLiveService.getIntegrationsWithGoLiveStatuses(
        userData.organizationId,
        projectId,
      ),
    onSuccess: (data) => {
      dispatch(appStoreDetailsActionCreator.changeGetIntegrationsWithGoLiveStatuses(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const createSameOrganizationInProd = useMutation({
    mutationFn: () => {
      return organizationGoLiveService.createSameOrganization({
        organizationId: userData.organizationId,
        userId: userData.userId,
      });
    },
    onSuccess: () => {
      setIsProductionEnabled(true);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions-api-credentials', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId, projectId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration-api-credentials', userData.organizationId],
    queryFn: () => integratioService.getActivatedIntegrations(userData.organizationId, projectId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingCheckProductionEnabled } = useQuery({
    queryKey: [`check-is-production-enabled`, userData.organizationId],
    queryFn: () => webhookService.checkIsProductionEnabled(userData.organizationId, projectId),
    onSuccess: (data) => {
      dispatch(webhookActionCreator.changeCheckIsProductionEnabled(data));
      if (data === true) {
        createSameOrganizationInProd.mutate();
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: isProductionRequired, isLoading: isLoadingCheckProductionRequired } = useQuery({
    queryKey: [`check-is-production-required`, userData.organizationId],
    queryFn: () => webhookService.checkIsProductionRequired(userData.organizationId, projectId),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getSegmentedControl = () => {
    if (isProductionRequired) {
      return [
        {
          value: EnvType.SANDBOX,
          label: (
            <Center>
              <Text
                size="sm"
                weight={selectedEnvType === EnvType.SANDBOX ? 'bold' : 'normal'}
                className={
                  selectedEnvType === EnvType.SANDBOX ? 'segmented-control-lbl-active' : ''
                }
                data-cy="segmented-control-sandbox"
              >
                For sandbox
              </Text>
            </Center>
          ),
        },
        {
          value: EnvType.PRODUCTION,
          label: (
            <Center>
              {!isProductionEnabled && <IconLock size={14} />}
              <Text
                size="sm"
                ml={10}
                weight={selectedEnvType === EnvType.PRODUCTION ? 'bold' : 'normal'}
                data-cy="segmented-control-production"
                className={
                  selectedEnvType === EnvType.PRODUCTION ? 'segmented-control-lbl-active' : ''
                }
              >
                For production
              </Text>
            </Center>
          ),
          disabled: !isProductionEnabled,
        },
      ];
    }
    return [
      {
        value: EnvType.SANDBOX,
        label: (
          <Center>
            <Text
              size="sm"
              weight={selectedEnvType === EnvType.SANDBOX ? 'bold' : 'normal'}
              className={selectedEnvType === EnvType.SANDBOX ? 'segmented-control-lbl-active' : ''}
              data-cy="segmented-control-sandbox"
            >
              For sandbox
            </Text>
          </Center>
        ),
      },
    ];
  };

  const getWebhookSections = useCallback(() => {
    return activatedIntegrations.map(({ integration }) => (
      <WebhookSection integrationId={integration.id} projectId={projectId} key={integration.id} />
    ));
  }, [activatedIntegrations]);

  const getWebhookSectionsForProduction = useCallback(() => {
    const isGoLiveRequestAllreadySent = (integrationId: string) => {
      const activatedIntegrationWithStatus = integrationsWithStatuses?.find(
        (integrationWithStatus) => integrationWithStatus?.integration?.id === integrationId,
      );
      if (!activatedIntegrationWithStatus) {
        return false;
      }

      return activatedIntegrationWithStatus.requestAlreadySent;
    };

    return activatedIntegrations.map(({ integration }) => (
      <WebhookSectionForProduction
        integrationId={integration.id}
        projectId={projectId}
        key={integration.id}
        isAllFieldsDisabled={isGoLiveRequestAllreadySent(integration.id)}
      />
    ));
  }, [activatedIntegrations, integrationsWithStatuses]);

  return (
    <Box sx={webhookUrlsStyles(theme)}>
      <LoadingOverlay
        visible={
          isLoadingIntegrationDefinitions ||
          isLoadingActivatedIntegrations ||
          isLoadingCheckProductionEnabled ||
          isLoadingIntegrationWithStatuses ||
          isLoadingCheckProductionRequired
        }
      />
      <Flex direction="row" justify="space-between" align="center">
        <SegmentedControl
          value={selectedEnvType}
          onChange={(value) => setSelectedEnvType(EnvType[value as keyof typeof EnvType])}
          data={getSegmentedControl()}
          size="md"
        />
        {/* <Text>
          Quick help links:{' '}
          <CustomLink
            to="#"
            target="_blank"
            text="Learn more about webhooks"
            dataCy="link-learn-more-about-webhooks"
            inline
          />
        </Text> */}
      </Flex>
      {selectedEnvType === EnvType.SANDBOX
        ? getWebhookSections()
        : getWebhookSectionsForProduction()}
    </Box>
  );
};

export default URLs;
