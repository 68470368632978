import { IntegrationCapabilityDefinition, PosCapabilityIdType } from '../models';

export const orderNotificationEventDisableHandle = (
  capabiltyValues: { [key: string]: string },
  itemId: PosCapabilityIdType,
  isMandatory: boolean,
) => {
  if (capabiltyValues?.[PosCapabilityIdType.POS_ORDER_LIFECYCLE] === 'true') {
    return true;
  } else if (
    capabiltyValues?.[PosCapabilityIdType.POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_COMPLETED] ===
      'true' &&
    PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_COMPLETED === itemId
  ) {
    return true;
  } else if (
    capabiltyValues?.[PosCapabilityIdType.POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_DISPATCHED] ===
      'true' &&
    PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_DISPATCHED === itemId
  ) {
    return true;
  } else if (
    capabiltyValues?.[PosCapabilityIdType.POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_ACCEPTED] ===
      'true' &&
    PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_ACCEPTED === itemId
  ) {
    return true;
  }
  return isMandatory;
};

export const modifierAvailabilityDisableHandle = (
  capabiltyValues: { [key: string]: string },
  isMandatory: boolean,
) => {
  if (
    capabiltyValues?.[PosCapabilityIdType.POS_MENU_MODIFIERS] === 'false' ||
    capabiltyValues?.[PosCapabilityIdType.POS_MENU_MODIFIERS] === undefined
  ) {
    return true;
  }
  return isMandatory;
};
export const recipeModifierDisableHandle = (
  capabiltyValues: { [key: string]: string },
  isMandatory: boolean,
) => {
  if (
    capabiltyValues?.[PosCapabilityIdType.POS_RECIPE_MODIFIERS] === 'false' ||
    capabiltyValues?.[PosCapabilityIdType.POS_RECIPE_MODIFIERS] === undefined
  ) {
    return true;
  }
  return isMandatory;
};

export const toggleChildrenConditionally = (
  item: IntegrationCapabilityDefinition,
  childCapability: IntegrationCapabilityDefinition,
  capabiltyValues: { [key: string]: string },
  flag: boolean,
) => {
  if (
    item.id === PosCapabilityIdType.POS_MENU_AVAILABILITY &&
    childCapability.id === PosCapabilityIdType.POS_MENU_MODIFIER_AVAILABILITY &&
    (capabiltyValues[PosCapabilityIdType.POS_MENU_MODIFIERS] === 'false' ||
      capabiltyValues[PosCapabilityIdType.POS_MENU_MODIFIERS] === undefined)
  ) {
    return false;
  } else if (
    ((item.id === PosCapabilityIdType.POS_RECIPE_IMAGE_SUP &&
      childCapability.id === PosCapabilityIdType.POS_RECIPE_IMAGE_SUP_MODIFIERS) ||
      (item.id === PosCapabilityIdType.POS_RECIPE_PRICE_UPDATES &&
        childCapability.id === PosCapabilityIdType.POS_RECIPE_PRICE_UPDATES_MODIFIERS) ||
      (item.id === PosCapabilityIdType.POS_RECIPE_AVAILABILITY &&
        childCapability.id === PosCapabilityIdType.POS_RECIPE_AVAILABILITY_MODIFIER)) &&
    (capabiltyValues[PosCapabilityIdType.POS_RECIPE_MODIFIERS] === 'false' ||
      capabiltyValues[PosCapabilityIdType.POS_RECIPE_MODIFIERS] === undefined)
  ) {
    return false;
  } else if (
    (item.id === PosCapabilityIdType.POS_RECIPE ||
      item.id === PosCapabilityIdType.POS_RECIPE_SYNC_STATUS) &&
    childCapability.id === PosCapabilityIdType.POS_RECIPE_SYNC_STATUS_RECEIVE
  ) {
    return false;
  }
  return flag;
};
