import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import PageLayout from '../../../components/PageLayout';
import { newProjectStyles } from './styles';
import { Paper, Group, TextInput, Alert, useMantineTheme, Box, Grid, Button } from '@mantine/core';
import { IconAlertCircle, IconArrowNarrowRight } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { sandboxActionCreator } from '../../../redux/actions';
import { IntegrationState, SandboxState } from '../../../redux/reducers';
import { RootState } from '../../../redux/common';
import { useFormik } from 'formik';
import PartnerPlatformCard from '../ProjectTypeCard';
import { string as validateString, object as validateObject } from 'yup';
import { SubStatusElement } from '../../../components/Card/Card';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const NewProject: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { sandboxCreationData, retrySandboxCreation } = useSelector<RootState, SandboxState>(
    (state: RootState) => state.sandbox,
  );

  const { integrationDefinitions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => state.integration,
  );

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

  const handleCardClick = (id: string) => {
    setSelectedCardId(id);
    setFieldValue('IntegrationId', id);
  };

  const getApiSubStatusElementsById = (id: string): SubStatusElement[] => {
    switch (id) {
      case 'ORDER':
        return orderApiSubStatusElements;
      case 'DELIVERY':
        return deliveryApiSubStatusElements;
      case 'POS':
        return posApiSubStatusElements;
      default:
        return [];
    }
  };

  const orderApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/createorder',
    },
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/upload-menu',
    },
  ];

  const deliveryApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Delivery API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/docs/overview-delivery',
    },
  ];

  const posApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/savemenu',
    },
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/createorder-1',
    },
    {
      text: 'Order Statuses Update API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/orderaccepted',
    },
  ];

  const generateValidateSchema = () => {
    return validateObject().shape({
      projectName: validateString()
        .trim()
        .matches(/^\S*$/, 'Project Name should not contain spaces')
        .required('Project Name is required'),
      IntegrationId: validateString().required('Please select an API Suite'),
    });
  };

  const generateInitialValues = () => {
    return {
      menuData: sandboxCreationData?.sandboxMenuDataRows ?? [],
      sandboxPropertyDefinitions:
        sandboxCreationData?.sandboxPropertyDefinitions.map((sandboxPropertyDefinition) => ({
          ...sandboxPropertyDefinition,
          value: '',
        })) ?? [],
      projectName: '',
      IntegrationId: '',
    };
  };

  const { values, touched, errors, handleBlur, setFieldValue, handleSubmit } = useFormik({
    initialValues: generateInitialValues(),
    onSubmit: () => {
      configureIntegration();
    },
    validationSchema: generateValidateSchema(),
    validateOnMount: true,
  });

  useEffect(() => {
    dispatch(sandboxActionCreator.changeEditingMenuId(null));
  }, [dispatch]);

  const configureIntegration = useCallback(() => {
    navigate(`/manage-projects/capabilities/${selectedCardId}/name/${values.projectName}`);
  }, [navigate, selectedCardId, values.projectName]);

  return (
    <PageLayout
      id="new-project-page"
      sx={newProjectStyles(theme)}
      headerSection={
        <PageHeader
          id="new-project-page"
          title="Create Project"
          backText="Project"
          onClickBack={() => navigate('/')}
        />
      }
    >
      <Paper shadow="none" className="retry-message-paper">
        {retrySandboxCreation && (
          <Alert icon={<IconAlertCircle size={18} />} color="orange">
            Only the failed steps will be retried. Therefore, if the menu and other values have
            already been created successfully, they will not be overwritten again.
          </Alert>
        )}
      </Paper>

      <Paper shadow="xs" className="site-details-paper">
        <Group position="left" className="site-details-group">
          <TextInput
            label="Project Name"
            required
            value={values.projectName}
            error={
              touched.projectName &&
              errors.projectName && (
                <div data-cy="create-project-name-error">{errors.projectName}</div>
              )
            }
            name="projectName"
            onChange={(e) => setFieldValue('projectName', e.target.value)}
            data-cy="text"
            onBlur={handleBlur}
            description="Sandbox will be created under this project name."
          />
        </Group>
      </Paper>

      <Paper shadow="none">
        <Grid gutter="lg" className="projects-grid">
          {integrationDefinitions.map((apiSuite) => {
            const isSelected = selectedCardId === apiSuite.id.id;
            return (
              <Grid.Col
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                key={apiSuite.id.id}
                className={`api-access-card-column ${isSelected ? 'selected' : ''}`}
                onClick={() => handleCardClick(apiSuite.id.id)}
                sx={{
                  ...newProjectStyles(theme),
                  borderColor: isSelected ? theme.colors.blue[5] : theme.colors.gray[9],
                }}
              >
                <PartnerPlatformCard
                  id={apiSuite.id.id}
                  availableApiSuiteElements={getApiSubStatusElementsById(apiSuite.id.id)}
                  onClick={() => handleCardClick(apiSuite.id.id)}
                  isSelected={isSelected}
                />
              </Grid.Col>
            );
          })}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
          <Button
            onClick={() => setShowConfirmationDialog(true)}
            disabled={!selectedCardId || !values.projectName}
          >
            Next
          </Button>
        </Box>
      </Paper>
      <ConfirmationDialog
        opened={showConfirmationDialog}
        close={() => setShowConfirmationDialog(false)}
        id="retry-scenario-dialog"
        title="Are You Sure?"
        contentText="Once the project is created, the project name cannot be changed. Do you want to proceed?"
        onClickCancel={() => {
          setShowConfirmationDialog(false);
        }}
        onClickConfirm={() => handleSubmit()}
      />
    </PageLayout>
  );
};

export default NewProject;
