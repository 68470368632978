import { MantineTheme, Sx } from '@mantine/core';

export const manageProjectsStyles = (theme: MantineTheme): Sx => {
  return {
    '.content': {
      background: '#fff',
      padding: theme.spacing.xl,
    },
    '.main-container': {
      width: '100%',
      marginTop: '12px',
    },
    '.card-content': {
      height: '100%',
    },
    '.sandbox-sites-paper': {
      padding: theme.spacing.xl,
      margin: theme.spacing.sm,

      '.sandbox-sites-empty-state': {
        height: 450,
      },

      '.sandbox-sites-search-input': {
        width: 375,
      },

      '.sandbox-sites-container': {
        marginTop: theme.spacing.xl,
      },
    },

    '.sandbox-sites-alert': {
      marginBottom: theme.spacing.xl,
    },
  };
};
