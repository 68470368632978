import { MantineTheme, Sx } from '@mantine/core';

export const parametersStyles = (theme: MantineTheme): Sx => {
  return {
    '.parameter-container': {
      display: 'flex',
      width: '100%',
      height: '100%',
      background: 'white',
      marginTop: '3px',
      padding: '16px',
      flexDirection: 'column',
    },
    '.description': {
      padding: '12px 24px 22px 24px',
      background: 'white',
      color: '#7787F3',
      fontSize: '18px',
    },
    '.integration-settings-container': {
      width: '100%',
      marginTop: '20px',
    },
    '.integration-settings-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      paddingLeft: '8px',
      marginBottom: '20px',
    },
    '.integration-settings-row': {
      display: 'flex',
      paddingLeft: '8px',
      marginBottom: '16px',
      alignItems: 'center',
    },
    '.integration-settings-checkbox': {
      flexBasis: '30%',
    },
    '.custom-application-url-container': {
      width: '100%',
      marginTop: '15px',
      paddingLeft: '8px',
    },
    '.custom-application-url-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '5px',
    },
    '.custom-application-row': {
      display: 'flex',
      width: '30%',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    '.custom-application-url-description': {
      marginBottom: '16px',
    },
    '.button-container': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.save-button': {
      marginLeft: '10px',
    },
    '.order-auto-acceptance-after': {
      flexBasis: '30%',
      '.mantine-NumberInput-wrapper': {
        width: '55%',
      },
    },
    '.empty-column': {
      flexBasis: '30%',
    },
    '.checkbox-with-tooltip-row': {
      display: 'flex',
      alignItems: 'center',
    },
    '.independent-tooltip': {
      marginLeft: '5px',
    },
    '.application-url-text': {
      flexBasis: '55%',
    },
    '.default-url-text': {
      flexBasis: '30%',
      '.mantine-InputWrapper-label': {
        fontWeight: 'normal',
        margin: 0,
      },
    },
  };
};
