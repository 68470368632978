import { FC } from 'react';
import { Box, Sx } from '@mantine/core';
import { toast } from 'react-toastify';
import { getCustomTheme } from '../theme';
const colors = getCustomTheme().colors;

// Creating new toasts for statuses

type CustomToastParams = {
  autoClose?: number;
  title: string;
  message: string;
};

type CustomToastBoxProps = {
  title: string;
  message: string;
  variant?: 'danger' | 'warning' | 'primary';
};

const variantColor = {
  primary: colors?.toastColor?.[2] ?? '#243FFA',
  danger: colors?.toastColor?.[1] ?? '#fa5252',
  warning: colors?.toastColor?.[0] ?? '#fcc419',
};
const variantTextColor = {
  primary: colors?.white?.[0] ?? '#FFF',
  danger: colors?.white?.[0] ?? '#FFF',
  warning: colors?.black?.[0] ?? '#000',
};

const toastStyle = (variant: 'danger' | 'warning' | 'primary'): Sx => {
  return {
    '.title': {
      fontWeight: 600,
      fontSize: '16px',
      color: variantTextColor[variant],
    },
    '.message': {
      fontWeight: 400,
      fontSize: '14px',
      color: variantTextColor[variant],
    },
  };
};

const ToastBox: FC<CustomToastBoxProps> = ({ title, message, variant = 'primary' }) => (
  <Box sx={toastStyle(variant)}>
    <div className="title">{title}</div>
    <div className="message">{message}</div>
  </Box>
);

export const warningToast = ({ autoClose = 60000, title, message }: CustomToastParams) => {
  return toast(<ToastBox title={title} message={message} variant="warning" />, {
    position: 'top-center',
    autoClose,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    theme: 'light',
    pauseOnFocusLoss: false,
    style: {
      background: variantColor['warning'],
    },
  });
};
export const dangerToast = ({ autoClose = 60000, title, message }: CustomToastParams) => {
  return toast(<ToastBox title={title} message={message} variant="danger" />, {
    position: 'top-center',
    autoClose,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    theme: 'light',
    pauseOnFocusLoss: false,
    style: {
      background: variantColor['danger'],
    },
  });
};
export const primaryToast = ({ autoClose = 60000, title, message }: CustomToastParams) => {
  return toast(<ToastBox title={title} message={message} />, {
    position: 'top-center',
    autoClose,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    theme: 'light',
    pauseOnFocusLoss: false,
    style: {
      background: variantColor['primary'],
    },
  });
};
