import { LoadingOverlay, Tabs, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import PageLayout from '../../components/PageLayout';
import URLs from './URLs';
import { webhooksStyles } from './style';
import { useQuery } from '@tanstack/react-query';
import organizationService from '../../service/organizations.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';
import { organizationActionCreator } from '../../redux/actions/organization';
import { notifications } from '@mantine/notifications';

const Webhooks: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { tabValue, projectId } = useParams();
  // const test = useIsProdWebhooksAdded();
  const dispatch = useDispatch();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return (
    <PageLayout
      id={'webhooks-page'}
      sx={webhooksStyles(theme)}
      headerSection={
        <PageHeader
          id={'webhooks-page'}
          title={'Webhooks'}
          backText="Back"
          onClickBack={() => navigate(`/projects/${projectId}/dashboard`)}
        />
      }
      isTabs={true}
    >
      <LoadingOverlay visible={isLoadingOrganization} />
      <Tabs
        color="teal"
        defaultValue="urls"
        value={tabValue}
        onTabChange={(value) => navigate(`/webhooks/${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="urls" data-cy="webhooks-urls-tab">
            Webhook URLs
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="urls" pt="xs">
          <URLs projectId={projectId || ''} />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

export default Webhooks;
