import { UserFederationType } from './Sandbox';

export type UserOnBoardingProgress = {
  email: string | null;
  otp: string | null;
  name: string | null;
  password: string | null;
  onBoardingProgressState: OnBoardingProgressState | null;
};

export enum OnBoardingProgressState {
  EMAIL_SUBMITTED = 'EMAIL_SUBMITTED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  USER_REGISTRATION_COMPLETED = 'USER_REGISTRATION_COMPLETED',
  COMPLETED = 'COMPLETED',
}

export type UserDataStore = {
  userId: string;
  organizationId: string;
  email: string;
  hasActiveApi: boolean;
  name: string;
};

export type UserData = {
  id: string;
  organizationId: string;
  email: string;
  hasActiveApi: boolean;
  name: string;
  userFederationType: UserFederationType;
};

export type UserModle = {
  id: string;
  organizationId: string;
  email: string;
  name: string;
  userFederationType: UserFederationType;
};
