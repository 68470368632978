import { Box, useMantineTheme } from '@mantine/core';
import { layoutStyles } from './styles';
import SideBar from '../SideBar';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import RoutesHandler from '../../routes/RoutesHandler';
import { publicRoutes } from '../../routes/routes';

const publicPaths = publicRoutes.map(({ path }) => path);

const Layout: FC = () => {
  const theme = useMantineTheme();
  const location = useLocation();

  const getSideBar = useMemo(() => {
    if (!publicPaths.includes(location.pathname))
      return (
        <div>
          <SideBar />
        </div>
      );
  }, [location]);

  return (
    <Box sx={layoutStyles(theme, publicPaths.includes(location.pathname))}>
      {getSideBar}
      <div className="main">
        <RoutesHandler />
      </div>
    </Box>
  );
};

export default Layout;
