import { Box, Button, LoadingOverlay, Text, TextInput, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { number as validateNumber, object as validateObject, string as validateString } from 'yup';
import { Organization } from '../../../models';
import { Address, ContactInfo } from '../../../models/user';
import { authenticationActionCreator } from '../../../redux/actions';
import { organizationActionCreator } from '../../../redux/actions/organization';
import { RootState } from '../../../redux/common';
import { AuthenticationState, OrganizationState } from '../../../redux/reducers';
import { commonService } from '../../../service';
import organizationService from '../../../service/organizations.service';
import { urlValidationRegex } from '../../../utils/string';
import { createOrganizationStyles } from './style';

const CreateOrganization = () => {
  const theme = useMantineTheme();
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countries } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { values, setFieldValue, isValid, handleBlur, touched, errors, setTouched } = useFormik({
    initialValues: {
      name: '',
      countryId: '',
      contactInfo: {} as ContactInfo,
      companyWebsite: '',
      agreedTermId: '5f7b2c528088545d9602408c',
      address: {} as Address,
      invitationCode: '',
      partnerId: '',
      cityId: '',
      locationCoordinates: {
        latitude: 0,
        longitude: 0,
      },
    },
    onSubmit: () => {},
    validationSchema: validateObject().shape({
      name: validateString().trim().required('Name required'),
      contactInfo: validateObject().shape({
        name: validateString().trim().required('Contact name required'),
        email: validateString()
          .trim()
          .required('contact email required')
          .email('Need to be valid email'),
        telephone: validateObject().shape({
          countryCode: validateString().trim().required('Country code required'),
          number: validateNumber().required('Number required'),
        }),
      }),
      companyWebsite: validateString()
        .trim()
        .required('Company Website required')
        .matches(urlValidationRegex, 'Must be a valid url')
        .url('Must be a valid url'),
      agreedTermId: validateString().trim().required('Agreed Tem Id required'),
      // countryId: validateString().trim().required('Country required'),
      // cityId: validateString().trim().required('City required'),
      // locationCoordinates: validateObject().shape({
      //   latitude: validateNumber()
      //     .min(-90, 'latitude must be within from -90 to 90 range')
      //     .max(90, 'latitude must be within from -90 to 90 range')
      //     .required('latitude is required'),
      //   longitude: validateNumber()
      //     .min(-180, 'longitude must be within from -180 to 180 range')
      //     .max(180, 'longitude must be within from -180 to 180 range')
      //     .required('longitude is required'),
      // }),
    }),
    validateOnMount: true,
  });

  // const [addressFieldValues, setAddressFieldValues] = useState<AddressBuilderField[]>([]);

  // const addressFieldsFormikInstance = useFormik({
  //   initialValues: {} as FormikValues,
  //   validationSchema: getAddressValidationSchema(addressFieldValues),
  //   validateOnMount: true,
  //   onSubmit: () => {
  //     return;
  //   },
  // });

  const { isLoading: isLoadingCountries } = useQuery({
    queryKey: ['get-countries'],
    queryFn: () => commonService.getAllCountries(),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCountries(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingCities } = useQuery({
    queryKey: ['get-cities-by-Country-id', values?.countryId],
    queryFn: () => commonService.getCitiesByCountryId(values?.countryId),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCities(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: values?.countryId && values?.countryId?.length > 0 ? true : false,
  });

  const createOrganization = useMutation({
    mutationFn: (organization: Organization) => {
      return organizationService.createOrganization({
        organization: organization,
        userId: userData.userId,
      });
    },
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeUpdateOrganization(data));
      dispatch(
        authenticationActionCreator.changeAuthData({
          isAuthenticated: true,
          userData: {
            ...userData,
            organizationId: data.id,
          },
        }),
      );
      notifications.show({
        message: 'Organization is created successfully',
        color: 'green',
      });
      navigate('/get-started');
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  // const getAddressFieldsByCountry = useMutation({
  //   mutationFn: (countryId: string) => {
  //     return organizationService.getAddressFieldsByCountry(countryId);
  //   },
  //   onSuccess: (data) => {
  //     const addressBuilderFields =
  //       data &&
  //       data?.addressFieldWithOptionalInfoList.map((item) => {
  //         return {
  //           label: formatAddressFieldLabel(item.addressField.displayName),
  //           name: item.addressField.id,
  //           value: '',
  //           optional: item.isOptional,
  //           requiredMessage: 'is required',
  //         };
  //       });
  //     if (addressBuilderFields) {
  //       setAddressFieldValues(addressBuilderFields);
  //     }
  //     dispatch(organizationActionCreator.changeAddressFields(data));
  //   },
  //   onError: (error: any) => {
  //     notifications.show({
  //       title: error.name ?? 'Something went wrong',
  //       message: error.message ?? 'Something went wrong',
  //       color: 'red',
  //     });
  //   },
  // });

  // const removeAddressFields = () => {
  //   Object.keys(addressFieldsFormikInstance.values).forEach((item: string) => {
  //     addressFieldsFormikInstance.setFieldValue(item, '');
  //   });
  // };

  const getOrganization = () => {
    let currentOrganization = {
      name: values.name,
      // countryId: values.countryId,
      // cityId: values.cityId,
      contactInfo: values.contactInfo,
      companyWebsite: values.companyWebsite,
      agreedTermId: values.agreedTermId,
      // address: {
      //   fields: Object.keys(addressFieldsFormikInstance.values).map((item: string) => ({
      //     addressFieldId: item,
      //     value: addressFieldsFormikInstance.values[item],
      //   })),
      //   countryAddressFormatId: addressFields.countryAddressFormatId,
      // },
      invitationCode: values.invitationCode,
      locationCoordinates: values.locationCoordinates,
    } as Organization;
    return currentOrganization;
  };

  const isSaveDisabled = () => {
    return !isValid;
  };

  const getPhoneInput = useCallback(() => {
    if (countries.length > 0)
      return (
        <PhoneInput
          onlyCountries={countries.map((country) => country.alpha2Code.toLowerCase())}
          inputClass="phone-input"
          country="uae"
          onChange={(
            phone,
            country: {
              countryCode: string;
              dialCode: string;
              format: string;
              name: string;
            },
          ) => {
            setFieldValue('contactInfo.telephone.countryCode', country.dialCode);
            setFieldValue('contactInfo.telephone.number', phone.substring(country.dialCode.length));
          }}
          value={
            (values.contactInfo?.telephone?.countryCode ?? '') +
            (values.contactInfo?.telephone?.number?.toString() ?? '')
          }
          countryCodeEditable={false}
          enableSearch={true}
          disableSearchIcon={true}
          inputProps={{
            name: 'phone',
            required: true,
          }}
          onBlur={() => {
            setTouched({
              ...touched,
              contactInfo: {
                ...touched.contactInfo,
                telephone: {
                  ...touched.contactInfo?.telephone,
                  number: true,
                  countryCode: true,
                },
              },
            });
          }}
        />
      );

    return null;
    // eslint-disable-next-line
  }, [countries, values, errors, touched]);

  return (
    <Box
      className="bottom-container"
      sx={createOrganizationStyles(
        theme,
        ((touched?.contactInfo?.telephone?.number &&
          (errors?.contactInfo?.telephone?.number ?? '')?.length > 0) ||
          (touched?.contactInfo?.telephone?.countryCode &&
            (errors?.contactInfo?.telephone?.countryCode ?? '')?.length > 0)) ??
          false,
      )}
    >
      <LoadingOverlay
        visible={
          isLoadingCountries || (values?.countryId && values?.countryId?.length > 0)
            ? isLoadingCities
            : false
        }
      />
      <Text className="main-title">Create a new organisation</Text>
      <Text className="sub-text">
        Please provide your official company details. These details will be used when you activate
        Grubtech API Suites.
      </Text>
      <TextInput
        placeholder="Company name"
        value={values.name}
        label="Company Name"
        withAsterisk
        onChange={(e) => {
          setFieldValue('name', e.target.value.trimStart());
        }}
        onBlur={(e) => {
          setFieldValue('name', e.target.value.trimEnd());
          handleBlur(e);
        }}
        name={'name'}
        error={touched?.name && errors?.name}
      />
      <TextInput
        placeholder="Contact name"
        label="Contact Name"
        withAsterisk
        value={values.contactInfo?.name}
        onChange={(e) => {
          setFieldValue('contactInfo.name', e.target.value);
        }}
        onBlur={handleBlur}
        name={'contactInfo.name'}
        error={touched?.contactInfo?.name && errors?.contactInfo?.name}
      />
      <TextInput
        placeholder="Contact email"
        label="Contact Email"
        withAsterisk
        value={values.contactInfo?.email}
        onChange={(e) => {
          setFieldValue('contactInfo.email', e.target.value);
        }}
        onBlur={handleBlur}
        name={'contactInfo.email'}
        error={touched?.contactInfo?.email && errors?.contactInfo?.email}
      />
      <div className="phone-container">
        <Text fz="sm" fw={700} mb={8}>
          Contact Phone Number <span>*</span>
        </Text>
        {getPhoneInput()}

        {touched?.contactInfo?.telephone?.number && errors?.contactInfo?.telephone?.number && (
          <Text className="error-message" fz="sm">
            {errors?.contactInfo?.telephone?.number}
          </Text>
        )}
        {touched?.contactInfo?.telephone?.countryCode &&
          errors?.contactInfo?.telephone?.countryCode && (
            <Text className="error-message" fz="xs">
              {errors?.contactInfo?.telephone?.countryCode}
            </Text>
          )}
      </div>
      <TextInput
        placeholder="Company website"
        label="Company Website"
        withAsterisk
        value={values.companyWebsite}
        onChange={(e) => {
          setFieldValue('companyWebsite', e.target.value);
        }}
        name={'companyWebsite'}
        onBlur={handleBlur}
        error={touched.companyWebsite && errors.companyWebsite}
      />
      {/* <SimpleGrid cols={2}>
        <Select
          searchable
          label="Country"
          withAsterisk
          placeholder="Select country"
          data={getCountryList()}
          value={values.countryId}
          name="countryId"
          onChange={(value) => {
            addressFieldsFormikInstance.resetForm();
            removeAddressFields();
            getAddressFieldsByCountry.mutate(value || '');
            setFieldValue('countryId', value);
            setFieldValue('cityId', '');
            setFieldValue('locationCoordinates', {
              latitude: 0,
              longitude: 0,
            });
          }}
          onBlur={handleBlur}
          error={touched?.countryId && errors?.countryId}
        />
        <Select
          label="City"
          withAsterisk
          placeholder="Select city"
          data={
            cities?.map((city) => {
              return {
                value: city.id,
                label: city.name,
              };
            }) ?? []
          }
          value={values.cityId}
          name="cityId"
          onChange={(value) => {
            setFieldValue('cityId', value);
            setFieldValue('locationCoordinates', {
              latitude: cities.find((city) => city.id === value)?.cityCoordinates?.latitude,
              longitude: cities.find((city) => city.id === value)?.cityCoordinates?.longitude,
            });
          }}
          onBlur={handleBlur}
          error={touched?.cityId && errors?.cityId}
        />
      </SimpleGrid>
      <SimpleGrid cols={2}>
        <TextInput
          placeholder="latitude"
          label="Latitude"
          value={values.locationCoordinates.latitude}
          disabled
        />
        <TextInput
          placeholder="longitude"
          label="Longitude"
          value={values.locationCoordinates.longitude}
          disabled
        />
      </SimpleGrid>
      <AddressBuilder fields={addressFieldValues} formikInstance={addressFieldsFormikInstance} /> */}
      <Button
        disabled={isSaveDisabled()}
        onClick={() => createOrganization.mutate(getOrganization())}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default CreateOrganization;
