import {
  Box,
  Button,
  Checkbox,
  NumberInput,
  Text,
  TextInput,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { parametersStyles } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';
import {
  number as validateNumber,
  object as validateObject,
  string as validateString,
  boolean as validateBoolean,
  lazy,
  ObjectSchema,
  mixed as validateMixed,
} from 'yup';
import { useParams } from 'react-router-dom';
import { EnvType, Parameter, ParameterInfo, ParameterType, SettingsItem } from '../../../models';
import { useFormik } from 'formik';
import { urlValidationRegex } from '../../../utils/string';
import { UseMutationResult } from '@tanstack/react-query';
import GlobalSettings from '../GlobalSettings/GlobalSettings';
import { IconInfoCircle } from '@tabler/icons-react';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useDisclosure } from '@mantine/hooks';

interface ParametersProps {
  parameters: Parameter | undefined;
  saveParameters: UseMutationResult<any, any, Parameter, unknown>;
  envType: EnvType;
  isConfirmationRequired: boolean;
}

const Parameters: FC<ParametersProps> = ({
  parameters,
  saveParameters,
  envType,
  isConfirmationRequired,
}) => {
  const theme = useMantineTheme();
  const { integrationId, projectId } = useParams();
  const [opened, { open, close }] = useDisclosure(false);

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const submitParameters = () => {
    saveParameters.mutate(createParameter());
  };

  const createParameter = () => {
    return {
      integration: {
        id: currentIntegrationID,
      },
      organizationId: userData.organizationId,
      projectId: projectId ?? '',
      globalSettings: formikInstance.values.globalSettings,
      customApplicationUrl: {
        partnerApplicationUrl: isPartnerApplicationUrlExist
          ? formikInstance.values.customApplicationUrl.partnerApplicationUrl
          : undefined,
        brandApplicationUrl: isBrandApplicationUrlExist
          ? formikInstance.values.customApplicationUrl.brandApplicationUrl
          : undefined,
        locationApplicationUrl: isLocationApplicationUrlExist
          ? formikInstance.values.customApplicationUrl.locationApplicationUrl
          : undefined,
        channelApplicationUrl: isChannelApplicationUrlExist
          ? formikInstance.values.customApplicationUrl.channelApplicationUrl
          : undefined,
      },
      customPosIntegrationConfiguration: {
        autoConfigureNewLocations: false,
        menuCreationViaIntegration:
          formikInstance.values.customPosIntegrationConfiguration.menuCreationViaIntegration,
        perBrandPerLocationConfig:
          formikInstance.values.customPosIntegrationConfiguration.perBrandPerLocationConfig,
        updateOrderStatusHistory:
          formikInstance.values.customPosIntegrationConfiguration.updateOrderStatusHistory,
        sendOrderFailureNotification:
          formikInstance.values.customPosIntegrationConfiguration.sendOrderFailureNotification,
        sendInvoiceNumber:
          formikInstance.values.customPosIntegrationConfiguration.sendInvoiceNumber,
        orderAutoAcceptedAfterSeconds:
          formikInstance.values.customPosIntegrationConfiguration.orderAutoAcceptedAfterSeconds,
        handlingOrderLifeCycle:
          formikInstance.values.customPosIntegrationConfiguration.handlingOrderLifeCycle,
        defaultWebhookUrl:
          envType === EnvType.PRODUCTION
            ? formikInstance.values.customPosIntegrationConfiguration.defaultWebhookUrl
            : null,
      },
    } as Parameter;
  };

  const checkIsPartnerApplicationUrlExist = () => {
    return (
      parameters?.customApplicationUrl?.partnerApplicationUrl !== undefined &&
      parameters?.customApplicationUrl?.partnerApplicationUrl !== null &&
      parameters?.customApplicationUrl?.partnerApplicationUrl !== ''
    );
  };

  const checkIsChannelApplicationUrlExist = () => {
    return (
      parameters?.customApplicationUrl?.channelApplicationUrl !== undefined &&
      parameters?.customApplicationUrl?.channelApplicationUrl !== null &&
      parameters?.customApplicationUrl?.channelApplicationUrl !== ''
    );
  };

  const checkIsBrandApplicationUrlExist = () => {
    return (
      parameters?.customApplicationUrl?.brandApplicationUrl !== undefined &&
      parameters?.customApplicationUrl?.brandApplicationUrl !== null &&
      parameters?.customApplicationUrl?.brandApplicationUrl !== ''
    );
  };

  const checkIsLocationApplicationUrlExist = () => {
    return (
      parameters?.customApplicationUrl?.locationApplicationUrl !== undefined &&
      parameters?.customApplicationUrl?.locationApplicationUrl !== null &&
      parameters?.customApplicationUrl?.locationApplicationUrl !== ''
    );
  };

  const [isPartnerApplicationUrlExist, setIsPartnerApplicationUrlExist] = useState<boolean>(
    checkIsPartnerApplicationUrlExist(),
  );
  const [isChannelApplicationUrlExist, setIsChannelApplicationUrlExist] = useState<boolean>(
    checkIsChannelApplicationUrlExist(),
  );
  const [isBrandApplicationUrlExist, setIsBrandApplicationUrlExist] = useState<boolean>(
    checkIsBrandApplicationUrlExist(),
  );
  const [isLocationApplicationUrlExist, setIsLocationApplicationUrlExist] = useState<boolean>(
    checkIsLocationApplicationUrlExist(),
  );

  const [keyErrors, setKeyErrors] = useState<(string | undefined)[]>([]);
  const [settingsArray, setSettingsArray] = useState<SettingsItem[]>([]);

  const generateInitialValues = useCallback(() => {
    return {
      globalSettings: parameters?.globalSettings,
      customApplicationUrl: {
        partnerApplicationUrl: parameters?.customApplicationUrl?.partnerApplicationUrl || '',
        brandApplicationUrl: parameters?.customApplicationUrl?.brandApplicationUrl || '',
        locationApplicationUrl: parameters?.customApplicationUrl?.locationApplicationUrl || '',
        channelApplicationUrl: parameters?.customApplicationUrl?.channelApplicationUrl || '',
      },
      customPosIntegrationConfiguration: {
        autoConfigureNewLocations:
          parameters?.customPosIntegrationConfiguration?.autoConfigureNewLocations,
        menuCreationViaIntegration:
          parameters?.customPosIntegrationConfiguration?.menuCreationViaIntegration,
        perBrandPerLocationConfig:
          parameters?.customPosIntegrationConfiguration?.perBrandPerLocationConfig,
        updateOrderStatusHistory:
          parameters?.customPosIntegrationConfiguration?.updateOrderStatusHistory,
        sendOrderFailureNotification:
          parameters?.customPosIntegrationConfiguration?.sendOrderFailureNotification,
        sendInvoiceNumber: parameters?.customPosIntegrationConfiguration?.sendInvoiceNumber,
        orderAutoAcceptedAfterSeconds:
          parameters?.customPosIntegrationConfiguration?.orderAutoAcceptedAfterSeconds,
        handlingOrderLifeCycle:
          parameters?.customPosIntegrationConfiguration?.handlingOrderLifeCycle,
        defaultWebhookUrl: parameters?.customPosIntegrationConfiguration?.defaultWebhookUrl,
      },
    };
  }, [parameters]);

  const generateNewElementInitialValues = () => {
    return {
      field: '',
      type: ParameterType.STRING,
      value: '',
    };
  };

  useEffect(() => {
    formikInstance.setValues(generateInitialValues());
    setIsPartnerApplicationUrlExist(checkIsPartnerApplicationUrlExist());
    setIsChannelApplicationUrlExist(checkIsChannelApplicationUrlExist());
    setIsBrandApplicationUrlExist(checkIsBrandApplicationUrlExist());
    setIsLocationApplicationUrlExist(checkIsLocationApplicationUrlExist());
    // eslint-disable-next-line
    formikInstance.setFieldValue(
      'customPosIntegrationConfiguration.orderAutoAcceptedAfterSeconds',
      parameters?.customPosIntegrationConfiguration?.orderAutoAcceptedAfterSeconds || 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    formikInstance.setFieldTouched('customApplicationUrl.channelApplicationUrl', false);
    formikInstance.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChannelApplicationUrlExist]);

  useEffect(() => {
    formikInstance.setFieldTouched('customApplicationUrl.partnerApplicationUrl', false);
    formikInstance.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPartnerApplicationUrlExist]);

  useEffect(() => {
    formikInstance.setFieldTouched('customApplicationUrl.brandApplicationUrl', false);
    formikInstance.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandApplicationUrlExist]);

  useEffect(() => {
    formikInstance.setFieldTouched('customApplicationUrl.locationApplicationUrl', false);
    formikInstance.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationApplicationUrlExist]);

  const getChannelValidation = () => {
    if (isChannelApplicationUrlExist) {
      return validateString()
        .trim()
        .required('Application URL is required.')
        .matches(urlValidationRegex, 'Application URL must be a valid url')
        .url('Application URL must be a valid url');
    }
    return validateString().notRequired();
  };

  const getBrandValidation = () => {
    if (isBrandApplicationUrlExist) {
      return validateString()
        .trim()
        .required('Application URL is required.')
        .matches(urlValidationRegex, 'Application URL must be a valid url')
        .url('Application URL must be a valid url');
    }
    return validateString().notRequired();
  };

  const getLocationValidation = () => {
    if (isLocationApplicationUrlExist) {
      return validateString()
        .trim()
        .required('Application URL is required.')
        .matches(urlValidationRegex, 'Application URL must be a valid url')
        .url('Application URL must be a valid url');
    }
    return validateString().notRequired();
  };

  const getPartnerValidation = () => {
    if (isPartnerApplicationUrlExist) {
      return validateString()
        .trim()
        .required('Application URL is required.')
        .matches(urlValidationRegex, 'Application URL must be a valid url')
        .url('Application URL must be a valid url');
    }
    return validateString().notRequired();
  };

  const getDefaultWebhookUrlValidation = () => {
    if (envType === EnvType.PRODUCTION) {
      return validateString()
        .trim()
        .required('Default Webhook URL is required.')
        .matches(urlValidationRegex, 'Default Webhook URL must be a valid url')
        .url('Default Webhook URL must be a valid url');
    }
    return validateString().notRequired();
  };

  const newElementValidationSchema = validateObject({
    field: validateString().trim().required('field is required'),
    type: validateMixed<ParameterType>()
      .oneOf(Object.values(ParameterType), 'Invalid type')
      .required('Type is required'),
    value: validateString()
      .required('Value is required')
      .when('type', (type) => {
        if (type[0] === ParameterType.BOOLEAN) {
          return validateString()
            .required('Value is required')
            .oneOf(['true', 'false'], 'Value must be "true" or "false" for boolean type');
        } else if (type[0] === ParameterType.NUMBER) {
          return validateString()
            .required('Value is required')
            .matches(/^\d+$/, 'Value must be a number');
        }
        return validateString().required('Value is required');
      }),
  });

  const validationSchema = validateObject({
    globalSettings: lazy((obj: { [key: string]: ParameterInfo } | null) => {
      if (obj === null) {
        return validateObject().nullable();
      }
      return validateObject(
        Object.keys(obj || []).reduce(
          (acc: { [key: string]: ObjectSchema<ParameterInfo> }, key) => {
            acc[key] = validateObject({
              value: validateString()
                .required('Value is required')
                .when('type', (type) => {
                  if (type[0] === ParameterType.BOOLEAN) {
                    return validateString()
                      .required('Value is required')
                      .oneOf(['true', 'false'], 'Value must be "true" or "false" for boolean type');
                  } else if (type[0] === ParameterType.NUMBER) {
                    return validateString()
                      .required('Value is required')
                      .matches(/^\d+$/, 'Value must be a number');
                  }
                  return validateString().required('Value is required');
                }),
              type: validateMixed<ParameterType>()
                .oneOf(Object.values(ParameterType), 'Invalid type')
                .required('Type is required'),
            });
            return acc;
          },
          {},
        ),
      );
    }),
    customApplicationUrl: validateObject({
      partnerApplicationUrl: getPartnerValidation(),
      brandApplicationUrl: getBrandValidation(),
      locationApplicationUrl: getLocationValidation(),
      channelApplicationUrl: getChannelValidation(),
    }),
    customPosIntegrationConfiguration: validateObject({
      autoConfigureNewLocations: validateBoolean(),
      menuCreationViaIntegration: validateBoolean(),
      perBrandPerLocationConfig: validateBoolean(),
      updateOrderStatusHistory: validateBoolean(),
      sendOrderFailureNotification: validateBoolean(),
      sendInvoiceNumber: validateBoolean(),
      handlingOrderLifeCycle: validateBoolean(),
      orderAutoAcceptedAfterSeconds: validateNumber().integer('Value must be an integer'),
      defaultWebhookUrl: getDefaultWebhookUrlValidation(),
    }),
  });

  const newElementFormikInstance = useFormik({
    initialValues: generateNewElementInitialValues(),
    onSubmit: () => {},
    validationSchema: newElementValidationSchema,
  });

  const formikInstance = useFormik({
    initialValues: generateInitialValues(),
    onSubmit: () => {},
    validationSchema: validationSchema,
  });

  useEffect(() => {
    const settings = Object.entries(formikInstance.values.globalSettings || {}).map(
      ([key, info]) => ({
        key,
        ...info,
      }),
    );
    setSettingsArray(settings);
  }, [formikInstance.values.globalSettings]);

  const currentIntegrationID = integrationId!.toUpperCase();

  const isKeyErrorsInvalid = () => {
    return keyErrors.some((keyError) => keyError);
  };

  const isApplicationUrlsValid = () => {
    return (
      isBrandApplicationUrlExist ||
      isPartnerApplicationUrlExist ||
      isLocationApplicationUrlExist ||
      isChannelApplicationUrlExist
    );
  };

  const isGlobalSettingsValid = () => {
    return Object.keys(formikInstance?.values?.globalSettings || {}).length > 0;
  };

  const isProductionAlreadyCreated = envType === EnvType.PRODUCTION && !!parameters;

  return (
    <Box sx={parametersStyles(theme)}>
      <div className="description">
        Add the global configuration parameters, other specific settings and custom URL that are
        required for the integration.
      </div>
      <div className="parameter-container">
        <GlobalSettings
          isProductionAlreadyCreated={isProductionAlreadyCreated}
          setSettingsArray={setSettingsArray}
          formikInstance={formikInstance}
          settingsArray={settingsArray}
          setKeyErrors={setKeyErrors}
          keyErrors={keyErrors}
        />
        <div className="integration-settings-container">
          <Text className="integration-settings-title">Integration settings</Text>
          <div className="integration-settings-row">
            <Checkbox
              label="Auto-Configure New Location"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.autoConfigureNewLocations',
                  event.target.checked,
                );
              }}
              checked={
                formikInstance.values.customPosIntegrationConfiguration.autoConfigureNewLocations
              }
              className="integration-settings-checkbox"
              disabled={true}
              style={{ display: 'none' }}
            />
            <Checkbox
              label="Menu creation via integration"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.menuCreationViaIntegration',
                  event.target.checked,
                );
                if (event.target.checked) {
                  formikInstance.setFieldValue(
                    'customPosIntegrationConfiguration.perBrandPerLocationConfig',
                    event.target.checked,
                  );
                }
              }}
              checked={
                formikInstance.values.customPosIntegrationConfiguration.menuCreationViaIntegration
              }
              className="integration-settings-checkbox"
              disabled={isProductionAlreadyCreated}
            />
            <Checkbox
              label="Generate store ID Brand & Location Specific"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.perBrandPerLocationConfig',
                  event.target.checked,
                );
              }}
              checked={
                formikInstance.values.customPosIntegrationConfiguration.perBrandPerLocationConfig
              }
              className="integration-settings-checkbox"
              disabled={
                formikInstance.values.customPosIntegrationConfiguration
                  .menuCreationViaIntegration || isProductionAlreadyCreated
              }
            />
            <div className="checkbox-with-tooltip-row">
              <Checkbox
                label="Independent from the order lifecycle statuses"
                onChange={(event) => {
                  formikInstance.setFieldValue(
                    'customPosIntegrationConfiguration.handlingOrderLifeCycle',
                    event.target.checked,
                  );
                }}
                checked={
                  formikInstance.values.customPosIntegrationConfiguration.handlingOrderLifeCycle
                }
                disabled={isProductionAlreadyCreated}
              />
              <Tooltip
                label={'Order status changes are irrelevant to the integration flow.'}
                color="gray"
                className="independent-tooltip"
              >
                <IconInfoCircle style={{ width: rem(16) }} />
              </Tooltip>
            </div>
          </div>
          <div className="integration-settings-row">
            <Checkbox
              label="Update Order Status History"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.updateOrderStatusHistory',
                  event.target.checked,
                );
              }}
              checked={
                formikInstance.values.customPosIntegrationConfiguration.updateOrderStatusHistory
              }
              className="integration-settings-checkbox"
              disabled={isProductionAlreadyCreated}
            />
            <Checkbox
              label="Send Order Failure Notification"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.sendOrderFailureNotification',
                  event.target.checked,
                );
              }}
              checked={
                formikInstance.values.customPosIntegrationConfiguration.sendOrderFailureNotification
              }
              className="integration-settings-checkbox"
              disabled={isProductionAlreadyCreated}
            />
            <Checkbox
              label="Send Invoice Number"
              onChange={(event) => {
                formikInstance.setFieldValue(
                  'customPosIntegrationConfiguration.sendInvoiceNumber',
                  event.target.checked,
                );
              }}
              checked={formikInstance.values.customPosIntegrationConfiguration.sendInvoiceNumber}
              className="integration-settings-checkbox"
              disabled={isProductionAlreadyCreated}
            />
          </div>
          <div className="integration-settings-row">
            <NumberInput
              placeholder="sec"
              value={
                formikInstance.values.customPosIntegrationConfiguration
                  .orderAutoAcceptedAfterSeconds
              }
              label="Order auto acceptance after(sec)"
              onChange={(e) => {
                if (typeof e === 'number') {
                  formikInstance.setFieldValue(
                    'customPosIntegrationConfiguration.orderAutoAcceptedAfterSeconds',
                    e,
                  );
                }
              }}
              name={'customPosIntegrationConfiguration.orderAutoAcceptedAfterSeconds'}
              min={0}
              disabled={isProductionAlreadyCreated}
              className="order-auto-acceptance-after"
              onBlur={formikInstance.handleBlur}
              error={
                formikInstance.touched?.customPosIntegrationConfiguration
                  ?.orderAutoAcceptedAfterSeconds &&
                formikInstance.errors?.customPosIntegrationConfiguration
                  ?.orderAutoAcceptedAfterSeconds
              }
            />
            {envType === EnvType.PRODUCTION && (
              <TextInput
                placeholder="Default Webhook URL"
                label="Default Webhook URL"
                value={formikInstance.values.customPosIntegrationConfiguration.defaultWebhookUrl}
                onChange={(e) => {
                  formikInstance.setFieldValue(
                    'customPosIntegrationConfiguration.defaultWebhookUrl',
                    e.target.value,
                  );
                }}
                name={'customPosIntegrationConfiguration.defaultWebhookUrl'}
                className="default-url-text"
                disabled={isProductionAlreadyCreated}
                onBlur={formikInstance.handleBlur}
                error={
                  formikInstance.touched?.customPosIntegrationConfiguration?.defaultWebhookUrl &&
                  formikInstance.errors?.customPosIntegrationConfiguration?.defaultWebhookUrl
                }
              />
            )}
            <div className="empty-column" />
          </div>
        </div>
        <div className="custom-application-url-container">
          <Text className="custom-application-url-title">Custom application URL</Text>
          <Text className="custom-application-url-description">
            The registered URLs will be used to render the custom UI components for the custom
            application once it is published to the Grubcenter app store. The restaurant partners on
            Grubtech platform will be able to install this app and setup the configurations required
            for the integration service exposed via the registered URLs.
          </Text>
          <div className="custom-application-row">
            <Checkbox
              label="Partner"
              onChange={(event) => {
                setIsPartnerApplicationUrlExist(event.target.checked);
                if (event.target.checked) {
                  if (formikInstance.values.customApplicationUrl.partnerApplicationUrl) {
                    setTimeout(
                      () =>
                        formikInstance.setFieldTouched(
                          'customApplicationUrl.partnerApplicationUrl',
                          true,
                        ),
                      100,
                    );
                    formikInstance.validateField('customApplicationUrl.partnerApplicationUrl');
                  }
                }
              }}
              checked={isPartnerApplicationUrlExist}
              className="application-url-checkbox"
              disabled={isProductionAlreadyCreated}
            />
            <TextInput
              placeholder="partner application url"
              value={formikInstance.values.customApplicationUrl.partnerApplicationUrl}
              onChange={(e) => {
                formikInstance.setFieldValue(
                  'customApplicationUrl.partnerApplicationUrl',
                  e.target.value,
                );
              }}
              name={'customApplicationUrl.partnerApplicationUrl'}
              className="application-url-text"
              disabled={!isPartnerApplicationUrlExist || isProductionAlreadyCreated}
              onBlur={formikInstance.handleBlur}
              error={
                isPartnerApplicationUrlExist &&
                formikInstance.touched?.customApplicationUrl?.partnerApplicationUrl &&
                formikInstance.errors?.customApplicationUrl?.partnerApplicationUrl
              }
            />
          </div>
          <div className="custom-application-row">
            <Checkbox
              label="Brand"
              onChange={(event) => {
                setIsBrandApplicationUrlExist(event.target.checked);
                if (event.target.checked) {
                  if (formikInstance.values.customApplicationUrl.brandApplicationUrl) {
                    setTimeout(
                      () =>
                        formikInstance.setFieldTouched(
                          'customApplicationUrl.brandApplicationUrl',
                          true,
                        ),
                      100,
                    );
                    formikInstance.validateField('customApplicationUrl.brandApplicationUrl');
                  }
                }
              }}
              checked={isBrandApplicationUrlExist}
              disabled={isProductionAlreadyCreated}
              className="application-url-checkbox"
            />
            <TextInput
              placeholder="brand application url"
              value={formikInstance.values.customApplicationUrl.brandApplicationUrl}
              onChange={(e) => {
                formikInstance.setFieldValue(
                  'customApplicationUrl.brandApplicationUrl',
                  e.target.value,
                );
              }}
              name={'customApplicationUrl.brandApplicationUrl'}
              className="application-url-text"
              onBlur={formikInstance.handleBlur}
              error={
                isBrandApplicationUrlExist &&
                formikInstance.touched?.customApplicationUrl?.brandApplicationUrl &&
                formikInstance.errors?.customApplicationUrl?.brandApplicationUrl
              }
              disabled={!isBrandApplicationUrlExist || isProductionAlreadyCreated}
            />
          </div>
          <div className="custom-application-row">
            <Checkbox
              label="Location"
              onChange={(event) => {
                setIsLocationApplicationUrlExist(event.target.checked);
                if (event.target.checked) {
                  if (formikInstance.values.customApplicationUrl.locationApplicationUrl) {
                    setTimeout(
                      () =>
                        formikInstance.setFieldTouched(
                          'customApplicationUrl.locationApplicationUrl',
                          true,
                        ),
                      100,
                    );
                    formikInstance.validateField('customApplicationUrl.locationApplicationUrl');
                  }
                }
              }}
              checked={isLocationApplicationUrlExist}
              disabled={isProductionAlreadyCreated}
              className="application-url-checkbox"
            />
            <TextInput
              placeholder="location application url"
              value={formikInstance.values.customApplicationUrl.locationApplicationUrl}
              onChange={(e) => {
                formikInstance.setFieldValue(
                  'customApplicationUrl.locationApplicationUrl',
                  e.target.value,
                );
              }}
              name={'customApplicationUrl.locationApplicationUrl'}
              className="application-url-text"
              disabled={!isLocationApplicationUrlExist || isProductionAlreadyCreated}
              onBlur={formikInstance.handleBlur}
              error={
                isLocationApplicationUrlExist &&
                formikInstance.touched?.customApplicationUrl?.locationApplicationUrl &&
                formikInstance.errors?.customApplicationUrl?.locationApplicationUrl
              }
            />
          </div>
          <div className="custom-application-row">
            <Checkbox
              label="Food aggregator/Channel"
              onChange={(event) => {
                setIsChannelApplicationUrlExist(event.target.checked);
                if (event.target.checked) {
                  if (formikInstance.values.customApplicationUrl.channelApplicationUrl) {
                    setTimeout(
                      () =>
                        formikInstance.setFieldTouched(
                          'customApplicationUrl.channelApplicationUrl',
                          true,
                        ),
                      100,
                    );
                    formikInstance.validateField('customApplicationUrl.channelApplicationUrl');
                  }
                }
              }}
              checked={isChannelApplicationUrlExist}
              className="application-url-checkbox"
              disabled={isProductionAlreadyCreated}
            />
            <TextInput
              placeholder="channel application url"
              value={formikInstance.values.customApplicationUrl.channelApplicationUrl}
              onChange={(e) => {
                formikInstance.setFieldValue(
                  'customApplicationUrl.channelApplicationUrl',
                  e.target.value,
                );
              }}
              name={'customApplicationUrl.channelApplicationUrl'}
              className="application-url-text"
              disabled={!isChannelApplicationUrlExist || isProductionAlreadyCreated}
              onBlur={formikInstance.handleBlur}
              error={
                isChannelApplicationUrlExist &&
                formikInstance.touched?.customApplicationUrl?.channelApplicationUrl &&
                formikInstance.errors?.customApplicationUrl?.channelApplicationUrl
              }
            />
          </div>
        </div>
        <div className="button-container">
          <Button
            className="cancel-button"
            onClick={() => {
              formikInstance.setValues(generateInitialValues());
              setIsPartnerApplicationUrlExist(checkIsPartnerApplicationUrlExist());
              setIsChannelApplicationUrlExist(checkIsChannelApplicationUrlExist());
              setIsBrandApplicationUrlExist(checkIsBrandApplicationUrlExist());
              setIsLocationApplicationUrlExist(checkIsLocationApplicationUrlExist());
              newElementFormikInstance.setValues(generateNewElementInitialValues());
              newElementFormikInstance.resetForm();
            }}
            data-cy="parameter-settings-cancel-button"
            size="sm"
            variant="outline"
            disabled={isProductionAlreadyCreated}
          >
            Cancel
          </Button>
          <Button
            className="save-button"
            disabled={
              !formikInstance.isValid ||
              isKeyErrorsInvalid() ||
              isProductionAlreadyCreated ||
              !isApplicationUrlsValid() ||
              !isGlobalSettingsValid()
            }
            onClick={() => {
              if (!isConfirmationRequired) {
                submitParameters();
              } else {
                open();
              }
            }}
            data-cy="parameter-settings-save-button"
            size="sm"
          >
            Save changes
          </Button>
        </div>
      </div>
      <ConfirmationDialog
        id={'save-parameters-for-prod'}
        opened={opened}
        onClickCancel={close}
        close={close}
        title="Are you sure?"
        onClickConfirm={() => {
          submitParameters();
          close();
        }}
        contentText="Are you sure? production details cannot be changed once submitted."
      />
    </Box>
  );
};

export default Parameters;
