import { MantineTheme, Sx } from '@mantine/core';

export const welcomeHeaderStyles = (theme: MantineTheme): Sx => {
  return {
    margin: `${theme.spacing.xl} 0`,
    '.titleSection__upperBlackMessage': {
      fontSize: '40px',
    },
    '.titleSection__lowerBlueMessage': {
      fontSize: '52px',
      color: theme.colors.grubtech[0],
    },
  };
};
