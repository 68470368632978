import { MantineTheme, Sx } from '@mantine/core';

export const backTextStyles = (theme: MantineTheme): Sx => {
  return {
    '.backText': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing.xs,
      textDecoration: 'none',
      color: theme.colors.grubtech[0],
      '.backText__text': {
        transform: 'translateY(-2px)',
      },
    },
  };
};
