import { DataWithFileName, NdaStatus } from '../../models/nda';
import { NdaAction } from '../payloadModels/nda';
import { NdaActionTypes } from '../types/nda';

export type NdaState = {
  ndaPdfPageCount: number;
  ndaStatus: NdaStatus;
  initialPdfFile: ArrayBuffer;
  signedPdfFile: DataWithFileName;
};

const initialState: NdaState = {
  ndaPdfPageCount: 0,
  ndaStatus: NdaStatus.NOT_SUBMITTED,
  initialPdfFile: new ArrayBuffer(0),
  signedPdfFile: { data: new ArrayBuffer(0), fileName: '' },
};

export const ndaReducer = (state = initialState, action: NdaAction): NdaState => {
  switch (action.type) {
    case NdaActionTypes.CHANGE_NDA_PDF_PAGES_COUNT:
      return { ...state, ndaPdfPageCount: action.payload as number };
    case NdaActionTypes.CHANGE_NDA_STATUS:
      return { ...state, ndaStatus: action.payload as NdaStatus };
    case NdaActionTypes.CHANGE_INITIAL_PDF:
      return { ...state, initialPdfFile: action.payload as ArrayBuffer };
    case NdaActionTypes.CHANGE_SIGNED_PDF:
      return { ...state, signedPdfFile: action.payload as DataWithFileName };
    default:
      return state;
  }
};
