import { Sx } from '@mantine/core';

export const invocationModlaStyles = (): Sx => {
  return {
    '.loader-wrapper': {
      display: 'flex',
      width: '100%',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};
