import { ApiKey, ApiSuiteDefinition, ApiSuiteId } from '../../models/apiKey';
import { actionCreator } from '../common';
import { APIKeyActionTypes } from '../types';

export const apiKeyActionCreator = {
  changeApiKeyList: actionCreator<ApiKey[]>(APIKeyActionTypes.CHANGE_API_KEY_LIST),
  changeApiSuiteDefinitions: actionCreator<ApiSuiteDefinition[]>(
    APIKeyActionTypes.CHANGE_API_SUITE_DEFINITIONS,
  ),
  resetApiKeyList: actionCreator<ApiKey[]>(APIKeyActionTypes.RESET_API_KEY_LIST),
  resetApiSuiteDefinitionList: actionCreator<ApiSuiteDefinition[]>(
    APIKeyActionTypes.RESET_API_SUITE_DEFINITIONS,
  ),
  insertApiKey: actionCreator<ApiKey>(APIKeyActionTypes.INSERT_API_KEY),
  RemoveApiKey: actionCreator<ApiKey>(APIKeyActionTypes.REMOVE_API_KEY),
  changeNewApiCredentialsObject: actionCreator<ApiKey | null>(
    APIKeyActionTypes.CHANGE_NEW_API_CREDENTIALS_OBJECT,
  ),
  changeGeneratingAPISuiteKeyId: actionCreator<ApiSuiteId | null>(
    APIKeyActionTypes.CHNAGE_GENERATING_API_SUITE_KEY_ID,
  ),
  changeNewApiCredentialsObjectForProduction: actionCreator<ApiKey | null>(
    APIKeyActionTypes.CHANGE_NEW_API_CREDENTIALS_OBJECT_FOR_PRODUCTION,
  ),
  changeGeneratingAPISuiteKeyIdForProduction: actionCreator<ApiSuiteId | null>(
    APIKeyActionTypes.CHANGE_GENERATING_API_SUITE_KEY_ID_FOR_PRODUCTION,
  ),
  changeCheckIsProductionEnabledForApiKey: actionCreator<Boolean>(
    APIKeyActionTypes.CHANGE_CHECK_IS_PRODUCTION_ENABLED_FOR_API_KEY,
  ),
};
