import { AuthTypes, DataPassingMedium, HTTPMethod, RequestTypes } from '../models';

export const HTTP_METHOD_VALUES = [
  { value: HTTPMethod.POST, label: 'POST' },
  { value: HTTPMethod.PUT, label: 'PUT' },
];

export const REQUEST_TYPE_VALUES = [
  {
    value: RequestTypes.APPLICATION_FORM_URLENCODED_VALUE,
    label: 'application/x-www-form-urlencoded',
  },
  { value: RequestTypes.APPLICATION_JSON_VALUE, label: 'application/json' },
];

export const DATA_PASSING_MEDIUM_VALUES = [
  { value: DataPassingMedium.HEADER, label: 'Header Parameter' },
  { value: DataPassingMedium.QUERY_PARAM, label: 'Query Parameter' },
];
export const AUTHENTICATION_TYPES = [
  { value: AuthTypes.BASIC_AUTH, label: 'Basic Authentication' },
  { value: AuthTypes.X_API_KEY, label: 'X-API' },
  { value: AuthTypes.O_AUTH_2, label: 'OAuth2' },
  { value: AuthTypes.NONE, label: 'None' },
];
