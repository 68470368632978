import { sandBoxAxios } from '../config/axios.config';
import {
  CreateSandboxApiData,
  SandboxCreationFormData,
  SandboxPropertyDefinitionValue,
  SandboxPropertyValue,
  SandboxPropertyValueValidation,
  SandboxSummaryDto,
  UserEmployee,
  UserModle,
} from '../models';

const webhookService = {
  createSandbox: async (payload: CreateSandboxApiData): Promise<SandboxSummaryDto> => {
    const { data } = await sandBoxAxios.put(
      `/create/${payload.partnerId}/projects/${payload.projectId}`,
      payload.creationData,
    );
    return data;
  },
  getSandboxStatus: async (partnerId: string, projectId: string): Promise<SandboxSummaryDto[]> => {
    const { data } = await sandBoxAxios.get(`/${partnerId}/projects/${projectId}`);
    return data;
  },
  getSandboxMenuData: async (
    organisationId: string,
    projectId: string,
  ): Promise<SandboxCreationFormData> => {
    const { data } = await sandBoxAxios.get(`/menu-data/${organisationId}/projects/${projectId}`);
    return data;
  },
  createUser: async (user: UserModle, projectId: string): Promise<UserEmployee> => {
    const { data } = await sandBoxAxios.put(
      `/create-user/${user.organizationId}/projects/${projectId}`,
      user,
    );
    return data;
  },
  deleteSandbox: async (payload: {
    organizationId: string;
    sandBoxId: string;
  }): Promise<UserEmployee> => {
    const { data } = await sandBoxAxios.put(`/delete`, payload);
    return data;
  },
  verifyEmail: async (email: string): Promise<any> => {
    const { data } = await sandBoxAxios.get(`/partner/byEmail/${email}`);
    return data;
  },
  getAllSandboxPropertyDefinitionValue: async (
    organizationId: string,
    projectId: string,
  ): Promise<SandboxPropertyDefinitionValue[]> => {
    const { data } = await sandBoxAxios.get(
      `/property-definition-value/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
  updatePropertyValue: async (payload: {
    organizationId: string;
    projectId: string;
    propertyValues: SandboxPropertyValue[];
  }): Promise<SandboxPropertyValueValidation[]> => {
    const { data } = await sandBoxAxios.put(
      `/update-property-value/${payload.organizationId}/projects/${payload.projectId}`,
      payload.propertyValues,
    );
    return data;
  },
};

export default webhookService;
