import { DataWithFileName, NdaStatus } from '../../models/nda';
import { actionCreator } from '../common';
import { NdaActionTypes } from '../types/nda';

export const ndaActionCreator = {
  changeNdaPdfCount: actionCreator<number>(NdaActionTypes.CHANGE_NDA_PDF_PAGES_COUNT),
  changeNdaStatus: actionCreator<NdaStatus>(NdaActionTypes.CHANGE_NDA_STATUS),
  changeInitialPdf: actionCreator<ArrayBuffer>(NdaActionTypes.CHANGE_INITIAL_PDF),
  changeSignedPdf: actionCreator<DataWithFileName>(NdaActionTypes.CHANGE_SIGNED_PDF),
};
