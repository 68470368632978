export enum TestFrameworkActionTypes {
  CHANGE_ALL_TEST_DEFINITION_DATA = 'CHANGE_ALL_TEST_DEFINITION_DATA',
  CHANGE_TEST_CASE_EXECUTION_DATA = 'CHANGE_TEST_CASE_EXECUTION_DATA',
  CHANGE_SELECTED_FLOW = 'CHANGE_SELECTED_FLOW',
  CHANGE_SELECTED_SUB_FLOW = 'CHANGE_SELECTED_SUB_FLOW',
  CHANGE_SELECTED_TEST_CASE = 'CHANGE_SELECTED_TEST_CASE',
  CHNAGE_SELECTED_TEST_SUITE = 'CHNAGE_SELECTED_TEST_SUITE',
  CHANGE_TEST_SUITE_DEFINITION = 'CHANGE_TEST_SUITE_DEFINITION',
  RESET_TEST_SUITE_DEFINITION = 'RESET_TEST_SUITE_DEFINITION',
  CHANGE_TEST_SUITE_INSTANCE_DATA = 'CHANGE_TEST_SUITE_INSTANCE_DATA',
  CHANGE_IS_TEST_CASE_BEGIN = 'CHANGE_IS_TEST_CASE_BEGIN',
  CHANGE_INVOCATION_ID = 'CHANGE_INVOCATION_ID',
  CHANGE_TEST_CASE_APPROVAL_STATUS = 'CHANGE_TEST_CASE_APPROVAL_STATUS',
  CHANGE_TEST_SUBMITED_DETAILS = 'CHANGE_TEST_SUBMITED_DETAILS',
  CLEAR_TEST_CASE_LOGS = 'CLEAR_TEST_CASE_LOGS',
  TOGGLE_REFETCH_ENABLE = 'TOGGLE_REFETCH_ENABLE',
}
