import { MantineTheme, Sx } from '@mantine/core';

export const overviewStyles = (theme: MantineTheme): Sx => {
  return {
    '.overview-instructions-paper': {
      width: '100%',
      padding: theme.spacing.xl,

      '.overview-description': {
        fontSize: theme.fontSizes.sm,
        color: theme.colors.gray[8],
      },

      '.overview-image': {
        marginRight: theme.spacing.xl,
      },

      '.overview-divider': {
        margin: `${theme.spacing.lg} 0`,
        borderColor: theme.colors.gray[3],
      },

      '.overview-instructions-title': {
        marginBottom: theme.spacing.xl,
      },
    },
    '.overview-status': {
      width: '30%',
    },
  };
};
