import { MantineTheme, Sx } from '@mantine/core';

export const customNavLinksStyles = (theme: MantineTheme): Sx => {
  return {
    '.mantine-NavLink-root': {
      minHeight: 80,
      padding: `10px 15px`,
      background: theme.colors.white[0],
      borderRadius: '5px',
      border: `1px solid ${theme.colors.grubtech[0]}`,

      '.mantine-NavLink-rightSection': {
        '.mantine-Alert-root': {
          '.mantine-Alert-wrapper': {
            '.mantine-Alert-body': {
              '.mantine-Alert-title': {
                marginBottom: 'unset',
              },
            },
          },
        },
      },
      '.nav-link-icon': {
        display: 'none',
      },

      '&[data-active]': {
        backgroundColor: theme.colors.grubtech[2],

        '.nav-link-icon': {
          color: theme.black,
          display: 'block',
        },
      },

      '.mantine-NavLink-label': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',

        '.title': {
          margin: 'unset',
          color: theme.colors.grubtech[0],
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        '.status': {
          marginLeft: 'auto',
        },
      },

      '.mantine-NavLink-description': {
        color: theme.black,
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
      },

      '&:hover': {
        backgroundColor: theme.colors.grubtech[2],
        '.nav-link-icon': {
          display: 'block',
        },
      },
    },
  };
};
