import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { IconAlarmOff, IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imageUpload } from '../../../../../assets/images';
import SnackBar from '../../../../../components/SnackBar';
import useSelectedTestDefinition from '../../../../../hooks/useSelectedTestDefinition';
import {
  ApprovalStatus,
  ExecutableActionStepExecutionDto,
  TestCaseExecutionDto,
  TestCaseStatus,
  TestStepDefinitionSummaryDto,
  TestStepType,
  UploadImageStepExecutionDto,
  UserInputStepExecutionDto,
  markActionAsDonePayload,
  triggerActionPayload,
} from '../../../../../models';
import { loaderActionCreator, testFrameworkActionCreator } from '../../../../../redux/actions';
import { RootState } from '../../../../../redux/common';
import { AuthenticationState, TestFrameworkState } from '../../../../../redux/reducers';
import { testFrameworkService } from '../../../../../service';
import { generateFileName } from '../../../../../utils/string';
import { stepStyles } from './styles';
import SkipConfirmationModal from '../SkipConfirmationModal';
import { useDisclosure } from '@mantine/hooks';
import parse from 'html-react-parser';

type StepProps = {
  stepId: string;
  projectId: string;
};

const Step: FC<StepProps> = ({ stepId, projectId }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const selectedTestCaseData = useSelectedTestDefinition();

  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }
  const stepData = selectedTestCaseData?.steps.find(({ id }) => id === stepId);
  const [
    skipConfirmationModalOpened,
    { open: openSkipConfirmationModal, close: closeSkipConfirmationModal },
  ] = useDisclosure();

  const {
    testSuiteInstanceData,
    selectedFlow,
    selectedSubFlow,
    selectedTestCase,
    testCaseExecutionData,
    testSuiteDefinitions,
    selectedTestSuite,
    testCaseApprovalStatusData,
    testSubmittedDetails,
    refetchEnable,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const imagePayloadRef = useRef<any>(null);

  useEffect(() => {
    if (testCaseExecutionData === null) {
      resetForm();
    }
    // eslint-disable-next-line
  }, [testCaseExecutionData]);

  const getInstanceId = useCallback(() => {
    return testSuiteInstanceData?.testCaseInstances?.find(
      (instance) =>
        instance.flowId === selectedFlow &&
        instance.subFlowId === selectedSubFlow &&
        instance.testCaseId === selectedTestCase,
    )?.instanceId;
  }, [testSuiteInstanceData, selectedFlow, selectedSubFlow, selectedTestCase]);

  const { isInitialLoading } = useQuery({
    queryKey: [`polling-for-updates-${getInstanceId()}`, getInstanceId()],
    queryFn: () => testFrameworkService.pollForUpdates(getInstanceId() ?? ''),
    onSuccess: (data: TestCaseExecutionDto) => {
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: getInstanceId() && refetchEnable ? true : false,
    refetchInterval: testCaseExecutionData?.overallStatus !== TestCaseStatus.PENDING ? false : 5000,
    refetchIntervalInBackground: true,
  });

  const skipTestCase = useMutation({
    mutationFn: (payload: { instanceId: string; reason: string }) => {
      return testFrameworkService.skipTestCase(payload);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
      closeSkipConfirmationModal();
      getLatestTestSuiteInstanceData.mutate();
      notifications.show({
        title: 'Test case skipped successfully',
        message: '',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getLatestTestSuiteInstanceData = useMutation({
    mutationFn: () => testFrameworkService.getLatestInstanceOfSuite(selectedTestSuite, projectId),
    onSuccess: (data) => {
      if (data?.instanceId !== undefined) {
        dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(data));
      } else {
        dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const markActionAsDone = useMutation({
    mutationFn: (payload: markActionAsDonePayload) => {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(false));
      return testFrameworkService.markActionAsDone(payload);
    },
    onSuccess: (data) => {
      setTimeout(() => {
        dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
      }, 13000);
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
    },
    onError: (error: any) => {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const cancelTestCase = useMutation({
    mutationFn: (instanceId: string) => {
      return testFrameworkService.cancelTestCase(instanceId);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const triggerAction = useMutation({
    mutationFn: (payload: triggerActionPayload) => {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(false));
      return testFrameworkService.triggerAction(payload);
    },
    onSuccess: (data) => {
      setTimeout(() => {
        dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
      }, 13000);
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
    },
    onError: (error: any) => {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const uploadFile = useMutation({
    mutationFn: (payload: {
      organizationId: string;
      testCaseInstanceId: string;
      stepId: string;
      imageDefinitionId: string;
      file: File;
    }) => {
      imagePayloadRef.current = payload;
      dispatch(loaderActionCreator.changeLoaderStatus(true));
      return testFrameworkService.uploadFile(payload);
    },
    onSuccess: (data) => {
      const payload = imagePayloadRef.current;
      if (payload) {
        const { organizationId, testCaseInstanceId, stepId, imageDefinitionId, file } = payload;
        const fileName = generateFileName(
          file,
          organizationId,
          testCaseInstanceId,
          stepId,
          imageDefinitionId,
        );
        setFieldValue(`${imageDefinitionId}`, { ...values[imageDefinitionId], value: fileName });
      }
      dispatch(loaderActionCreator.changeLoaderStatus(false));
    },
    onError: (error: any) => {
      dispatch(loaderActionCreator.changeLoaderStatus(false));
    },
  });
  const isActiveStep = useCallback(() => {
    return testCaseExecutionData?.activeTestSteps.steps.find(({ id }) => id === stepId)
      ? true
      : false;
  }, [testCaseExecutionData, stepId]);

  const isPendingStep = useCallback(() => {
    return (
      testCaseExecutionData?.currentStepsWithStatuses.find((step) => step.stepId === stepId)
        ?.status === TestCaseStatus.PENDING
    );
  }, [testCaseExecutionData, stepId]);

  const activeStepData = useCallback(
    (verifyStepId?: string) => {
      return testCaseExecutionData?.activeTestSteps.steps.find(
        ({ id }) => id === (verifyStepId !== undefined ? verifyStepId : stepId),
      );
    },
    [testCaseExecutionData, stepId],
  );

  const getCurrentStepData = useCallback(
    (verifyStepId?: string) => {
      return testCaseExecutionData?.currentStepsWithStatuses.find(
        (stepData) => stepData.stepId === (verifyStepId !== undefined ? verifyStepId : stepId),
      );
    },
    [testCaseExecutionData, stepId],
  );

  const generateInitialValues = () => {
    const data = activeStepData();
    const initialData: Record<
      string,
      { id: string; description: string; value: string | null; type?: string }
    > = {};
    if (data && data?.type === TestStepType.UPLOAD_IMAGE_ACTION) {
      const imageData = (data as UploadImageStepExecutionDto)?.images;
      imageData.forEach((stepData) => {
        initialData[stepData.id] = { ...stepData, value: null };
      });
    }
    if (data && data?.type === TestStepType.USER_INPUT_ACTION) {
      const inputData = (data as UserInputStepExecutionDto).inputs;
      inputData.forEach((stepData) => {
        initialData[stepData.id] = { ...stepData, value: '' };
      });
    }
    return initialData;
  };

  const isAllPreviousStepsCompleted = () => {
    if (testCaseExecutionData && testCaseExecutionData?.currentStepsWithStatuses) {
      for (let i = 0; i < testCaseExecutionData.currentStepsWithStatuses.length; i++) {
        const stepData = testCaseExecutionData.currentStepsWithStatuses[i];
        if (stepData.stepId === stepId) {
          return true;
        }
        if (stepData?.status !== TestCaseStatus.SUCCESS) {
          return false;
        }
      }
    }
    return false;
  };

  const { values, setFieldValue, resetForm, setFieldError, errors } = useFormik({
    initialValues: generateInitialValues(),
    onSubmit: () => {},
  });

  const handdleMarkAsDoneImages = () => {
    const instanceId = getInstanceId();
    const activeStep = activeStepData();
    const inputPayload: { payload: { [key: string]: string } } = { payload: {} };

    (activeStep as UploadImageStepExecutionDto)?.images.forEach((image) => {
      inputPayload.payload[image.id] = values[image.id].value ?? '';
    });

    if (instanceId) {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(false));
      const payload = {
        testCaseId: selectedTestCase,
        instanceId: instanceId,
        stepId,
        input: inputPayload,
      };
      markActionAsDone.mutate(payload);
    }
  };

  const handdleMarkAsDoneInputs = () => {
    const instanceId = getInstanceId();
    const activeStep = activeStepData();
    const inputPayload: { payload: { [key: string]: string } } = { payload: {} };

    (activeStep as UserInputStepExecutionDto)?.inputs.forEach((input) => {
      inputPayload.payload[input.id] = values[input.id].value ?? '';
    });

    if (instanceId) {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(false));
      const payload = {
        testCaseId: selectedTestCase,
        instanceId: instanceId,
        stepId,
        input: inputPayload,
      };
      markActionAsDone.mutate(payload);
    }
  };

  const handleTriggerAction = () => {
    const instanceId = getInstanceId();
    if (instanceId) {
      dispatch(testFrameworkActionCreator.toggleRefetchEnable(false));
      const payload = {
        testCaseId: selectedTestCase,
        instanceId: instanceId,
        stepId,
      };
      triggerAction.mutate(payload);
    }
  };

  const getRejectMessage = (subStepId: string) => {
    let subStepData =
      testCaseApprovalStatusData?.approvalStatusByStepId[stepId]?.approvalStatusBySubStepId[
        subStepId
      ];
    if (!subStepData) return null;
    if (subStepData.status === ApprovalStatus.REJECTED) {
      return (
        <SnackBar
          id={subStepData.subStepId}
          type={TestCaseStatus.FAILED}
          title={ApprovalStatus.REJECTED}
          description={subStepData.adminComment}
        />
      );
    }
    if (subStepData.status === ApprovalStatus.APPROVED) {
      return (
        <SnackBar
          id={subStepData.subStepId}
          type={TestCaseStatus.SUCCESS}
          description={subStepData.adminComment}
          title={ApprovalStatus.APPROVED}
        />
      );
    }
  };

  const isDisabledImageUpload = () => {
    return (activeStepData() as UploadImageStepExecutionDto)?.images.some(
      (image) => values?.[image.id]?.value === undefined || values?.[image.id]?.value === null,
    );
  };

  const getContent = (nextStep?: TestStepDefinitionSummaryDto) => {
    if (
      nextStep &&
      testCaseExecutionData?.currentStepsWithStatuses.find(
        (stepData) => stepData.stepId === nextStep?.id,
      )
    ) {
      const currentStepData = getCurrentStepData(nextStep?.id);
      if (isAllPreviousStepsCompleted() && (isActiveStep() || currentStepData)) {
        return (
          <div className="verification-content">
            <SnackBar
              id={currentStepData?.stepId ?? ''}
              type={currentStepData?.status}
              description={
                activeStepData(nextStep?.id)?.description ??
                getCurrentStepData(nextStep?.id)?.description ??
                nextStep?.description
              }
              documentationUrl={
                selectedTestCaseData?.steps.find((step) => step.id === nextStep.id)
                  ?.documentationUrl
              }
            />
          </div>
        );
      }
      return null;
    }

    switch (stepData?.type) {
      case TestStepType.BEGIN_TEST_CASE_ACTION:
        return null;
      case TestStepType.EXECUTABLE_ACTION:
        return <div className="executable-action-content">{null}</div>;
      case TestStepType.INSTRUCTION:
        return <div className="instruction-content"></div>;
      case TestStepType.UPLOAD_IMAGE_ACTION:
        return (
          <div className="image-upload-content">
            {isActiveStep() &&
              isAllPreviousStepsCompleted() &&
              (activeStepData() as UploadImageStepExecutionDto)?.images.map(
                ({ id, description }, index) => (
                  <>
                    <div className="sub-description">{`${index + 1}. ${description}`}</div>
                    <div className="dropzone-wrapper">
                      <Dropzone
                        onDrop={(files: FileWithPath[]) => {
                          uploadFile.mutate({
                            organizationId: userData.organizationId,
                            testCaseInstanceId: testCaseExecutionData?.instanceId ?? '',
                            stepId: stepId,
                            imageDefinitionId: id,
                            file: files[0],
                          });
                        }}
                        onReject={(files) => {
                          setFieldError(id, 'Please choose an image under 10MB for upload.');
                        }}
                        maxSize={10 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                        className={errors[id] ? 'error' : ''}
                      >
                        <Group position="center" spacing="xl">
                          <Dropzone.Idle>
                            {values[id]?.value === null || values[id]?.value === undefined ? (
                              <div className="image-upload-base">
                                <img src={imageUpload} alt={'base'} />
                                <span className="description">Upload image</span>
                              </div>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${values[id]?.value}`}
                                className="uploaded-image"
                                alt={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${values[id]?.value}`}
                              />
                            )}
                          </Dropzone.Idle>
                        </Group>
                      </Dropzone>
                      <div className="image-upload-limits">*max size: 10mb</div>
                      {(activeStepData() as UploadImageStepExecutionDto)?.images?.length - 1 ===
                        index && (
                        <div className="button-wrapper">
                          <Button
                            className="upload-button"
                            onClick={handdleMarkAsDoneImages}
                            variant="outline"
                            disabled={isDisabledImageUpload()}
                          >
                            {(activeStepData() as UploadImageStepExecutionDto)?.images?.length > 1
                              ? 'Upload all images'
                              : 'Upload image'}
                          </Button>
                        </div>
                      )}
                    </div>
                    {errors[id] && (
                      <div className="error-content">
                        <IconExclamationCircle size={25} />
                        {errors[id] as string}
                      </div>
                    )}
                  </>
                ),
              )}
            {testCaseExecutionData?.overallStatus !== TestCaseStatus.PENDING &&
              testSuiteInstanceData?.submittedForAdminApproval &&
              testSuiteInstanceData?.testCaseInstances?.find(
                (testCaseInstance) => testCaseInstance.testCaseId === selectedTestCase,
              )?.approvalStatus !== ApprovalStatus.NOT_SUBMITTED_FOR_APPROVAL &&
              stepData.subSteps.map(({ description, id }, index) => (
                <>
                  <div className="sub-description">{`${index + 1}. ${description}`}</div>
                  <div className="dropzone-wrapper">
                    <Dropzone
                      onDrop={(files: FileWithPath[]) => {
                        return;
                      }}
                      onReject={(files) => {
                        return;
                      }}
                      maxSize={10 * 1024 ** 2}
                      accept={IMAGE_MIME_TYPE}
                      className={errors[id] ? 'error' : ''}
                      disabled={true}
                    >
                      <Group position="center" spacing="xl">
                        <Dropzone.Idle>
                          {
                            <img
                              src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${
                                testSubmittedDetails?.testCaseInputByTestCaseId?.[
                                  selectedTestCase as string
                                ]?.testStepInputs?.find((step) => step.stepId === stepId)
                                  ?.inputBySubStepId?.[id]
                              }`}
                              className="uploaded-image"
                              alt={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}${
                                testSubmittedDetails?.testCaseInputByTestCaseId?.[
                                  selectedTestCase as string
                                ]?.testStepInputs?.find((step) => step.stepId === stepId)
                                  ?.inputBySubStepId?.[id]
                              }`}
                            />
                          }
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                    <div className="image-upload-limits">*max size: 10mb</div>
                  </div>
                  <div className="rejected-message">{getRejectMessage(id)}</div>
                </>
              ))}
          </div>
        );
      case TestStepType.USER_INPUT_ACTION:
        return (
          <div className="user-input-action-content">
            {isActiveStep() &&
              isPendingStep() &&
              isAllPreviousStepsCompleted() &&
              (activeStepData() as UserInputStepExecutionDto).inputs.map(
                ({ id, description }, index) => (
                  <>
                    <div className="sub-description">{`${index + 1}. ${description}`}</div>
                    <div className="text-input-container">
                      <TextInput
                        size="sm"
                        placeholder={description}
                        name={id}
                        value={values[id]?.value ?? ''}
                        onChange={(e) => {
                          setFieldValue(`${id}.value`, e.target.value);
                        }}
                      />
                      {(activeStepData() as UserInputStepExecutionDto)?.inputs?.length === 1 && (
                        <Button
                          className="submit-button"
                          onClick={handdleMarkAsDoneInputs}
                          variant="outline"
                        >
                          {(activeStepData() as UserInputStepExecutionDto)?.inputs?.length > 1
                            ? 'Verify all'
                            : 'Verify'}
                        </Button>
                      )}
                    </div>
                    {(activeStepData() as UserInputStepExecutionDto)?.inputs?.length - 1 ===
                      index &&
                      (activeStepData() as UserInputStepExecutionDto)?.inputs?.length > 1 && (
                        <div className="button-wrapper">
                          <Button
                            className="submit-button"
                            onClick={handdleMarkAsDoneInputs}
                            variant="outline"
                          >
                            {(activeStepData() as UserInputStepExecutionDto)?.inputs?.length > 1
                              ? 'Verify all'
                              : 'Verify'}
                          </Button>
                        </div>
                      )}
                  </>
                ),
              )}
            {testCaseExecutionData?.overallStatus !== TestCaseStatus.PENDING &&
              testSuiteInstanceData?.submittedForAdminApproval &&
              testSuiteInstanceData?.testCaseInstances?.find(
                (testCaseInstance) => testCaseInstance.testCaseId === selectedTestCase,
              )?.approvalStatus !== ApprovalStatus.NOT_SUBMITTED_FOR_APPROVAL &&
              stepData.subSteps.map(({ description, id }, index) => (
                <>
                  <div className="sub-description">{`${index + 1}. ${description}`}</div>
                  <div className="text-input-container">
                    <TextInput
                      size="sm"
                      placeholder="Enter text"
                      name={id}
                      value={
                        testCaseApprovalStatusData?.approvalStatusByStepId[stepId]
                          ?.approvalStatusBySubStepId[id].adminComment ?? ''
                      }
                      onChange={(e) => {
                        setFieldValue(`${id}.value`, e.target.value);
                      }}
                      disabled={true}
                    />
                  </div>
                  <div className="rejected-message">{getRejectMessage(id)}</div>
                </>
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  const getStepNumber = () => {
    const index = selectedTestCaseData?.steps
      .filter(({ type }) => type !== TestStepType.VERIFICATION)
      .findIndex(({ id }) => id === stepId);

    if (getCurrentStepData()?.status === TestCaseStatus.SUCCESS) {
      return <IconCheck />;
    }

    if (index && selectedTestCaseData?.steps[0].type === TestStepType.BEGIN_TEST_CASE_ACTION) {
      return `${index}`;
    }
    return `${index ?? 0 + 1}`;
  };

  const getStatus = useCallback(() => {
    const currentStepData = getCurrentStepData();
    if (!currentStepData || !isAllPreviousStepsCompleted()) return null;

    switch (currentStepData.status) {
      case TestCaseStatus.FAILED:
        return (
          // <div className="title-status">
          //   <IconSquareRoundedX color="red" />
          // </div>
          null
        );

      case TestCaseStatus.PENDING:
        return (
          // <div className="title-status">
          //   <Loader size="sm" variant="oval" color="blue" />
          // </div>
          null
        );
      case TestCaseStatus.SUCCESS:
        return (
          // <div className="title-status">
          //   <IconCheck color="green" />
          // </div>
          null
        );
      case TestCaseStatus.TIMED_OUT:
        return (
          <div className="title-status">
            <IconAlarmOff color="orange" />
          </div>
        );
      default:
        return null;
    }
    // eslint-disable-next-line
  }, [testCaseExecutionData, stepId]);

  const getValidationError = useCallback(() => {
    const currentStepIndex = testCaseExecutionData?.currentStepsWithStatuses.findIndex(
      (stepData) => stepData.stepId === stepId,
    );

    if (
      testCaseExecutionData?.currentStepsWithStatuses &&
      currentStepIndex !== testCaseExecutionData?.currentStepsWithStatuses.length - 1
    )
      return null;

    return (
      <Text size="sm" color="red">
        {testCaseExecutionData?.validationErrors &&
        testCaseExecutionData?.validationErrors?.length > 0
          ? testCaseExecutionData?.validationErrors[0]?.message
          : ''}
      </Text>
    );
    // eslint-disable-next-line
  }, [[testCaseExecutionData, stepId]]);

  const isNextStepVerification = (currentStepId?: string) => {
    const currentStepIndex = selectedTestCaseData?.steps.findIndex(
      ({ id }) => id === (currentStepId !== undefined ? currentStepId : stepId),
    );
    if (!currentStepIndex) return false;
    const nextStep = selectedTestCaseData?.steps[currentStepIndex + 1];
    if (!nextStep) return false;
    if (nextStep.type === TestStepType.VERIFICATION) return true;
  };

  const getNextStepVerificationData = (currentStepId?: string) => {
    const currentStepIndex = selectedTestCaseData?.steps.findIndex(
      ({ id }) => id === (currentStepId !== undefined ? currentStepId : stepId),
    );
    if (!currentStepIndex) return undefined;
    const nextStep = selectedTestCaseData?.steps[currentStepIndex + 1];
    if (!nextStep) return undefined;
    if (nextStep.type === TestStepType.VERIFICATION) return nextStep;
    return undefined;
  };

  const isLastStepWithoutVerification = (): boolean => {
    const filteredData = selectedTestCaseData?.steps.filter(
      (step) => step.type !== TestStepType.VERIFICATION,
    );

    if (!filteredData) return false;

    const stepIndex = filteredData?.findIndex(({ id }) => id === stepId);

    if (!stepIndex || stepIndex < 0) return false;

    if (filteredData?.length === stepIndex + 1) {
      return true;
    }
    return false;
  };

  const indexOfScenario = testSuiteDefinitions
    ?.find((suite) => suite?.id === selectedTestSuite)
    ?.flows?.find((flow) => flow?.id === selectedFlow)
    ?.subFlows?.find((subflow) => subflow?.id === selectedSubFlow)
    ?.testCases.filter((testCase) => testCase.enabled)
    ?.findIndex((testCase) => testCase?.id === selectedTestCase);

  const nextStepId = () => {
    if (indexOfScenario !== undefined && indexOfScenario >= 0)
      return testSuiteDefinitions
        ?.find((suite) => suite?.id === selectedTestSuite)
        ?.flows?.find((flow) => flow?.id === selectedFlow)
        ?.subFlows?.find((subflow) => subflow?.id === selectedSubFlow)
        ?.testCases.filter((testCase) => testCase.enabled)?.[indexOfScenario + 1]?.id;
  };

  const verificationStepContent = (currentStepId?: string): ReactNode => {
    return (
      isNextStepVerification(currentStepId) &&
      testCaseExecutionData?.currentStepsWithStatuses.find(
        (stepData) => stepData.stepId === getNextStepVerificationData(currentStepId)?.id,
      ) && (
        <>
          {verificationStepContent(getNextStepVerificationData(currentStepId)?.id)}
          <div className={`content verification`}>
            {getContent(getNextStepVerificationData(currentStepId))}
          </div>
        </>
      )
    );
  };

  const isNextButtonDisabled = () => {
    return !(
      testCaseExecutionData?.overallStatus === TestCaseStatus.SUCCESS ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.TO_BE_REVIEWED ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.SKIPPED
    );
  };

  const isSkipButtonDisabled = useCallback(() => {
    return (
      getInstanceId() === null ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.TO_BE_REVIEWED ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.SKIPPED ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.SUCCESS
    );
  }, [getInstanceId, testCaseExecutionData]);

  const handleSkipSubmit = (reason: string) => {
    dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
    skipTestCase.mutate({ instanceId: getInstanceId() ?? '', reason: reason });
  };

  return (
    <Box
      sx={stepStyles(
        theme,
        stepData?.type === TestStepType.BEGIN_TEST_CASE_ACTION,
        stepData?.type === TestStepType.VERIFICATION,
        isLastStepWithoutVerification(),
      )}
      id={stepId}
    >
      <SkipConfirmationModal
        id={'test'}
        opened={skipConfirmationModalOpened}
        close={closeSkipConfirmationModal}
        handleSubmit={handleSkipSubmit}
      />
      <LoadingOverlay
        visible={
          markActionAsDone.isLoading ||
          triggerAction.isLoading ||
          isInitialLoading ||
          skipTestCase.isLoading ||
          getLatestTestSuiteInstanceData.isLoading
        }
      />
      {(stepData?.type === TestStepType.EXECUTABLE_ACTION ||
        stepData?.type === TestStepType.INSTRUCTION ||
        stepData?.type === TestStepType.UPLOAD_IMAGE_ACTION ||
        stepData?.type === TestStepType.USER_INPUT_ACTION) && (
        <div className="step-title">
          <div className="number-description">
            <div
              className={`number ${
                getCurrentStepData()?.status === TestCaseStatus.SUCCESS ? 'success' : ''
              }`}
            >
              {getStepNumber()}
            </div>
            <div className="description">
              {parse(
                activeStepData()?.description ??
                  getCurrentStepData()?.description ??
                  stepData?.description,
              )}
            </div>
            {getStatus()}
            <div className="execute-button">
              {isActiveStep() && stepData?.type === TestStepType.EXECUTABLE_ACTION && (
                <Button size="md" variant="outline" onClick={handleTriggerAction}>
                  {(activeStepData() as ExecutableActionStepExecutionDto)?.buttonDisplayName ??
                    'Verify'}
                </Button>
              )}
            </div>
            <div className="validation-errors">{getValidationError()}</div>
          </div>
        </div>
      )}
      {stepData?.type !== TestStepType.BEGIN_TEST_CASE_ACTION && (
        <>
          {verificationStepContent()}
          <div className={`content`}>{getContent()}</div>
        </>
      )}
      {isLastStepWithoutVerification() && (
        <div className="next-step-container">
          <Button
            className="skip-button"
            variant="white"
            onClick={openSkipConfirmationModal}
            disabled={isSkipButtonDisabled()}
          >
            Skip
          </Button>
          <Button
            disabled={isNextButtonDisabled()}
            onClick={() => {
              const nextStep = nextStepId();
              dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
              if (nextStep) {
                dispatch(testFrameworkActionCreator.changeselectedTestCase(nextStep));
                dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              const instanceId = getInstanceId();
              dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
              if (instanceId) cancelTestCase.mutate(instanceId);
            }}
            disabled={testCaseExecutionData?.overallStatus !== TestCaseStatus.PENDING}
          >
            Cancel
          </Button>
        </div>
      )}
    </Box>
  );
};

export default Step;
