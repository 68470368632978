import { PosCapabilityIdType } from '../models';

export const OrderNotificationEventsList = [
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_ACCEPTED,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_COMPLETED,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_DISPATCHED,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_PREPARED,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_REJECTED,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_STARTED,
];

export const needToTickCapabilities = [];

export const preventTogglingAsChildCapabilities = [
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT,
  PosCapabilityIdType.POS_ORDERS_NOTIFICATION_EVENT_CANCELLED,
];
