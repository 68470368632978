import { apiKeyAxios } from '../config/axios.config';
import { ApiKey, ApiKeyGenerationRequest, ApiSuiteDefinition } from '../models/apiKey';

const apikeyService = {
  getApiKeysbyIntegrationAndOrganizationId: async (
    integrationId: string,
    organizationId: string,
    projectId: string,
  ): Promise<ApiKey[]> => {
    const { data } = await apiKeyAxios.get(
      `/keys/${integrationId}/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
  generateApiKey: async (payload: ApiKeyGenerationRequest): Promise<ApiKey> => {
    const { data } = await apiKeyAxios.post(`/keys/projects/${payload.projectId}`, payload);
    return data;
  },
  getApiSuites: async (integrationId: string, projectId: string): Promise<ApiSuiteDefinition[]> => {
    const { data } = await apiKeyAxios.get(`/suites/${integrationId}/projects/${projectId}`);
    return data;
  },
  checkIsProductionEnabledByIntegration: async (
    integrationId: string,
    organizationId: string,
    projectId: string,
  ): Promise<boolean> => {
    const { data } = await apiKeyAxios.get(
      `/is-production-enabled-by-integration/${integrationId}/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
  checkIsProductionEnabled: async (organizationId: string, projectId: string): Promise<Boolean> => {
    const { data } = await apiKeyAxios.get(
      `/is-production-enabled/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
};

export default apikeyService;
