import { IntegrationId } from '../../models';
import { AppStoreDetails, IntegrationWithGoLiveStatus } from '../../models/appStoreDetails';
import { actionCreator } from '../common';
import { AppStoreDetailsActionTypes } from '../types/appStoreDetails';

export const appStoreDetailsActionCreator = {
  changeGetReadyToGoLiveIntegrations: actionCreator<IntegrationId[]>(
    AppStoreDetailsActionTypes.CHANGE_READY_TO_GO_LIVE_INTEGRATIONS,
  ),
  changeAppStoreDetails: actionCreator<AppStoreDetails>(
    AppStoreDetailsActionTypes.CHANGE_APP_STORE_DETAILS,
  ),
  changeGetIntegrationsWithGoLiveStatuses: actionCreator<IntegrationWithGoLiveStatus[]>(
    AppStoreDetailsActionTypes.CHANGE_INTEGRATIONS_WITH_GO_LIVE_STATUSES,
  ),
};
