import { MantineTheme, Sx } from '@mantine/core';

export const capabilitiesStyles = (theme: MantineTheme): Sx => {
  return {
    '.capabilities-paper': {
      marginTop: theme.spacing.md,
      padding: theme.spacing.xl,

      '.radio-group': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '2.5rem',
        gap: '1rem',
        marginTop: '1rem',

        '.radio-button': {
          input: {
            '&:checked:not(:disabled)': {
              backgroundColor: theme.colors.grubtech[0],
              borderColor: theme.colors.grubtech[0],
            },
          },
        },
      },

      '.capabilities-row': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing.sm,
        marginTop: theme.spacing.md,
      },

      '.capabilities-row-intended': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing.sm,
        marginLeft: 50,
        marginTop: theme.spacing.sm,
      },
    },
    '.capabilities-action-buttons': {
      display: 'flex',
      justifyContent: 'flex-end',

      '.save-capabilities-button': {
        width: 200,
        marginTop: theme.spacing.md,
        marginLeft: 10,
      },
      '.cancel-capabilities-button': {
        width: 200,
        marginTop: theme.spacing.md,
      },
    },
  };
};
