import { useEffect, useState } from 'react';
import { string as validateString, object as validateObject } from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import { notifications } from '@mantine/notifications';
import {
  Box,
  Button,
  LoadingOverlay,
  PasswordInput,
  TextInput,
  useMantineTheme,
  Text,
} from '@mantine/core';

import LoginPagesLayout from '../../../components/LoginPagesLayout';
import WelcomeHeader from '../../../components/WelcomeHeader';
import NavigationText from '../../../components/NavigationText';

import { authenticationActionCreator } from '../../../redux/actions';
import userOnboardingService from '../../../service/userOnboarding.service';
import { OnBoardingProgressState, UserOnBoardingProgress } from '../../../models';

import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';

const SignupDetails = () => {
  const theme = useMantineTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onboardingProgress, setOnboardingProgress] = useState<UserOnBoardingProgress | null>(null);
  const [signUpError, setSignUpError] = useState('');

  const { values, errors, setFieldValue, isValid, resetForm, handleBlur, touched } = useFormik({
    initialValues: {
      name: '',
      password: '',
    },
    validationSchema: validateObject().shape({
      name: validateString().trim().required('Name is required!'),
      password: validateString()
        .trim()
        .required('Password is required!')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/,
          'Password must be at least 8 characters, contain at least one lowercase character, one uppercase character, and one number or symbol',
        ),
    }),
    onSubmit: () => {},
    validateOnMount: true,
  });

  useEffect(() => {
    if (!location.state || !location.state.progress) {
      navigate('/login');
    } else {
      setOnboardingProgress(location.state.progress);
    }
    // eslint-disable-next-line
  }, [location]);

  const submitDetails = useMutation({
    mutationFn: (payload: UserOnBoardingProgress) => {
      return userOnboardingService.submitUserDataandPsw(payload);
    },
    onSuccess: (data) => {
      if (data.onBoardingProgressState === OnBoardingProgressState.USER_REGISTRATION_COMPLETED) {
        notifications.show({
          title: 'Registration successfull!',
          message: 'Please sign in using your credentials.',
          color: 'green',
        });
        Auth.signOut()
          .then((data) => {
            redirectToLoginPage();
          })
          .catch((e) => {
            console.error(e);
            redirectToLoginPage();
          });
      } else {
        setSignUpError('Something went wrong');
        // notifications.show({
        //   title: 'Something went wrong',
        //   message: 'Something went wrong',
        //   color: 'red',
        // });
      }
    },
    onError: (error: any) => {
      setSignUpError(error.name ?? 'Something went wrong');
      // notifications.show({
      //   title: error.name ?? 'Something went wrong',
      //   message: error.message ?? 'Something went wrong',
      //   color: 'red',
      // });
    },
  });

  const redirectToLoginPage = () => {
    dispatch(
      authenticationActionCreator.changeAuthData({
        isAuthenticated: false,
        userData: null,
      }),
    );
    navigate(`/login`);
    resetForm();
  };

  return (
    <LoginPagesLayout sx={signSectionStyles(theme)} id={'signup-details-page'}>
      <LoadingOverlay visible={submitDetails.isLoading} />
      <Box className="signPage__container">
        <Box className="signPage__content">
          <WelcomeHeader upperBlackMessage="Start integrating with" lowerBlueMessage="Grubtech" />
          <Box className="signPage__emailSection">
            {signUpError ? (
              <Text color={theme.colors.red[6]} fz={theme.fontSizes.sm}>
                {signUpError}
              </Text>
            ) : null}
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Name"
                size="md"
                required
                value={values.name}
                error={touched.name && errors.name}
                name="name"
                onChange={(e) => {
                  setSignUpError('');
                  setFieldValue('name', e.target.value);
                  if (onboardingProgress) {
                    setOnboardingProgress({ ...onboardingProgress, name: e.target.value });
                  }
                }}
                onBlur={handleBlur}
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <Box className="signPage__input">
              <PasswordInput
                variant="unstyled"
                placeholder="Password"
                size="md"
                required
                value={values.password}
                error={touched.password && errors.password}
                name="password"
                onChange={(e) => {
                  setSignUpError('');
                  setFieldValue('password', e.target.value);
                  if (onboardingProgress) {
                    setOnboardingProgress({ ...onboardingProgress, password: e.target.value });
                  }
                }}
                onBlur={handleBlur}
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <div className="navigation-text-container">
              <NavigationText to="/forgot-password" navigationText="Forgot Password?" align="end" />
            </div>
            <Button
              size="lg"
              disabled={!isValid}
              onClick={() => onboardingProgress && submitDetails.mutate(onboardingProgress)}
            >
              Create a developer account
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default SignupDetails;
