import { MantineTheme, Sx } from '@mantine/core';

export const loginPagesLayoutStyles = (sx = {}, theme: MantineTheme): Sx => {
  return {
    ...sx,
    width: '100%',
    height: '-webkit-fill-available',
    padding: '52px 72px',
    backgroundColor: 'white',
    '.mantine-Grid-root': {
      height: '-webkit-fill-available',
    },
    overflow: 'hidden',
    '.logo': {
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.contents': {
      padding: theme.spacing.xl,
      paddingRight: '90px',
      '.mantine-UnstyledButton-root.mantine-Button-root': {
        borderRadius: theme.spacing.md,
      },
    },
    '.login-backgroundImage': {
      height: '100%',
      backgroundPosition: '-35px 0px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    '.signPage__logo': {
      width: '34px',
      height: '34px',
    },
  };
};

export const signTextBoxStyles = (theme: MantineTheme): Sx => {
  return {
    minHeight: '64px',
    input: {
      paddingLeft: 0,
      '&:-webkit-autofill,&:-webkit-autofill:focus': {
        transition: 'background-color 0s 600000s, color 0s 600000s',
      },
    },
    '.mantine-PasswordInput-input,.mantine-TextInput-input': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.gray[4],
      ':focus-within': {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.gray[4],
      },
    },
  };
};

export const signSectionStyles = (theme: MantineTheme): Sx => {
  return {
    '.navigation-text-container': {
      marginBottom: '15px',
      // marginTop: theme.spacing.sm,
    },
    '.signPage__container': {
      height: '100%',
      width: '100%',
      margin: '0 auto',
    },

    '.signPage__content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '.mantine-UnstyledButton-root.mantine-Button-root': {
        borderRadius: theme.spacing.md,
      },
    },

    '.signPage__titleSection': {
      margin: `${theme.spacing.xl} 0`,
      '.signPage__welcomeMessage': {
        fontSize: '40px',
      },
      '.signPage__developerPortalMessage': {
        fontSize: '52px',
        color: theme.colors.grubtech[0],
      },
    },

    '.signPage__emailSection': {
      width: '95%',
      marginTop: '3rem',
      display: 'flex',
      flexDirection: 'column',

      '.signPage__input': {
        marginBottom: '4px',
      },

      '.signPage__forgotPassText': {
        marginTop: theme.spacing.sm,
        marginBottom: theme.spacing.xl,
        cursor: 'pointer',
      },
      '.signPage__verifyButton': {
        marginTop: theme.spacing.sm,
      },

      '.signPage__orText': {
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl,
      },

      '.signPage__socialButtons': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.sm,
        '.social-placeholder': {
          height: '50px',
          backgroundColor: 'red',
        },
      },

      '.signPage__signUpText': {
        display: 'flex',
        gap: theme.spacing.xs,
        justifyContent: 'center',
        cursor: 'pointer',
      },
    },
  };
};
