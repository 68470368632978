import { adminPortalAxios } from '../config/axios.config';

import { MaintenanceStatus, MaintenanceStatusResponse } from '../models/maintenanceStatus';

const maintenanceStatusService = {
  fetchLatestMainteanceStatus: async (): Promise<MaintenanceStatus[]> => {
    const dateNow = new Date().toISOString();
    const { data }: { data: MaintenanceStatusResponse } = await adminPortalAxios.get(
      `/maintenance-info?time=${dateNow}`,
    );
    return data.maintenanceInfos;
  },
};

export default maintenanceStatusService;
