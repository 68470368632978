import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ProjectContextProps {
  activeProject: { name: string; id: string } | null;
  setActiveProject: React.Dispatch<
    React.SetStateAction<{ name: string; id: string; enableParameterDefinitions: boolean } | null>
  >;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeProject, setActiveProject] = useState<{
    name: string;
    id: string;
    enableParameterDefinitions: boolean;
  } | null>(null);

  return (
    <ProjectContext.Provider value={{ activeProject, setActiveProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};
