import { MantineTheme, Sx } from '@mantine/core';

export const testScenariosStyles = (theme: MantineTheme): Sx => {
  return {
    '.test-scenarios-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      gap: theme.spacing.xl,

      '.navigation': {
        display: 'flex',
        flexDirection: 'column',
        width: '375px',
      },
    },
    '.test-scenarios-content': {
      width: 'calc(100% - 375px)',
    },

    //new ui styles
    '.test-flow': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '.flow-button': {
        paddingLeft: 0,
        paddingRight: 0,
        width: '100%',
        background: theme.colors.white[0],
        color: theme.colors.font[0],
        justifyContent: 'flex-start',
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',

        '.mantine-Button-inner': {
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',

          '.mantine-Button-rightIcon': {
            marginLeft: 'auto',
          },
        },

        '.mantine-Button-label': {
          width: '100%',
        },

        '.count-section': {
          color: theme.colors.grubtech[0],
          marginLeft: 'auto',
          marginRight: '5px',
        },
      },

      '.subflow-wrapper': {
        transition: 'all 1s ease',

        '.sub-flow': {
          width: '100%',
          marginLeft: '0.5rem',
          paddingLeft: '0.5rem',
          borderLeft: '1px solid black',

          '.subflow-button': {
            width: '100%',
            justifyContent: 'flex-start',
            display: 'flex',
            color: theme.colors.font[0],
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            background: 'transparent',
            '&.active': {
              background: theme.colors.grubtech[0],
              color: theme.colors.white[0],
              marginBottom: '0.5rem',
            },
          },
        },
      },
    },
    '.test-case-content': {
      width: '100%',
    },
  };
};
