import { Box, Button, Text, TextInput, Title, useMantineTheme } from '@mantine/core';
import { useFormik } from 'formik';
import { object as validateObject, string as validateString } from 'yup';
import BackText from '../../../components/BackText';
import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';
import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import { Auth } from 'aws-amplify';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';

const ForgotPassword = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { values, isValid, touched, errors, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validateObject().shape({
      email: validateString().trim().required('email required').email('Need to be valid email'),
    }),
    onSubmit: (e) => {
      console.log('lol');
    },
    validateOnMount: true,
  });

  const handleSubmit = () => {
    Auth.forgotPassword(values.email)
      .then((e) => {
        dispatch(
          authenticationActionCreator.changeAuthData({
            isAuthenticated: false,
            userData: {
              ...userData,
              email: values.email,
            },
          }),
        );
        navigate('/reset-password');
      })
      .catch((error) => {
        notifications.show({
          title: error.name ?? 'Something went wrong',
          message: error.message ?? 'Something went wrong',
          autoClose: 2000,
          color: 'red',
        });
      });
  };

  return (
    <LoginPagesLayout sx={{ ...signSectionStyles(theme) }} id={'forgotPassword-page'}>
      <Box className="signPage__container">
        <Box className="signPage__content">
          <BackText to="/login" />
          <Title size={52} color={theme.colors.dark[9]}>
            Reset Password
          </Title>
          <Text size={20} color={theme.colors.gray[6]}>
            To reset your password, we need to send you a code to your email
          </Text>

          <Box className="signPage__emailSection">
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Email"
                size="md"
                value={values.email}
                name="email"
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                }}
                onBlur={handleBlur}
                error={touched['email'] && errors['email']}
                data-cy="forgot-password-email-field"
                sx={signTextBoxStyles(theme)}
              />
            </Box>
            <Button
              onClick={handleSubmit}
              size="lg"
              disabled={!isValid}
              data-cy="forgot-password-submit-button"
            >
              Send me the link
            </Button>
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default ForgotPassword;
