import { MantineTheme, Sx } from '@mantine/core';

export const globalSettingsStyles = (theme: MantineTheme): Sx => {
  return {
    '.title-container': {
      display: 'flex',
      width: '100%',
      paddingLeft: '8px',
      marginBottom: '13px',
      marginTop: '13px',
    },
    '.field-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
      fontSize: '12px',
    },
    '.type-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
      fontSize: '12px',
    },
    '.value-container': {
      flexBasis: '20%',
      color: '#5C5E6A',
      fontSize: '12px',
    },
    '.global-settings-title': {
      fontWeight: 'bold',
      fontSize: '18px',
      paddingLeft: '8px',
    },
    '.global-settings-container': {
      width: '100%',
    },
    '.global-settings-content-container': {
      display: 'flex',
      alignItems: 'flex-start',
      border: '1px solid #ccc',
      padding: '16px',
      borderBottom: 'none',
    },
    '.field-input': {
      paddingRight: '5px',
    },
    '.type-dropdown': {
      paddingRight: '5px',
    },
    '.trash-icon': {
      marginLeft: 'auto',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    '.add-setting-text': {
      marginLeft: 'auto',
      color: '#0727fa',
      cursor: 'pointer',
      textDecoration: 'underline',
      alignSelf: 'center',
    },
    '.global-settings-add-element-container': {
      display: 'flex',
      alignItems: 'flex-start',
      border: '1px solid #ccc',
      padding: '16px',
    },
  };
};
