import { Auth } from 'aws-amplify';

export const getJwtIdToken = async (): Promise<string> => {
  try {
    const authResponse = await Auth?.currentSession();
    return authResponse?.getIdToken()?.getJwtToken();
  } catch (e) {
    return '';
  }
};
