import {
  Box,
  Text,
  Flex,
  Table,
  useMantineTheme,
  LoadingOverlay,
  Grid,
  Select,
  Button,
  TextInput,
  Divider,
  Pagination,
} from '@mantine/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { productionDeploymentStyle } from './style';
import {
  ComponentDetails,
  DeploymentRequestStatus,
  Environment,
  EnvironmentResponse,
  PagebleProductionDeploymentStatus,
  ProdDeploymentRequest,
  ProductionDeploymentStatus,
} from '../../../../models/deployment';
import { IconClipboard } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '../../../../redux/reducers';
import { RootState } from '../../../../redux/common';
import deploymentService from '../../../../service/deployment.service';
import { notifications } from '@mantine/notifications';
import CustomDialogBox from '../../../../components/CustomDialogBox/CustomDialogBox';
import { deploymentDialogBoxContentStyle } from '../DeploymentTable/style';
import { formatDateTime, getStatusString } from '../../../../utils/string';
import { RequestStatus } from '../../../../models';
import InfiniteScroll from 'react-infinite-scroll-component';
import usePagination from '../../../../hooks/usePagination';
export interface ProductionDeploymentProps {
  id: string;
  env: Environment | null;
  prodEnv: Environment | null;
  isProdDeploymentDialogOpen: boolean;
  onCloseProdDeploymentDialog: () => void;
  repositoryUrl: string;
  componentId: string;
  isProdComponentCreated: boolean;
}
const ProductionDeployment: FC<ProductionDeploymentProps> = ({
  id,
  env,
  prodEnv,
  isProdDeploymentDialogOpen,
  onCloseProdDeploymentDialog,
  repositoryUrl,
  componentId,
  isProdComponentCreated,
}) => {
  const theme = useMantineTheme();

  const { projectId } = useParams();
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const [listOfComponent, setListOfComponent] = useState<ComponentDetails[]>([]);
  const [listOfProdComponent, setListOfProdComponent] = useState<ComponentDetails[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<ComponentDetails | null>(null);
  const [form, setForm] = useState<{ [key: string]: any } | null>(null);

  const [listOfData, page, pageSize, hasMore, totalPages, setTotalPages, updatePage, updateList] =
    usePagination([]);

  useEffect(() => {
    if (isProdDeploymentDialogOpen) {
      if (listOfComponent.length === 1) {
        setSelectedComponent(listOfComponent[0]);
      }
    }
  }, [isProdDeploymentDialogOpen]);

  const onClickClose = () => {
    onCloseProdDeploymentDialog();
    setSelectedComponent(null);
    setForm(null);
  };

  const { isFetching: isLoadingDeploymentData, refetch: refetchDeploymentData } = useQuery({
    queryKey: [
      `load-deployment-data-for-${env?.environmentName}`,
      userData.organizationId,
      projectId,
    ],
    queryFn: () =>
      deploymentService.getDeploymentDetails(env?.environmentId ?? '', componentId ?? ''),
    enabled: env !== null && componentId !== '',
    onSuccess: (data: EnvironmentResponse) => {
      if (data) {
        setListOfComponent(data.componentDetails);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  const { isFetching: isLoadingDeploymentDataForProd, refetch: refetchDeploymentDataForProd } =
    useQuery({
      queryKey: [
        `load-deployment-data-for-${prodEnv?.environmentName}`,
        userData.organizationId,
        projectId,
      ],
      queryFn: () =>
        deploymentService.getDeploymentDetails(prodEnv?.environmentId ?? '', componentId ?? ''),
      enabled: prodEnv !== null && componentId !== '' && isProdComponentCreated,
      onSuccess: (data: EnvironmentResponse) => {
        if (data) {
          setListOfProdComponent(data.componentDetails);
        }
      },
      onError: (error: any) => {
        notifications.show({
          title: error.name ?? 'Something went wrong',
          message: error.message ?? 'Something went wrong',
          autoClose: 2000,
          color: 'red',
        });
      },
    });
  const { isFetching: isProdDeploymentStatusLoading, refetch } = useQuery({
    queryKey: [
      `load-deployment-status-data-for-${env?.environmentName}`,
      userData.organizationId,
      pageSize,
      page,
      projectId,
    ],
    queryFn: () => deploymentService.getProductionDeploymentStatus(repositoryUrl, page, pageSize),
    enabled: env !== null && repositoryUrl !== '',
    onSuccess: (data: PagebleProductionDeploymentStatus) => {
      if (data && data.content && data.content.length > 0) {
        const sortedData = data.content.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
        setTotalPages(data.totalPages);
        updateList(sortedData);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: branches = [], isFetching: isLoadingBranch } = useQuery({
    queryKey: [`load-branch-data-for`, selectedComponent?.repositoryUrl],
    queryFn: () => deploymentService.getBranches(selectedComponent?.repositoryUrl ?? ''),
    enabled: selectedComponent?.repositoryUrl != null && selectedComponent?.repositoryUrl !== '',
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  const { data: releaseVersion = [], isFetching: isLoadingReleaseVersion } = useQuery({
    queryKey: [`load-release-version`, selectedComponent?.componentId, form?.['branchId']],
    queryFn: () =>
      deploymentService.getReleaseVersion(selectedComponent?.componentId ?? '', form?.['branchId']),
    enabled: selectedComponent?.componentId !== null && form?.['branchId'] !== undefined,
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const deployRequest = useMutation({
    mutationFn: (payload: ProdDeploymentRequest) => {
      return deploymentService.requestProductionDeployment(payload);
    },
    onSuccess: () => {
      refetch();
      refetchDeploymentData();
      if (prodEnv !== null && componentId !== '' && isProdComponentCreated) {
        refetchDeploymentDataForProd();
      }
      onClickClose();
      notifications.show({
        title: `Success`,
        message: (
          <Text>
            Successfully requested a deployment for <strong>{env?.environmentName}</strong>
          </Text>
        ),
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const deployComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)}>
      <Box p={8} className="dialog_continer_box">
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Component</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Component"
              data={
                listOfComponent?.map((b) => {
                  return { value: b.componentId, label: b.componentName };
                }) ?? []
              }
              value={selectedComponent != null ? selectedComponent.componentId : ''}
              onChange={(option) => {
                setSelectedComponent(listOfComponent.find((c) => c.componentId === option) ?? null);
                setForm({ ...form, branchName: option ?? '' });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Branch</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Branch"
              data={
                branches?.map((b) => {
                  return { value: b.branchName, label: b.branchName };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, branchId: option ?? '' });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Release Version</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Release Version"
              data={
                releaseVersion?.map((b) => {
                  return { value: b.releaseVersion, label: b.releaseVersion };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, deployedVersion: option });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Commit Link</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              className="input-field"
              placeholder="Commit Link"
              value={form?.['commitLink'] ?? ''}
              onChange={(event) => {
                setForm({ ...form, commitLink: event.currentTarget.value });
              }}
            />
          </Grid.Col>
        </Grid>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() =>
              deployRequest.mutate({
                componentId: selectedComponent?.componentId ?? '',
                branch: form?.['branchId'] ?? '',
                commitLink: form?.['commitLink'] ?? '',
                projectId: projectId ?? '',
                organizationId: userData.organizationId ?? '',
                environmentId: prodEnv?.environmentId ?? '',
                repositoryName: repositoryUrl,
                requestedVersion: form?.['deployedVersion'] ?? '',
                componentName: selectedComponent?.componentName ?? '',
                createdAt: new Date().toISOString(),
                statuses: [
                  {
                    status: RequestStatus.PENDING,
                    time: new Date().toISOString(),
                  },
                ],
              })
            }
            variant="filled"
            disabled={!(form?.['branchId'] && form?.['deployedVersion'] && form?.['commitLink'])}
          >
            Request
          </Button>
        </Flex>
      </Box>
    </Box>
  );

  const getStatus = (status: DeploymentRequestStatus[]) => {
    if (status.length > 0) {
      status = status.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
    }
    return status[0].status;
  };
  const getStatusTime = (statuses: DeploymentRequestStatus[], status: RequestStatus) => {
    return statuses.find((s) => s.status === status)?.time ?? '';
  };
  const rows = useCallback(() => {
    return (listOfData as ProductionDeploymentStatus[])?.map((component, index) => (
      <tr key={index}>
        <td>{component.componentName}</td>
        <td>{component.requestedVersion}</td>
        <td>{formatDateTime(getStatusTime(component.statuses, RequestStatus.PENDING))}</td>
        <td>
          {formatDateTime(getStatusTime(component.statuses, RequestStatus.APPROVED)) || (
            <Text fw={600}>-</Text>
          )}
        </td>
        <td>{getStatusString(getStatus(component.statuses))}</td>
      </tr>
    ));
    // eslint-disable-next-line
  }, [listOfData]);
  const deploymentRows = useCallback(() => {
    return (listOfProdComponent as ComponentDetails[])?.map((component) => (
      <tr key={component.componentId}>
        <td>{component.componentName}</td>
        <td>{component.deployedVersion}</td>
        <td>{formatDateTime(component.deployedTime)}</td>
      </tr>
    ));
    // eslint-disable-next-line
  }, [listOfProdComponent]);
  return (
    <Box sx={productionDeploymentStyle(theme)} id={id} p={0}>
      <LoadingOverlay
        visible={
          isProdDeploymentStatusLoading ||
          isLoadingBranch ||
          isLoadingReleaseVersion ||
          isLoadingDeploymentData ||
          deployRequest.isLoading ||
          isLoadingDeploymentDataForProd
        }
      />
      <Box className="deployment-table-header">
        <Text size="md" weight={700} pt={15} pl={15}>
          Current Deployment Details
        </Text>
        <Table className="deployment-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
          <thead>
            <tr>
              <th>Component</th>
              <th>Deployed Version</th>
              <th>Deployed Time</th>
            </tr>
          </thead>
          {listOfProdComponent?.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                    <IconClipboard size={48} color={theme.colors.gray[5]} />
                    <Text size="sm">No Data found</Text>
                    <Flex justify={'center'} gap={theme.spacing.xs}></Flex>
                  </Flex>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>{deploymentRows()}</tbody>
          )}
        </Table>
      </Box>
      <Box className="deployment-table-header">
        <Text size="md" weight={700} pt={15} pl={15}>
          Production Deployment Requests
        </Text>
        <InfiniteScroll
          dataLength={listOfData.length}
          next={() => {}}
          hasMore={hasMore}
          loader={<></>}
          scrollableTarget="table-container-integrator-accounts"
        >
          <Table className="deployment-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
            <thead>
              <tr>
                <th>Component</th>
                <th>Requested Version</th>
                <th>Requested Time</th>
                <th>Deployed Time</th>
                <th>Status</th>
              </tr>
            </thead>
            {listOfData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                      <IconClipboard size={48} color={theme.colors.gray[5]} />
                      <Text size="sm">No Data found</Text>
                      <Flex justify={'center'} gap={theme.spacing.xs}></Flex>
                    </Flex>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{rows()}</tbody>
            )}
          </Table>
        </InfiniteScroll>
        {totalPages > 1 && (
          <Flex justify="center" align="center" mt={20} pb={20}>
            <Pagination
              className=""
              total={totalPages}
              withControls={false}
              onChange={(page) => updatePage(--page)}
              siblings={2}
            />
          </Flex>
        )}
      </Box>
      <CustomDialogBox
        id="deployment-dialog-box"
        isOpen={isProdDeploymentDialogOpen}
        onClose={() => onClickClose()}
        component={deployComponent}
        title="Deployment Request"
      />
    </Box>
  );
};
export default ProductionDeployment;
