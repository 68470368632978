import { MantineTheme, Sx } from '@mantine/core';

const fontStylesEndpoint = (theme: MantineTheme): Sx => {
  return {
    color: theme.colors.font[3],
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fonWeight: 400,
    lineHeight: 'normal',
  };
};

export const integrationSectionForProductionStyles = (theme: MantineTheme): Sx => {
  return {
    padding: `calc(${theme.spacing.xl} * 2)`,
    marginTop: theme.spacing.xl,

    '.integration-suite-title': {
      width: '15%',
    },
    '.integration-suite-container': {
      width: '80%',

      '.api-suite-wrapper': {
        marginBottom: '25px',
        '.header-section': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          ':not(:first-child)': {
            marginTop: '2rem',
          },

          '.header': {
            marginBottom: '0.5rem',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          },
        },
        '.endpoints': {
          marginTop: '0.5rem',

          '.title': { ...fontStylesEndpoint(theme) },
          '.description': { ...fontStylesEndpoint(theme) },
          '.endpoint': { ...fontStylesEndpoint(theme) },
          '.icon': { ...fontStylesEndpoint(theme), cursor: 'pointer' },
        },
        '.base-url': {
          marginTop: '0.5rem',

          '.title': { ...fontStylesEndpoint(theme), color: theme.colors.font[0] },
          '.description': { ...fontStylesEndpoint(theme), color: theme.colors.font[0] },
        },
      },
    },
  };
};
