import { Box, Button, Collapse, Grid, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconStethoscope } from '@tabler/icons-react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuFlowIcon, orderFlowIcon } from '../../../assets/images/testFramework';
import { testFrameworkActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { TestFrameworkState } from '../../../redux/reducers';
import CustomNavLink from './CustomNavLink';
import TestLinkSectionWrapper from './TestLinkSectionWrapper';
import TestStepContent from './TestStepContent';
import { testScenariosStyles } from './style';
import { ApprovalStatus, TestCaseStatus, TestSuiteDefinitionSummaryDto } from '../../../models';
import useSelectedTestDefinition from '../../../hooks/useSelectedTestDefinition';

type TestScenariosProps = {
  projectId: string;
};
const TestScenarios: React.FC<TestScenariosProps> = ({ projectId }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const selectedTestCaseData = useSelectedTestDefinition();

  const {
    selectedTestCase,
    testSuiteDefinitions,
    selectedTestSuite,
    testCaseExecutionData,
    selectedSubFlow,
    selectedFlow,
    testSuiteInstanceData,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const getTestSuiteData = useCallback(() => {
    return testSuiteDefinitions.find(({ id }) => id === selectedTestSuite);
  }, [testSuiteDefinitions, selectedTestSuite]);

  useEffect(() => {
    if (!selectedTestCase) {
      const id = getTestSuiteData()?.flows[0].id;
      const subFlowId = getTestSuiteData()?.flows[0].subFlows[0].id;
      const testCaseId = getTestSuiteData()?.flows[0].subFlows[0].testCases[0].id;
      dispatch(testFrameworkActionCreator.changeSelectedFlow(id ?? null));
      dispatch(testFrameworkActionCreator.changeSelectedSubFlow(subFlowId ?? null));
      dispatch(testFrameworkActionCreator.changeselectedTestCase(testCaseId ?? null));
    }
    // eslint-disable-next-line
  }, [testSuiteDefinitions, selectedTestSuite]);

  const getStatus = (testCaseId: string, subFlowId: string) => {
    const instanceData = testSuiteInstanceData?.testCaseInstances?.find(
      (testCaseInstance) => testCaseInstance?.testCaseId === testCaseId,
    );
    if (testCaseId === selectedTestCase && selectedSubFlow === subFlowId && testCaseExecutionData) {
      if (
        instanceData?.approvalStatus === ApprovalStatus.REJECTED ||
        instanceData?.approvalStatus === ApprovalStatus.APPROVED ||
        instanceData?.approvalStatus === ApprovalStatus.SUBMITTED_FOR_APPROVAL
      ) {
        return instanceData?.approvalStatus;
      }
      return testCaseExecutionData?.overallStatus;
    }

    if (
      instanceData?.approvalStatus === ApprovalStatus.NOT_SUBMITTED_FOR_APPROVAL ||
      instanceData?.approvalStatus === ApprovalStatus.NO_MANUAL_APPROVAL_NEEDED
    ) {
      return instanceData.status;
    }

    return instanceData?.approvalStatus;
  };

  const getTestCasesCount = (mainFlowId: string, testSuite?: TestSuiteDefinitionSummaryDto) => {
    if (!testSuite) return null;
    let totalTestCases = 0;
    let passedTestCases = 0;

    testSuite.flows
      .find((mainFlow) => mainFlow.id === mainFlowId)
      ?.subFlows.forEach((subflow) => {
        totalTestCases +=
          subflow?.testCases?.filter((testCase) => testCase.enabled === true)?.length ?? 0;
        subflow.testCases.forEach((testCase) => {
          testSuiteInstanceData?.testCaseInstances.forEach((testCaseInstance) => {
            if (
              testCase.enabled === true &&
              testCaseInstance.subFlowId === subflow.id &&
              testCaseInstance.testCaseId === testCase.id &&
              testCaseInstance.approvalStatus !== ApprovalStatus.REJECTED &&
              (testCaseInstance.status === TestCaseStatus.SUCCESS ||
                testCaseInstance.status === TestCaseStatus.TO_BE_REVIEWED ||
                testCaseInstance.status === TestCaseStatus.SKIPPED)
            ) {
              passedTestCases += 1;
            }
          });
        });
      });

    return (
      <div className="count-section">
        {passedTestCases}/{totalTestCases}
      </div>
    );
  };

  return (
    <Box sx={testScenariosStyles(theme)}>
      <Grid className="test-framework">
        <Grid.Col xs={5} sm={4} md={3} lg={2.5}>
          <TestLinkSectionWrapper id={`test-flows`}>
            {getTestSuiteData()?.flows.map((mainFlow) => (
              <div
                className="test-flow"
                key={`${selectedTestSuite}-${mainFlow.id}`}
                id={mainFlow.id}
              >
                <Button
                  key={`${selectedTestSuite}-${mainFlow.id}-button`}
                  className="flow-button"
                  onClick={() => {
                    const subFlowId = mainFlow?.subFlows[0]?.id;
                    const testCaseId = mainFlow?.subFlows[0]?.testCases[0]?.id;

                    dispatch(testFrameworkActionCreator.changeSelectedFlow(mainFlow.id));
                    dispatch(testFrameworkActionCreator.changeSelectedSubFlow(subFlowId ?? null));
                    dispatch(testFrameworkActionCreator.changeselectedTestCase(testCaseId ?? null));
                    dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
                    dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(false));
                  }}
                  leftIcon={
                    mainFlow.id === 'MENU_FLOW' ? (
                      <img src={menuFlowIcon} alt={'icon'} />
                    ) : mainFlow.id === 'ORDER_FLOW' ? (
                      <img src={orderFlowIcon} alt={'icon'} />
                    ) : (
                      <IconStethoscope size={16} />
                    )
                  }
                  rightIcon={
                    mainFlow.id === selectedFlow ? (
                      <IconChevronUp size={16} />
                    ) : (
                      <IconChevronDown size={16} />
                    )
                  }
                >
                  {`${mainFlow.name}`} {getTestCasesCount(mainFlow.id, getTestSuiteData())}
                </Button>

                {mainFlow.id === selectedFlow && (
                  <div key={mainFlow.id} className="subflow-wrapper">
                    {mainFlow?.subFlows?.map((subFlow) => (
                      <div
                        className="sub-flow"
                        key={`${selectedTestSuite}-${mainFlow.id}-${subFlow.id}`}
                      >
                        <Button
                          onClick={() => {
                            if (subFlow.id !== selectedSubFlow) {
                              dispatch(
                                testFrameworkActionCreator.changeSelectedSubFlow(subFlow.id),
                              );
                              dispatch(
                                testFrameworkActionCreator.changeselectedTestCase(
                                  subFlow.testCases[0].id,
                                ),
                              );
                              dispatch(
                                testFrameworkActionCreator.changeTestCaseExecutionData(null),
                              );
                              dispatch(testFrameworkActionCreator.changeIsTestCaseBegin(false));
                            }
                          }}
                          className={`subflow-button ${
                            subFlow.id === selectedSubFlow ? 'active' : ''
                          }`}
                        >
                          {subFlow.name}
                        </Button>
                        <Collapse in={subFlow.id === selectedSubFlow}>
                          {subFlow?.testCases
                            ?.filter((testCase) => testCase.enabled === true)
                            ?.map((testCase, index) => (
                              <CustomNavLink
                                key={`${selectedTestSuite}-${mainFlow.id}-${subFlow.id}-${testCase.id}`}
                                label={`Scenario ${index + 1}`}
                                id={testCase.id}
                                description={testCase.name}
                                isActive={
                                  testCase.id === selectedTestCase && selectedSubFlow === subFlow.id
                                }
                                onClick={() => {
                                  if (
                                    !(
                                      testCase.id === selectedTestCase &&
                                      selectedSubFlow === subFlow.id
                                    )
                                  ) {
                                    dispatch(
                                      testFrameworkActionCreator.changeSelectedSubFlow(subFlow.id),
                                    );
                                    dispatch(
                                      testFrameworkActionCreator.changeselectedTestCase(
                                        testCase.id,
                                      ),
                                    );
                                    dispatch(
                                      testFrameworkActionCreator.changeTestCaseExecutionData(null),
                                    );
                                    dispatch(
                                      testFrameworkActionCreator.changeIsTestCaseBegin(false),
                                    );
                                  }
                                }}
                                status={getStatus(testCase.id, subFlow.id)}
                              />
                            ))}
                        </Collapse>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </TestLinkSectionWrapper>
        </Grid.Col>
        <Grid.Col xs={7} sm={8} md={9} lg={9.5}>
          {selectedTestCase && selectedTestCaseData?.enabled && (
            <div className="test-case-content">
              <TestStepContent projectId={projectId} scenarioId={selectedTestCase} />
            </div>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default TestScenarios;
