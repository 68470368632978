import { MantineTheme, Sx } from '@mantine/core';

export const navigationTextStyles = (theme: MantineTheme): Sx => {
  return {
    display: 'flex',
    gap: '4px',
    '.navigationText__navigation': {
      textDecoration: 'none',
      color: theme.colors.dark[9],
      cursor: 'pointer',
    },
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
  };
};
