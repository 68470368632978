import { organizationAxios } from '../config/axios.config';
import { Organization } from '../models';

const organizationGoLiveService = {
  createSameOrganization: async (payload: {
    organizationId: string;
    userId: string;
  }): Promise<Organization> => {
    const { data } = await organizationAxios.post(
      `/go-live/${payload.organizationId}/userId/${payload.userId}`,
    );
    return data;
  },
};

export default organizationGoLiveService;
