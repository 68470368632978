import { Sx, MantineTheme } from '@mantine/core';

export const apiCredentialsStyles = (theme: MantineTheme): Sx => {
  return {
    '.segment': {
      margin: '12px 0',
    },
    '.segmented-control-lbl-active': {
      color: theme.colors.white[0],
    },
    '.description': {
      fontSize: theme.fontSizes.sm,
    },
    '.tabs': {
      marginTop: theme.spacing.md,
      height: '90%',
    },
    '.active-tab': {
      background: `${theme.colors.grubtech[0]} !important`,
      color: '#fff !important',
      fontWeight: 'bold',
      ':hover': {
        background: `${theme.colors.grubtech[1]} !important`,
      },
    },
    '.tab': {
      padding: '8px 16px',
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.sm,
      height: '38px',
      marginRight: '-1px !important',
      border: '1px solid #edeef0',
      ':hover': {
        borderBottom: '1px solid #edeef0 !important',
      },
    },
    '.lock-icon': {
      marginRight: '5px',
    },
    '.help-text': {
      alignSelf: 'center',
      marginLeft: 'auto',
    },
    '.api-credentials-page-content': {
      background: '#fff',
      height: '100%',
    },
    '.api-credentials-box': {
      width: '100%',
      height: '100%',
    },
    '.env-tabs': {
      height: '90%',
    },
  };
};
