import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  PinInput,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
// import { notifications } from '@mantine/notifications';

import LoginPagesLayout from '../../../components/LoginPagesLayout';
import NavigationText from '../../../components/NavigationText';
import BackText from '../../../components/BackText';

import userOnboardingService from '../../../service/userOnboarding.service';
import { OnBoardingProgressState, UserOnBoardingProgress } from '../../../models';

import { signSectionStyles } from '../../../components/LoginPagesLayout/styles';
import { otpStyles } from './styles';

const OTP = () => {
  const theme = useMantineTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [onboardingProgress, setOnboardingProgress] = useState<UserOnBoardingProgress | null>(null);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');

  useEffect(() => {
    if (!location.state || !location.state.progress) {
      navigate('/signup');
    } else {
      setOnboardingProgress(location.state.progress);
    }
    // eslint-disable-next-line
  }, [location]);

  const submitOtp = useMutation({
    mutationFn: (payload: UserOnBoardingProgress) => {
      return userOnboardingService.verifyOtp(payload);
    },
    onSuccess: (data: any) => {
      if (data.onBoardingProgressState === OnBoardingProgressState.EMAIL_VERIFIED) {
        navigate(`/signup-details`, { state: { progress: { ...data, otp } } });
      } else if (data.errorCode && data.message) {
        const notificationTitle = data?.message?.includes('Incorrect OTP value')
          ? 'Incorrect verification code entered.'
          : data?.message?.includes('Expired')
          ? 'Entered verification code has expired.'
          : data?.message;
        setOtpError(notificationTitle);
        // notifications.show({
        //   title: notificationTitle,
        //   message: 'Please try again.',
        //   color: 'red',
        // });
      } else {
        setOtpError('Something went wrong');
        // notifications.show({
        //   title: 'Something went wrong',
        //   message: 'Something went wrong',
        //   color: 'red',
        // });
      }
    },
    onError: () => {
      setOtpError('Something went wrong');
      // notifications.show({
      //   title: error.name ?? 'Something went wrong',
      //   message: error.message ?? 'Something went wrong',
      //   color: 'red',
      // });
    },
  });

  const isValid = (_otp: string) => _otp.length === 6;

  return (
    <LoginPagesLayout id={'otp-page'} sx={{ ...signSectionStyles(theme), ...otpStyles(theme) }}>
      <LoadingOverlay visible={submitOtp.isLoading} />
      <Box className="signPage__container">
        <Box className="signPage__content">
          <BackText to="/signup" />
          <Title color={theme.colors.dark[9]} size={52}>
            Check your email
          </Title>
          <Box className="otpPage__emailDetailsContainer">
            <Text size={20} color={theme.colors.gray[6]} className="otpPage__verificationText">
              Please enter the verification code we sent to:
            </Text>
            <Text size={20} fw={500} color={theme.colors.gray[6]} className="otpPage__emailText">
              {onboardingProgress && onboardingProgress.email}
            </Text>
          </Box>

          <Box className="otpPage__verificationSection">
            {otpError ? (
              <Text color={theme.colors.red[6]} fz={theme.fontSizes.sm}>
                {otpError}
              </Text>
            ) : null}
            <Text
              size={20}
              fw={500}
              color={theme.colors.gray[6]}
              align="center"
              className="otpPage__verificationTitle"
            >
              Verification code
            </Text>
            <Group position="center">
              <PinInput
                onChange={(e) => {
                  setOtp(e);
                  setOtpError('');
                  if (onboardingProgress && isValid(e)) {
                    setOnboardingProgress({ ...onboardingProgress, otp: e });
                  }
                }}
                length={6}
                size="lg"
                data-cy="otp-input"
                mask
              />
            </Group>
          </Box>

          <Button
            size="lg"
            disabled={!isValid(otp)}
            onClick={() => onboardingProgress && submitOtp.mutate(onboardingProgress)}
            className="signPage__button"
          >
            Confirm
          </Button>
          <NavigationText
            to={'/signup'}
            questionText={`Didn't get the email?`}
            navigationText={`Resend or edit your email address`}
          />
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default OTP;
