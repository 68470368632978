import { Modal, Box, useMantineTheme, Flex, Title } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { customDialogBoxStyle } from './style';
import { IconAlertTriangle } from '@tabler/icons-react';
export interface customDialogBoxProps {
  id: string;
  onClose: () => void;
  isOpen: boolean;
  component: ReactNode;
  title: string;
}
const CustomDialogBox: FC<customDialogBoxProps> = ({ id, onClose, isOpen, component, title }) => {
  const theme = useMantineTheme();

  return (
    <Modal
      withCloseButton={false}
      onClose={onClose}
      opened={isOpen}
      key={id}
      centered
      size={'550px'}
      sx={customDialogBoxStyle(theme)}
    >
      <Flex
        className="customDialog__container"
        direction={'column'}
        justify={'center'}
        align={'center'}
      >
        <Box className="customDialog__icon">
          <IconAlertTriangle width={32} height={32} color="white" />
        </Box>
        <Flex className="customDialog__textContainer" direction={'column'} align={'center'}>
          <Title order={3}>{title}</Title>
        </Flex>
        <Box className="customDialog__content">{component}</Box>
      </Flex>
    </Modal>
  );
};
export default CustomDialogBox;
