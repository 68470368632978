import { MantineTheme, Sx } from '@mantine/core';

export const stepStyles = (
  theme: MantineTheme,
  headerHeight = 0,
  logsHeight = 0,
  headerWidth = 0,
): Sx => {
  return {
    '.steps-container': {
      display: 'flex',
      width: '100%',
      padding: '16px 16px 24px 16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      borderRadius: '0px 0px 8px 8px',
      background: theme.colors.white[0],
      boxShadow:
        '0px 4px 8px 0px rgba(32, 35, 45, 0.03), 0px 2px 4px 0px rgba(32, 35, 45, 0.02), 0px 1px 2px 0px rgba(32, 35, 45, 0.01)',
      marginBottom: '12px',
      overflow: 'auto',
      maxHeight: `calc(100vh - ${headerHeight + logsHeight}px - 170px)`,
    },

    '.logs-container': {
      width: `${headerWidth}px`,
      height: 'auto',
      display: 'flex',
    },

    '.step-paper': {
      '.steps-inputs': {
        margin: `${theme.spacing.md} 0`,

        '.select-basket': {
          width: '70%',
          marginTop: theme.spacing.md,
        },

        '.create-basket-button': {
          width: '27%',
        },
      },
      '.responses-title': {
        marginBottom: theme.spacing.sm,
      },

      '.responses-subtitle': {
        fontWeight: 650,
        marginBottom: theme.spacing.xs,
      },

      '.responses-code': {
        marginBottom: theme.spacing.md,
      },
    },
  };
};
