import { MantineTheme, Sx } from '@mantine/core';

export const languageChangerStyles = (theme: MantineTheme): Sx => {
  return {
    '.languageChanger__button': {
      width: '120px',
      borderBottom: '1px solid transparent',
      borderBottomColor: theme.colors.gray[6],
      borderRadius: 0,
      color: theme.colors.gray[7],
      padding: `0 ${theme.spacing.sm}`,
      ':hover': {
        color: theme.colors.grubtech[0],
        borderBottomColor: theme.colors.grubtech[0],
      },
    },
    '.languageChanger__language': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing.xs,

      '&--selected': {
        color: theme.colors.grubtech[0],
      },
    },
  };
};
