import React, { useEffect, useState } from 'react';
import { Box, Image, LoadingOverlay, Navbar, Text, useMantineTheme } from '@mantine/core';
import {
  IconBriefcase,
  IconBuildingStore,
  IconExternalLink,
  IconKey,
  IconLogout2,
  IconNotebook,
  IconPlug,
  IconTestPipe,
  IconUsers,
  IconWebhook,
  IconCloudUpload,
  IconFiles,
  IconDrone,
} from '@tabler/icons-react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GTLOGO from '../../assets/images/gt-logo.png';
import useIsItegrationApprovedForGoLive from '../../hooks/useIsItegrationApprovedForGoLive';
import { IntegrationActivationStatus } from '../../models';
import {
  authenticationActionCreator,
  integrationActionCreator,
  testFrameworkActionCreator,
} from '../../redux/actions';
import { RootState } from '../../redux/common';
import SideBarLink from './SideBarLink';
import { sidebarStyles } from './styles';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import documentationService from '../../service/documentation.service';
import projectService from '../../service/project.service';
import { persistor } from '../../redux/store';
import { useProjectContext } from '../../context/ProjectContext';
import { WebhookState } from '../../redux/reducers';
import { Project } from '../../models/Project';
import { testFrameworkService } from '../../service';

const useGetTestSuitesByIntegration = (currentProjectId: string) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getTestSuitesByIntegration(integartion, currentProjectId);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
};

const useProjectDetailsQuery = (projectId: string | undefined) => {
  const { userData } = useSelector((state: RootState) => state.authentication);
  const { setActiveProject } = useProjectContext();
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const dispatch = useDispatch();

  const getTestSuitesByIntegration = useGetTestSuitesByIntegration(projectId!);

  const projectDetailsQuery = useQuery<Project>({
    queryKey: ['get-project-details', userData.organizationId],
    queryFn: () => projectService.getProjectById(projectId!),
    enabled: !!projectId,
    onSuccess: (data: Project) => {
      if (data && data.id) {
        setCurrentProject(data);
        setActiveProject(data);
        dispatch(integrationActionCreator.changeSelectedProject(data));
        getTestSuitesByIntegration.mutate(data.integrationId);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return { projectDetailsQuery, currentProject, setCurrentProject };
};

const SideBar: React.FC = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isItegrationApprovedForGoLive = useIsItegrationApprovedForGoLive();

  const { userData } = useSelector((state: RootState) => state.authentication);
  const { testSuiteDefinitions } = useSelector((state: RootState) => state.testFramework);
  const { activatedIntegrations } = useSelector((state: RootState) => state.integration);
  const { webhookDefinitions } = useSelector<RootState, WebhookState>((state: RootState) => {
    return state.webhook;
  });

  const { activeProject } = useProjectContext();
  const [sidebarVersion, setSidebarVersion] = useState(0);

  // Extract the projectId from URL
  const projectIdFromUrl = window.location.pathname.split('/projects/')[1]?.split('/')[0];
  const { currentProject, setCurrentProject } = useProjectDetailsQuery(
    !activeProject ? projectIdFromUrl : undefined,
  );

  useEffect(() => {
    if (!activeProject && !projectIdFromUrl) {
      setCurrentProject(null);
    }

    // Update the sidebar version
    setSidebarVersion((prevVersion) => prevVersion + 1);
  }, [activeProject, currentProject, projectIdFromUrl]);

  const documentationAuthRequest = useMutation({
    mutationFn: documentationService.getDocumentationAuth,
    onSuccess: (data) => {
      if (data?.authToken) {
        window.open(`https://docs.grubtech.io?auth_token=${data.authToken}`);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const isThereAnyIntegrationTestCasesApprovedByAdmin = activatedIntegrations.some(
    (activatedIntegration) =>
      activatedIntegration.status.includes(
        IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
  );

  const isThereAnyIntegrationTestCasesApprovedByAdminNeededProductionWebhook = activatedIntegrations
    .filter((activatedIntegration) => {
      const integrationIdOfProdRequiredWebhookDefinitions = webhookDefinitions
        .filter((webhookDefinition) => webhookDefinition.productionRequired)
        .map((webhookDefinition) => webhookDefinition.integration.id);
      return integrationIdOfProdRequiredWebhookDefinitions.includes(
        activatedIntegration.integration.id,
      );
    })
    .some((activatedIntegration) =>
      activatedIntegration.status.includes(
        IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
    );

  const configureLinks = [
    {
      link: '/projects/' + currentProject?.id + '/credentials',
      label: 'API Credentials',
      icon: IconKey,
      isWarning: isItegrationApprovedForGoLive,
      warningText: 'Generate your production API credentials',
    },
    {
      link: '/projects/' + currentProject?.id + '/webhooks',
      label: 'Webhooks',
      icon: IconWebhook,
      isWarning:
        isThereAnyIntegrationTestCasesApprovedByAdminNeededProductionWebhook &&
        !isItegrationApprovedForGoLive,
      warningText: 'Configure your production Webhooks',
    },
    {
      link: '/projects/' + currentProject?.id + '/sandbox-sites',
      label: 'Sandbox',
      icon: IconBuildingStore,
    },
    currentProject?.enableParameterDefinitions && {
      link: '/projects/' + currentProject?.id + '/definition-settings',
      label: 'Definition Settings',
      icon: IconCloudUpload,
      isWarning: isThereAnyIntegrationTestCasesApprovedByAdmin && !isItegrationApprovedForGoLive,
      warningText: 'Configure definition settings',
    },
    currentProject?.enableInfrastructure && {
      link: '/projects/' + currentProject?.id + '/project-settings/repo-details',
      label: 'Project Settings',
      icon: IconDrone,
      subLinks: [
        '/projects/' + currentProject?.id + '/project-settings/bitbucket-permission',
        '/projects/' + currentProject?.id + '/project-settings/deployment-and-release',
      ],
    },
    {
      link: '/projects/' + currentProject?.id + '/app-store-details',
      label: 'App Store Details',
      icon: IconBriefcase,
      disabled: !isThereAnyIntegrationTestCasesApprovedByAdmin,
      disabledText: 'UAT should be approved by administrator',
      isWarning: isThereAnyIntegrationTestCasesApprovedByAdmin && !isItegrationApprovedForGoLive,
      warningText: 'Enter your app details',
    },
  ].filter(Boolean);

  const uatLinks = testSuiteDefinitions?.map(({ id, name }) => ({
    link: `/projects/${currentProject?.id}/uat/${id}`,
    label: name,
    icon: IconTestPipe,
    id: id,
  }));

  const mainLinks = [
    { link: '/organisation', label: 'Organisation', icon: IconUsers },
    { link: '/manage-projects', label: 'Manage Project', icon: IconPlug },
  ];

  const projectLinks = [
    {
      link: '/projects/' + currentProject?.id + '/dashboard',
      label: currentProject?.name,
      icon: IconFiles,
      submenu: configureLinks,
      submenu2: uatLinks,
    },
  ];

  const supportLinks = [
    {
      title: 'Support',
      link: 'https://docs.grubtech.io/',
      label: 'Documentation',
      icon: IconNotebook,
      externalIcon: IconExternalLink,
      external: true,
    },
  ];

  const renderLinks = (links: any) => {
    return links.map((item: any) => (
      <SideBarLink
        to={item.link}
        item={item}
        key={item.label}
        disabled={!userData || userData.organizationId === null}
      />
    ));
  };

  const onClickVerify = async () => {
    await signOut();
  };

  const signOut = async () => {
    try {
      Auth.signOut()
        .then(async () => {
          await persistor.purge();
          await persistor.flush();
          persistor.pause();
          window.location.reload();
          redirectToLoginPage();
        })
        .catch((e) => {
          console.error(e);
          redirectToLoginPage();
        });
    } catch (error) {
      notifications.show({
        title: 'Error signing out',
        message: 'Please try again.',
        color: 'red',
      });
      redirectToLoginPage();
    }
  };

  const redirectToLoginPage = () => {
    dispatch(
      authenticationActionCreator.changeAuthData({
        isAuthenticated: false,
        userData: null,
      }),
    );
    navigate(`/login`);
  };

  return (
    <Box sx={sidebarStyles(theme)} key={sidebarVersion}>
      <LoadingOverlay id={'side-bar-loading'} visible={documentationAuthRequest.isLoading} />
      <Navbar height={'100vh'} width={{ sm: 240 }} p="md" c={'gray'}>
        <Navbar.Section>
          <Box className="sidebar-header">
            <Image src={GTLOGO} width={150} height={45} />
            <Text className="sidebar-header-title">Developer Portal</Text>
          </Box>
        </Navbar.Section>
        <Navbar.Section grow>
          {renderLinks(mainLinks)}
          {currentProject && <Box className="color-top-border">{renderLinks(projectLinks)}</Box>}
        </Navbar.Section>
        <Navbar.Section>
          <Text className="sidebar-section-title">Support</Text>

          {renderLinks(supportLinks)}
        </Navbar.Section>
        <Navbar.Section className="sidebar-footer">
          <IconLogout2 className="sidebar-footer-icon" onClick={onClickVerify} />
          <Text>{userData?.name}</Text>
        </Navbar.Section>
      </Navbar>
    </Box>
  );
};

export default SideBar;
