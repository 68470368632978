import { MantineTheme, Sx } from '@mantine/core';

export const statusStyles = (theme: MantineTheme, type?: string): Sx => {
  return {
    '.status-paper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '2rem',
      alignItems: 'center',

      '.status': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '39px',
        background: theme.colors.background[1],
        color: theme.colors.font[5],
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        padding: '0.5rem',
        borderRadius: '8px',
      },
    },
  };
};
