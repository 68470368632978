import { ApiKey, ApiSuiteDefinition, ApiSuiteId } from '../../models/apiKey';
import { ApiKeyAction } from '../payloadModels/apiKey';
import { APIKeyActionTypes } from '../types';

export type APIKeyState = {
  apiKeyList: ApiKey[];
  newApiCredentialsObject: ApiKey | null;
  apiSuiteDefinitions: ApiSuiteDefinition[];
  generatingAPISuiteId: ApiSuiteId | null;
  newApiCredentialsObjectForProduction: ApiKey | null;
  generatingAPISuiteIdForProduction: ApiSuiteId | null;
  checkIsProductionenabledForApiKey: Boolean;
};

const initialState: APIKeyState = {
  apiKeyList: [],
  newApiCredentialsObject: null,
  apiSuiteDefinitions: [],
  generatingAPISuiteId: null,
  newApiCredentialsObjectForProduction: null,
  generatingAPISuiteIdForProduction: null,
  checkIsProductionenabledForApiKey: false,
};

export const apiKeyReducer = (state = initialState, action: ApiKeyAction): APIKeyState => {
  switch (action.type) {
    case APIKeyActionTypes.CHANGE_API_KEY_LIST:
      const array = [...state.apiKeyList, ...(action.payload as ApiKey[])];
      //@ts-ignore
      const unique = [...new Map(array.map((item) => [item['apiKeyId'], item])).values()];
      return { ...state, apiKeyList: unique as ApiKey[] };
    case APIKeyActionTypes.CHANGE_API_SUITE_DEFINITIONS:
      const apiSuiteDefinitionArray = [
        ...state.apiSuiteDefinitions,
        ...(action.payload as ApiSuiteDefinition[]),
      ];
      const uniqueApiSuiteList = [
        //@ts-ignore
        ...new Map(apiSuiteDefinitionArray.map((item) => [item.id['id'], item])).values(),
      ];
      return { ...state, apiSuiteDefinitions: uniqueApiSuiteList as ApiSuiteDefinition[] };
    case APIKeyActionTypes.RESET_API_KEY_LIST:
      return { ...state, apiSuiteDefinitions: [] };
    case APIKeyActionTypes.RESET_API_SUITE_DEFINITIONS:
      return { ...state, apiKeyList: [] };
    case APIKeyActionTypes.INSERT_API_KEY:
      const arrayList = [...state.apiKeyList, action.payload as ApiKey];
      //@ts-ignore
      const uniqueList = [...new Map(arrayList.map((item) => [item['apiKeyId'], item])).values()];
      return { ...state, apiKeyList: uniqueList as ApiKey[] };
    case APIKeyActionTypes.REMOVE_API_KEY:
      return {
        ...state,
        apiKeyList: state.apiKeyList.filter(
          (apiKeyData) => apiKeyData.apiKeyId !== (action.payload as ApiKey).apiKeyId,
        ),
      };
    case APIKeyActionTypes.CHANGE_NEW_API_CREDENTIALS_OBJECT:
      return { ...state, newApiCredentialsObject: action.payload as ApiKey };
    case APIKeyActionTypes.CHNAGE_GENERATING_API_SUITE_KEY_ID:
      return { ...state, generatingAPISuiteId: action.payload as ApiSuiteId };
    case APIKeyActionTypes.CHANGE_NEW_API_CREDENTIALS_OBJECT_FOR_PRODUCTION:
      return { ...state, newApiCredentialsObjectForProduction: action.payload as ApiKey };
    case APIKeyActionTypes.CHANGE_GENERATING_API_SUITE_KEY_ID_FOR_PRODUCTION:
      return { ...state, generatingAPISuiteIdForProduction: action.payload as ApiSuiteId };
    case APIKeyActionTypes.CHANGE_CHECK_IS_PRODUCTION_ENABLED_FOR_API_KEY:
      return { ...state, checkIsProductionenabledForApiKey: action.payload as Boolean };
    default:
      return state;
  }
};
