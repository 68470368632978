import { Sx, MantineTheme } from '@mantine/core';

export const appDetailsCardStyles = (theme: MantineTheme): Sx => {
  return {
    '.app-details-content-container': {
      padding: '1.5rem',
      background: '#ffff',
      width: '80%',
    },
    '.main-title': {
      fontSize: '34px',
      fontWeight: 700,
    },
    '.name-container': {
      marginRight: '10px',
      width: '20%',
    },
    '.form-container': {
      marginLeft: '40px',
      width: '80%',
    },
    '.app-details-text': {
      fontSize: '26px',
      fontWeight: 700,
      marginBottom: '10px',
    },
    '.app-name-container': {
      width: '100%',
      marginBottom: '10px',
    },
    '.name-input': {
      width: '80%',
    },
    '.image-text': {
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '8px',
      marginTop: '20px',
    },
    '.upload-image-container': {
      padding: '1rem',
    },
    '.upload-image-button': {
      marginLeft: '70px',
    },
    '.button-container': {
      padding: '1.5rem',
      background: '#ffff',
      height: 'fit-content',
    },
    '.app-details-card-container': {
      justifyContent: 'space-between',
      marginBottom: '40px',
    },
    '.no-image-div': {
      width: '200px',
      height: '150px',
      background: '#D9D9D9',
    },
  };
};
