import { Box, Button, CopyButton, Flex, Title, useMantineTheme } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { FC, useCallback, useMemo } from 'react';
import { TestCaseLogs } from '../../models';
import LogsWindow from './LogsWindow';
import { logsContainerStyles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { testFrameworkActionCreator } from '../../redux/actions';
import { RootState } from '../../redux/common';
import { TestFrameworkState } from '../../redux/reducers';

export type LogsContainerProps = {
  id: string;
  title?: string;
  messages?: TestCaseLogs;
};

const LogsContainer: FC<LogsContainerProps> = ({ id, title = 'Logs', messages }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { clearedTestLogs } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const getMessages = useCallback(() => {
    return (
      messages?.logs
        .map(({ time, logLevel, message }) => `[${time}] [${logLevel}] [${message}]`)
        .join('\n') ?? ''
    );
  }, [messages]);

  const handleClearMessages = () => {
    dispatch(testFrameworkActionCreator.clearTestCaseLogs(messages?.logs ?? []));
  };

  const filteredMessages = useMemo(() => {
    const getClearedMessages = (logs: TestCaseLogs['logs'] | undefined) => {
      if (logs) {
        return logs.filter(
          (log) =>
            !clearedTestLogs?.find(
              (clearedMessage) =>
                clearedMessage.message === log.message && clearedMessage.time === log.time,
            ),
        );
      }
      return [];
    };

    const newMessages: TestCaseLogs = { ...messages, logs: getClearedMessages(messages?.logs) };
    return newMessages;
  }, [messages, clearedTestLogs]);

  return (
    <Box id={id} sx={logsContainerStyles(theme)} className="logsContainer__container">
      <Title className="logsContainer__title" order={6}>
        {title}
      </Title>
      <Box className="logsContainer__textContainer">
        <LogsWindow messages={!!filteredMessages?.logs?.length ? filteredMessages : undefined} />
        <Flex
          align={'center'}
          justify={'flex-end'}
          gap={theme.spacing.sm}
          className="logsContainer__copyContainer"
        >
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              handleClearMessages();
            }}
          >
            Clear
          </Button>
          <CopyButton value={getMessages()} timeout={2000}>
            {({ copied, copy }) => (
              <Button
                className="logsContainer__copyButton"
                onClick={copy}
                data-cy="copy-log-btn"
                leftIcon={<IconCopy size={theme.fontSizes.xl} data-cy="copy-log-icon" />}
                variant="outline"
              >
                {copied ? 'Copied' : 'Copy'}
              </Button>
            )}
          </CopyButton>
        </Flex>
      </Box>
    </Box>
  );
};

export default LogsContainer;
