import { IntegrationId } from './integration';

export type WebHookDefinition = {
  id: WebHookId;
  name: string;
  description: string;
  mandatory: boolean;
  integration: IntegrationId;
  secretKeyRequired: boolean;
  endpoints: Endpoints;
  supportedAuthTypes: AuthTypes[];
  specialApiKey: boolean;
  productionRequired: boolean;
};

export type Endpoints = {
  endpoints: Array<EndpointDefinitionContract>;
  urlResolvers: Record<string, string>;
};

export type EndpointDefinitionContract = {
  endpoint: string;
  description: string;
  endpointType: string;
  httpMethod: string;
  endpointIdentifier: string;
  documentationUrl: string;
  responseClassDefinition: string;
  requestBodyClassDefinition: string;
};

export type WebHookId = {
  id: string;
};

export type BasicAuthValue = {
  userName: string;
  password: string;
};
export type OAuthValue = {
  authUrl: string;
  headerPrefix: string;
  authDataPassingMedium?: DataPassingMedium.QUERY_PARAM | null;
  method: HTTPMethod;
  requestType: RequestTypes;
  request: Record<string, string>;
  response: Record<string, string>;
};

export type XApiValue = {
  key: string;
  value: string;
  authDataPassingMedium: DataPassingMedium;
};

export type WebHookValue = CustomAuthValue & {
  integration: IntegrationId;
  webHookId: WebHookId;
  value: string;
  secretKey: string;
  authType: AuthTypes;
};

export type CustomAuthValue = {
  basicAuthValue: BasicAuthValue | null;
  specialApiKeyValue: XApiValue | null;
  oauthValue: OAuthValue | null;
};

export enum AuthTypes {
  X_API_KEY = 'X_API_KEY',
  BASIC_AUTH = 'BASIC_AUTH',
  O_AUTH_2 = 'O_AUTH_2',
  NONE = 'NONE',
}

export enum AuthValueType {
  X_API_KEY = 'specialApiKeyValue',
  BASIC_AUTH = 'basicAuthValue',
  O_AUTH_2 = 'oauthValue',
}

export enum HTTPMethod {
  POST = 'POST',
  PUT = 'PUT',
}

export enum DataPassingMedium {
  HEADER = 'HEADER',
  QUERY_PARAM = 'QUERY_PARAM',
}

export enum RequestTypes {
  APPLICATION_FORM_URLENCODED_VALUE = 'APPLICATION_FORM_URLENCODED_VALUE',
  APPLICATION_JSON_VALUE = 'APPLICATION_FORM_URLENCODED_VALUE',
}

export enum OAuthParameterType {
  REQUEST = 'request',
  RESPONSE = 'response',
}

export type AuthFormSave = CustomAuthValue & {
  specialApiKey: boolean;
  authType: AuthTypes;
};
