import { deploymentAxios } from '../config/axios.config';
import {
  Branch,
  DeployRequest,
  Environment,
  EnvironmentResponse,
  NextVersion,
  PagebleProductionDeploymentStatus,
  ProdDeploymentRequest,
  releaseRequest,
  ReleaseVersion,
} from '../models/deployment';

const deploymentService = {
  getEnvironments: async (): Promise<Environment[]> => {
    const { data } = await deploymentAxios.get(`/components/all-environments`);
    return data;
  },
  getDeploymentDetails: async (
    environmentId: string,
    componentId: string,
  ): Promise<EnvironmentResponse> => {
    const { data } = await deploymentAxios.get(
      `/components/environments/${environmentId}/components/${componentId}`,
    );
    return data;
  },
  build: async (componentId: string, branchname: string): Promise<any> => {
    const { data } = await deploymentAxios.post(
      `/components/build?componentId=${componentId}&branchName=${branchname}`,
    );
    return data;
  },
  release: async (request: releaseRequest): Promise<any> => {
    const { data } = await deploymentAxios.post(`/components/release`, request);
    return data;
  },
  deploy: async (request: DeployRequest): Promise<any> => {
    const { data } = await deploymentAxios.post(`/components/deploy`, request);
    return data;
  },
  getBranches: async (repositoryName: string): Promise<Branch[]> => {
    const { data } = await deploymentAxios.get(
      `/components/repositories/${repositoryName}/branches`,
    );
    return data;
  },
  getNextVersion: async (componentId: string, branch: string): Promise<NextVersion> => {
    const { data } = await deploymentAxios.get(
      `/components/deployments/next-version?componentId=${componentId}&branch=${branch}`,
    );
    return data;
  },
  getReleaseVersion: async (componentId: string, branch: string): Promise<ReleaseVersion[]> => {
    const { data } = await deploymentAxios.get(
      `/components/branchReleases?componentId=${componentId}&branch=${branch}`,
    );
    return data;
  },
  getProductionDeploymentStatus: async (
    repositoryName: string,
    page: number,
    size: number,
  ): Promise<PagebleProductionDeploymentStatus> => {
    const { data } = await deploymentAxios.get(
      `/components/deployment-requests?repositoryName=${repositoryName}&page=${page}&size=${size}`,
    );
    return data;
  },
  requestProductionDeployment: async (request: ProdDeploymentRequest): Promise<any> => {
    const { data } = await deploymentAxios.post(`/components/deployment-requests`, request);
    return data;
  },
};
export default deploymentService;
