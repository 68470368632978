import { FC, useCallback } from 'react';
import { ApprovalStatus, TestCaseStatus } from '../../models';
import { error, pass, pending } from '../../assets/images';
import { IconClockCancel } from '@tabler/icons-react';
import { Box, useMantineTheme } from '@mantine/core';
import { statusChipStyles } from './styles';
import { timeIcon } from '../../assets/images/testFramework';

type StatusChipProps = {
  id: string;
  type?: TestCaseStatus | ApprovalStatus;
  withoutText?: boolean;
  withoutIcon?: boolean;
};

const StatusChip: FC<StatusChipProps> = ({ id, type, withoutText, withoutIcon }) => {
  const theme = useMantineTheme();

  const getText = useCallback(() => {
    if (withoutText) return null;

    switch (type) {
      case TestCaseStatus.SUCCESS:
        return 'Passed';
      case TestCaseStatus.TO_BE_REVIEWED:
        return 'To be reviewed';
      case TestCaseStatus.PENDING:
        return 'In progress';
      case TestCaseStatus.FAILED:
        return 'Error';
      case TestCaseStatus.CANCELLED:
        return 'Cancelled';
      case TestCaseStatus.TIMED_OUT:
        return 'Time out';
      case ApprovalStatus.APPROVED:
        return 'Passed';
      case ApprovalStatus.REJECTED:
        return 'Rejected';
      case ApprovalStatus.SUBMITTED_FOR_APPROVAL:
        return 'Under review';
      case TestCaseStatus.SKIPPED:
        return 'Under review';
    }
  }, [type, withoutText]);

  const getIcon = useCallback(() => {
    if (withoutIcon) return null;

    switch (type) {
      case TestCaseStatus.SUCCESS:
        return <img src={pass} alt={'success'} />;
      case TestCaseStatus.TO_BE_REVIEWED:
        return <img src={pass} alt={'success'} />;
      case TestCaseStatus.PENDING:
        return <img src={pending} alt={'pending'} />;
      case TestCaseStatus.FAILED:
        return <img src={error} alt={'error'} />;
      case TestCaseStatus.CANCELLED:
        return <img src={error} alt={'error'} />;
      case TestCaseStatus.TIMED_OUT:
        return <IconClockCancel size={14} />;
      case ApprovalStatus.APPROVED:
        return <img src={pass} alt={'success'} />;
      case ApprovalStatus.REJECTED:
        return <img src={error} alt={'error'} />;
      case ApprovalStatus.SUBMITTED_FOR_APPROVAL:
        return <img src={pass} alt={'success'} />;
      case TestCaseStatus.SKIPPED:
        return <img src={timeIcon} alt={'skipped'} />;
    }
  }, [type, withoutIcon]);

  return (
    <Box id={id} className={`chip`} sx={statusChipStyles(theme, type)}>
      <div className="icon">{getIcon()}</div>
      <div className="text">{getText()}</div>
    </Box>
  );
};

export default StatusChip;
