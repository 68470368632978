import { FC, useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { projectSettingsStyles } from './style';
import { Box, LoadingOverlay, Tabs, useMantineTheme } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import RepoDetails from './RepoDetails/RepoDetails';
import { RootState } from '../../redux/common';
import { AuthenticationState, IntegrationState } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { notifications } from '@mantine/notifications';
import repositoryService from '../../service/repository.service';
import { RepoRequestStatus, RepositoryStatus, RepositoryStatusStep } from '../../models/repository';
import DeploymentAndRelease from './DeploymentAndRelease/DeploymentAndRelease';
import BitBucketPermission from './BitbucketPermission/BitBucketPermission';
import integratioService from '../../service/integration.service';
import { integrationActionCreator } from '../../redux/actions';

const ProjectSetting: FC = () => {
  const theme = useMantineTheme();
  const { projectId, tabValue } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedProject } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const [repoRequest, setRepoRequest] = useState<RepoRequestStatus | null>(null);
  const [isRepoCreated, setIsRepoCreated] = useState<boolean>(false);

  useEffect(() => {
    if (selectedProject && !selectedProject.enableInfrastructure) {
      navigate(`/projects/${selectedProject.id}/dashboard`);
    }
  }, [selectedProject]);

  const checkRepoCreation = (statuses: RepositoryStatus[]) => {
    if (statuses == null || statuses.length === 0) {
      return false;
    }
    return statuses.some((status) => status.status === RepositoryStatusStep.REPOSITORY_CREATED);
  };

  const { isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration-api-credentials', userData.organizationId],
    queryFn: () =>
      integratioService.getActivatedIntegrations(userData.organizationId, projectId ?? ''),
    enabled: !!projectId,
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingRepoRequest, refetch } = useQuery({
    queryKey: ['get-repo-request-status', userData.organizationId],
    queryFn: () => repositoryService.getRepoCreateRequest(userData.organizationId, projectId ?? ''),
    onSuccess: (data) => {
      setRepoRequest(data);
      setIsRepoCreated(checkRepoCreation(data.repositoryStatuses));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return (
    <PageLayout
      id={'project-settings-page'}
      sx={projectSettingsStyles(theme)}
      headerSection={
        <PageHeader
          id={'project-settings-page'}
          title="Project settings"
          backText="Back"
          onClickBack={() => navigate(`/projects/${projectId}/dashboard`)}
        />
      }
      isTabs={true}
    >
      <LoadingOverlay visible={isLoadingRepoRequest || isLoadingActivatedIntegrations} />
      <Box className="setting-pannel">
        <Tabs
          defaultValue="team"
          className="project-setting-tabs"
          value={tabValue}
          onTabChange={(value) => navigate(`/projects/${projectId}/project-settings/${value}`)}
        >
          <Tabs.List>
            <Tabs.Tab value="repo-details" data-cy="repo-details-tab">
              Repo Details
            </Tabs.Tab>
            <Tabs.Tab
              value="bitbucket-permission"
              data-cy="bitbucket-permission-tab"
              disabled={!isRepoCreated}
            >
              Bitbucket Permission
            </Tabs.Tab>
            <Tabs.Tab
              value="deployment-and-release"
              data-cy="deployment-and-release-tab"
              disabled={!isRepoCreated}
            >
              Deployment & Release
            </Tabs.Tab>
            {!repoRequest?.serverless && (
              <Tabs.Tab
                value="service-monitor"
                data-cy="service-monitor-tab"
                disabled={!isRepoCreated}
              >
                Service Monitor
              </Tabs.Tab>
            )}
          </Tabs.List>
          <Tabs.Panel value="repo-details" pl={0} ml={'xs'} mr={0}>
            {tabValue === 'repo-details' && (
              <RepoDetails
                projectName={selectedProject?.name ?? ''}
                suite={selectedProject?.integrationId ?? ''}
                repositoryRequest={repoRequest}
                isRepoCreated={isRepoCreated}
                refresh={() => refetch()}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="bitbucket-permission" pl={0} ml={'xs'} mr={0}>
            {tabValue === 'bitbucket-permission' && (
              <BitBucketPermission
                repositoryUrl={repoRequest?.repositoryName ?? ''}
                integrationId={selectedProject?.integrationId ?? ''}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="deployment-and-release" pl={0} ml={'xs'} mr={0}>
            {tabValue === 'deployment-and-release' && (
              <DeploymentAndRelease
                repositoryUrl={repoRequest?.repositoryName ?? ''}
                componentId={repoRequest?.componentId ?? ''}
                environmentServiceIds={repoRequest?.environmentServiceIds ?? {}}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="service-monitor" pl={0} ml={'xs'} mr={0}>
            {tabValue === 'service-monitor' && <div>test</div>}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </PageLayout>
  );
};
export default ProjectSetting;
