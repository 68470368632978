import { AddressFieldWithCountryAddressId, City, Country, Organization, User } from '../../models';
import { OrganizationAction } from '../payloadModels/organization';
import { OrganizationActionTypes } from '../types/organization';

export type OrganizationState = {
  organization: Organization;
  invitationCode: string;
  countries: Country[];
  addressFields: AddressFieldWithCountryAddressId;
  companyUsers: User[];
  cities: City[];
};

const initialState: OrganizationState = {
  organization: {} as Organization,
  invitationCode: '',
  countries: [],
  addressFields: {} as AddressFieldWithCountryAddressId,
  companyUsers: [],
  cities: [],
};

export const organizationReducer = (
  state = initialState,
  action: OrganizationAction,
): OrganizationState => {
  switch (action.type) {
    case OrganizationActionTypes.CHANGE_ORGANIZATION:
      return { ...state, organization: action.payload as Organization };
    case OrganizationActionTypes.CHANGE_INVITATION_CODE:
      return { ...state, invitationCode: action.payload as string };
    case OrganizationActionTypes.CHANGE_COUNTRIES:
      return { ...state, countries: action.payload as Country[] };
    case OrganizationActionTypes.CHANGE_ADDRESS_FIELD:
      return { ...state, addressFields: action.payload as AddressFieldWithCountryAddressId };
    case OrganizationActionTypes.CHANGE_UPDATE_ORGANIZATION:
      return { ...state, organization: action.payload as Organization };
    case OrganizationActionTypes.CHANGE_COMPANY_USERS:
      return { ...state, companyUsers: action.payload as User[] };
    case OrganizationActionTypes.CHANGE_CITIES:
      return { ...state, cities: action.payload as City[] };
    default:
      return state;
  }
};
