import { Box, Select, Text, TextInput, useMantineTheme } from '@mantine/core';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { globalSettingsStyles } from './style';
import { object as validateObject, string as validateString, mixed as validateMixed } from 'yup';
import { ParameterInfo, ParameterType, SettingsItem } from '../../../models';
import { IconTrash } from '@tabler/icons-react';
import { FormikProps, getIn, useFormik } from 'formik';
import { useDisclosure } from '@mantine/hooks';
import DeleteDialog from '../../../components/DeleteDialog';

interface GlobalSettingsProps {
  formikInstance: FormikProps<any>;
  settingsArray: SettingsItem[];
  setKeyErrors: Dispatch<SetStateAction<(string | undefined)[]>>;
  keyErrors: (string | undefined)[];
  setSettingsArray: Dispatch<SetStateAction<SettingsItem[]>>;
  isProductionAlreadyCreated: boolean;
}

const dropdownOptions = [
  {
    value: 'STRING',
    label: 'Text',
  },
  {
    value: 'BOOLEAN',
    label: 'Boolean',
  },
  {
    value: 'NUMBER',
    label: 'Number',
  },
];

const GlobalSettings: FC<GlobalSettingsProps> = ({
  formikInstance,
  settingsArray,
  setKeyErrors,
  keyErrors,
  setSettingsArray,
  isProductionAlreadyCreated,
}) => {
  const theme = useMantineTheme();

  const [newKeyError, setNewKeyError] = useState<string>('');
  const [deletedItemKey, setDeletedItemKey] = useState<string>('');
  const [opened, { open, close }] = useDisclosure(false);

  const newElementValidationSchema = validateObject({
    field: validateString().trim().required('field is required'),
    type: validateMixed<ParameterType>()
      .oneOf(Object.values(ParameterType), 'Invalid type')
      .required('Type is required'),
    value: validateString()
      .required('Value is required')
      .when('type', (type) => {
        if (type[0] === ParameterType.BOOLEAN) {
          return validateString()
            .required('Value is required')
            .oneOf(['true', 'false'], 'Value must be "true" or "false" for boolean type');
        } else if (type[0] === ParameterType.NUMBER) {
          return validateString()
            .required('Value is required')
            .matches(/^\d+$/, 'Value must be a number');
        }
        return validateString().required('Value is required');
      }),
  });

  const generateNewElementInitialValues = () => {
    return {
      field: '',
      type: ParameterType.STRING,
      value: '',
    };
  };

  const newElementFormikInstance = useFormik({
    initialValues: generateNewElementInitialValues(),
    onSubmit: () => {},
    validationSchema: newElementValidationSchema,
  });

  const handleChangeKey = (
    event: React.ChangeEvent<HTMLInputElement>,
    oldKey: string,
    index: number,
  ) => {
    const newKey = event.target.value.trim();
    if (
      formikInstance.values &&
      formikInstance.values.globalSettings &&
      newKey in formikInstance.values.globalSettings
    ) {
      const newKeyErrors = [...keyErrors];
      newKeyErrors[index] = 'Key should be unique';
      setKeyErrors(newKeyErrors);
      return;
    }
    const updatedSettings = settingsArray.map((item) => {
      if (item.key === oldKey) {
        return { ...item, key: newKey };
      }
      return item;
    });
    setSettingsArray(updatedSettings);
    formikInstance.setFieldValue(
      'globalSettings',
      Object.fromEntries(
        updatedSettings.map((item) => [item.key, { type: item.type, value: item.value }]),
      ),
    );

    const newKeyErrors = [...keyErrors];
    if (!newKey) {
      newKeyErrors[index] = 'Key is required';
    } else {
      newKeyErrors[index] = undefined;
    }
    setKeyErrors(newKeyErrors);
  };

  return (
    <Box sx={globalSettingsStyles(theme)}>
      <div className="global-settings-container">
        <Text className="global-settings-title">Global settings</Text>
        <div className="title-container">
          <div className="field-container">FIELD</div>
          <div className="type-container">TYPE</div>
          <div className="value-container">VALUE</div>
        </div>

        {settingsArray.map((item, index) => (
          <div className="global-settings-content-container">
            <div className="field-container">
              <TextInput
                placeholder="Field"
                withAsterisk
                value={item.key}
                onChange={(e) => handleChangeKey(e, item.key, index)}
                name={`globalSettings.${item.key}.key`}
                className="field-input"
                onBlur={formikInstance.handleBlur}
                error={keyErrors[index]}
                disabled={isProductionAlreadyCreated}
              />
            </div>
            <div className="type-container">
              <Select
                className="type-dropdown"
                data={dropdownOptions}
                value={item.type}
                onChange={(newValue) => {
                  formikInstance.setFieldValue(`globalSettings.${item.key}.type`, newValue);
                }}
                data-cy="type-dropdown"
                onBlur={formikInstance.handleBlur}
                disabled={isProductionAlreadyCreated}
              />
            </div>
            <div className="value-container">
              <TextInput
                placeholder="value"
                withAsterisk
                value={item.value}
                onChange={(e) =>
                  formikInstance.setFieldValue(`globalSettings.${item.key}.value`, e.target.value)
                }
                name={`globalSettings.${item.key}.value`}
                className="value-input"
                onBlur={formikInstance.handleBlur}
                disabled={isProductionAlreadyCreated}
                error={getIn(formikInstance.errors, `globalSettings.${item.key}.value`)}
              />
            </div>
            <IconTrash
              id="delete-icon-id"
              className="trash-icon"
              style={{
                opacity: isProductionAlreadyCreated ? '0.5' : '1',
                pointerEvents: isProductionAlreadyCreated ? 'none' : 'unset',
              }}
              onClick={() => {
                setDeletedItemKey(item.key);
                open();
              }}
            />
          </div>
        ))}
      </div>
      <div className="global-settings-add-element-container">
        <div className="field-container">
          <TextInput
            placeholder="Field"
            withAsterisk
            value={newElementFormikInstance.values.field}
            onChange={(e) => {
              newElementFormikInstance.setFieldValue('field', e.target.value);
              if (
                formikInstance.values &&
                formikInstance.values.globalSettings &&
                e.target.value in formikInstance.values.globalSettings
              ) {
                setNewKeyError('key should be unique');
              } else {
                setNewKeyError('');
              }
            }}
            name={'field'}
            className="field-input"
            disabled={isProductionAlreadyCreated}
            onBlur={newElementFormikInstance.handleBlur}
            error={
              (newElementFormikInstance.touched.field && newElementFormikInstance.errors?.field) ||
              newKeyError
            }
          />
        </div>
        <div className="type-container">
          <Select
            className="type-dropdown"
            data={dropdownOptions}
            value={newElementFormikInstance.values.type}
            onChange={(newValue: ParameterType) =>
              newElementFormikInstance.setFieldValue('type', newValue)
            }
            data-cy="type-dropdown"
            disabled={isProductionAlreadyCreated}
          ></Select>
        </div>
        <div className="value-container">
          <TextInput
            placeholder="value"
            withAsterisk
            value={newElementFormikInstance.values.value}
            onChange={(e) => {
              newElementFormikInstance.setFieldValue('value', e.target.value);
            }}
            name={'value'}
            className="value-input"
            onBlur={newElementFormikInstance.handleBlur}
            disabled={isProductionAlreadyCreated}
            error={newElementFormikInstance.touched.value && newElementFormikInstance.errors?.value}
          />
        </div>
        <div
          className="add-setting-text"
          style={{
            opacity:
              newKeyError ||
              !newElementFormikInstance.isValid ||
              !newElementFormikInstance.values.field ||
              !newElementFormikInstance.values.value ||
              isProductionAlreadyCreated
                ? '0.5'
                : '1',
            pointerEvents: isProductionAlreadyCreated ? 'none' : 'unset',
          }}
          onClick={() => {
            if (
              !newKeyError &&
              newElementFormikInstance.isValid &&
              newElementFormikInstance.values.field &&
              newElementFormikInstance.values.value
            ) {
              const existingGlobalSettings = { ...formikInstance.values.globalSettings };
              const newItem: ParameterInfo = {
                value: newElementFormikInstance.values.value,
                type: newElementFormikInstance.values.type,
              };
              existingGlobalSettings[newElementFormikInstance.values.field] = newItem;
              formikInstance.setFieldValue('globalSettings', existingGlobalSettings);
              newElementFormikInstance.setValues(generateNewElementInitialValues());
              newElementFormikInstance.resetForm();
            }
          }}
        >
          Add setting
        </div>
      </div>
      <DeleteDialog
        id={'delete-global-settings'}
        opened={opened}
        onClickCancel={close}
        close={close}
        onClickConfirm={() => {
          const existingGlobalSettings = { ...formikInstance.values.globalSettings };
          delete existingGlobalSettings[deletedItemKey];
          formikInstance.setFieldValue('globalSettings', existingGlobalSettings);
          setDeletedItemKey('');
          close();
        }}
        variant="danger"
        contentText="Are you sure you want to delete this field?"
        confirmText="Delete"
      />
    </Box>
  );
};

export default GlobalSettings;
