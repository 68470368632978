import { webhookAxios } from '../config/axios.config';
import { WebHookDefinition, WebHookValue } from '../models/webhook';

const webhookService = {
  getWebHookDefinitions: async (integrationId: string): Promise<WebHookDefinition[]> => {
    const { data } = await webhookAxios.get(`/definitions/${integrationId}`);
    return data;
  },
  persistWebHookValues: async (payload: {
    integrationId: string;
    partnerId: string;
    projectId: string;
    values: WebHookValue[];
  }): Promise<void> => {
    await webhookAxios.post(
      `/values/${payload.integrationId}/${payload.partnerId}/projects/${payload.projectId}`,
      {
        organizationId: payload.partnerId,
        webHookValues: payload.values,
      },
    );
  },
  getWebHookValues: async (
    integrationId: string,
    partnerId: string,
    projectId: string,
  ): Promise<WebHookValue[]> => {
    const { data } = await webhookAxios.get(
      `/values/${integrationId}/${partnerId}/projects/${projectId}`,
    );
    return data.webHookValues;
  },

  checkIsProductionEnabledByIntegration: async (
    integrationId: string,
    organizationId: string,
    projectId: string,
  ): Promise<Boolean> => {
    const { data } = await webhookAxios.get(
      `/is-production-enabled-by-integration/${integrationId}/${organizationId}/projects/${projectId}`,
    );
    return data;
  },

  checkIsProductionEnabled: async (organizationId: string, projectId: string): Promise<Boolean> => {
    const { data } = await webhookAxios.get(
      `/is-production-enabled/${organizationId}/projects/${projectId}`,
    );
    return data;
  },

  checkIsProductionRequired: async (
    organizationId: string,
    projectId: string,
  ): Promise<Boolean> => {
    const { data } = await webhookAxios.get(
      `/is-production-required/${organizationId}/projects/${projectId}`,
    );
    return data;
  },
};

export default webhookService;
