import {
  Button,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Table,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAsterisk } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  array as validateArray,
  boolean as validateBoolean,
  object as validateObject,
  string as validateString,
} from 'yup';
import PageHeader from '../../../components/PageHeader';
import PageLayout from '../../../components/PageLayout';
import {
  SandboxPropertyDefinitionDisplayType,
  SandboxPropertyDefinitionValue,
  SandboxPropertyValue,
} from '../../../models';
import { sandboxActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, SandboxState } from '../../../redux/reducers';
import { sandboxService } from '../../../service';
import { convertEnumToString } from '../../../utils/string';
import { editSandBoxSiteStyles } from './styles';

const EditSandboxSite: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }
  const { sandboxEditingId, sandboxSummaryData } = useSelector<RootState, SandboxState>(
    (state: RootState) => {
      return state.sandbox;
    },
  );

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const getSandBoxData = useCallback(() => {
    return sandboxSummaryData.find((summaryData) => summaryData.id === sandboxEditingId);
  }, [sandboxSummaryData, sandboxEditingId]);

  const { isLoading: isLoadingGetAllSandboxPropertyDefinitionValue } = useQuery({
    queryKey: ['get-all-sandbox-property-definition-value', userData.organizationId],
    queryFn: () =>
      sandboxService.getAllSandboxPropertyDefinitionValue(userData.organizationId, projectId),
    onSuccess: (data) => {
      setFieldValue(
        'sandboxPropertyInputDefinitions',
        data.filter((item) => item.displayType == SandboxPropertyDefinitionDisplayType.INPUT),
      );
      setFieldValue(
        'sandboxPropertySelectDefinitions',
        data.filter((item) => item.displayType == SandboxPropertyDefinitionDisplayType.SELECT),
      );
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const updatePropertyValue = useMutation({
    mutationFn: (payload: {
      organizationId: string;
      projectId: string;
      propertyValues: SandboxPropertyValue[];
    }) => {
      return sandboxService.updatePropertyValue(payload);
    },
    onSuccess: (data) => {
      if (data && data.length) {
        notifications.show({
          title: 'Values could not be updated',
          message: data[0].validationErrorMessage,
          autoClose: 2000,
          color: 'red',
        });
        return;
      }

      navigate(`/projects/${projectId}/sandbox-sites`);
      dispatch(sandboxActionCreator.changeMenudata(null));
      dispatch(sandboxActionCreator.changeEditingMenuId(null));
      dispatch(sandboxActionCreator.newSandboxCreated(true));
      dispatch(sandboxActionCreator.retrySandboxCreation(false));
      resetForm();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const generateValidateSchema = () => {
    return validateObject().shape({
      sandboxPropertyDefinitions: validateArray().of(
        validateObject().shape({
          mandatory: validateBoolean(),
          value: validateString()
            .trim()
            .when('mandatory', (mandatory, schema) => {
              if (mandatory) return schema.required('value is required');
              return schema;
            }),
        }),
      ),
    });
  };

  const { values, touched, errors, setFieldValue, handleBlur, resetForm, isValid } = useFormik({
    initialValues: {
      sandboxPropertyInputDefinitions: [] as SandboxPropertyDefinitionValue[],
      sandboxPropertySelectDefinitions: [] as SandboxPropertyDefinitionValue[],
    },
    onSubmit: () => {},
    validationSchema: generateValidateSchema(),
  });

  const onClickBack = () => {
    navigate(`/projects/${projectId}/sandbox-sites`);
  };

  const getPropertyValues = () => {
    const inputValues =
      values?.sandboxPropertyInputDefinitions?.map((sandboxPropertyDefinition) => {
        return {
          integration: sandboxPropertyDefinition.integration,
          propertyId: sandboxPropertyDefinition.id,
          value: sandboxPropertyDefinition.value,
        };
      }) ?? [];
    const selectValues =
      values?.sandboxPropertySelectDefinitions?.map((sandboxPropertyDefinition) => {
        return {
          integration: sandboxPropertyDefinition.integration,
          propertyId: sandboxPropertyDefinition.id,
          value: sandboxPropertyDefinition.value,
        };
      }) ?? [];
    return [...inputValues, ...selectValues];
  };

  const onClickSubmit = () => {
    updatePropertyValue.mutate({
      organizationId: userData.organizationId,
      projectId: projectId,
      propertyValues: getPropertyValues(),
    });
  };

  const getSelectRows = useCallback(() => {
    return values?.sandboxPropertySelectDefinitions?.map(
      (
        { name, description, id, mandatory, value, readOnly, availableValues, displayLabel },
        index,
      ) => {
        return (
          <div className="select-section-wrapper">
            <Select
              label={displayLabel}
              searchable
              name={displayLabel}
              data={generateSelectOptions(availableValues)}
              value={value}
              onChange={(value) => {
                setFieldValue(`sandboxPropertySelectDefinitions[${index}].value`, value);
              }}
              onBlur={handleBlur}
              error={
                (touched &&
                  touched?.sandboxPropertySelectDefinitions &&
                  touched?.sandboxPropertySelectDefinitions[index]?.value &&
                  errors &&
                  errors?.sandboxPropertySelectDefinitions &&
                  //@ts-ignore
                  errors?.sandboxPropertySelectDefinitions[index]?.value) ??
                ''
              }
              required={mandatory}
              data-cy="apiSuite-select"
            />
            <div>
              <h5>{name}</h5>
              <div>{value}</div>
            </div>
          </div>
        );
      },
    );
  }, [values, touched, errors, handleBlur, setFieldValue]);

  const generateSelectOptions = (availableValues: any): any => {
    if (availableValues) {
      return Object.keys(availableValues).map((key) => {
        return { value: key, label: availableValues[key] };
      });
    }
    return [];
  };

  const getOtherRows = useCallback(() => {
    return values?.sandboxPropertyInputDefinitions?.map(
      (
        { name, description, id, mandatory, value, readOnly, availableValues, displayLabel },
        index,
      ) => {
        return (
          <tr data-cy={`other-value-${id.id}-data-row`} key={id.id}>
            <td data-cy={`other-value-${id.id}-name`}>{convertEnumToString(name)}</td>
            <td data-cy={`other-value-${id.id}-description`}>{description}</td>
            <td data-cy={`other-value-${id.id}-value`}>
              {readOnly ? (
                <span>{value}</span>
              ) : (
                <TextInput
                  value={value}
                  variant="default"
                  onChange={(e) => {
                    setFieldValue(
                      `sandboxPropertyInputDefinitions[${index}].value`,
                      e.target.value,
                    );
                  }}
                  data-cy={`other-value-${id.id}-value-input`}
                  withAsterisk={mandatory}
                  placeholder={`Enter ${convertEnumToString(name)}`}
                  rightSection={
                    mandatory ? (
                      <IconAsterisk color="red" style={{ height: '10px', width: '10px' }} />
                    ) : undefined
                  }
                  name={`sandboxPropertyInputDefinitions[${index}].value`}
                  onBlur={handleBlur}
                  error={
                    (touched &&
                      touched?.sandboxPropertyInputDefinitions &&
                      touched?.sandboxPropertyInputDefinitions[index]?.value &&
                      errors &&
                      errors?.sandboxPropertyInputDefinitions &&
                      //@ts-ignore
                      errors?.sandboxPropertyDefinitions[index]?.value) ??
                    ''
                  }
                />
              )}
            </td>
          </tr>
        );
      },
    );
  }, [values, touched, errors, handleBlur, setFieldValue]);

  return (
    <PageLayout
      id={'edit-sandbox-site-page'}
      sx={editSandBoxSiteStyles(theme)}
      headerSection={
        <PageHeader
          id={'new-sandbox-site-page'}
          title={getSandBoxData()?.name ?? ''}
          backText="Sandbox"
          onClickBack={onClickBack}
        />
      }
    >
      <LoadingOverlay visible={isLoadingGetAllSandboxPropertyDefinitionValue} />

      {(values?.sandboxPropertyInputDefinitions ?? [])?.length > 0 && (
        <Paper shadow="xs" className="section-paper">
          <Group position="center" className="section-group">
            {/* <div className="section-title">
              <Title order={3} data-cy="section-title">
                Other Values
              </Title>
            </div> */}
            <div className="section-container">
              <Table className="menu-table" verticalSpacing={'md'} horizontalSpacing={'md'}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>description</th>
                    <th>value</th>
                  </tr>
                </thead>
                <tbody>{getOtherRows()}</tbody>
              </Table>
            </div>
          </Group>
        </Paper>
      )}

      {(values?.sandboxPropertySelectDefinitions ?? [])?.length > 0 && (
        <Paper shadow="xs" className="section-paper">
          <Group position="center" className="section-group">
            <div className="section-container">{getSelectRows()}</div>
          </Group>
        </Paper>
      )}

      <Paper shadow="xs" className="action-paper">
        <Button
          className="create-site-button"
          disabled={!isValid}
          onClick={onClickSubmit}
          data-cy="update-site-button"
        >
          Update sandbox site
        </Button>
      </Paper>
    </PageLayout>
  );
};

export default EditSandboxSite;
