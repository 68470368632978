import {
  ActionIcon,
  Button,
  CopyButton,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Title,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconCopy, IconHelpCircle } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApiKeyGenerationRequest,
  ApiSubStatus,
  ApiSuiteDefinition,
  ApiSuiteId,
  Endpoints,
} from '../../../../models';
import { apiKeyActionCreator } from '../../../../redux/actions';
import { RootState } from '../../../../redux/common';
import { APIKeyState, AuthenticationState, IntegrationState } from '../../../../redux/reducers';
import { apikeyService } from '../../../../service';
import { endPointReplacer } from '../../../../utils/string';
import ConfirmationGenerateApiKey from '../ConfirmationGenerateApiKey';
import { integrationSectionStyles } from './styles';
import documentationService from '../../../../service/documentation.service';

type IntegrationSectionProps = {
  integrationId: ApiSubStatus;
  projectId: string;
  openGenerated: () => void;
};

const IntegrationSection: FC<IntegrationSectionProps> = ({
  integrationId,
  projectId,
  openGenerated,
}) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const [opened, { open, close }] = useDisclosure(false);

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { integrationDefinitions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => {
      return state.integration;
    },
  );

  const { apiSuiteDefinitions, generatingAPISuiteId } = useSelector<RootState, APIKeyState>(
    (state: RootState) => {
      return state.apiKey;
    },
  );

  const {
    data: apiKeys,
    isLoading: isLoadingApiKeys,
    refetch: refetchApiKeys,
  } = useQuery({
    queryKey: [
      'get-activated-integration-api-credentials',
      userData?.organizationId,
      integrationId,
    ],
    queryFn: () =>
      apikeyService.getApiKeysbyIntegrationAndOrganizationId(
        integrationId,
        userData.organizationId,
        projectId,
      ),
    onSuccess: (data) => {},
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: userData?.organizationId ? true : false,
  });

  const generateApiKey = useMutation({
    mutationFn: (payload: ApiKeyGenerationRequest) => {
      return apikeyService.generateApiKey(payload);
    },
    onSuccess: (data) => {
      refetchApiKeys();
      dispatch(apiKeyActionCreator.changeNewApiCredentialsObject(data));
      dispatch(apiKeyActionCreator.changeGeneratingAPISuiteKeyId(null));
      close();
      openGenerated();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getIntegrationData = useCallback(() => {
    return integrationDefinitions?.find(({ id }) => id.id === integrationId);
  }, [integrationDefinitions, integrationId]);

  const getApiSuitesByIntegrationId = useCallback(() => {
    return apiSuiteDefinitions?.filter(({ integration }) => integration.id === integrationId);
  }, [apiSuiteDefinitions, integrationId]);

  const documentationAuthRequest = useMutation({
    mutationFn: (payload: { email: string; url: string }) => {
      return documentationService.getDocumentationAuth(payload.email);
    },
    onSuccess: (data, initialValues) => {
      if (!!data && data?.authToken) {
        window.open(`${initialValues.url}?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getEndpoints = useCallback(
    (endPoints: Endpoints) => {
      if (!endPoints?.endpoints || endPoints?.endpoints?.length <= 0) return null;
      return (
        <Grid className="endpoints">
          {endPoints?.endpoints?.map(({ description, endpoint, documentationUrl }, index) => {
            return (
              <>
                <Grid.Col span={2} className="title">
                  {index === 0 && 'EndPoints'}
                </Grid.Col>
                <Grid.Col span={2} className="description">
                  {description}
                </Grid.Col>
                <Grid.Col span={6} className="endpoint">
                  {endPointReplacer(endpoint, endPoints.urlResolvers)}
                </Grid.Col>
                <Grid.Col span={1} className="icon">
                  <IconHelpCircle
                    size={18}
                    onClick={() => {
                      documentationAuthRequest.mutate({
                        email: userData.email,
                        url: documentationUrl,
                      });
                    }}
                  />
                </Grid.Col>
              </>
            );
          })}
        </Grid>
      );
    },
    // eslint-disable-next-line
    [apiSuiteDefinitions, integrationDefinitions],
  );

  const isApiKeyAlreadyCreated = (apiSuiteData: ApiSuiteDefinition) => {
    return apiKeys?.some((apiKey) => apiKey.apiSuiteId.id === apiSuiteData.id.id);
  };

  const getContent = useCallback(
    (apiSuiteData: ApiSuiteDefinition) => {
      return (
        <div className="api-suite-wrapper">
          <div className="header-section">
            <div className="header">{apiSuiteData?.name ?? ''}</div>
            <Button
              onClick={() => {
                if (apiKeys && apiKeys?.length > 0 && isApiKeyAlreadyCreated(apiSuiteData)) {
                  open();
                  dispatch(apiKeyActionCreator.changeGeneratingAPISuiteKeyId(apiSuiteData.id));
                  return;
                }
                handleGenerateCredentials(apiSuiteData.id);
              }}
            >
              Generate new credentials
            </Button>
          </div>
          <Grid className="base-url">
            <Grid.Col span={2} className="title">
              Base URL
            </Grid.Col>
            <Grid.Col span={8} className="description">
              {apiSuiteData.baseUrl}
            </Grid.Col>
            <Grid.Col span={1} className="icon">
              <CopyButton value={apiSuiteData.baseUrl} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                    <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                      {copied ? (
                        <IconCheck style={{ width: rem(16) }} />
                      ) : (
                        <IconCopy style={{ width: rem(16) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Grid.Col>
          </Grid>
          {getEndpoints(apiSuiteData?.endpoints)}
        </div>
      );
    },
    // eslint-disable-next-line
    [apiSuiteDefinitions, integrationId, apiKeys],
  );

  const handleGenerateCredentials = (apiSuiteId: ApiSuiteId) => {
    if (apiSuiteId.id.length > 0)
      generateApiKey.mutate({
        integration: { id: integrationId },
        organizationId: userData.organizationId,
        apiSuiteId: apiSuiteId,
        projectId: projectId,
      });
  };

  const getZeroContent = () => {
    return <div>Your suite configuration does not require any API access.</div>;
  };

  const apiSuiteIntegrations = getApiSuitesByIntegrationId();

  return (
    <Paper sx={integrationSectionStyles(theme)} shadow="xs">
      <LoadingOverlay
        visible={generateApiKey.isLoading || isLoadingApiKeys || documentationAuthRequest.isLoading}
      />
      <ConfirmationGenerateApiKey
        id={'confirmation-generate-api-key'}
        opened={opened}
        close={() => {
          dispatch(apiKeyActionCreator.changeGeneratingAPISuiteKeyId(null));
          close();
        }}
        handleConfirm={() => {
          generatingAPISuiteId && handleGenerateCredentials(generatingAPISuiteId);
        }}
      />
      <Group position="center">
        <Title
          order={3}
          className="integration-suite-title"
          data-cy={`integration-suite-${integrationId}`}
        >
          {getIntegrationData()?.name}
        </Title>
        <Divider orientation="vertical" />
        <div className="integration-suite-container">
          {apiSuiteIntegrations && apiSuiteIntegrations.length
            ? apiSuiteIntegrations.map((apiSuiteData) => getContent(apiSuiteData))
            : getZeroContent()}
        </div>
      </Group>
    </Paper>
  );
};

export default IntegrationSection;
