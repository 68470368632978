import { Box, Button, PasswordInput, Text, TextInput, useMantineTheme } from '@mantine/core';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useDisclosure } from '@mantine/hooks';
import { useFormik } from 'formik';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
// import { notifications } from '@mantine/notifications';
import { string as validateString, object as validateObject } from 'yup';

import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';
import { authenticationActionCreator, loaderActionCreator } from '../../../redux/actions';
import { NormalLoginFlow } from '../../../models/user';
import { userService } from '../../../service';

import WelcomeHeader from '../../../components/WelcomeHeader';
import LoginPagesLayout from '../../../components/LoginPagesLayout/LoginPagesLayout';
import SocialSignButton, {
  SocialType,
} from '../../../components/SocialLoginButton/SocialSignButton';

import { signSectionStyles, signTextBoxStyles } from '../../../components/LoginPagesLayout/styles';
import NavigationText from '../../../components/NavigationText';
import { useEffect, useState } from 'react';

const Login = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, { toggle }] = useDisclosure(false);
  const [loginError, setLoginError] = useState('');

  const { values, touched, errors, handleBlur, handleChange, isValid, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: () => {},
    validationSchema: validateObject().shape({
      email: validateString().trim().required('Email required').email('Must be valid email'),
      password: validateString().trim().required('Password required'),
    }),
    validateOnMount: true,
  });

  useEffect(() => {
    authenticationActionCreator.changeNormalLoginFlow(NormalLoginFlow.EMAIL_VERIFIED);
  }, []);

  const handleGoogleLogin = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      .then(console.log)
      .catch(console.log);
  };

  // const handleFacebookLogin = () => {
  //   Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
  //     .then(console.log)
  //     .catch(console.log);
  // };

  const { normalLoginFlowStep } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => {
      return state.authentication;
    },
  );

  const handleLogin = async () => {
    try {
      if (normalLoginFlowStep === NormalLoginFlow.INITIAL) {
        return dispatch(
          authenticationActionCreator.changeNormalLoginFlow(NormalLoginFlow.EMAIL_VERIFIED),
        );
      }
      dispatch(loaderActionCreator.changeLoaderStatus(true));
      const currentUser = await Auth.signIn(values.email, values.password);
      if (!currentUser) {
        navigate('/login');
        dispatch(loaderActionCreator.changeLoaderStatus(false));
      } else {
        const decoded: any = jwt_decode(
          currentUser.getSignInUserSession()?.getIdToken().getJwtToken(),
        );
        userService
          .getUserByEmail(decoded?.email)
          .then((res) => {
            if (res.organizationId === null) {
              dispatch(
                authenticationActionCreator.changeAuthData({
                  isAuthenticated: true,
                  userData: {
                    userId: res?.id,
                    organizationId: res?.organizationId ?? null,
                    email: decoded?.email,
                    hasActiveApi: res.hasActiveApi,
                    name: res.name ?? '',
                  },
                }),
              );
              navigate('/get-started');
              dispatch(loaderActionCreator.changeLoaderStatus(false));
              return;
            }
            dispatch(
              authenticationActionCreator.changeAuthData({
                isAuthenticated: true,
                userData: {
                  userId: res?.id,
                  organizationId: res?.organizationId,
                  email: decoded?.email,
                  hasActiveApi: res.hasActiveApi,
                  name: res.name ?? '',
                },
              }),
            );
            dispatch(loaderActionCreator.changeLoaderStatus(false));
            navigate('/');
          })
          .catch(() => {
            dispatch(loaderActionCreator.changeLoaderStatus(false));
            navigate('/organisation');
          });
      }
    } catch (error: any) {
      dispatch(loaderActionCreator.changeLoaderStatus(false));
      const errorMessage = error.message ?? 'Something went wrong';
      setLoginError(errorMessage);
      // notifications.show({
      //   title: errorMessage,
      //   message: '',
      //   color: 'red',
      // });
      navigate('/login');
    }
  };

  return (
    <LoginPagesLayout sx={signSectionStyles(theme)} id={'login-page'}>
      <Box className="signPage__container">
        <Box className="signPage__content">
          <WelcomeHeader />
          <Box className="signPage__emailSection">
            {loginError ? (
              <Text color={theme.colors.red[6]} fz={theme.fontSizes.sm}>
                {loginError}
              </Text>
            ) : null}
            <Box className="signPage__input">
              <TextInput
                variant="unstyled"
                placeholder="Email"
                size="md"
                value={values.email}
                name="email"
                onChange={(e) => {
                  setFieldValue('email', e.target.value.trim());
                  setLoginError('');
                }}
                onBlur={handleBlur}
                error={touched['email'] && errors['email']}
                data-cy="sign-in-email-field"
                sx={signTextBoxStyles(theme)}
              />
            </Box>

            {normalLoginFlowStep === NormalLoginFlow.EMAIL_VERIFIED && (
              <>
                <Box className="signPage__input">
                  <PasswordInput
                    variant="unstyled"
                    placeholder="Password"
                    size="md"
                    value={values.password}
                    name="password"
                    onChange={(e) => {
                      handleChange(e);
                      setLoginError('');
                    }}
                    onBlur={handleBlur}
                    error={touched['password'] && errors['password']}
                    visible={visible}
                    onVisibilityChange={toggle}
                    data-cy="sign-in-password-field"
                    sx={signTextBoxStyles(theme)}
                  />
                </Box>
                <div className="navigation-text-container">
                  <NavigationText
                    to="/forgot-password"
                    navigationText="Forgot password?"
                    align="end"
                  />
                </div>
              </>
            )}
            {normalLoginFlowStep === NormalLoginFlow.INITIAL && (
              <Button
                onClick={handleLogin}
                size="lg"
                disabled={errors['email'] ? true : false}
                data-cy="sign-in-verify-email-buttton"
                className="signPage__verifyButton"
              >
                Verify Email
              </Button>
            )}
            {normalLoginFlowStep === NormalLoginFlow.EMAIL_VERIFIED && (
              <>
                <Button
                  onClick={handleLogin}
                  size="lg"
                  disabled={!isValid}
                  data-cy="sign-in-submit-button"
                >
                  Login
                </Button>

                <Text className="signPage__orText" align="center">
                  or
                </Text>
                <Box className="signPage__socialButtons">
                  <SocialSignButton
                    socialType={SocialType.GOOGLE}
                    data-cy="sign-in-google-button"
                    text="Log In with Google"
                    onClick={handleGoogleLogin}
                  />
                  {/* <SocialSignButton
                    socialType={SocialType.FACEBOOK}
                    data-cy="sign-in-facebook-button"
                    text="Log In with Facebook"
                    onClick={handleFacebookLogin}
                  /> */}
                  <NavigationText
                    to={'/signup'}
                    questionText={`Didn’t have an account?`}
                    navigationText={`Sign Up`}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </LoginPagesLayout>
  );
};

export default Login;
