import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Flex,
  List,
  LoadingOverlay,
  Paper,
  Radio,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';

import PageHeader from '../../../components/PageHeader';
import PageLayout from '../../../components/PageLayout';
import CapabilityRow from './CapabilityRow';

import {
  authenticationActionCreator,
  integrationActionCreator,
  testFrameworkActionCreator,
} from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';

import {
  ActivateIntegrationPayload,
  Capability,
  DeliveryCapabilityIdType,
  IntegrationCapability,
  IntegrationCapabilityDefinition,
  IntegrationDefinition,
  IntegrationId,
  OrderCapabilityIdType,
  PosCapabilityIdType,
} from '../../../models';
import { testFrameworkService } from '../../../service';
import integratioService from '../../../service/integration.service';
import { createCapabilityPayload, createInitialData, validateFormData } from './schema/formUtils';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import {
  OrderNotificationEventsList,
  // needToTickCapabilities,
  preventTogglingAsChildCapabilities,
} from '../../../constants/capabilities';
import {
  modifierAvailabilityDisableHandle,
  orderNotificationEventDisableHandle,
  toggleChildrenConditionally,
  recipeModifierDisableHandle,
} from '../../../utils/capability';
import { capabilitiesStyles } from './style';

const Capabilities: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { integrationId, projectName, projectId } = useParams<{
    integrationId: string;
    projectName?: string;
    projectId?: string;
  }>();
  const dispatch = useDispatch();
  const [isListValid, setListValid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    if (!integrationId && !projectName) {
      navigate('/manage-projects');
    }
  }, []);

  const getIntegrationId = (): string => {
    return integrationId ? integrationId.toUpperCase() : '';
  };

  const getProjectName = (): string => {
    return projectName ? projectName : '';
  };

  const { integrationDefinitions, capability, activationFlowId } = useSelector<
    RootState,
    IntegrationState
  >((state: RootState) => {
    return state.integration;
  });

  const currentIntegrationID = integrationId!.toUpperCase();
  const currentIntegrationDefinition =
    integrationDefinitions?.find((integration) => integration.id.id === currentIntegrationID) ??
    ({} as IntegrationDefinition);
  const currentCapabilityValues =
    capability?.integrationCapabilities.find(
      (integration) =>
        integration.integrationId.id === currentIntegrationID && capability.projectId === projectId,
    ) ?? ({} as IntegrationCapability);

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const capabilityFormikInstance = useFormik({
    initialValues: createInitialData(currentCapabilityValues, currentIntegrationDefinition),
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    capabilityFormikInstance.setValues(
      createInitialData(currentCapabilityValues, currentIntegrationDefinition),
    );
    // eslint-disable-next-line
  }, [capability]);

  const getTestSuitesByIntegration = useMutation(
    (params: { integration: string; projectId: string }) => {
      return testFrameworkService.getTestSuitesByIntegration(params.integration, params.projectId);
    },
    {
      onSuccess: (data: any) => {
        dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
      },
      onError: (error: any) => {
        notifications.show({
          title: error.name ?? 'Something went wrong',
          message: error.message ?? 'Something went wrong',
          color: 'red',
        });
      },
    },
  );

  const activateIntegration = useMutation({
    mutationFn: (payload: ActivateIntegrationPayload) => {
      return integratioService.activateIntegration(payload);
    },
    onSuccess: (data) => {
      if (data && data.integration && data.integration.id) {
        getTestSuitesByIntegration.mutate({
          integration: data.integration.id,
          projectId: data.projectId,
        });
        dispatch(integrationActionCreator.changeUpdateActivatedIntegration(data));
        dispatch(
          authenticationActionCreator.changeAuthData({
            isAuthenticated: true,
            userData: {
              ...userData,
              hasActiveApi: true,
            },
          }),
        );
      } else {
        notifications.show({
          title: 'Activation failed',
          message: 'The integration data is not valid.',
          color: 'red',
        });
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingIntegrationCapability } = useQuery({
    enabled: !!projectId,
    queryKey: ['get-integration-capability', userData.organizationId],
    queryFn: () =>
      integratioService.getIntegrationCapability(userData.organizationId, projectId ?? ''),
    onSuccess: (data) => {
      if (data !== '') {
        dispatch(integrationActionCreator.changeCapability(data as Capability));
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const saveCapabilities = useMutation({
    mutationFn: (payload: Capability) => {
      const { organizationId, projectId, integrationCapabilities } = payload;
      return integratioService.createIntegrationCapability(
        userData.organizationId,
        getIntegrationId(),
        {
          organizationId,
          projectId: projectId ?? '', // Set projectId from the URL parameter
          integrationCapabilities,
        },
        getProjectName(),
      );
    },
    onSuccess: (data) => {
      if (integrationId && !activationFlowId && data.projectId) {
        activateIntegration.mutate({
          organizationId: userData.organizationId,
          integration: { id: integrationId.toUpperCase() } as IntegrationId,
          projectId: data.projectId,
        });
        getTestSuitesByIntegration.mutate({
          integration: currentIntegrationID.toUpperCase(),
          projectId: data.projectId,
        });
        navigate('/projects/' + data.projectId + '/dashboard');

        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const updateCapabilities = useMutation({
    mutationFn: (payload: Capability) => {
      return integratioService.updateIntegrationCapability(
        userData.organizationId,
        getIntegrationId(),
        {
          ...payload,
          projectId: projectId ?? '', // Set projectId from the URL parameter
        },
        projectId ?? '',
      );
    },
    onSuccess: (data) => {
      if (data.projectId) {
        navigate('/projects/' + data.projectId + '/dashboard');

        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const onClickSubmit = () => {
    if (projectId) {
      setShowConfirmationDialog(true);
    } else {
      submitCapabilities();
    }
  };

  const submitCapabilities = () => {
    saveCapabilities.mutate(
      createCapabilityPayload(
        capabilityFormikInstance.values,
        currentIntegrationID.toUpperCase(),
        capability ?? ({ organizationId: userData.organizationId } as Capability),
      ),
    );
  };

  const submitUpdateCapabilities = () => {
    updateCapabilities.mutate(
      createCapabilityPayload(
        capabilityFormikInstance.values,
        currentIntegrationID.toUpperCase(),
        capability ?? ({ organizationId: userData.organizationId } as Capability),
      ),
    );
  };

  const renderCapabilityRows = (
    items: IntegrationCapabilityDefinition[],
    parentPath = '',
    parentItem?: IntegrationCapabilityDefinition,
  ): JSX.Element[] => {
    return items?.map((item) => {
      const path = parentPath ? `${parentPath}.${item.id}` : item.id;
      const isChecked =
        capabilityFormikInstance.values[
          item.id as OrderCapabilityIdType | DeliveryCapabilityIdType | PosCapabilityIdType
        ] === 'true';
      const isDisabled = () => {
        if (item?.disabled) return true;
        if (parentItem?.disabled) return true;
        if (parentItem && capabilityFormikInstance.values[parentItem.id] === 'false') return true;

        if (OrderNotificationEventsList.includes(item.id as PosCapabilityIdType)) {
          return orderNotificationEventDisableHandle(
            capabilityFormikInstance.values,
            item.id as any,
            item.mandatory,
          );
        }
        if (item.id === PosCapabilityIdType.POS_MENU_MODIFIER_AVAILABILITY) {
          return modifierAvailabilityDisableHandle(capabilityFormikInstance.values, item.mandatory);
        } else if (
          item.id === PosCapabilityIdType.POS_RECIPE_IMAGE_SUP_MODIFIERS ||
          item.id === PosCapabilityIdType.POS_RECIPE_PRICE_UPDATES_MODIFIERS ||
          item.id === PosCapabilityIdType.POS_RECIPE_AVAILABILITY_MODIFIER
        ) {
          return recipeModifierDisableHandle(capabilityFormikInstance.values, item.mandatory);
        }
        return item.mandatory;
      };

      const toggleChildren = (
        e: React.ChangeEvent<HTMLInputElement>,
        capability: IntegrationCapabilityDefinition,
      ) => {
        if (capability.childCapabilities) {
          capability.childCapabilities.forEach((childCapability) => {
            toggleChildren(e, childCapability);
            capabilityFormikInstance.setFieldValue(
              childCapability.id,
              `${toggleChildrenConditionally(
                item,
                childCapability,
                capabilityFormikInstance.values,
                e?.target.checked,
              )}`,
            );
          });
        }
      };

      const checkParent = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!!parentPath && `${e?.target.checked}` === 'true') {
          parentPath.split('.').forEach((capabilityId) => {
            capabilityFormikInstance.setFieldValue(capabilityId, `${e?.target.checked}`);
          });
        }
      };

      const getLabel = (required: boolean, capability: string) => {
        return (
          <Flex direction={'row'} gap={'12px'}>
            {capability} {required && <Text className="asterix">*</Text>}
          </Flex>
        );
      };

      const getSelectedDefaultValue = (item: IntegrationCapabilityDefinition) => {
        let selectedValue = '';
        item.childCapabilities.forEach(({ id }) => {
          if (capabilityFormikInstance.values?.[id] === 'true') {
            selectedValue = id;
          }
        });
        return selectedValue;
      };

      const toggleOmittedCapability = (
        idsOfTheCapabilitiesShouldBeOmitted: Array<string>,
        checked: boolean,
      ) => {
        idsOfTheCapabilitiesShouldBeOmitted.forEach((id) => {
          if (preventTogglingAsChildCapabilities.includes(id as any)) return;

          if (!PosCapabilityIdType.POS_MENU && !PosCapabilityIdType.POS_RECIPE) {
            capabilityFormikInstance.setFieldValue(id, `${!checked}`);
          } else {
            capabilityFormikInstance.setFieldValue(id, 'false');
          }
          return;
        });
      };

      const omitRelatedCapabilitySameAsMainCapability = (id: string) => {
        return id === PosCapabilityIdType.POS_MENU_MODIFIERS;
      };

      const deselctParentWhenAllChildresDeselected = (id: string, isChecked: boolean) => {
        if (
          isChecked ||
          !parentItem ||
          !parentItem.childCapabilities ||
          parentItem.id === PosCapabilityIdType.POS_RECIPES
        )
          return;
        const allChildrenDeselected = Object.values(parentItem.childCapabilities).every(
          (child) => child.id === id || capabilityFormikInstance.values[child.id] === 'false',
        );

        if (allChildrenDeselected) {
          capabilityFormikInstance.setFieldValue(parentItem.id, 'false');
        }
      };
      return (
        <>
          <List withPadding={!!parentPath}>
            <List.Item icon={<></>}>
              <Flex gap={theme.spacing.xs} align={'center'}>
                <CapabilityRow
                  key={item.id}
                  id={item.id}
                  capability={item.capability}
                  disabled={isDisabled()}
                  checked={isChecked}
                  onChange={(e) => {
                    capabilityFormikInstance.setFieldValue(item.id, `${e?.target.checked}`);
                    item.idsOfTheCapabilitiesShouldBeOmitted &&
                      toggleOmittedCapability(
                        item.idsOfTheCapabilitiesShouldBeOmitted,
                        omitRelatedCapabilitySameAsMainCapability(item.id)
                          ? !e?.target.checked
                          : e?.target.checked,
                      );
                    toggleChildren(e, item);
                    checkParent(e);
                    deselctParentWhenAllChildresDeselected(item.id, e?.target.checked);
                  }}
                  description={item.description}
                  required={item.mandatory}
                />
              </Flex>
            </List.Item>

            {item.id === PosCapabilityIdType.POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT ? (
              <Radio.Group
                className="radio-group"
                onChange={(value) => {
                  item?.childCapabilities.forEach(({ id, idsOfTheCapabilitiesShouldBeOmitted }) => {
                    if (id === value) {
                      idsOfTheCapabilitiesShouldBeOmitted &&
                        toggleOmittedCapability(idsOfTheCapabilitiesShouldBeOmitted, true);
                      capabilityFormikInstance.setFieldValue(id, `true`);
                      return;
                    }
                    if (capabilityFormikInstance.values?.[id]) {
                      capabilityFormikInstance.setFieldValue(id, `false`);
                    }
                  });
                }}
                value={getSelectedDefaultValue(item)}
              >
                {item?.childCapabilities?.map((childCapability) => {
                  // If one item in the radio group is mandatory, disable others
                  const hasMandatoryItem = item?.childCapabilities?.some(
                    (radioChild) => !!radioChild?.mandatory,
                  );
                  return (
                    <Radio
                      id={childCapability.id}
                      className="radio-button"
                      value={childCapability.id}
                      checked={isChecked}
                      label={getLabel(childCapability.mandatory, childCapability.capability)}
                      disabled={hasMandatoryItem}
                    />
                  );
                })}
              </Radio.Group>
            ) : (
              renderCapabilityRows(item.childCapabilities, path, item)
            )}
          </List>
        </>
      );
    });
  };

  useEffect(() => {
    setListValid(
      validateFormData(capabilityFormikInstance.values, currentIntegrationDefinition.capabilities),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilityFormikInstance.values]);

  return (
    <PageLayout
      id={'manage-api-access-capabilities-page'}
      sx={capabilitiesStyles(theme)}
      headerSection={
        <PageHeader
          id={'manage-api-access-capabilities-page'}
          title={currentIntegrationDefinition?.name ?? ''}
          backText={'Back'}
          onClickBack={() => navigate('/manage-projects')}
        />
      }
    >
      <LoadingOverlay
        visible={
          activateIntegration.isLoading || projectId
            ? isLoadingIntegrationCapability
            : false || getTestSuitesByIntegration.isLoading || saveCapabilities.isLoading
        }
      />
      <Paper shadow="xs" className="capabilities-paper">
        <Text
          className="select-capabilities-description"
          color={theme.colors.grubtech[0]}
          size={theme.fontSizes.lg}
        >
          Select the functions that you support
        </Text>

        {renderCapabilityRows(currentIntegrationDefinition.capabilities)}

        <div className="capabilities-action-buttons">
          <Button
            className="cancel-capabilities-button"
            data-cy="save-capabilities-button"
            onClick={() =>
              capabilityFormikInstance.setValues(
                createInitialData(currentCapabilityValues, currentIntegrationDefinition),
              )
            }
            disabled={!isListValid}
          >
            Cancel
          </Button>
          <Button
            className="save-capabilities-button"
            data-cy="save-capabilities-button"
            onClick={onClickSubmit}
            disabled={!isListValid}
          >
            Save Changes
          </Button>
        </div>
      </Paper>
      <ConfirmationDialog
        opened={showConfirmationDialog}
        close={() => setShowConfirmationDialog(false)}
        id="retry-scenario-dialog"
        title="Are You Sure?"
        contentText="Applying these changes will affect the UAT, affected test scenarios will be reset by clearing previously recorded data. In order to complete the UAT, you will be required only to re-execute affected test scenarios and/or additional test scenarios that were added due to the suite configuration change."
        onClickCancel={() => {
          setShowConfirmationDialog(false);
        }}
        onClickConfirm={() => submitUpdateCapabilities()}
      />
    </PageLayout>
  );
};

export default Capabilities;
