import { NormalLoginFlow } from '../../models/user';
import { actionCreator } from '../common';
import { AuthenticationActionTypes } from '../types';

export const authenticationActionCreator = {
  changeAuthData: actionCreator<any>(AuthenticationActionTypes.CHANGE_AUTH_DATA),
  resetUserData: actionCreator<any>(AuthenticationActionTypes.RESET_USER_DATA),
  logoutHandle: actionCreator<any>(AuthenticationActionTypes.LOGOUT_HANDLE),
  changeNormalLoginFlow: actionCreator<NormalLoginFlow>(
    AuthenticationActionTypes.CHANGE_NORMAL_LOGIN_FLOW_STEP,
  ),
  changeIsFromDocumentationLogin: actionCreator<boolean>(
    AuthenticationActionTypes.TOGGLE_IS_FROM_DOCUMENTAION_LOGIN,
  ),
};
